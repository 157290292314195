import React, { Component } from 'react';
import axios from 'axios';
import uniqid from 'uniqid'
import Loader from '../components/loader/loader';
import HubsComponent from './productionHubs';
import PieComponent from './pieCharts/pieDevices';
import PieLogs from './pieCharts/pieLogs';
import BarChart from './barCharts/bar';
import ConnectedHubs from './connectedHubs';
import LoaderGif from '../assets/img/loader/loader.svg';
import Socket from "./logs/logSocket"
import { Modal, ModalHeader, Collapse, ModalBody, ModalFooter } from 'reactstrap';

import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
    FormFeedback,

} from 'reactstrap';

import { getAllUsers, getAllDevices, getAllDevicesCount, getAllSensors, getAllHubs, getFailedEvent, getLogs, getHubsStorage, getOrganizationsStorage, getConnectedDevices } from '../shared/services/production'
import { uniqueId } from 'lodash';
// import { Socket } from 'socket.io-client';

class Production extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: null,
            devicesLogs: null,
            devicesCount: null,
            sensors: null,
            devicess: null,
            hubs: null,
            failedCmd: null,
            WaterLogs: null,
            AgriLogs: null,
            HubLogs: null,
            AtmosphereLogs: null,
            PowerLogs: null,
            hubsStorage: null,
            organizationsStorage: null,
            connectedDevices: null,
            loadingConnectedDevices: true,
            data: [],
            orgModal: true,
            isOpen: [],
            dropdownOpen: false,
            array: [
                "devices",
                "Power",
                "Hub",
                "Agri",
                "Water",
                "Atmosphere",
                "Identifier"
            ]
        }


        this.handleClick = this.handleClick.bind(this);
        this.getHubStorage = this.getHubStorage.bind(this);
        this.getOrgStorage = this.getOrgStorage.bind(this);
        this.getConDevices = this.getConDevices.bind(this);
        this.toggleOrgModel = this.toggleOrgModel.bind(this);
    }

    toggleOrgModel() {
        if (!this.state.orgModal) {
            this.getConDevices();
        }
        this.setState({
            orgModal: !this.state.orgModal
        });
    }
    toggle = (index) => {
        // isOpen.map(open => //console.log(open, index))
        if (this.state.isOpen.find(open => open === index)) {
            this.setState({ isOpen: this.state.isOpen.filter(open => open !== index) })
        } else {
            this.state.isOpen.push(index)
            this.setState({ isOpen: this.state.isOpen })
            this.getLogsFunc(this.state.array[index - 1]);
        }
    };
    async getConDevices() {
        this.setState({ loadingConnectedDevices: true })
        let connectedDevices = await getConnectedDevices();

        this.setState({ connectedDevices, loadingConnectedDevices: false })
    }
    async getOrgStorage() {
        let organizationsStorage = await getOrganizationsStorage();

        this.setState({ organizationsStorage })
    }
    async getHubStorage() {
        let hubsStorage = await getHubsStorage();
        this.setState({ hubsStorage })
    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    async componentDidMount() {
        this.getLogsFunc()
        this.getConDevices();

        let users = await getAllUsers();

        // Api calls for operation dashboard

        let sensors = await getAllSensors();
        // let failedCmd = await getFailedEvent();
        let hubs = await getAllHubs();
        let devicess = await getAllDevicesCount()
        console.log(devicess)
        // let devicesCount = 0
        // devicess.faulty.map(d => {
        //     devicesCount += d.devices.length
        // })
        // devicess.working.map(d => {
        //     devicesCount += d.devices.length
        // })
        ////console.log("nauman2",atmosphere.Atmosphere_Logs.lenght)
        // let failedCmdCount = 0;
        // failedCmd.map(f => {
        //     failedCmdCount += f.events.length;
        // })

        this.setState({
            users, sensors, hubs, devicess,
            data: [{ title: 'Devices', value: devicess }, { title: 'Data Points', value: sensors.length }, { title: 'Users', value: users.length }]
        })

        this.getOrgStorage();
        this.getHubStorage();
    }

    getLogsFunc = async (name) => {
        //console.log(name)
        let data = [],
            stateData = this.state.data
        if (name === "devices") {
            let response = await getAllDevices()
            response["hubType"] = "Device Status"
            data[name] = response;
            let devicesCount = 0;
            data[name].faulty.map(d => {
                devicesCount += d.devices.length
            })
            data[name].working.map(d => {
                devicesCount += d.devices.length
            })
            data[name].offDevices = [this.state.devicess - devicesCount]
            console.log(data[name])
            // if (!stateData.find(data1 => data1.title === "Devices")) {
            //     stateData.unshift({ title: 'Devices', value: devicesCount })
            // }
            // else {
            //     stateData.map(data1 => {
            //         if (data1.title === "Devices") {
            //             data1.value = devicesCount


            //         }
            //     })
            // }


            this.setState({
                [name + "Logs"]: data[name],
                data: this.state.data,
            })

        }

        // else {
        //     data[name] = await getLogs(data[name]);
        //     console.log(name)
        //     this.setState({
        //         [name + "Logs"]: data[name]
        //     })
        // }
        // let waterLogs = await getLogs('Water');
        // let agriLogs = await getLogs('Agri');
        // let powerLogs = await getLogs('Power');
        // let hubLogs = await getLogs('Hub');
        // let atmosphere = await getLogs('Atmosphere');
    }
    handleClick(d) {
        if (d.title === 'Failed Events') {
            this.props.history.push({ pathname: '/operation/failedevents', events: this.state.failedCmd })
        }
    }
    render() {
        //console.log('render', this.state.isOpen)
        return (
            <div className="animated fadeIn">
                <div className="card-header gradient-primary3 row justify-content-between" style={{ borderRight: '2px solid white' }}>
                    <h2 className="text-light">
                        Operations
                    </h2>
                    <div onClick={this.toggleOrgModel} style={{ marginTop: '8px' }}>
                        <div className="h5 mb-0 text-light mt-0">Alerts</div>
                    </div>

                </div>
                {/* <div className="row"> */}
                {(this.state.data.length > 0) ?
                    <React.Fragment>
                        <div className="gradient-primary3 row" style={{ marginBottom: '20px' }}>
                            {this.state.data.map(d => (
                                ((d === this.state.data.length - 1) ?
                                    <div key={uniqid()} className='col-4' style={{ fontSize: '24px', textAlign: 'center', color: 'white' }} onClick={() => this.handleClick(d)}>

                                        <p style={{ margin: '5px 0px' }}>{d.value}</p>
                                        <p style={{ fontSize: '16px', margin: '5px 0px' }}>{d.title}</p>
                                    </div>
                                    :
                                    <div key={uniqid()} className='col-4' style={{ fontSize: '24px', textAlign: 'center', color: 'white', borderRight: '2px solid' }} onClick={() => this.handleClick(d)}>
                                        <p style={{ margin: '5px 0px' }}>{d.value}</p>
                                        <p style={{ fontSize: '16px', margin: '5px 0px' }}>{d.title}</p>
                                    </div>
                                )))
                            }
                        </div>
                        <div className="row justify-content-center" style={{ marginBottom: '20px', paddingTop: '20px' }}>
                            {this.state.array.map((arr, key) =>
                                <div key={key} className="col-sx-12 col-sm-12 col-md-3 col-lg-2 col-xl-4">
                                    {/* <button onClick={() => { this.getLogsFunc('Atmosphere') }}><i class="fas fa-redo"></i></button> */}
                                    <div class="card rounded-1 dropshadow" style={{ border: 0 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>{arr}</div>
                                            <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                                                {(!this.state.isOpen) ?
                                                    <i class="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => { this.toggle.bind(this, key + 1) }}></i> :
                                                    <i class="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={this.toggle.bind(this, key + 1)}></i>
                                                }
                                            </div>
                                        </div>
                                        {/* {(this.state.isOpen) ? */}
                                        <Collapse isOpen={this.state.isOpen.find(openedIndex => openedIndex === (key + 1))} id="collapseExample">

                                            {this.state[arr + 'Logs'] ?
                                                <PieLogs logs={this.state[arr + 'Logs']} />
                                                : <img src={LoaderGif} className="login-loader" />
                                            }

                                        </Collapse>
                                        {/* : null
                                        } */}

                                    </div>

                                </div>
                            )}
                        </div>
                        <div className="row" style={{ marginBottom: '20px' }}>
                            <h2 className="card-header gradient-primary3 text-light w-100 mb-2 mx-3">
                                Failed Logs
                            </h2>
                            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <Socket />
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: '20px', paddingTop: '20px' }}>
                            <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                {(this.state.hubsStorage) ?
                                    <BarChart storageData={this.state.hubsStorage} type='Hubs' />
                                    :
                                    null
                                }
                            </div>
                            <div className="col-sx-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                {(this.state.organizationsStorage) ?
                                    <BarChart storageData={this.state.organizationsStorage} type='Organizations' />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <Loader />
                }
                <div className="row" style={{ marginBottom: '20px' }}>
                    <h2 className="card-header gradient-primary3 text-light w-100 mb-2 mx-3">
                        Hubs
                    </h2>
                    {(this.state.hubs) ?
                        <div className='col-12' style={{ marginTop: 20 }}>
                            <HubsComponent hubs={this.state.hubs} />
                        </div>
                        :
                        null
                    }
                </div>
                {/* </div > */}
            </div >
        );
    }
}

export default Production;