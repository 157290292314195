import React, { Component } from 'react';
import Popup from "reactjs-popup";
import axios from 'axios';
import { connect } from 'react-redux'
import $ from 'jquery';
import uniqid from 'uniqid'

import { Button, CardBody, Table, Row, Col, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { getHubDevices } from '../shared/services/production';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import ProductionHubsDevices from './productionHubsDevices';
import LoaderGif from '../assets/img/loader/loader.svg';
import LineConnectionComponent from '../components/graphs/general/lineConnection'
import ConfirmPassword from "../components/popup/confirmPassword"
import ModalOverview from "../components/popup/Modal"

import * as functions from '../functions/functions';
import { isThisISOWeek } from 'date-fns';
let selectedHub = null

class productionHubsLogs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            dropdownOpen: false,
            timeStamp: 'N/A',
            selectedHubDevices: null,
            value: null,
            password: '',
            ota: false,
            data: '',
            wrong: false,
            popup: true,
            _id: null,
            popup: false,
            verif_val: 0,
            u_role: props.u_role !== "USER" && props.u_role !== "VIEWER" ? true : false,
            viewer: props.u_role === "VIEWER" ? false : true,
            graphStatus: false,
            eventValue: 1,
            eventDate: null
        }
        selectedHub = this.props.selectedHub
        selectedHub = { ...selectedHub, ...{ createdBy: this.props.userInfo.varification._id } }
    }
    componentWillMount() {
        if (this.props.selectedHub.message !== "Not Connected") {
            let timeStamp = functions.getTime(this.props.selectedHub.timeStamp) + ' ' + functions.getDate(this.props.selectedHub.timeStamp)
            this.setState({ timeStamp })
        }
        // console.log("this.props", this.props)
    }

    async componentDidMount() {
        let height = $("#" + this.props.selectedHub.hubId + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.selectedHub.hubId + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.selectedHub.hubId + "-card").height();
            height = (height / 2);
            $("#" + this.props.selectedHub.hubId + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    toggle = async () => {
        this.setState({ collapse: !this.state.collapse });

        let selectedHubDevices = await getHubDevices(this.props.selectedHub.hubType, this.props.selectedHub.hubId);
        this.setState({
            selectedHubDevices
        })

    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    addvalues = (value, eventDate) => {
        this.setState({
            value,
            timeStamp: eventDate
        })
    }
    handle = (e) => {
        const newdata = { ...this.state.data }
        if (e.target.name === "esp2") {
            newdata[e.target.name] = e.target.checked
        } else {

            newdata[e.target.name] = e.target.value
        }

        this.state.data = newdata;
        this.setState({ data: this.state.data })
        console.log(this.state.data)
    }
    changePopup = (value) => {
        this.setState({ popup: !this.state.popup, verif_val: value })
        this.hideUsPopup()
    }
    hideUsPopup = () => {
        this.state.ota = false
        this.setState({ ota: this.state.ota })
    }
    otaPopup = () => {


        return (
            <>
                {/* <ToastPopup ref={child} /> */}
                <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                    <div onClick={() => this.hideUsPopup()} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                    </div>
                    <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', width: "50%", margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, maxHeight: "80vh" }}>
                        <div className="card" style={{ border: '0px' }}>
                            <a className="close align-self-end rounded-circle" onClick={() => this.hideUsPopup()} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                                &times;
                            </a>
                            <h4 style={{ color: "black" }}>Over The Air Update(OTA)</h4>
                            <div style={{ overflow: "auto", maxHeight: "calc(100vh - 300px)" }}>
                                <div className="row m-0">
                                    <div style={{ width: "100%" }}>
                                        <Label style={{ color: "black", fontSize: "12px" }}>
                                            {console.log(this.state.data)}
                                            URL {!this.state.data.url ? <small>(Required)</small> : null}
                                        </Label>
                                        <Input className='w-100' onChange={(e) => this.handle(e)} name="url" placeholder="OTA URL" />
                                        {this.state.requiredBatch ? <span style={{ color: "red" }}> Required</span> : null}
                                    </div>
                                    <div >
                                        <Label style={{ color: "black", fontSize: "15px" }}>
                                            <input style={{ marginTop: "15px" }} onChange={(e) => this.handle(e)} type="checkbox" name="esp2" placeholder="" />
                                            {" "}ESP2
                                        </Label>
                                    </div>
                                    {/* <div className="col-md-6 col-12">
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}>
                                        MAC
                                    </Label>
                                    <Input style={{ width: "100%" }} onChange={(e) => handle(e)} type="text" value={data._id} name="_id" placeholder="minimum 11 digits" />
                                </FormGroup>
                            </div> */}


                                </div>



                            </div>






                        </div>
                        <div className="row m-0 px-3 justify-content-end">
                            {/* {loader == false ? */}
                            {this.state.data.url ?
                                <Button color="success"
                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }} onClick={() => this.changePopup(4, this.state.data)}  >
                                    Submit</Button> : <Button color="success" disabled
                                        style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }} onClick={() => this.changePopup(4, this.state.data)} >
                                    Submit</Button>}
                            {/* : <img
                                        src={LoaderGif}
                                        className="w-100 m-1"
                                        style={{ height: "34.8px" }}
                                    />} */}
                        </div>
                    </div>
                </div >
            </>
        );

    }


    toggleRole = () => {
        this.setState({ check_role: !this.state.check_role })
    }

    getConnections = (value, date) => {
        this.setState({ eventValue: value, graphStatus: true, eventDate: date })
    }

    render() {

        return (

            <div className="card" style={{ borderColor: '#31A2AC' }} id={this.props.selectedHub.hubId + "-card"}>
                {this.state.ota ? this.otaPopup() : null}
                <ModalOverview check_role={this.state.check_role} toggleRole={this.toggleRole} />
                {this.state.popup ? this.state.viewer ? <ConfirmPassword popup={this.changePopup} device={null} currentHub={null} data={this.state.data} verif_val={this.state.verif_val} selectedHub={selectedHub} />
                    : this.setState({ check_role: !this.state.check_role, popup: !this.state.popup }) : null}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>
                        <div>{this.props.selectedHub.location}</div>
                        <small>{this.props.selectedHub.hubId}</small>
                        {this.state.graphStatus ?
                            <small style={{ display: 'block' }}>{this.state.eventValue ? <span>Connected</span> : <span>Not Connected</span>}</small>
                            :
                            <small style={{ display: 'block' }}>{this.props.selectedHub.message ? <span>Connected</span> : <span>Not Connected</span>}</small>
                        }
                        {this.state.graphStatus ?
                            <small style={{ display: 'block' }}><b>Last Updated: </b>{this.state.timeStamp}</small>
                            :
                            <small style={{ display: 'block' }}><b>Last Updated: </b>{this.state.timeStamp}</small>
                        }
                    </div>
                    {"operations" in this.props.userInfo.varification ?
                        this.props.userInfo.varification.operations === "ADMIN" ?
                            <div className=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto' }}>
                                {this.state.graphStatus ?
                                    <span className={this.state.eventValue ? "wifi-connection my-wifi on float-right mt-1 p-2" : "wifi-connection my-wifi off float-right mt-1 p-2"} style={{ position: 'absolute', top: '5px', right: '20px' }}></span>
                                    :
                                    <span className={this.props.selectedHub.message ? "wifi-connection my-wifi on float-right mt-1 p-2" : "wifi-connection my-wifi off float-right mt-1 p-2"} style={{ position: 'absolute', top: '5px', right: '20px' }}></span>
                                }
                                <button type="button" className="btn btn-light" onClick={() => this.changePopup(0, null)} style={{ position: 'absolute', top: '5px', right: '315px', }}> Restart </button>
                                <button type="button" className="btn btn-light" onClick={() => this.changePopup(2, null)} style={{ position: 'absolute', top: '5px', right: '221px', }}> Reset 1 </button>
                                <button type="button" className="btn btn-light" onClick={() => this.changePopup(3, null)} style={{ position: 'absolute', top: '5px', right: '127px', }}> Reset 2 </button>
                                <button type="button" className="btn btn-light" onClick={() => this.setState({ ota: true, data: { url: '' } })} style={{ position: 'absolute', top: '5px', right: '55px', }}> OTA </button>
                            </div> :
                            <div className=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto' }}>
                                {this.state.graphStatus ?
                                    <span className={this.state.eventValue ? "wifi-connection my-wifi on float-right mt-1 p-2" : "wifi-connection my-wifi off float-right mt-1 p-2"} style={{ position: 'absolute', top: '5px', right: '20px' }}></span>
                                    :
                                    <span className={this.props.selectedHub.message ? "wifi-connection my-wifi on float-right mt-1 p-2" : "wifi-connection my-wifi off float-right mt-1 p-2"} style={{ position: 'absolute', top: '5px', right: '20px' }}></span>
                                }
                            </div> : null}
                    <div className=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i className="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i className="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            <div className="row">
                                {this.state.selectedHubDevices ?
                                    (<div className='col-sx-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                        <LineConnectionComponent hub={this.props.selectedHub} onaddvalue={this.addvalues} getConnections={this.getConnections} />
                                    </div>)
                                    : null
                                }
                                {(this.state.selectedHubDevices) ?
                                    // console.log(this.state.selectedHubDevices)
                                    (this.state.selectedHubDevices.map(d => (
                                        <div key={uniqid()} className='col-sx-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                            <ProductionHubsDevices device={d} />
                                        </div>
                                    )))
                                    :
                                    <img src={LoaderGif} className="login-loader" />
                                }
                            </div>
                        </CardBody>
                    </Collapse>
                    : null
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps, null)(productionHubsLogs);
