import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { dataHandler, toTitles } from '../../functions/functions'
import { connect } from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import { Input } from 'reactstrap';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from '../../components/loader/loader'
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
import LoaderGif from "../../assets/img/loader/loader.svg";
import ConfirmPopup from '../../components/popup/confirmPopup'
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import AddWinglePopup from './AddWinglePopup';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import cookies from '../../shared/utilities/cookies';
// import { data } from 'jquery';

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];



const Wingles = (props) => {


    const reload = () => {
        getUsers()
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });

    useEffect(() => {
        getUsers()
    }, [])

    const updateUser = async (wingleId, state) => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let result = await axios.put("/wingles/imei/" + wingleId, state)
        // reload()
        let temp = dataHandler(result.data)
        // return handleChangeInput(null)
        reload()
        return temp;
    }
    const handle2 = (e) => {
        if (e) {
            if (e.target.value) {
                put.body[e.target.name] = e.target.value
            }
        }
        return;
    }
    const [UsPopup, setUsPopup] = useState(false)
    const hideUsPopup = (check) => {
        setUsPopup(false)
        reload()
    }

    const [wingle, setWingle] = useState([])
    const [uspopup, setUspopup] = useState(false)
    const [wingleId, setWingleId] = useState()
    const [loader, setLoader] = useState(false)
    const [toggleConfirm, setToggleConfirm] = useState(false)
    const [searchWingles, setSearchwingles] = useState('')
    let operatorArray = [
        "Jazz",
        "Telenor",
        "Zong",
        "Ufone",
        "Warid"
    ]
    let put = null;
    const getUsers = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/wingles')
        let temp = dataHandler(response.data)
        // setUser(user.data)
        setWingle(temp.reverse())

        return;
    }
    // const levelId = async (_id) => {
    //     let levelArray = [];
    //     const response = await fetch('https://linked-things-s4-organizations.eu-de.mybluemix.net/api/v1/levels/' + _id + '/hierarchy')
    //     let temp = await response.json()
    //     getLevelIDs(temp)


    // }
    const deleteWingles = async (imei) => {
        setToggleConfirm(true)
        setWingleId(imei);
        return;
    }
    const confirmDelete = async (imei) => {
        setLoader(true)
        if (imei) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.delete("/wingles/imei/" + wingleId)
                    .then((res) => {
                        let temp = dataHandler(res.data)
                        setLoader(false)
                        alert("Successfully delete", wingleId)
                        reload()
                        setToggleConfirm(false)
                        setWingleId(null)
                        return temp;

                    })

            } catch (error) {
                alert(error)
                setToggleConfirm(false)
                setWingleId(null)
                return;
            }
        }
        setToggleConfirm(false)
        return;




    }
    const update = async (put) => {
        setLoader(true)

        if (put) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.put("/wingles/imei/" + put.imei, put.body)
                    .then((res) => {
                        let temp = dataHandler(res.data)
                        alert("Successfully Updated")
                        reload()
                        setLoader(false)
                        setUspopup(null)
                        return temp;
                    })
            } catch (error) {
                alert(error.res.data.message)
                setUspopup(null)
                setLoader(false)
                return
            }
        }

    }
    let filterWingles = []
    if (wingle !== 0) {
        filterWingles = wingle.filter(item => {
            return searchWingles !== "" ? item.espmac.toLowerCase().includes(searchWingles.toLowerCase()) || item.location.toLowerCase().includes(searchWingles.toLowerCase()) || item.imei.toLowerCase().includes(searchWingles.toLowerCase()) || item.simmsisdn.toLowerCase().includes(searchWingles.toLowerCase()) : item;
        })
    }

    let Popup = (data, imei1, name) => {
        let tempdata = data
        let imei2 = imei1
        put = { imei: imei2, body: { [name]: data } };
        setUspopup(

            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => setUspopup(null)} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{
                    background: 'rgb(255, 255, 255)', width: "80%", boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, position: "absolute",
                    top: "calc(50% + 25px)",
                    left: "calc(50% + 25px)",
                    transform: "translate(-50%, -50%)"
                }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => setUspopup(null)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        {
                            "operator" in put.body ?
                                <div>
                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                    <p>{imei1}</p>
                                    <Autocomplete filterOptions={filterOptions}

                                        options={operatorArray}
                                        getOptionLabel={(option) => option}

                                        defaultValue={tempdata}
                                        style={{ width: "100%" }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={"Operators"}
                                                onSelect={e => handle2(e)}
                                                variant="filled"
                                                name={name} />
                                        }


                                    />
                                </div>
                                :
                                <div>
                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                    <p>{imei1}</p>
                                    <Input defaultValue={tempdata} name={name} className="col-12 overflow-auto" onChange={(e) => handle2(e)} style={{ maxHeight: "65vh" }}></Input>
                                </div>
                        }
                        <div className="row m-0 px-3 justify-content-end">
                            {loader === false ?
                                <Button color="success" onClick={() => update(put)}
                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                    Update</Button>
                                : <img
                                    src={LoaderGif}
                                    className="w-100 m-1"
                                    style={{ height: "34.8px" }}
                                />}
                        </div>
                        {/* </Form> */}

                    </div>
                </div>
            </div >

        )



    }
    if ("operations" in props.userInfo.varification) {
        if (props.userInfo.varification.operations === "ADMIN") {
            return (

                <div>
                    {/* <AddUser reload={reload} /> */}
                    {/* {toggleConfirm ?
                <ConfirmPopup returnConfirm={confirmDelete} />
                : null} */}

                    {wingle == 0 ?
                        <Loader />
                        :

                        <div>
                            <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                                Wingles
                                <button onClick={() => { setUsPopup(true) }} className="btn btn-light"><i className="fa fa-user-plus"></i> Add Wingle</button>
                                {UsPopup ?
                                    <AddWinglePopup hideUsPopup={hideUsPopup} />
                                    : null}
                            </h2>
                            {toggleConfirm ?
                                <ConfirmPopup id={wingleId} returnConfirm={confirmDelete} />
                                : null}
                            <TextField
                                className="m-0"
                                id="filled-full-width"
                                label="Wingles"
                                style={{ margin: 8 }}
                                placeholder="Search Wingles"
                                fullWidth
                                margin="normal"
                                onChange={(e) => { setSearchwingles(e.target.value); }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                            />
                            {/* <div>
                        <div className="container">
                            <table>
                                <th>
                                    <td>Email</td>
                                    <td>Rigestered</td>
                                    <td>Logo</td>
                                    <td>Organizations</td>
                                    <td>Email</td>
                                </th>
</table>
                        </div>
                    </div> */}
                            <div style={{ overflow: "scroll" }}>
                                <div>
                                    <Paper >
                                        {/* <TableHead> */}
                                        {/* <TableCell>
                                            <div className="row m-0">
                                                <div className="col-1"><b>Espmac</b></div>
                                                <div className="col-2"><b>Location</b></div>
                                                <div className="col-1"><b>Operator</b></div>
                                                <div className="col-2"><b>IMEI </b></div>
                                                <div className="col-1"><b>Simmsisdn</b></div>
                                                <div className="col-2"><b>Imsi</b></div>
                                                <div className="col-2"><b>Activate</b></div>
                                                <div className="col-1"><b>Delete</b></div>
                                            </div>
                                        </TableCell>
                                    </TableHead> */}
                                        <div
                                            className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                                        >
                                            {/* <div className="Index"></div> */}
                                            <div className="row w-100 p-2 m-0">
                                                <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                    <b>Espmac</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Location</b>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                    <b>Operator</b>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                    <b>IMEI</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Simmsisdn</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Imsi</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Activate</b>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                    <b>Delete</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row m-0 rounded-brl-1 "
                                            style={{ maxHeight: "400px", minHeight: "100px", overflowY: "auto" }}
                                        >
                                            {filterWingles.map((wingles, key) => {

                                                return (
                                                    <>
                                                        <div className="row w-100 p-2 m-0 listView" key={key}>
                                                            <div className="col-lg-1 col-md-6 col-12 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Espmac</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.espmac} onClick={() => Popup(wingles.espmac, wingles.imei, 'espmac')} >    {wingles.espmac ? wingles.espmac : "Not Available"}</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-6 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Location</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.location} title={wingles.location} onClick={() => Popup(wingles.location, wingles.imei, 'location')} >{wingles.location ? wingles.location : "Not Available"}</Button>
                                                            </div>
                                                            {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                            <div className="col-lg-1 col-md-6 col-6 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Operator</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.operator} title={wingles.operator} onClick={() => Popup(wingles.operator, wingles.imei, 'operator')} >{wingles.operator ? wingles.operator : "Not Available"}</Button>
                                                            </div>
                                                            <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                                <b className="d-lg-none d-inline">Imei</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <div title={wingles.imei} className="text-truncate">
                                                                    {wingles.imei}

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                                <b className="d-lg-none d-inline">Simmsisdin</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button className='text-truncate' title={wingles.simmsisdn} title={wingles.simmsisdn} onClick={() => Popup(wingles.simmsisdn, wingles.imei, 'simmsisdn')} >{wingles.simmsisdn}</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-6 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Imsi</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.imsi} title={wingles.imsi} onClick={() => Popup(wingles.imsi, wingles.imei, 'imsi')} >
                                                                    {wingles.imsi ? wingles.imsi : "Not Available"}</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-12 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Activate</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <div className="live_hisbtn" style={{ width: '100px' }}>
                                                                    <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                        <div onClick={() => updateUser(wingles.imei, { activate: true })} className={wingles.activate ? "togglebtn selectbtn" : "togglebtn"}> True </div>
                                                                        <div onClick={() => updateUser(wingles.imei, { activate: false })} className={!wingles.activate ? "togglebtn selectbtn" : "togglebtn"}> False</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1 col-md-6 col-6 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Delete</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button onClick={() => deleteWingles(wingles.imei)} className="btn btn-danger rounded-circle-pxl "><i class="fas fa-minus-circle"></i></Button>
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })}



                                        </div>
                                    </Paper>
                                </div>
                            </div>


                        </div >
                    }
                    {uspopup}
                </div >
            )
        } else {
            return (

                <div>
                    {/* <AddUser reload={reload} /> */}
                    {/* {toggleConfirm ?
                <ConfirmPopup returnConfirm={confirmDelete} />
                : null} */}

                    {wingle == 0 ?
                        <Loader />
                        :

                        <div>
                            <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                                Wingles
                            </h2>
                            {toggleConfirm ?
                                <ConfirmPopup returnConfirm={confirmDelete} />
                                : null}
                            <TextField
                                className="m-0"
                                id="filled-full-width"
                                label="Wingles"
                                style={{ margin: 8 }}
                                placeholder="Search Wingles"
                                fullWidth
                                margin="normal"
                                onChange={(e) => { setSearchwingles(e.target.value); }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                            />
                            {/* <div>
                        <div className="container">
                            <table>
                                <th>
                                    <td>Email</td>
                                    <td>Rigestered</td>
                                    <td>Logo</td>
                                    <td>Organizations</td>
                                    <td>Email</td>
                                </th>
</table>
                        </div>
                    </div> */}
                            <div style={{ overflow: "scroll" }}>
                                <div>
                                    <Paper >
                                        {/* <TableHead> */}
                                        {/* <TableCell>
                                            <div className="row m-0">
                                                <div className="col-1"><b>Espmac</b></div>
                                                <div className="col-2"><b>Location</b></div>
                                                <div className="col-1"><b>Operator</b></div>
                                                <div className="col-2"><b>IMEI </b></div>
                                                <div className="col-1"><b>Simmsisdn</b></div>
                                                <div className="col-2"><b>Imsi</b></div>
                                                <div className="col-2"><b>Activate</b></div>
                                                <div className="col-1"><b>Delete</b></div>
                                            </div>
                                        </TableCell>
                                    </TableHead> */}
                                        <div
                                            className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                                        >
                                            {/* <div className="Index"></div> */}
                                            <div className="row w-100 p-2 m-0">
                                                <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                    <b>Espmac</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Location</b>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                    <b>Operator</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>IMEI</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Simmsisdn</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Imsi</b>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                    <b>Activate</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row m-0 rounded-brl-1 "
                                            style={{ maxHeight: "400px", minHeight: "100px", overflowY: "auto" }}
                                        >
                                            {filterWingles.map((wingles, key) => {

                                                return (
                                                    <>
                                                        <div className="row w-100 p-2 m-0 listView" key={key}>
                                                            <div className="col-lg-1 col-md-6 col-12 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Espmac</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.espmac} disabled >    {wingles.espmac ? wingles.espmac : "Not Available"}</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-6 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Location</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.location} disabled >{wingles.location ? wingles.location : "Not Available"}</Button>
                                                            </div>
                                                            {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                            <div className="col-lg-1 col-md-6 col-6 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Operator</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.operator} disabled>{wingles.operator ? wingles.operator : "Not Available"}</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                                <b className="d-lg-none d-inline">Imei</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <div title={wingles.imei} className="text-truncate">
                                                                    {wingles.imei}

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                                <b className="d-lg-none d-inline">Simmsisdin</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button className='text-truncate' title={wingles.simmsisdn} disabled >{wingles.simmsisdn}</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-6 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Imsi</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <Button title={wingles.imsi} title={wingles.imsi} disabled >
                                                                    {wingles.imsi ? wingles.imsi : "Not Available"}</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 col-12 Date align-self-center text-truncate">
                                                                <b className="d-lg-none d-inline">Activate</b>
                                                                <br className="d-inline d-lg-none d-md-none" />
                                                                <div className="live_hisbtn" style={{ width: '100px' }}>
                                                                    <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                        <div className={wingles.activate ? "togglebtn selectbtn" : "togglebtn"}> True </div>
                                                                        <div className={!wingles.activate ? "togglebtn selectbtn" : "togglebtn"}> False</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })}



                                        </div>
                                    </Paper>
                                </div>
                            </div>


                        </div >
                    }
                </div >
            )
        }
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps, null)(Wingles);

