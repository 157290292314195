import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';


import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';

import DeviceGraphs from './deviceGraphs/deviceGraphs';

import LoaderGif from '../assets/img/loader/loader.svg';

import * as functions from '../functions/functions';

class productionHubsDevices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updated: 'N/A',
            updatedMotor: 'N/A',
            collapse: false,
            dropdownOpen: false,
        }
    }
    componentWilllMount() {
        if (this.props.device.deviceEvent) {
            this.props.device.deviceEvent.map(d => {
                if (d.type === "Water_Motor") {
                    let updatedMotor = functions.getTime(d.created) + ' ' + functions.getDate(d.created)
                    this.setState({ updatedMotor })
                } else {
                    let updated = functions.getTime(d.created) + ' ' + functions.getDate(d.created)
                    this.setState({ updated })
                }
            })
        }
    }

    // componentDidMount() {
    //     // //console.log(this.props.selectedHub.hubId)

    //     let height = $("#" + this.props.selectedHub.hubId + '-card').height();
    //     height = (height / 2);
    //     // //console.log('Heightttttttttttttttttttttt:' + height)
    //     $("#" + this.props.selectedHub.hubId + "-arrow").css("padding-top", "" + height + "px");


    //     $(window).resize(() => {
    //         let height = $("#" + this.props.selectedHub.hubId + "-card").height();
    //         height = (height / 2);
    //         //console.log('Heightttttttttttttttttttttt:' + height)
    //         $("#" + this.props.selectedHub.hubId + "-arrow").css("padding-top", "" + height + "px");
    //     })
    // }

    toggle = async () => {
        this.setState({ collapse: !this.state.collapse });
    }
    // toggleDropdown = () => {
    //     this.setState({
    //         dropdownOpen: !this.state.dropdownOpen
    //     });
    // }

    render() {
        return (
            <div className="card" style={{ borderColor: '#31A2AC' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>
                        <div>{this.props.device.deviceId} ({this.props.device.device.name})</div>
                        {(this.props.device.deviceEvent) ?
                            (this.props.device.deviceEvent.map((d, key) => (
                                ((d.type === "Water_Motor") ?
                                    <div key={key} style={{ marginTop: '5px' }}>
                                        <small> <b>Motor Status: </b>{(d.value === 0) ? <React.Fragment>Off</React.Fragment> : <React.Fragment>On</React.Fragment>}</small>
                                        <small style={{ display: 'block' }}><b>Motor Last Updated: </b>{this.state.updatedMotor}</small>
                                    </div>
                                    :
                                    ((d.type === "Water_WaterTank_Status") ?
                                        <div key={key} style={{ marginTop: '5px' }}>
                                            <small> <b>Value: </b>{d.value}</small>
                                            <small style={{ display: 'block' }}> <b>Raw: </b>{d.raw}</small>
                                            {(d.reason) ?
                                                <small style={{ display: 'block' }}><b>Reason: </b>{d.reason}</small>
                                                :
                                                null
                                            }
                                            <small style={{ display: 'block' }}><b>Last Updated: </b>{this.state.updated}</small>
                                        </div>
                                        :
                                        ((d.type.split('_')[0] === "Power" || d.type.split('_')[1] === "PackMachine") ?
                                            <div key={key} style={{ marginTop: '5px' }}>
                                                {(d.voltage !== undefined) ?
                                                    <small style={{ display: 'block' }}><b>Voltage: </b>{d.voltage}</small>
                                                    :
                                                    null
                                                }
                                                {(d.current !== undefined) ?
                                                    <small style={{ display: 'block' }}><b>Current: </b>{d.current}</small>
                                                    :
                                                    null
                                                }
                                                {(d.power !== undefined) ?
                                                    <small style={{ display: 'block' }}><b>Power: </b>{d.power}</small>
                                                    :
                                                    null
                                                }
                                                {(d.energy !== undefined) ?
                                                    <small style={{ display: 'block' }}><b>Energy: </b>{d.energy}</small>
                                                    :
                                                    null
                                                }
                                                {(d.load !== undefined) ?
                                                    <small style={{ display: 'block' }}><b>Load: </b>{d.load}</small>
                                                    :
                                                    null
                                                }
                                                {(d.reason) ?
                                                    <small style={{ display: 'block' }}><b>Reason: </b>{d.reason}</small>
                                                    :
                                                    null
                                                }
                                                <small style={{ display: 'block' }}><b>Last Updated: </b>{this.state.updated}</small>
                                            </div>
                                            :
                                            <div key={key} style={{ marginTop: '5px' }}>
                                                <small> <b>Value: </b>{d.value}</small>
                                                {(d.reason) ?
                                                    <small style={{ display: 'block' }}><b>Reason: </b>{d.reason}</small>
                                                    :
                                                    null
                                                }
                                                <small style={{ display: 'block' }}><b>Last Updated: </b>{this.state.updated}</small>
                                            </div>
                                        )
                                    )
                                )
                            ))
                            )
                            :
                            null
                        }
                    </div>
                    <div className=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i className="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i className="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            <div className="row">
                                <div className='col-sx-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                    <DeviceGraphs device={{ device: this.props.device.device, hub: this.props.device.hub }} />
                                </div>
                            </div>
                        </CardBody>
                    </Collapse>
                    : null
                }

            </div >
        );
    }
}

export default productionHubsDevices;