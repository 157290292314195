import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as functions from '../../../functions/functions';
import $ from 'jquery';
import LoaderGif from '../../../assets/img/loader/loader.svg';
import ChartConfig from './chartConfig';
import { getEventsByDevice, getEventsByDeviceAndDate } from '../../../shared/services/events'

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';

import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
} from 'reactstrap';

let selectedStartDate = null;
let selectedEndDate = null;       // for selecting Start and End Date

class LineConnectionComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            download: true,
            unmount: false,
            width: 0,
            rand: 0.0,
            radioSelected: 1,
            renderVar: 20,
            overflow: 'scroll',
            graphDate: null,
            startDate: null,
            endDate: null,
            isMounted: false,
            shouldRedraw: false,
            eventValue: 0.0,
            limit: 10,
            eventDate: "0/0/0",
            isLoader: false,
            max: 0.0,
            min: 0.0,
            xlsxData: null,
            chartDetail: {
                id: this.props.hub.hubId + "_graph",
                axisX: "date",
                axisY: "value",
                scrollbar: true,
                series: [
                    {
                        type: "binary",
                        name: this.props.hub.hubType,
                        color: "#008df2",
                        x: "date",
                        y: "value",
                    },
                ],
            },
            chartData: [],
        }

        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
        this.getGraph = this.getGraph.bind(this);
        this.todayDate = this.todayDate.bind(this);

    }


    componentDidMount() {
        // $(window).resize(() => {
        //     if (this.state.radioSelected === 2) {
        //         this.state.unmount = true;
        //         var len = this.state.mainChart.datasets[0].data.length
        //         if (len === 0) {
        //             this.state.limit = 100;
        //             this.state.renderVar = 20;
        //         }
        //         else {
        //             var graphWidth = $("#graph-" + this.props.hub.hubId).width();

        //             var approxWidth = 20 * len
        //             if (approxWidth <= graphWidth) {
        //                 var renderVar = graphWidth / len;
        //                 this.state.renderVar = renderVar;
        //             }
        //             else {
        //                 this.state.renderVar = 20;
        //             }
        //             this.state.limit = len;
        //         }
        //         this.forceUpdate();

        //         this.state.unmount = false;
        //         this.forceUpdate();

        //     //     }
        // });
        this.state.isMounted = true

        this.state.startDate = this.todayDate('start');
        this.state.endDate = this.todayDate('end')

        if (this.state.isMounted) {
            this.getGraph("live");
        }



    }
    todayDate(type) {

        if (type === "start") {
            var currentdate = new Date();
            currentdate.setDate(currentdate.getDate());
            var currentYear = currentdate.getFullYear();
            var currentMonth = (currentdate.getMonth() + 1);
            if (currentMonth <= 9) {
                currentMonth = "0" + currentMonth;
            }
            var currentDate = currentdate.getDate();
            if (currentDate <= 9) {
                currentDate = "0" + currentDate;
            }
            var datetime = "" + currentYear + "-"
                + currentMonth + "-"
                + currentDate
                + "T00:00:00";


            return datetime

        }
        else {
            var currentdate = new Date();
            var currentYear = currentdate.getFullYear();
            var currentMonth = (currentdate.getMonth() + 1);
            var currentHours = currentdate.getHours();
            var currentMinutes = currentdate.getMinutes();
            var currentSeconds = currentdate.getMinutes();


            if (currentMonth <= 9) {
                currentMonth = "0" + currentMonth;
            }
            var currentDate = currentdate.getDate();
            if (currentDate <= 9) {
                currentDate = "0" + currentDate;
            }
            if (currentHours <= 9) {
                currentHours = "0" + currentHours;
            }
            if (currentMinutes <= 9) {
                currentMinutes = "0" + currentMinutes;
            }
            if (currentSeconds <= 9) {
                currentSeconds = "0" + currentSeconds;
            }

            var datetime = "" + currentYear + "-"
                + currentMonth + "-"
                + currentDate
                + "T" + currentHours + ":" + currentMinutes + ":" + currentSeconds;

            return datetime

        }

    }
    componentWillUnmount() {
        this.todayDate("end")
        this.state.isMounted = false

    }

    onRadioBtnClick(value) {
        this.state.overflow = 'scroll'
        this.setState({
            radioSelected: value
        })
        if (value === 1) {
            this.state.limit = 10;
            this.state.renderVar = 20;
            this.getGraph('live');
            //defaults.global.responsive = true;
            this.state.shouldRedraw = true;
            this.forceUpdate();
            setTimeout(() => {
                this.state.shouldRedraw = false;
            }, 1000);

        }
        else {
            this.state.limit = 10;
            this.state.renderVar = 20;
            this.forceUpdate();
            // defaults.global.responsive = false;
            // this.state.shouldRedraw = true;
            this.forceUpdate();
            this.state.startDate = this.todayDate('start');
            this.state.endDate = this.todayDate('end')
            this.getGraph('day')
        }
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {

            if (this.state.radioSelected === 2) {
                this.getGraph('day')

            }
            else if (this.state.radioSelected === 3) {
                this.getGraph('month')

            }

            else if (this.state.radioSelected === 4) {
                this.getGraph('year')

            }
        }
    }

    graphStartDateHandler = (event) => {
        selectedStartDate = moment(event._d).format('YYYY-MM-DDTHH:mm');
        if (event === '') {
            this.setState({
                startDate: null
            })
            return;
        }
        this.setState({
            startDate: selectedStartDate,
        });
        setTimeout(() => {
            this.getGraph('day')
        }, 200);
    }

    graphEndDateHandler = (event) => {
        selectedEndDate = moment(event._d).format('YYYY-MM-DDTHH:mm');
        if (event === '') {
            this.setState({
                endDate: null,
            })
            return;
        }
        this.setState({
            endDate: selectedEndDate,
        });
        setTimeout(() => {
            this.getGraph('day')
        }, 200);
    }

    async getGraph(type) {
        let chartData = [];
        this.setState({
            chartData
        })
        if (type === 'day') {
            let start = this.state.startDate,
                enddt = this.state.endDate
            var startDateInt = functions.convertToInt(start),
                endDateInt = functions.convertToInt(enddt),
                type = this.props.hub.hubType + '_Connection',
                limit = 200,
                hubId = this.props.hub.hubId;

            this.setState({
                isLoader: true
            })
            let data = await getEventsByDeviceAndDate(hubId, type, startDateInt, endDateInt, limit);

            if (!data.error) {

                console.log("DATE", data)
                if (!data.error) {
                    if (data.length != 0) {
                        console.log("date", data)
                        var eventValue = data[0].value;
                        var eventDate = functions.convertToDate(data[0].created, "none")
                        this.props.onaddvalue(data[0].value, eventDate)
                        if (this.state.isMounted) {
                            data.map(row => {
                                let milsec = 60 * 60 * 1000
                                chartData.push({
                                    date: new Date(row.created).getTime() - (milsec * 5),
                                    value: row.value
                                })
                            })
                            chartData.reverse();
                            console.log("Date2", chartData)
                            this.setState({
                                eventValue: eventValue,
                                eventDate: eventDate,
                                chartData
                            })
                        }



                    }
                    else {
                        this.props.onaddvalue(0, hubId)
                    }
                }
                this.setState({
                    isLoader: false
                })
            }
            else {
                this.setState({
                    isLoader: false
                })
            }
        }




        else if (type === 'live') {
            this.setState({
                radioSelected: 1
            })
            let
                type = this.props.hub.hubType + '_Connection',
                limit = 10,
                hubId = this.props.hub.hubId;


            let data = await getEventsByDevice(hubId, type, limit);
            if (!data.error) {
                if (data.length != 0) {
                    var eventValue = data[0].value;
                    var eventDate = functions.convertToDate(data[0].created, "none")
                    this.props.onaddvalue(data[0].value, eventDate)
                    if (this.state.isMounted) {
                        data.map(row => {
                            let milsec = 60 * 60 * 1000
                            chartData.push({
                                date: new Date(row.created).getTime() - (milsec * 5),
                                value: row.value
                            })
                        })
                        chartData.reverse();
                        this.setState({
                            eventValue: eventValue,
                            eventDate: eventDate,
                            chartData
                        })
                    }



                }
                else {
                    this.props.onaddvalue(0, hubId)
                }
            }

        }
    }


    render() {
        return (
            <Card>
                <CardBody>
                    <Row>
                        <Col sm="12" md="12" lg="12" xs="12" xl="12">
                            {(this.state.radioSelected === 1) ?
                                <div>
                                    {this.props.getConnections(this.state.eventValue, this.state.eventDate)}
                                    <CardTitle className="mb-0" style={{ fontWeight: 'bold' }}>{(this.state.eventValue) ? <span>Connected</span> : <span>Not Connected</span>}</CardTitle>
                                    <div className="small text-muted">Last updated: {this.state.eventDate}</div>
                                </div>
                                :
                                < div >
                                    <CardTitle className="mb-0" style={{ fontWeight: 'bold' }}>{this.props.hub.hubType}
                                        {(this.state.isLoader) ?

                                            <img src={LoaderGif} className="login-loader" />

                                            :
                                            null

                                        }
                                    </CardTitle>
                                </div>
                            }
                        </Col>
                        <Col sm="12" md="12" lg="12" xs="12" xl="12">
                            {(this.state.radioSelected === 1) ?

                                <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row margin-top-3">


                                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <label style={{ marginBottom: 0 }}>Start Date </label>
                                        {/* <Input disabled type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                                        <div id="startDate">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DateTimePicker
                                                    disabled='true'
                                                    format='--/--/---- --:--'
                                                    name="startDate"
                                                    // format='DD-MM-YYYY HH:mm'
                                                    onChange={this.graphStartDateHandler.bind(this)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>


                                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <label style={{ marginBottom: 0 }}>End Date </label>
                                        {/* <Input disabled type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                                        <div id="endDate">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DateTimePicker
                                                    disabled='true'
                                                    format='--/--/---- --:--'
                                                    onChange={this.graphEndDateHandler.bind(this)}
                                                    name="endDate"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>

                                    <Col sm="12" md="4" lg="12" xs="12" xl="4">
                                        <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                                            <ButtonGroup className="float-right mr-3" aria-label="First group">
                                                <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                                                <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                </div>
                                :
                                null
                            }
                            {(this.state.radioSelected === 2) ?
                                <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row  margin-top-3">

                                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <label style={{ marginBottom: 0 }}>Start Date </label>
                                        {/* <Input type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                                        <div id="startDate">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DateTimePicker
                                                    name="startDate"
                                                    disableFuture="true"
                                                    value={this.state.startDate}
                                                    // format='DD-MM-YYYY HH:mm'
                                                    onChange={this.graphStartDateHandler.bind(this)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>


                                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <label style={{ marginBottom: 0 }}>End Date </label>
                                        {/* <Input type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                                        <div id="endDate">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DateTimePicker
                                                    disableFuture="true"
                                                    value={this.state.endDate}
                                                    // format='DD-MM-YYYY HH:mm'
                                                    onChange={this.graphEndDateHandler.bind(this)}
                                                    name="endDate"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>

                                    <Col sm="12" md="4" lg="12" xs="12" xl="4">
                                        <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                                            <ButtonGroup className="float-right mr-3" aria-label="First group">
                                                <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                                                <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                </div>
                                :
                                null
                            }
                        </Col>
                    </Row>



                    {/* <div id={"graph-" + this.props.hub.hubId} className="chart-wrapper" style={(this.state.radioSelected === 1) ? {
                        height: 200 + 'px',
                        marginTop: 15 + 'px'
                    } : { overflowX: this.state.overflow, marginTop: 15 + 'px' }} rand={this.state.rand}>
                        {(this.state.unmount) ?
                            null :
                            <Line width={this.state.renderVar * this.state.limit} redraw={this.state.shouldRedraw}
                                height={200}
                                options={{
                                    maintainAspectRatio: true
                                }} data={this.state.mainChart} options={this.state.mainChartOpts} rand={this.state.rand} />
                        }

                    </div> */}
                    {this.state.chartDetail && this.state.chartData.length ?
                        <div style={{ height: 400, marginBottom: -20, overflow: "hidden" }}>
                            <ChartConfig
                                chart={this.state.chartDetail}
                                data={this.state.chartData}
                                graphcolor={"#008DF2"}
                            />
                        </div>
                        : "No Data"}


                </CardBody>
            </Card >
        )
    }

}

const mapStateToProps = (state) => {
    return {
        organizationsReducer: state.organizationsReducer,
    }
}

export default connect(mapStateToProps, null)(LineConnectionComponent);