

const INITIAL_STATE = {
    events: [],
    connect: false,
    currentPowerEvent: {
        deviceID: 0.0,
        levelId: 0.0,
        value: 0.0,
        type: 0.0,
        created: 0.0,
        eventId: 0.0,
        hubId: 0.0,

    },
    currentWaterEvent: {
        deviceID: 0.0,
        levelId: 0.0,
        value: 0.0,
        type: 0.0,
        created: 0.0,
        eventId: 0.0,
        hubId: 0.0,
    },
    currentHomeEvent: {
        deviceID: 0.0,
        levelId: 0.0,
        value: 0.0,
        type: 0.0,
        created: 0.0,
        eventId: 0.0,
        hubId: 0.0,
        eventType: 0.0

    },
    currentAlerts: {

        deviceID: null,
        levelId: 0.0,
        value: null,
        type: null,
        created: null,
        eventId: 0.0,
        hubId: 0.0,
    },
    alerts: []
}
const eventReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case "ADD_EVENT":
            if (action.eventType === "WaterEvents") {   //changed to 
                /* var oldevents = state.events;
                oldevents.push(action.payload); */


                return { ...state, currentHomeEvent: action.payload };
            }
            else if (action.eventType === "HomeEvents") {
                return { ...state, currentHomeEvent: action.payload };

            }

            else if (action.eventType === "PowerEvents") {

                return { ...state, currentHomeEvent: action.payload };

            }
            else if (action.eventType === "AgriEvents") {

                return { ...state, currentHomeEvent: action.payload };

            }
            else if (action.eventType === "MonitorEvents") {

                return { ...state, currentHomeEvent: action.payload };

            }
            else if (action.eventType === "EnviEvents") {

                return { ...state, currentHomeEvent: action.payload };

            }
            else if (action.eventType === "DeviceAlerts") {
                var alertsArr = state.events;
                alertsArr.push(action.payload);
                return { ...state, currentAlerts: action.payload, alerts: alertsArr };

            }

            return { ...state }

        case "CONNECT":

            return { ...state, connect: true };

        case "DISCONNECT":

            return { ...state, connect: false, events: [] };
        default:
            return state
    }
}



export default eventReducer