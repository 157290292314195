import React, { Component } from 'react';
import { withRouter } from 'react-router'

import { Doughnut } from 'react-chartjs-2';

class Pie extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faulty: [],
            working: [],

            doughnut: {
                labels: ['Faulty', 'Working'],
                datasets: [
                    {
                        data: [0, 0],
                        backgroundColor: [
                            '#FF6B33',
                            '#abd372'

                        ],
                        hoverBackgroundColor: [
                            '#FF6B33',
                            '#abd372'
                        ],
                    }],
            },
            options: {
                tooltips: {
                    enabled: true,

                },
                maintainAspectRatio: false,
                cutoutPercentage: 60,
                legend: {
                    position: 'bottom',
                }
            }
        }
    }

    componentWillMount() {
        //console.log(this.props.devices)

        let faulty = 0;
        let working = 0;

        this.props.devices.faulty.map(d => {
            faulty += d.devices.length
        })
        this.props.devices.working.map(d => {
            working += d.devices.length
        })

        let arr = [faulty, working];

        let doughnutObj = this.state.doughnut
        doughnutObj.datasets[0].data = arr

        this.setState({
            faulty,
            working,
            doughnut: doughnutObj
        })
    }

    _handleDevices = () => {
        this.props.history.push({ pathname: '/operation/devices_status', devices: this.props.devices })
    }

    render() {
        // //console.log(this.state.faulty)
        // //console.log(this.state.working)
        return (
            <React.Fragment>
                {(this.state.faulty) ?
                    <div class="card pb-3 dropshadow rounded-1" style={{ backgroundColor: 'white', borderColor: 'white' }} onClick={this._handleDevices}>
                        <div style={{ fontSize: 24, paddingBottom: '26px', paddingTop: '12px', paddingLeft: '10px' }}>
                            Devices Status
                        </div>
                        <div id="chart">
                            <Doughnut data={this.state.doughnut} options={this.state.options} height={200} />
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
        );
    }
}

export default withRouter(Pie);