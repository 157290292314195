import React, { useState, useEffect, useRef } from 'react'
import { Card, CardBody, Button, Input } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import ConfirmPopup from '../../components/popup/confirmPopup'
import { dataHandler, toTitles } from '../../functions/functions'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
import LoaderGif from "../../assets/img/loader/loader.svg";
import { connect } from 'react-redux';
import AddDevicePopup from './AddDevicePopup';
import JSONInput from 'react-json-editor-ajrm';
// import locale from 'react-json-editor-ajrm/locale/en';
import cookies from '../../shared/utilities/cookies';


const Devices = (props) => {
    const [usPopup, setUsPopup] = useState(false)
    let [getDevice, setGetDevice] = useState([])
    const hideUsPopup = (check) => {
        setUsPopup(false)
        // props.reload()
    }
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });
    const reload = () => {
        orgId()
        if (orgId1) {
            levelId(orgId1)
        }
        setLoader(false)
    }
    const getDeviceObj = (obj) => {
        levelObj.push(obj)
        if (!obj.levels) {
            return
        }

        obj.levels.forEach(child => getDeviceObj(child))

    }
    let levelArray = []
    let levelObj = []
    const levelId = async (_id) => {
        levelArray = []
        levelObj = []
        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + _id + '/devices')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {
                    getDeviceObj(temp)
                    setGetDevice(levelObj);
                }
            } catch (error) {
                return console.log(error)
            }
        }
        return;
    }
    const [data, setData] = useState('')
    const handle = (e) => {
        if (e) {
            if (e.target.value) {
                const newdata = { ...data }
                if (e.target.name === "Organizations") {
                    levelId(e.target.value)
                    newdata["SelectedLevel"] = null
                }
                newdata[e.target.name] = e.target.value
                setData(newdata);
                return
            }
        }
    }

    const handle2 = (e) => {
        if (e) {
            if (e.target.value) {
                // if (put.body[e.target.name] === "metadata") {
                //     put = { id: e.target.id, body: { [e.target.name]: JSON.parse(JSON.parse(JSON.stringify(e.target.value))) } }
                // }
                // else {
                put.body[e.target.name] = e.target.value
                // }
            }
        }

        return;
    }
    let [obj, setObj] = useState()

    const handleJson = async (e, name, id) => {
        console.log(e, name, id);
        let obj1 = await e.jsObject
        setObj(obj1)
        if (e.jsObject) {
            put = { id: id, body: { [name]: e.jsObject } }
        }
        else { put = { id: id, body: { [name]: null } } }
        return;
    }
    const [loader, setLoader] = useState(false)
    const [levelIds, setLevelIds] = useState('')
    const [typeArray, setTypeArray] = useState([])
    let [levelList, setLevelList] = useState([])
    let [hubList, setHubList] = useState([])
    const deviceType = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/sensors')
        let temp = dataHandler(response.data)
        setTypeArray(temp)
        return;
    }
    useEffect(() => {
        deviceType()
    }, [])
    useEffect(() => {
        orgId()
    }, [])
    const [orgId1, setOrgId1] = useState(null);
    const [orgId2, setOrgId2] = useState(null);
    let [organizationsId, setOrganizationId] = useState([])
    const orgId = async () => {
        setLoader(true)
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getRootLevels')
        let temp = dataHandler(response1.data)
        setOrganizationId(temp)
        return setLoader(false)

    }
    const getLevelIDs = (obj) => {
        levelArray.push(obj._id)
        if (!obj.levels) {
            return;
        }

        obj.levels.forEach(child => getLevelIDs(child))


    }
    const updateArchieve = async (id, state) => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let result = await axios.put("/devices/" + id, state)
        let data = dataHandler(result.data)
        reload()
        // console.warn(userId, state)
        return data;
        // return handleChangeInput(null)
    }
    // const getLevelIDs = (obj) => {
    //     array.push(obj._id)
    //     if (!obj.levels) {
    //         return;
    //     }

    //     obj.levels.forEach(child => getLevelIDs(child))


    // }
    let [confirmsPopup, setConfirmsPopup] = useState(false)
    let [deviceId, setDeviceId] = useState('')

    // const reload = () => {
    //     levelId()
    // }
    const deletedevice = async (id) => {
        setConfirmsPopup(true)
        setDeviceId(id);
        return;
    }
    const CfrmPoprup = async (e) => {
        if (e) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.delete("/devices/" + deviceId)
                    .then((res) => {
                        alert("Successfully Delete", deviceId)
                        setConfirmsPopup(null)
                        reload()
                    })
                // let temp = dataHandler(result.data)
            } catch (error) {
                alert(error)
                setConfirmsPopup(null)
            }
        }
        setConfirmsPopup(null)

        return;



    }
    let [searchDevices, setSearchDevices] = useState('')
    let filterDevices = []
    if (getDevice.length !== 0) {
        filterDevices = getDevice[0].filter(item => {
            return searchDevices !== "" ? item._id.toLowerCase().includes(searchDevices.toLowerCase()) || item.levelId.toLowerCase().includes(searchDevices.toLowerCase()) || item.type.toLowerCase().includes(searchDevices.toLowerCase()) || item.name.toLowerCase().includes(searchDevices.toLowerCase()) : item;
        })
    }


    // let array = []
    const changeLevelId = async (_id) => {
        levelArray = []
        if (_id) {

            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + _id + '/hierarchy')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {
                    getLevelIDs(temp)
                }
            } catch (error) {
                return console.log(error)
            }
        }


        return;
    }
    const update = async () => {
        setLoader(true)

        if (put) {
            try {
                setLoader(true)
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.put("/devices/" + put.id, put.body)
                    .then((res) => {
                        let temp = dataHandler(res.data)
                        setLoader(false)
                        alert("Succesfully Updated")
                        reload()
                        setUspopup(null)
                        return temp;
                    })
            } catch (error) {
                alert(error.response.data.msg)
                setUspopup(null)
                setLoader(false)
                return
            }
        }


    }

    let put = null
    const [uspopup, setUspopup] = useState(false)
    const Popup = (data, Id, name) => {
        changeLevelId(Id.split("_")[0])
        let tempdata;
        if (name === "metadata") {
            tempdata = data
        }
        else {
            tempdata = data
        }
        // let imei2 = imei1
        put = { id: Id, body: { [name]: data } };
        setUspopup(

            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => setUspopup(null)} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{
                    background: 'rgb(255, 255, 255)', width: "60%", boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, position: "absolute",
                    top: "calc(50% + 25px)",
                    left: "calc(50% + 25px)",
                    transform: "translate(-50%, -50%)"
                }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => setUspopup(null)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        {

                            "name" in put.body ?
                                <div>
                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                    <p>{Id}</p>
                                    <Input defaultValue={tempdata} name={name} id={Id} className="col-12 overflow-auto" onChange={(e) => handle2(e)} style={{ maxHeight: "65vh" }}></Input>
                                </div>
                                :

                                "levelId" in put.body ?


                                    <div>
                                        <h3 className="text-primary ">{toTitles(name)}</h3>
                                        <p>{Id}</p>
                                        <Autocomplete

                                            options={levelArray}
                                            getOptionLabel={(option) => option}

                                            defaultValue={tempdata} Id={Id}
                                            className="w-100"
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    onSelect={(e) => handle2(e)}
                                                    label={"Change LevelId"}
                                                    variant="filled"
                                                    name={name}
                                                // name={"levelId"}
                                                />
                                            }

                                        />
                                    </div>

                                    :
                                    "type" in put.body ?


                                        <div>
                                            <h3 className="text-primary ">{toTitles(name)}</h3>
                                            <p>{Id}</p>

                                            <Autocomplete

                                                options={typeArray}
                                                getOptionLabel={(option) => option}


                                                defaultValue={tempdata} Id={Id}
                                                className="w-100"
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={"Change HubType"}
                                                        onSelect={(e) => handle2(e)}
                                                        variant="filled"
                                                        name={name}
                                                    // name={name}
                                                    />
                                                }

                                            />
                                        </div>

                                        :
                                        "operations" in props.userInfo.varification ?
                                            props.userInfo.varification.operations === "ADMIN" ?
                                                <div>
                                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                                    <p>{Id}</p>
                                                    <JSONInput colors={{
                                                        default: "black",
                                                        background: "white",
                                                        string: "red"

                                                    }} theme="light_mitsuketa_tribute" width="100%" placeholder={tempdata} onChange={(e) => handleJson(e, name, Id)} height='65vh' />
                                                </div> : <div>
                                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                                    <p>{Id}</p>
                                                    <JSONInput colors={{
                                                        default: "black",
                                                        background: "white",
                                                        string: "red"

                                                    }} theme="light_mitsuketa_tribute" viewOnly={true} width="100%" placeholder={tempdata} onChange={(e) => handleJson(e, name, Id)} height='65vh' />
                                                </div> : null
                        }
                    </div>
                    < div className="row m-0 px-3 justify-content-end">
                        {loader === false ?
                            <Button color="success" onClick={() => update()}
                                style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                Update</Button>
                            : <img
                                src={LoaderGif}
                                className="w-100 m-1"
                                style={{ height: "34.8px" }}
                            />}
                    </div>
                    {/* </Form> */}

                </div>
            </div>

        )

    }
    if ("operations" in props.userInfo.varification) {
        if (props.userInfo.varification.operations === "ADMIN") {
            return (
                <div>
                    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                        Devices
                        <button onClick={() => { setUsPopup(true) }} className="btn btn-light"><i className="fa fa-user-plus"></i> Add Device</button>
                        {usPopup ?
                            <AddDevicePopup hideUsPopup={hideUsPopup} />
                            : null}
                    </h2>
                    {confirmsPopup ?
                        <ConfirmPopup id={deviceId} returnConfirm={(e) => CfrmPoprup(e)} />
                        : null}

                    <FormGroup>
                        <div className="row">
                            <div className="col-md-10 col-12">
                                <Autocomplete

                                    options={organizationsId}
                                    getOptionLabel={(option) => option}

                                    style={{ width: "100%" }}
                                    renderInput={(params) =>
                                        <TextField {...params} a
                                            onSelect={(e) => setOrgId1(e.target.value)}
                                            label={"Organizations"}
                                            variant="filled"
                                            value={data.Organizations}

                                            name={"Organizations"} />
                                    }

                                />
                            </div>
                            <div className="col-md-2 d-flex mt-1">
                                <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary w-100" onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i> Search</Button>

                                {/* <div className="col-md-2 col-4 "> */}

                                {/* </div> */}
                            </div>
                        </div>
                    </FormGroup>

                    <TextField
                        className="m-0"
                        id="filled-full-width"
                        label="Devices"
                        style={{ margin: 8 }}
                        placeholder="Search Devices"
                        fullWidth
                        margin="normal"
                        onChange={(e) => { setSearchDevices(e.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                    />

                    <Paper>
                        <Table>

                            <div
                                className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                            >
                                {/* <div className="Index"></div> */}
                                <div className="row w-100 p-2 m-0">
                                    <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                        <b>ID</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-6 align-self-center">
                                        <b>Name</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-6 align-self-center">
                                        <b>Type</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-6 align-self-center">
                                        <b>LevelId</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-6 align-self-center">
                                        <b>Archieve</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-6 align-self-center">
                                        <b>MetaData</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-6 align-self-center">
                                        <b>Delete</b>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="row m-0 rounded-brl-1 "
                                style={{ maxHeight: "400px", minHeight: "350px", overflowY: "auto" }}
                            >
                                {filterDevices.length ?
                                    filterDevices.map((device, key) => {
                                        return (
                                            <>


                                                <div className="row w-100 p-2 m-0 listView" key={key}>
                                                    <div className="col-lg-3 col-md-6 col-12 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline ">Id: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        {device._id}
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">Name: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={device.name} onClick={() => Popup(device.name, device._id, 'name')} >{device.name}</Button>
                                                    </div>
                                                    {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">Type: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={device.type} onClick={() => Popup(device.type, device._id, 'type')} >{device.type}</Button>
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">LevelId: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={device.levelId} onClick={() => Popup(device.levelId, device._id, 'levelId')} >{device.levelId}</Button>
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Archieve: </b>
                                                        <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                        <div className="live_hisbtn" style={{ width: '200px' }}>
                                                            <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                <div onClick={() => updateArchieve(device._id, { enabled: true })} className={device.enabled ? "togglebtn selectbtn" : "togglebtn"}> True </div>
                                                                <div onClick={() => updateArchieve(device._id, { enabled: false })} className={!device.enabled ? "togglebtn selectbtn" : "togglebtn"}> False </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">MetaData: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={JSON.stringify(device.metadata)} onClick={() => Popup(device.metadata, device._id, 'metadata')} >MetaData</Button>
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">Delete: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <button className="btn btn-danger  rounded-circle-pxl" onClick={() => deletedevice(device._id)}  ><i class="fas fa-minus-circle"></i></button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    : null}
                            </div>

                        </Table>




                    </Paper>

                    {uspopup}
                </div>
            )
        } else {
            return (
                <div>
                    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                        Devices
                    </h2>
                    {confirmsPopup ?
                        <ConfirmPopup returnConfirm={(e) => CfrmPoprup(e)} />
                        : null}

                    <FormGroup>
                        <div className="row">
                            <div className="col-md-10 col-12">
                                <Autocomplete

                                    options={organizationsId}
                                    getOptionLabel={(option) => option}

                                    style={{ width: "100%" }}
                                    renderInput={(params) =>
                                        <TextField {...params} a
                                            onSelect={(e) => setOrgId1(e.target.value)}
                                            label={"Organizations"}
                                            variant="filled"
                                            value={data.Organizations}

                                            name={"Organizations"} />
                                    }

                                />
                            </div>
                            <div className="col-md-2 d-flex mt-1">
                                <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary w-100" onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i> Search</Button>

                                {/* <div className="col-md-2 col-4 "> */}

                                {/* </div> */}
                            </div>
                        </div>
                    </FormGroup>

                    <TextField
                        className="m-0"
                        id="filled-full-width"
                        label="Devices"
                        style={{ margin: 8 }}
                        placeholder="Search Devices"
                        fullWidth
                        margin="normal"
                        onChange={(e) => { setSearchDevices(e.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                    />

                    <Paper>
                        <Table>

                            <div
                                className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                            >
                                {/* <div className="Index"></div> */}
                                <div className="row w-100 p-2 m-0">
                                    <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                        <b>ID</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-6 align-self-center">
                                        <b>Name</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-6 align-self-center">
                                        <b>Type</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-6 align-self-center">
                                        <b>LevelId</b>
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 align-self-center'>
                                        <b> Archieve</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-6 align-self-center">
                                        <b>MetaData</b>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row m-0 rounded-brl-1 "
                                style={{ maxHeight: "400px", minHeight: "350px", overflowY: "auto" }}
                            >
                                {filterDevices.length ?
                                    filterDevices.map((device, key) => {
                                        return (
                                            <>


                                                <div className="row w-100 p-2 m-0 listView" key={key}>
                                                    <div className="col-lg-3 col-md-6 col-12 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline ">Id: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        {device._id}
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">Name: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={device.name} disabled >{device.name}</Button>
                                                    </div>
                                                    {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">Type: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={device.type} disabled >{device.type}</Button>
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">LevelId: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={device.levelId} disabled >{device.levelId}</Button>
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Archieve: </b>
                                                        <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                        <div className="live_hisbtn" style={{ width: '200px' }}>
                                                            <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                <div className={device.enabled ? "togglebtn selectbtn" : "togglebtn"}> True </div>
                                                                <div className={!device.enabled ? "togglebtn selectbtn" : "togglebtn"}> False </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center  text-truncate">
                                                        <b className="d-lg-none d-inline">MetaData: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button title={JSON.stringify(device.metadata)} onClick={() => Popup(device.metadata, device._id, 'metadata')} >MetaData</Button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    : null}
                            </div>

                        </Table>




                    </Paper>

                    {uspopup}
                </div>
            )
        }
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps, null)(Devices);
