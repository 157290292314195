import React, { useState, useEffect } from 'react'
// import SocketIOClient from 'socket.io-client'
// import { useEffect } from 'react'
import { Button } from 'reactstrap';
import JSONInput from 'react-json-editor-ajrm';
import Table from '@material-ui/core/Table';
import { dataHandler, toTitles } from '../../functions/functions'
import Loader from '../../components/loader/loader'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import axios from '../../shared/utilities/axios'
import { getDate, getTime, todayDate } from '../../functions/functions'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import Logs from '../../operations/logs/logs';
import { connect } from 'react-redux';
// import { blue } from '@material-ui/core/colors';

// let client = SocketIOClient()


const RecentEvent = (props) => {
    let [data, setData] = useState([])
    let put = null
    const [uspopup, setUspopup] = useState(false)

    const Popup = (id, value,) => {
        let tempdata;
        tempdata = value
        setUspopup(

            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => setUspopup(null)} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{
                    background: 'rgb(255, 255, 255)', width: "60%", boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, position: "absolute",
                    top: "calc(50% + 25px)",
                    left: "calc(50% + 25px)",
                    transform: "translate(-50%, -50%)"
                }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => setUspopup(null)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        {
                            <div>
                                <JSONInput colors={{
                                    default: "black",
                                    background: "white",
                                    string: "red"

                                }} theme="light_mitsuketa_tribute" viewOnly={true} width="100%" placeholder={tempdata} height='25vh' />
                            </div>
                        }
                    </div>
                    {/* </Form> */}
                </div>
            </div>

        )

    }
    useEffect(() => {
        let Events = data
        let deviceEvent = props.eventReducer.currentHomeEvent;
        if (deviceEvent.hubId === props.hubId) {
            Events.unshift(
                {
                    value: deviceEvent,
                    hubId: deviceEvent.hubId,
                    event: deviceEvent.eventType,
                    created: deviceEvent.created,
                    JSON: JSON,
                }
            )
            if (Events.length > 20) {
                Events.splice(19, 20)
            }
        }
        setData(Events)
        // console.log(data)
    }, [props.eventReducer])
    // const failedLog = () => {
    //     // data.reverse()
    //     // console.log(Log)
    //     // if (!data.length) {
    //     //     data = data.reverse()
    //     // }
    //     // data.push(Log)
    //     // if (data.length > 9) {
    //     //     data.splice(0, 9)
    //     // }
    //     // data = data.reverse()

    //     // setData(data)
    //     // setData(currentLogs => {
    //     //     if (!currentLogs.length) {
    //     //         currentLogs = currentLogs.reverse()
    //     //     }
    //     //     currentLogs.push(Log)
    //     //     if (currentLogs.length > 9) {
    //     //         currentLogs.splice(0, 9)
    //     //     }
    //     //     currentLogs = currentLogs.reverse()
    //     // })
    //     // data = data.reverse()

    //     setData(currentEvents => [...currentEvents.length > 9 ? currentEvents.splice(9, 10) : currentEvents])
    //     // setData(data.reverse())  
    //     //     logs.unshift(
    //     //         {
    //     //             name: props.deviceName[logsEvent.deviceId],
    //     //             message: logsEvent.message,
    //     //             created: functions.getTime(logsEvent.created) + " " + functions.getDate(logsEvent.created)
    //     //         }
    //     //     )
    //     //     if (logs.length > 20) {
    //     //         logs.splice(19, 20)
    //     //     }
    //     // }

    //     // setData(data.reverse())

    //     // console.log('data', data)

    // }
    let [loader, setLoader] = useState(false)
    return (
        <div className="dropshadow rounded-1">


            <>
                <div
                    className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                >
                    {/* <div className="Index"></div> */}
                    <div className="row w-100 p-2 m-0">
                        <div className="col-lg-2 col-md-6 col-12 align-self-center">
                            <b>Event</b>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">
                            <b>Value</b>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12 align-self-center">
                            <b>Format </b>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12 align-self-center">
                            <b>Last Recived</b>
                        </div>
                    </div>
                </div>

                <div
                    className="row m-0 rounded-brl-1"
                    style={{ maxHeight: "200px", minHeight: "100px", overflowY: "auto" }}
                >
                    {data.length ? data.map((recentEvent, key) => (
                        <div className="row w-100 p-2 m-0 listView" key={key}>
                            <div className="col-lg-2 col-md-86col-12 Date align-self-center ">
                                <b className="d-lg-none d-inline">Event: </b>
                                <br className="d-inline d-lg-none d-md-none d-sm-none" />

                                {recentEvent.value.eventType}
                            </div>
                            <div title={JSON.stringify(recentEvent.value)} className="col-lg-6 col-md-86col-12 Date align-self-center text-truncate">
                                <b className="d-lg-none d-inline">Value: </b>
                                <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                <Button onClick={() => Popup(recentEvent.value.hubId, recentEvent.value, 'Value')}>{JSON.stringify(recentEvent.value)}</Button>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                <b className="d-lg-none d-inline">Format: </b>
                                <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                JSON
                            </div>
                            {/* <StyledTableCell >{getTime(recentEvent.created) + " " + getDate(recentEvent.created)}</StyledTableCell> */}
                            <div div className="col-lg-2 col-md-6 col-12 Date align-self-center" >
                                <b className="d-lg-none d-inline">Last Recived: </b>
                                <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                {getTime(recentEvent.value.created) + "  " + getDate(recentEvent.value.created)}
                            </div>
                        </div>
                    )) : <div className="col noData">No Events</div>}
                </div>
            </>

            {uspopup}
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        eventReducer: state.eventReducer
    };
};

export default connect(mapStateToProps, null)(RecentEvent);
