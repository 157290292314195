import React from 'react'
import logo2 from '../../assets/img/logo/logo.svg'

const Footer = () => {
    let currentdate = new Date
    var currentYear = currentdate.getFullYear()
    return (
        <span className="footer-align">Powered by <a href="http://linked-things.com/" target="_blank"><img src={logo2} className='footer-img' /></a> &copy; {currentYear} </span>
    );
}

export default Footer
