import React, { useState, useEffect, useRef } from 'react'
import { Card, CardBody, Button } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import moment from 'moment';
import { dataHandler } from '../../functions/functions';
import { subMinutes } from 'date-fns';
import axios from '../../shared/utilities/axios';
// import axios from '../../shared/utilities/axios';
// import ToastPopup from '../../components/popup/toastPopup'
import LoaderGif from "../../assets/img/loader/loader.svg";
import cookies from '../../shared/utilities/cookies';

// import ToastPopup from "../popup/toastPopup";

// import { Button, Container } from '@material-ui/core';




const AddUserPopup = (props) => {
    const [loader, setLoader] = useState(false)
    const [visiblePass, setVisiblePass] = useState(false)
    const [visibleConfrmPass, setVisibleConfrmPass] = useState(false)
    const [confirmPass, setConfirmPass] = useState(false)
    // const child = useRef();
    // const url = "https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2/users/signup"
    const [data, setData] = useState({
        _id: "",
        password: "",
        confirmPassword: "",
    })
    const submit = () => {
        // refs.child.myToast_danger(header, massage);
        setLoader(true)
        let body = {
            _id: data._id,
            password: data.password
        }

        // useEffect(() => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        axios.post("/users/signup", body)

            .then((res) => {
                setLoader(false)
                // console.log(res.data)
                props.hideUsPopup()
                let data = dataHandler(res.data)
                alert("User Succesfully Added")
                return data;
                // props.reload()


            }).catch(e => {
                if (e.response.data.message == "Duplicate Key error.") {
                    alert("User Already Acess")
                } else {
                    alert(e.response.data.message)
                }
                setLoader(false)
                props.hideUsPopup()
            })
        // }, [])
    }





    const handle = (e) => {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata);

    }


    return (
        <>
            {/* <ToastPopup ref={child} /> */}
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1020' }}>
                <div onClick={() => props.hideUsPopup()} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', width: "50%", margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1 }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => props.hideUsPopup()} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        <h4 style={{ color: "black" }}>Add User</h4>

                        <FormGroup>
                            <Label style={{ color: "black", fontSize: "12px" }}>Email</Label>
                            <Input onChange={(e) => handle(e)} id="_id" type="email" name="_id" value={data._id} placeholder="Email" />
                        </FormGroup>
                        <FormGroup>
                            <Label style={{ color: "black", fontSize: "12px" }}>Password</Label>
                            <div className='d-flex'>
                                <Input className='col-11' onChange={(e) => handle(e)} id="password" type={visiblePass ? "text" : "password"} name="password" value={data.password} placeholder="password" />
                                <button className={visiblePass ? 'btn btn-light fa fa-eye-slash rounded-circle-px col-1' : 'btn btn-light fa fa-eye rounded-circle-px col-1'} onClick={() => setVisiblePass(!visiblePass)} ></button>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label style={{ color: "black", fontSize: "12px" }}>Confirm Password</Label>
                            <div className='d-flex'>
                                <Input className='col-11' onChange={(e) => handle(e)} id="confirmPassword" type={visibleConfrmPass ? "text" : "password"} name="confirmpassword" value={data.confirmPassword} placeholder="Confirm Password" />
                                <button className={visibleConfrmPass ? 'btn btn-light fa fa-eye-slash rounded-circle-px col-1' : 'btn btn-light fa fa-eye rounded-circle-px col-1'} onClick={() => setVisibleConfrmPass(!visibleConfrmPass)} ></button>
                            </div>
                            {data.password !== data.confirmPassword ?
                                <p style={{ color: "red", fontSize: "12px" }}>Password did't match</p> : null}

                        </FormGroup>

                        {data.password === data.confirmPassword ?
                            loader == false ?
                                <Button color="success" onClick={submit}
                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                    User Create</Button>
                                : <img
                                    src={LoaderGif}
                                    className="w-100 m-1"
                                    style={{ height: "34.8px" }}
                                /> : loader == false ?
                                <Button color="success" onClick={submit}
                                    disabled
                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                    User Create</Button>
                                : <img
                                    src={LoaderGif}
                                    className="w-100 m-1"
                                    style={{ height: "34.8px" }}
                                />}



                    </div>
                </div>
            </div >
        </>
    );
}


export default AddUserPopup


