import React, { useState, useEffect } from 'react'
import { Card, CardBody, Button } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import LoaderGif from "../../assets/img/loader/loader.svg";
import moment, { relativeTimeRounding } from 'moment';
import { subMinutes } from 'date-fns';
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
import { dataHandler } from '../../functions/functions';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import cookies from '../../shared/utilities/cookies';



const AddLevelPopup
    = (props) => {
        const [loader, setLoader] = useState(false)
        // const url = ('https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2/levels/')
        const [data, setData] = useState({
        })
        const submit = async () => {
            setLoader(true)
            if ("type" in data) {
                if (data.type === "Organization") {
                    data['config'] = { hierarchy: true };

                } else if (data.type !== "") {
                    delete data.Organization
                }
            }
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.post("/levels", data)
                    .then((res) => {
                        setLoader(false)
                        console.log(res.data)
                        let data = dataHandler(res.data)
                        // let body = {
                        //     level: data.level,
                        //     Id: data.Id,
                        //     name: data.Name,
                        //     config: data.config
                        // }
                        alert("Successfully Adeed")
                        props.hideUsPopup()
                        return data;
                    })
            } catch (error) {
                if (error.response.data.message == "Duplicate Key error.") {
                    alert("Level Already Access")
                } else {
                    alert(error.response.data.message)
                }
                setLoader(false)
                props.hideUsPopup()
            }
            // props.reload()

        }

        let levelArray = [];
        let [levelList, setLevelList] = useState([])
        const levelId = async (_id) => {
            levelArray = []
            console.log(_id)
            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            const response = await axios.get('/levels/' + _id + '/hierarchy')
            let temp = dataHandler(response.data)
            // const temp = dataHandler(response.data)
            getLevelIDs(temp)
            setLevelList(levelArray)
            // console.log(levelList);
        }




        // useEffect(() => {
        //     Org()
        // }, [])

        // const Organizations = [

        // ]
        // const orgName = {
        //     HumairaHouse: [
        //         "abc",
        //         "def",
        //         'ghi'
        //     ],
        //     KElectric: [
        //         "jkl",
        //         "mno",
        //         'pqr'
        //     ],
        //     LinkedThings: [
        //         "stu",
        //         'vwx',
        //         "yz"
        //     ]
        // }



        const filterOptions = createFilterOptions({
            matchFrom: 'start',
            stringify: (option) => option,
        });
        const handle = (e) => {
            if (e) {
                if (e.target.name === "Organization") {
                    levelId(e.target.value)
                }
                const newdata = { ...data }
                newdata[e.target.name] = e.target.value
                setData(newdata);
            }
        }
        // let levelList = [];
        const getLevelIDs = (obj) => {
            levelArray.push(obj._id)
            // setLevelList(...levelList, obj._id)
            if (!obj.levels) {
                return
            }

            obj.levels.forEach(child => getLevelIDs(child))
        }
        const [orgId1, setOrgId1] = useState(null);


        return (
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => props.hideUsPopup()} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', width: "80%", margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, maxHeight: "80vh" }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => props.hideUsPopup()} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        {/* <Form> */}
                        <h4 style={{ color: "black" }}>Add Level</h4>
                        <div style={{ overflow: "auto", maxHeight: "calc(100vh - 300px)" }}>
                            <div className="col-12">

                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}>level Type</Label>
                                    <Autocomplete

                                        options={props.org}
                                        getOptionLabel={(option) => option}
                                        onSelect={event => handle(event)}

                                        style={{ width: "100%" }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={"Levels Type"}
                                                variant="filled"
                                                value={data.type}

                                                name={"type"} />
                                        }

                                    />
                                    {/* <AutoCom onChange={(e) => handle(e)} id="_id" type="email" name="_id" value={data._id} placeholder="Email" /> */}
                                </FormGroup>
                            </div>

                            {"type" in data ? data.type === "Organization" ?
                                <div className="row m-0">
                                    <div className="col-md-6 col-12">
                                        <FormGroup>
                                            <Label style={{ color: "black", fontSize: "12px" }}>Id</Label>
                                            <Input onChange={(e) => handle(e)} id="Id" type="text" name="_id" value={data._id} placeholder="Id" />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <FormGroup>
                                            <Label style={{ color: "black", fontSize: "12px" }}>Name</Label>
                                            <Input onChange={(e) => handle(e)} id="Name" type="text" name="name" value={data.name} placeholder="Name" />
                                        </FormGroup>
                                    </div>

                                    {/* <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}>Config</Label>
                                    <Input onChange={(e) => handle(e)} id="Config" type="text" name="Config" value={true} placeholder="Config" />
                                </FormGroup> */}
                                </div>


                                :
                                data.type !== "" ?
                                    <div className="row m-0">
                                        {/* <FormGroup>
                                        <Label style={{ color: "black", fontSize: "12px" }}>level Type</Label>
                                        <Autocomplete 

                                            options={levelType}
                                            getOptionLabel={(option) => option}

                                            style={{ width: 200 }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    onSelect={event => handle(event)}
                                                    label={"LevelType"}
                                                    variant="filled"
                                                    value={data.levelType}

                                                    name={"levelType"} />
                                            }

                                        />
                                       
                                       
                                        //   </FormGroup> */}
                                        <div className="col-md-6 col-12">
                                            <Label style={{ color: "black", fontSize: "12px" }}>Organization Type</Label>
                                            <Autocomplete

                                                options={props.orgId}
                                                getOptionLabel={(option) => option}

                                                style={{ width: "100%" }}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        onSelect={(e) => setOrgId1(e.target.value)}
                                                        label={"Organizations"}
                                                        variant="filled"
                                                        value={data.Organizations}

                                                        name={"Organizations"} />
                                                }

                                            />
                                            <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary ml-2 col-2 mt-2" style={{ float: "right" }} onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i><span className="d-md-inline d-none"> Search </span></Button>
                                        </div>
                                        {/* <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label style={{ color: "black", fontSize: "12px" }}>Organizations</Label>
                                                <Autocomplete

                                                    options={props.orgId}
                                                    getOptionLabel={(option) => option}

                                                    style={{ width: "100%" }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            onSelect={event => handle(event)}
                                                            label={"Organization"}
                                                            variant="filled"
                                                            value={data.Organization}

                                                            name={"Organization"} />
                                                    }

                                                />
                                            </FormGroup>
                                        </div> */}
                                        <div className="col-md-6 col-12">
                                            {levelList.length > 0 ?
                                                <FormGroup>
                                                    <Label style={{ color: "black", fontSize: "12px" }}>Select Level</Label>
                                                    <Autocomplete

                                                        options={levelList}
                                                        getOptionLabel={(list) => list}

                                                        style={{ width: "100%" }}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                onSelect={event => handle(event)}
                                                                label={"All Level List of Selected"}
                                                                variant="filled"
                                                                value={data.levelId}

                                                                name={"levelId"} />
                                                        }

                                                    />
                                                </FormGroup> : null}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label style={{ color: "black", fontSize: "12px" }}>Id</Label>
                                                <Input onChange={(e) => handle(e)} id="Id" type="text" name="_id" value={data._id} placeholder="Id" />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <FormGroup>
                                                <Label style={{ color: "black", fontSize: "12px" }}>Name</Label>
                                                <Input onChange={(e) => handle(e)} id="Name" type="text" name="name" value={data.name} placeholder="Name" />
                                            </FormGroup>
                                        </div>
                                        {console.log("Second Check", levelList)}
                                    </div>


                                    : null : null}
                        </div>

                        <div className="row m-0 px-3 justify-content-end">
                            {loader == false ?
                                "type" in data ? data.type === "Organization" ?
                                    <Button color="success" onClick={submit}
                                        style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                        Level Create</Button>
                                    :
                                    data.levelId ?
                                        <Button color="success" onClick={submit}
                                            style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                            Level Create</Button>
                                        : <Button color="success" onClick={submit} disabled
                                            style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                            Level Create</Button>
                                    : <img
                                        src={LoaderGif}
                                        className="w-100 m-1"
                                        style={{ height: "34.8px" }}
                                    /> : null}
                        </div>
                        {/* </Form> */}

                    </div>
                </div>
            </div >

        );
    }


export default AddLevelPopup



