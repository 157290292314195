import { decryptConfig } from '../../config';
import axios from './../utilities/axios';
import store from './../../store'
import cookies from '../utilities/cookies';
import zlib from "zlib";
import CryptoJS from 'crypto-js';

// export const encrypt = (data) => {
//   return CryptoJS.AES.encrypt(JSON.stringify(data), decryptConfig()).toString();
// }

// export const decrypt = async (data, key = null) => {
//   try {
//     // console.log("DECRYPT Function",CryptoJS.AES.decrypt(data, decryptConfig(key)))
//     let bytes = CryptoJS.AES.decrypt(data, decryptConfig(key));
//     // console.log("DECRYPT bytes",JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8))))
//     return JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//   }
//   catch (err) {
//     // console.log("CATCH in Decrypt")
//     let token = cookies.getCookie('operationToken');
//     axios.defaults.headers['Authorization'] = 'Bearer ' + token;
//     let response1 = await axios.get("/start");
//     key = response1.data.key;
//     store.dispatch({ type: "ADD_SECRETKEY", payload: response1.data.key });
//     let bytes = CryptoJS.AES.decrypt(data, key);
//     return JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//   }
// }

export function decrypt(data) {
  let temp = data.substring(0, 6);
  data = data.substring(6);
  const bytes = CryptoJS.AES.decrypt(data, temp);
  let aa = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(JSON.parse(zlib.inflateSync(Buffer.from(aa, 'base64')).toString()));
}