import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper';
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import cookies from '../../shared/utilities/cookies';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddUser from './Adduser';
import Loader from '../../components/loader/loader'
import LoaderGif from "../../assets/img/loader/loader.svg";
import ConfirmPopup from '../../components/popup/confirmPopup'
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { dataHandler } from '../../functions/functions';
// import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import axios from '../../shared/utilities/axios';
// import axios from '../../shared/utilities/axios';
const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];



const Users = (props) => {

    let [userIds, setUserIds] = useState([])
    const orgId = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getRootLevels')
        let temp = dataHandler(response1.data)
        setUserIds(temp)
        return;
        //   getLevelIDs(temp)
        // setBranchList(levelArray)
    }
    useEffect(() => {
        orgId()
    }, [])

    const [loader, setLoader] = useState(false)
    const userRoles = ["ADMIN", "OWNER", "USER", "VIEWER", "OPERATOR", "DEMO", "MANAGEMENT", "MANAGER"]

    const [levels, setLevels] = useState([{}]);
    let levelArray = [];
    let [branchList, setBranchList] = useState([])
    const levelId = async (_id) => {
        levelArray = []
        // console.log(_id)
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/levels/' + _id + '/hierarchy')
        const temp = dataHandler(response.data)
        getLevelIDs(temp)
        setBranchList(levelArray)
        return;
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("levels", levels);
    //     set
    // };
    // const branchList = [
    //     "LinkedThings_AtmosphereTesting3"
    // ]

    let [branch, setBranch] = useState({})
    let [list, setList] = useState([])
    const addstage = async (key, index, id) => {
        levelArray = []
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/levels/' + id + '/hierarchy')
        const data = dataHandler(response.data)
        // let data = dataHandler(response.data)
        getLevelIDs(data)
        setList(levelArray)
        let rows = branch;
        let branches = [];
        if (key in rows) {
            branches = rows[key]
        }
        branches.push("");
        rows[key] = branches;
        toggle(index)
        setBranch(rows)
        toggle(index)

        // let nested3 = [],
        //     nested2 = [];
        // if (branch.length) {
        //     nested2 = branch;
        // }
        // // if (nested2.length) {
        // //     nested3 = nested2[key];
        // //     console.log("2ndloop cond2", nested3, nested2[key])
        // // }
        // nested3.push("check" + key + " " + key2);
        // nested2[key2] = nested3;
        // branch[key] = nested2;
        // setBranch(branch)
        return;
    }
    const branchSend = async (level, userId) => {
        let branches = Object.values(branch)[0]
        console.log("add0", branch)
        if ("branches" in level && branches.length) {
            let findBranch = branches.filter(data => data === "")
            console.log("add01", findBranch)
            if (!findBranch.length) {
                branches = [...branches, ...level.branches]

                console.log("add02", branches)
                let result = await axios.put('/branches/update/users/' + userId, {
                    "branches": branches
                })
                // let data = dataHandler(result.data)
                reload()
                console.warn(result)
            }
            setBranch(null)
        }


    }
    const handleChange = (e, key, index) => {
        let branch1 = branch
        let branch2 = branch1[key]
        branch2[index] = e.target.value
        branch1[key] = branch2
        setBranch(branch1)
        // console.log("branch1 value", branch)
        // this.setState({ stages: this.state.stages })
        console.log(branch1)
        // setBranch(branch)
        return;
    }
    const handleremove = (key, index, key2) => {
        console.log("index", index)
        let branch1 = branch
        console.log(index)
        branch1[key].splice(index, 1)
        toggle(key2)
        setBranch(branch1)
        toggle(key2)
        console.log("Remove", branch1[key])
        return;
    }
    const handleChangeInput = (event, value, id) => {
        console.log("levels", levels)
        // console.log("id:", id)
        // levelId(event.target.value)
        // console.log("event:", event)
        const name = event.target.id.split("-")[0]
        const newlevels = levels.map(i => {


            //  console.log("target", event.target.value)
            if (name === "_id") {
                if (i._id !== null) {
                    levelId(value)
                }
            }
            if (id === i._id) {
                if (name === "roles") {
                    console.log("value role", value)
                    i['roles'] = [value]
                }
                else {
                    console.log("value else", value)
                    i[name] = value
                }
            }
            console.log(i)
            return i;
        })
        // console.log("event.target.name",)

        setLevels(newlevels);
        return;
    }

    const [toggleConfirm, setToggleConfirm] = useState(false)
    const [userId, setUserId] = useState(null)
    const [isOpen, setIsOpen] = useState([]);

    const toggle = (index) => {
        // isOpen.map(open => console.log(open, index))
        // console.log("isOpen", isOpen)
        isOpen.find(open => open === index) ?
            setIsOpen(isOpen.filter(open => open !== index)) :
            setIsOpen(isOpen.concat([index]));
    };

    const reload = () => {
        getUsers()
    }
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });

    useEffect(() => {
        getUsers()
    }, [])
    const deleteLevels = async (levelId, userId) => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let result = await axios.delete('/levels/' + levelId + '/users/' + userId);
        console.log("levelID", levelId, " userId: ", userId)
        let data = dataHandler(result.data)
        reload()
        // console.warn(userId)
        return;
    }
    const updateLevels = async (id, state) => {
        if (levels[0].roles && levels[0]._id) {
            setLoader(true)
            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let result = await axios.put('/levels/update/users/' + id, levels[0]);
            let data = dataHandler(result.data)
            // if (result) { }
            setLoader(false)
            reload()
            setLevels([{
                roles: null,
                _id: null
            }])
            return data;
        }
        else {
            alert("Id And Roles is Undefined")
        }
        return;

    }

    const deleteOperation = (_id) => {
        setUserId(_id);
        setToggleConfirm(true)
        return;
    }
    const confirmDelete = async (state) => {
        if (state) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.delete("/users/" + userId)
                    .then(res => {
                        setLoader(false)
                        setToggleConfirm(false)
                        reload()
                        alert("Successfully delete user ")
                    })
            } catch (err) {
                alert(err)
                setToggleConfirm(false)
                setUserId(null)
                return;
            }
        }
        setToggleConfirm(false)
    }
    const updateUser = async (id, state) => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let result = await axios.put("/users/" + id, state)
        let data = dataHandler(result.data)
        reload()
        console.warn(userId, state)
        return data;
        // return handleChangeInput(null)
    }

    // const US_telenor = async (id, state) => {
    //     let result = axios.put('https://linked-things-s4-organizations.eu-de.mybluemix.net/api/v1/users/' + id, {
    //         telenor: state,

    //     })
    //     reload();
    // }

    // console.log("check", isOpen.map(open => (open)))
    const [state, setState] = useState('')
    const [user, setUser] = useState([])
    const [searchUsers, setSearchUsers] = useState('')
    const [searchOrg, setSearchOrg] = useState("")
    const getUsers = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/users')
        // let temp = await response.json()
        // setUser(user.data)
        let data = dataHandler(response.data)
        setUser(data.reverse())
        // console.log(data);
        return;
    }
    let [currentPage, setCurrentPage] = useState(1)
    let [getLimit, setGetLimit] = useState(10)
    let pagenumbers = [];
    const Pagination = () => {
        for (let i = 1; i <= Math.ceil(filterUsers.length / getLimit); i++) {
            pagenumbers.push(i)
        }
    }
    const paginate = (pagenumbers) => {
        setCurrentPage(pagenumbers)
    }
    let filterUsers = []
    let filterOrg = []
    if (user !== 0) {
        filterUsers = user.filter(item => {
            return searchUsers !== "" ? item._id.includes(searchUsers) : item;
        })
        if (filterUsers !== 0) {
            filterOrg = filterUsers.filter(item => {
                return searchOrg !== "" ? item.levels.map(level => level._id).includes(searchOrg) : item
            })
        }
        // filterUsers = user.filter(item => {
        //     return searchOrg !== "" ? item.levels.map(level => level._id).includes(searchOrg) : item;
        // })
        Pagination()
    }
    const removeBranch = async (id, branch, value) => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let result = await axios.delete('/branches/' + value + '/users/' + id)
        reload()
        console.warn(result)
    }
    // const levelId = async (_id) => {
    //     let levelArray = [];
    //     const response = await fetch('https://linked-things-s4-organizations.eu-de.mybluemix.net/api/v1/levels/' + _id + '/hierarchy')
    //     let temp = await response.json()
    //     getLevelIDs(temp)


    // }
    // let [getLimit, setGetLimit] = useState(10)
    let limit = (e) => {
        setGetLimit(parseInt(e.target.value))
        Pagination()
    }
    const indexOflastPost = currentPage * getLimit
    const indexofFirstPost = indexOflastPost - getLimit
    const currentUsers = filterOrg.slice(indexofFirstPost, indexOflastPost)



    // let [Pages, setPages] = useState(0)
    // let [pages, setPages] = useState(0)
    // let Pagination = () => {
    //     let limit = parseInt(getLimit)
    //     let Page = parseInt(Math.floor((filterUsers.length / limit)))
    //     console.log("pagess", Page)
    //     let lastBtn = filterUsers.length - (Page * limit)
    //     console.log("Btn", lastBtn)
    //     let pagination = [];
    //     var i = 0;
    //     for (i = 0; i <= Page; i++) {
    //         pagination.push(i * limit)
    //     }
    //     console.log("pagination", pagination)
    //     if (lastBtn) {
    //         let last = pagination[pagination.length - 1] + lastBtn;
    //         pagination.push(last)
    //         Page += 1;
    //         setPages(Page)
    //     }
    //     // console.log(filterUsers.length, Page, pagination, lastBtn, selectedPage.end)

    //     setSelectedPage({ start: 0, end: getLimit })



    // }

    const getLevelIDs = (obj) => {
        levelArray.push(obj._id)
        if (!obj.levels) {
            return
        }

        obj.levels.forEach(child => getLevelIDs(child))
    }
    //     const Pagination = () => {
    //     let limit  = parseInt(getLimit)
    // }
    // console.log("Collapse", isOpen)
    if ("operations" in props.userInfo.varification) {
        if (props.userInfo.varification.operations === "ADMIN") {
            return (

                <div>
                    <AddUser reload={reload} />
                    {toggleConfirm ?
                        <ConfirmPopup id={userId} returnConfirm={confirmDelete} />
                        : null}

                    {user == 0 ?
                        <Loader />
                        :

                        <div>

                            <div className="row m-0 ">
                                <div className="col-md-2   form-group MuiFilledInput-root">
                                    <select className="MuiInputBase-input MuiFilledInput-input"

                                        id="filled-full-width"
                                        style={{ width: "calc(100% - 30px)" }}
                                        onChange={(e) => limit(e)}
                                        defualtValue={10}
                                        name={"Item"}
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <TextField

                                        className="m-0"
                                        id="filled-full-width"
                                        label="Users"

                                        placeholder="Search Users"
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => { setSearchUsers(e.target.value); setIsOpen([]) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                    />
                                </div>
                                <div className='col-md-4' >
                                    <Autocomplete filterOptions={filterOptions}
                                        variant="filled"

                                        // onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                        id={"Organizations"}
                                        options={userIds}
                                        getOptionLabel={(option) => option}
                                        style={{ width: "100%" }}
                                        onSelect={(e) => { setSearchOrg(e.target.value); setIsOpen([]) }}

                                        renderInput={(params) =>
                                            <TextField  {...params} label="Search Organizations"
                                                variant="filled"

                                            />
                                        }

                                    />
                                </div>
                            </div>
                            {/* <div>
                        <div className="container">
                            <table>
                                <th>
                                    <td>Email</td>
                                    <td>Rigestered</td>
                                    <td>Logo</td>
                                    <td>Organizations</td>
                                    <td>Email</td>
                                </th>
</table>
                        </div>
                    </div> */}
                            <Paper >
                                <Table>
                                    {/* <TableHead>
                                <TableCell>
                                    <div className="row m-0">
                                        <div className="col-1">#</div>
                                        <div className="col-3">Email</div>
                                        <div className="col-2">Rigestered</div>
                                        <div className="col-3">Logo</div>
                                        <div className="col-2">Organizations</div>
                                        <div className="col-1">Delete</div>
                                    </div>
                                </TableCell>
                            </TableHead> */}
                                    <div
                                        className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                                    >
                                        {/* <div className="Index"></div> */}
                                        <div className="row w-100 p-2 m-0">
                                            <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                <b>#</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Email</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Registered</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Logo</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Operation Access</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Organizations</b>
                                            </div>
                                            <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                <b>Delete</b>
                                            </div>
                                        </div>
                                    </div>
                                    <TableBody>
                                        {/* this.state.filterdocs.slice(this.state.selectedPage.start, this.state.selectedPage.end).map((data, index) => */}
                                        {currentUsers.map((users, key) => {
                                            return (
                                                <>
                                                    <div className="row w-100 p-2 m-0 listView" key={key}>
                                                        <div className="col-lg-1 col-md-86col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline"># </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            {key + 1}
                                                        </div>
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Email: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            {users._id}
                                                        </div>
                                                        {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Rigestered: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <div className="live_hisbtn" style={{ width: '200px' }}>
                                                                <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                    <div onClick={() => updateUser(users._id, { registered: true })} className={users.registered ? "togglebtn selectbtn" : "togglebtn"}> Registered </div>
                                                                    <div onClick={() => updateUser(users._id, { registered: false })} className={!users.registered ? "togglebtn selectbtn" : "togglebtn"}> Unregister </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Logo: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <div className="live_hisbtn" style={{ width: '180px' }}>
                                                                <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                    <div onClick={() => updateUser(users._id, { telenor: true })} className={users.telenor ? "togglebtn selectbtn" : "togglebtn"}> True </div>
                                                                    <div onClick={() => updateUser(users._id, { telenor: false })} className={!users.telenor ? "togglebtn selectbtn" : "togglebtn"}> False </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Operation Access: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <div className="live_hisbtn" style={{ width: '220px' }}>
                                                                <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                    <div onClick={() => updateUser(users._id, { operations: "ADMIN" })} className={users.operations === "ADMIN" ? "togglebtn selectbtn" : "togglebtn"}> ADMIN </div>
                                                                    <div onClick={() => updateUser(users._id, { operations: "VIEWER" })} className={users.operations === "VIEWER" ? "togglebtn selectbtn" : "togglebtn"}> VIEWER </div>
                                                                    <div onClick={() => updateUser(users._id, { operations: "" })} className={users.operations === "" ? "togglebtn selectbtn" : "togglebtn"}> N/A </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Organizations: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <Button className=" btn btn-dark border-0 gradient-primary3 rounded-circle-pxl m-0" onClick={toggle.bind(this, key + 1)} style={{ marginBottom: '1rem' }}>Levels <i className="fa fa-angle-down"></i>  </Button>
                                                        </div>
                                                        <div className="col-lg-1 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Delete: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <button onClick={() => deleteOperation(users._id)} className="btn btn-danger  rounded-circle-pxl"><i class="fas fa-minus-circle"></i></button>                                                </div>

                                                    </div>
                                                    <TableRow>
                                                        <Collapse isOpen={isOpen.find(openedIndex => openedIndex === (key + 1))}>
                                                            <Container>

                                                                {loader == false ?
                                                                    <div className="row justify-content-end py-3" >
                                                                        {levels.map((level, key2) => (<React.Fragment key={key2}>
                                                                            <div className='col-6 mb-2 ' >
                                                                                <Autocomplete filterOptions={filterOptions}


                                                                                    onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                                                                    id={"roles"}
                                                                                    options={userRoles}
                                                                                    getOptionLabel={(option) => option}

                                                                                    style={{ width: "100%" }}
                                                                                    renderInput={(params) =>

                                                                                        <TextField  {...params} label="UserRoles" defaultValue={level.roles}


                                                                                        />

                                                                                    }

                                                                                />
                                                                            </div>
                                                                            <div className='col-6 mb-2 ' >
                                                                                <Autocomplete filterOptions={filterOptions}
                                                                                    options={userIds}
                                                                                    getOptionLabel={(option) => option}
                                                                                    onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                                                                    id={"_id"}
                                                                                    style={{ width: "100%" }}
                                                                                    renderInput={(params) =>
                                                                                        <TextField {...params} name={"Id" + key + key2}

                                                                                            label={"Organizations Id"}

                                                                                            defaultValue={level._id} />
                                                                                    }

                                                                                />
                                                                            </div>
                                                                            {branchList.length > 1 ?
                                                                                <div className='col-12 mb-2 ' >
                                                                                    <Autocomplete filterOptions={filterOptions}

                                                                                        onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                                                                        options={branchList}
                                                                                        getOptionLabel={(option) => option}
                                                                                        multiple
                                                                                        id="branches"
                                                                                        style={{ width: "100%" }}
                                                                                        renderInput={(params) =>
                                                                                            <TextField  {...params} name={"Id" + key + key2}
                                                                                                label={"Branches"}

                                                                                                defaultValue={level.branches} />
                                                                                        }

                                                                                    />
                                                                                </div>
                                                                                : null}
                                                                        </React.Fragment>
                                                                        ))}

                                                                        <Button className="mr-3"

                                                                            variant="contained"
                                                                            color="primary"
                                                                            // type="submit"
                                                                            endIcon={<Icon>send</Icon>}
                                                                            onClick={() => updateLevels(users._id)}
                                                                        >Send</Button>

                                                                    </div>

                                                                    : <img
                                                                        src={LoaderGif}
                                                                        className="w-100 m-1 p-6"
                                                                        style={{ height: "56.94px" }}
                                                                    />}
                                                            </Container>


                                                            <TableCell >{
                                                                users.levels.length > 0 ?
                                                                    users.levels.map((level, key2) =>

                                                                        <div key={key2} className="row card-body d-inline-block">
                                                                            < Paper >
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        <div className="row">
                                                                                            <div className="col">Role: <br /> <b>{level.roles}</b></div>
                                                                                            <div className="col">Id: <br /> <b>{level._id}</b></div>
                                                                                            <div className="col"><button onClick={() => deleteLevels(level._id, users._id)} className=" btn btn-dark border-0 gradient-primary3 rounded-circle-pxl">Remove</button>

                                                                                            </div>
                                                                                        </div>
                                                                                    </TableCell>
                                                                                </TableRow>

                                                                                {"branches" in level ? level.branches.length > 0 ?
                                                                                    level.branches.map((branches, key3) =>
                                                                                        <TableRow>
                                                                                            <TableCell key={key3}>{branches}</TableCell>
                                                                                            <TableCell><Button color="danger" onClick={() => removeBranch(users._id, level._id, branches)}>Remove Branch</Button></TableCell>
                                                                                        </TableRow>


                                                                                    ) : null : null}
                                                                                {branch ? key + "_" + key2 in branch ?
                                                                                    branch[key + "_" + key2].map((branches, index) =>
                                                                                        <div key={index} className="row"  >
                                                                                            {/* <select className="mt-2" style={{ width: '85%', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} placeholder="Branch Name"
                                                                                        defaultValue={branches} name={"branch"} onChange={(e) => handleChange(e)}>
                                                                                        <option value="Manufacturing">Manufacturing</option>
                                                                                        <option value="Development">Development</option>
                                                                                        <option value="Testing">Testing</option>
                                                                                        <option value="Installation">Installation</option>
                                                                                        <option value="Done">Done</option>
                                                                                    </select> */}
                                                                                            <div className="col-md-9">
                                                                                                <Autocomplete
                                                                                                    id="combo-box-demo"
                                                                                                    options={list}
                                                                                                    getOptionLabel={(option) => option}
                                                                                                    style={{ width: "100 %" }}
                                                                                                    onSelect={(e) => handleChange(e, key + "_" + key2, index)}

                                                                                                    renderInput={(params) => <TextField  {...params} label="branches" defaultValue={branches} variant="outlined" />}
                                                                                                    name={"branch"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                                <Button color="danger" onClick={() => { handleremove(key + "_" + key2, index, key2) }} style={{ display: 'block', marginTop: '10px', borderRadius: '50%', float: 'right' }}> <i class="fas fa-minus-circle"></i></Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                    : null : null}
                                                                                <Button
                                                                                    style={{ display: 'block', border: 'none', background: 'white', justifyContent: "center", marginTop: '10px', borderRadius: '50px', float: 'right' }} onClick={() => { addstage(key + "_" + key2, key2, level._id) }}><i className="fas fa-plus"></i></Button>



                                                                                <div>
                                                                                    <Button
                                                                                        style={{ display: 'block', border: 'none', marginTop: '10px', background: 'white', borderRadius: '50px', float: 'right' }} onClick={() => { branchSend(level, users._id) }}>
                                                                                        <i class="far fa-paper-plane"></i></Button>
                                                                                </div>
                                                                            </Paper>
                                                                        </div>

                                                                    ) : "N /A"
                                                            }
                                                            </TableCell>
                                                        </Collapse>
                                                    </TableRow>
                                                </>
                                            )
                                        })}



                                        {/* <div className="d-flex justify-content-end my-3 mr-3">
                                    <div className="d-flex m-1">
                                        {pagenumbers.map(number =>
                                            <div key={number} className="page-item">
                                                <button onClick={() => paginate(number)} className="page-link">
                                                    {number}
                                                </button>
                                            </div>

                                        )}
                                    </div>
                                </div> */}
                                    </TableBody>
                                </Table>
                            </Paper>

                        </div >
                    }

                </div >
            )
        }
        else {
            return (

                <div>
                    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                        Users
                    </h2>
                    {toggleConfirm ?
                        <ConfirmPopup returnConfirm={confirmDelete} />
                        : null}

                    {user == 0 ?
                        <Loader />
                        :

                        <div>

                            <div className="row m-0 ">
                                <div className="col-md-2   form-group MuiFilledInput-root">
                                    <select className="MuiInputBase-input MuiFilledInput-input"

                                        id="filled-full-width"
                                        style={{ width: "calc(100% - 30px)" }}
                                        onChange={(e) => limit(e)}
                                        defualtValue={10}
                                        name={"Item"}
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <TextField

                                        className="m-0"
                                        id="filled-full-width"
                                        label="Users"

                                        placeholder="Search Users"
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => { setSearchUsers(e.target.value); setIsOpen([]) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                    />
                                </div>
                                <div className='col-md-4' >
                                    <Autocomplete filterOptions={filterOptions}


                                        // onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                        id={"Organizations"}
                                        options={userIds}
                                        getOptionLabel={(option) => option}

                                        style={{ width: "100%" }}
                                        onSelect={(e) => { setSearchOrg(e.target.value); setIsOpen([]) }}
                                        renderInput={(params) =>
                                            <TextField  {...params} label="Search Organizations"


                                            />

                                        }

                                    />
                                </div>
                            </div>

                            {/* <div>
                        <div className="container">
                            <table>
                                <th>
                                    <td>Email</td>
                                    <td>Rigestered</td>
                                    <td>Logo</td>
                                    <td>Organizations</td>
                                    <td>Email</td>
                                </th>
</table>
                        </div>
                    </div> */}
                            <Paper >
                                <Table>
                                    {/* <TableHead>
                                <TableCell>
                                    <div className="row m-0">
                                        <div className="col-1">#</div>
                                        <div className="col-3">Email</div>
                                        <div className="col-2">Rigestered</div>
                                        <div className="col-3">Logo</div>
                                        <div className="col-2">Organizations</div>
                                        <div className="col-1">Delete</div>
                                    </div>
                                </TableCell>
                            </TableHead> */}
                                    <div
                                        className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                                    >
                                        {/* <div className="Index"></div> */}
                                        <div className="row w-100 p-2 m-0">
                                            <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                                <b>#</b>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                                <b>Email</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Registered</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Operation Access</b>
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Logo</b>
                                            </div>

                                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                                <b>Organizations</b>
                                            </div>
                                        </div>
                                    </div>
                                    <TableBody>
                                        {/* this.state.filterdocs.slice(this.state.selectedPage.start, this.state.selectedPage.end).map((data, index) => */}
                                        {currentUsers.map((users, key) => {
                                            return (
                                                <>
                                                    <div className="row w-100 p-2 m-0 listView" key={key}>
                                                        <div className="col-lg-1 col-md-86col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline"># </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            {key + 1}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Email: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            {users._id}
                                                        </div>
                                                        {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Rigestered: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <div className="live_hisbtn" style={{ width: '200px' }}>
                                                                <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                    <div className={users.registered ? "togglebtn selectbtn" : "togglebtn"}> Registered </div>
                                                                    <div className={!users.registered ? "togglebtn selectbtn" : "togglebtn"}> Unregister </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline"> Operation Access: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <div className="live_hisbtn" style={{ width: '200px' }}>
                                                                <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                    {"operations" in users ?
                                                                        <>
                                                                            <div className={users.operations === "ADMIN" ? "togglebtn selectbtn" : "togglebtn"}> ADMIN </div>
                                                                            <div className={users.operations !== "ADMIN" ? "togglebtn selectbtn" : "togglebtn"}> VIEWER</div>
                                                                        </>
                                                                        : <div className='togglebtn selectbtn'>No Acsess</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Logo: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <div className="live_hisbtn" style={{ width: '200px' }}>
                                                                <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                                                                    <div className={users.telenor ? "togglebtn selectbtn" : "togglebtn"}> True </div>
                                                                    <div className={!users.telenor ? "togglebtn selectbtn" : "togglebtn"}> False </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                            <b className="d-lg-none d-inline">Organizations: </b>
                                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                                            <Button className=" btn btn-dark border-0 gradient-primary3 rounded-circle-pxl m-0" onClick={toggle.bind(this, key + 1)} style={{ marginBottom: '1rem' }}>Levels <i className="fa fa-angle-down"></i>  </Button>
                                                        </div>

                                                    </div>
                                                    <TableRow>
                                                        <Collapse isOpen={isOpen.find(openedIndex => openedIndex === (key + 1))}>
                                                            <Container>

                                                                <div className="row justify-content-end py-3" >
                                                                    {levels.map((level, key2) => (<React.Fragment key={key2}>
                                                                        <div className='col-6 mb-2 ' >
                                                                            <Autocomplete filterOptions={filterOptions}


                                                                                onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                                                                id={"roles"}
                                                                                options={userRoles}
                                                                                getOptionLabel={(option) => option}

                                                                                style={{ width: "100%" }}
                                                                                renderInput={(params) =>

                                                                                    <TextField  {...params} label="UserRoles" value={level.roles}


                                                                                    />

                                                                                }

                                                                            />
                                                                        </div>
                                                                        <div className='col-6 mb-2 ' >
                                                                            <Autocomplete filterOptions={filterOptions}
                                                                                options={userIds}
                                                                                getOptionLabel={(option) => option}
                                                                                onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                                                                id={"_id"}
                                                                                style={{ width: "100%" }}
                                                                                renderInput={(params) =>
                                                                                    <TextField {...params} name={"Id" + key + key2}

                                                                                        label={"Organizations Id"}

                                                                                        value={level._id} />
                                                                                }

                                                                            />
                                                                        </div>
                                                                        {branchList.length > 1 ?
                                                                            <div className='col-12 mb-2 ' >
                                                                                <Autocomplete filterOptions={filterOptions}

                                                                                    onChange={(event, value) => handleChangeInput(event, value, level._id)}
                                                                                    options={branchList}
                                                                                    getOptionLabel={(option) => option}
                                                                                    multiple
                                                                                    id="branches"
                                                                                    style={{ width: "100%" }}
                                                                                    renderInput={(params) =>
                                                                                        <TextField  {...params} name={"Id" + key + key2}
                                                                                            label={"Branches"}

                                                                                            value={level.branches} />
                                                                                    }

                                                                                />
                                                                            </div>
                                                                            : null}


                                                                        <TextField
                                                                            name={"Id" + key + key2}
                                                                            label={"Organizations Id"}
                                                                            variant="filled"
                                                                            value={levels["id" + key + key2]}
                                                                            onChange={event => handleChangeInput(levels.id, event)}
                                                                        />
                                                                    </React.Fragment>
                                                                    ))}
                                                                    {loader == false ?
                                                                        <Button className="mr-3"

                                                                            variant="contained"
                                                                            color="primary"
                                                                            // type="submit"
                                                                            endIcon={<Icon>send</Icon>}
                                                                            onClick={() => updateLevels(users._id)}
                                                                        >Send</Button>

                                                                        : <img
                                                                            src={LoaderGif}
                                                                            className="w-100 m-1 p-6"
                                                                            style={{ height: "56.94px" }}
                                                                        />}
                                                                </div>

                                                            </Container>


                                                            <TableCell >{
                                                                users.levels.length > 0 ?
                                                                    users.levels.map((level, key2) =>

                                                                        <div key={key2} className="row card-body d-inline-block">
                                                                            < Paper >
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        <div className="row">
                                                                                            <div className="col">Role: <br /> <b>{level.roles}</b></div>
                                                                                            <div className="col">Id: <br /> <b>{level._id}</b></div>
                                                                                            {/* <div className="col"><button disabled onClick={() => deleteLevels(level._id, users._id)} className=" btn btn-dark border-0 gradient-primary3 rounded-circle-pxl">Remove</button>

                                                                                            </div> */}
                                                                                        </div>
                                                                                    </TableCell>
                                                                                </TableRow>

                                                                                {"branches" in level ? level.branches.length > 0 ?
                                                                                    level.branches.map((branches, key3) =>
                                                                                        <TableRow>
                                                                                            <TableCell key={key3}>{branches}</TableCell>
                                                                                            {/* <TableCell><Button color="danger" disabled onClick={() => removeBranch(users._id, level._id, branches)}>Remove Branch</Button></TableCell> */}
                                                                                        </TableRow>


                                                                                    ) : null : null}
                                                                                {branch ? key + "_" + key2 in branch ?
                                                                                    branch[key + "_" + key2].map((branches, index) =>
                                                                                        <div key={index} className="row"  >
                                                                                            {/* <select className="mt-2" style={{ width: '85%', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} placeholder="Branch Name"
                                                                                        defaultValue={branches} name={"branch"} onChange={(e) => handleChange(e)}>
                                                                                        <option value="Manufacturing">Manufacturing</option>
                                                                                        <option value="Development">Development</option>
                                                                                        <option value="Testing">Testing</option>
                                                                                        <option value="Installation">Installation</option>
                                                                                        <option value="Done">Done</option>
                                                                                    </select> */}
                                                                                            {/* <div className="col-md-9">
                                                                                                <Autocomplete
                                                                                                    id="combo-box-demo"
                                                                                                    options={list}
                                                                                                    getOptionLabel={(option) => option}
                                                                                                    style={{ width: "100 %" }}
                                                                                                    onSelect={(e) => handleChange(e, key + "_" + key2, index)}

                                                                                                    renderInput={(params) => <TextField  {...params} label="branches" defaultValue={branches} variant="outlined" />}
                                                                                                    name={"branch"}
                                                                                                />
                                                                                            </div> */}
                                                                                            {/* <div className="col-md-2">
                                                                                                <Button color="danger" onClick={() => { handleremove(key + "_" + key2, index, key2) }} style={{ display: 'block', marginTop: '10px', borderRadius: '50%', float: 'right' }}> <i class="fas fa-minus-circle"></i></Button>
                                                                                            </div> */}
                                                                                        </div>
                                                                                    )
                                                                                    : null : null}
                                                                                {/* <Button
                                                                                    style={{ display: 'block', border: 'none', background: 'white', justifyContent: "center", marginTop: '10px', borderRadius: '50px', float: 'right' }} onClick={() => { addstage(key + "_" + key2, key2, level._id) }}><i className="fas fa-plus"></i></Button>
 */}


                                                                                {/* <div>
                                                                                    <Button
                                                                                        style={{ display: 'block', border: 'none', marginTop: '10px', background: 'white', borderRadius: '50px', float: 'right' }} onClick={() => { branchSend(level, users._id) }}>
                                                                                        <i class="far fa-paper-plane"></i></Button>
                                                                                </div> */}
                                                                            </Paper>
                                                                        </div>

                                                                    ) : "N /A"
                                                            }
                                                            </TableCell>
                                                        </Collapse>
                                                    </TableRow>
                                                </>
                                            )
                                        })}



                                        {/* <div className="d-flex justify-content-end my-3 mr-3">
                                    <div className="d-flex m-1">
                                        {pagenumbers.map(number =>
                                            <div key={number} className="page-item">
                                                <button onClick={() => paginate(number)} className="page-link">
                                                    {number}
                                                </button>
                                            </div>

                                        )}
                                    </div>
                                </div> */}
                                    </TableBody>
                                </Table>
                            </Paper>

                        </div >
                    }

                </div >
            )
        }

    }

}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps, null)(Users);
