import React, { useState, useEffect, useRef } from 'react'
import { Card, CardBody, Button } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import moment from 'moment';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { subMinutes } from 'date-fns';
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
// import ToastPopup from '../../components/popup/toastPopup'
import LoaderGif from "../../assets/img/loader/loader.svg";
import { message } from 'antd';
import { dataHandler } from '../../functions/functions';
import cookies from '../../shared/utilities/cookies';
// import ToastPopup from "../popup/toastPopup";

// import { Button, Container } from '@material-ui/core';




const AddDevicePopup = (props) => {

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });

    const [loader, setLoader] = useState(false)
    const [levelIds, setLevelIds] = useState('')
    const [typeArray, setTypeArray] = useState([])
    let [levelList, setLevelList] = useState([])
    let [hubList, setHubList] = useState([])
    const deviceType = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/sensors')
        let temp = dataHandler(response.data)
        setTypeArray(temp)
        return;
    }
    useEffect(() => {
        deviceType()
    }, [])
    useEffect(() => {
        orgId()
    }, [])
    let [device, setDevice] = useState([{ name: "", type: "", hubId: "", levelId: "", _id: "" }])

    const addDevice = (index) => {
        // for (var i = 1; i <= device.length; i++) {
        //     setDevice({
        //         name: "device" + i,
        //         type: "",
        //     })
        // device.push({
        //     name: "device " + device.length + 1,
        //     type: "",
        // })
        // }
        // if (device[0] == {}) {
        //     setDevice(device => [{ name: "device" + device.length, type: "" }])
        // }
        setDevice(device => [...device, { name: "", type: "", hubId: "", levelId: "", _id: "" }])
        console.log(device)
    }

    const removeDevice = (index) => {
        let remove = [...device]
        remove.splice(index, 1)
        setDevice(remove)
    }
    const [orgId1, setOrgId1] = useState(null);
    const [orgId2, setOrgId2] = useState(null);
    let [organizationsId, setOrganizationId] = useState([])
    const orgId = async () => {
        setLoader(true)
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getRootLevels')
        let temp = dataHandler(response1.data)
        setOrganizationId(temp)
        return setLoader(false)
    }
    const getLevelIDs = (obj) => {
        levelArray.push(obj._id)
        if (!obj.levels) {
            return;
        }
        obj.levels.forEach(child => getLevelIDs(child))
    }


    const ChangeHandle = (e, index) => {
        if (e) {
            console.log(e.target.name, e.target.value, index)
            let device1 = [...device]
            let name = e.target.name
            let value = e.target.value
            device1[index][name] = value
            // if (name === "name") {
            //     handle()
            // }
            // let name1 = device1.name.replaceAll(" ", "");
            // name1 = device1.name?.replaceAll(/[^A-Za-z0-9_]/g, "")
            // device1["_id"] = device1.hubId + "_" + name1
            setDevice(device1)
            // device1[name] = e.target.value
        }
    }


    const [data, setData] = useState('')
    const handle = (e, index) => {
        if (e) {
            const newdata = { ...data }
            if (e.target.name === "Organizations") {
                levelId(e.target.value)
                newdata["SelectedLevel"] = null
            }
            newdata[e.target.name] = e.target.value
            device.forEach((index) => {
                index.hubId = newdata.hubId
                let name = index.name.replace(" ", "")
                index._id = newdata.hubId + "_" + name
                index.levelId = levelIds
            })
            setData(newdata)
            return
            // }
        }
    }
    let levelArray = []
    const levelId = async (_id) => {
        levelArray = []
        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + _id + '/hierarchy')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {
                    getLevelIDs(temp)
                    setLevelList(levelArray)
                }
            } catch (error) {
                return console.log(error)
            }
        }
        return;
    }
    let hubArray = []
    const hubId = async (levelId) => {
        hubArray = []
        setLevelIds(levelId)
        if (levelId) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + levelId + '/hubs')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {
                    temp.map(data => {
                        hubArray.push(data._id)
                    })
                    setHubList(hubArray)
                }
            } catch (error) {
                return console.log(error)
            }
        }


        return;
    }

    const submit = async () => {
        let checkNull = true;
        let checkName = true
        device.map((data, key) => {
            if (!data.name || !data.type || !levelIds || !data.hubId) { checkNull = false }
            device.map((data1, key1) => {
                if (data.name === data1.name && key !== key1) {
                    checkName = false
                }
            })

        }
        )
        if (checkNull) {
            if (checkName) {
                setLoader(true)
                device.forEach((index) => {
                    index.hubId = data.hubId
                    let name = index.name.replace(" ", "")
                    index._id = data.hubId + "_" + name
                    index.levelId = levelIds
                })
                let body = device
                console.log(body)
                try {
                    setLoader(true)
                    let token = cookies.getCookie('operationToken');
                    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                    await axios.post('/devices/bulk', body)
                        .then((res) => {
                            let temp = dataHandler(res.data)
                            setLoader(false)
                            alert("Succesfully Created")
                            props.hideUsPopup()
                            return temp;
                        })
                } catch (error) {
                    if (error.response.data.message == "Duplicate Key error.") {
                        alert("Device Already exist")
                    } else {
                        alert(error.response.data.message)
                    }
                    setLoader(false)
                    props.hideUsPopup()
                }
            } else {
                alert("Dulicate name not allowed")
            }
        }
        else {
            alert("plz fill all inputs filled")
            setLoader(false)
        }
    }
    return (
        <>
            {/* <ToastPopup ref={child} /> */}
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => props.hideUsPopup()} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', width: "80%", margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, maxHeight: "80vh" }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => props.hideUsPopup()} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        <div className='d-flex justify-content-between p-2'>
                            <h4 style={{ color: "black" }}>Add Device</h4>
                            <div>
                                <Button onClick={() => addDevice()} ><i className="fas fa-plus"></i></Button>
                            </div>
                        </div>
                        <div style={{ overflow: "auto", maxHeight: "calc(100vh - 300px)" }}>
                            {device.map((devices, index) =>

                                <div className="row m-0" key={index} >

                                    <div className='col-md-1 d-flex p-4'>
                                        <Label style={{ color: "black", fontSize: "12px" }}>
                                            {"Device" + " " + (index + 1)}
                                        </Label></div>

                                    {/* <div className="col-md-6 col-12">
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}>
                                        MAC
                                    </Label>
                                    <Input style={{ width: "100%" }} onChange={(e) => handle(e)} type="text" value={data._id} name="_id" placeholder="minimum 11 digits" />
                                </FormGroup>
                            </div> */}
                                    <div className="col-md-5 col-12">
                                        <FormGroup>
                                            <Label style={{ color: "black", fontSize: "12px" }}>
                                                Name
                                            </Label>
                                            <Input style={{ width: "100%" }} type="text" onChange={(e) => ChangeHandle(e, index)} name="name" defaultValue={devices.name} placeholder="name" />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <FormGroup>
                                            <Label style={{ color: "black", fontSize: "12px" }}>Type</Label>
                                            <Autocomplete
                                                options={typeArray}
                                                getOptionLabel={(option) => option}
                                                // onSelect={event => handle(event)}

                                                style={{ width: "100%" }}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={"Device Type"}
                                                        variant="filled"
                                                        onSelect={(e) => ChangeHandle(e, index)}
                                                        defaultValue={devices.type}
                                                        name="type" />
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-1 col-12 d-flex p-5'>
                                        <div>
                                            {device.length > 1 ?
                                                <Button onClick={() => removeDevice(index)}><i className="fas fa-minus " style={{ color: "red" }}></i></Button>
                                                :
                                                // <Button onClick={() => removeDevice(index)}><i className="fas fa-minus " disabled style={{ color: "red" }}></i></Button>
                                                null
                                            }</div>
                                    </div>
                                    {/* <div className='col-md-2 col-12'>
                                    <FormGroup>
                                        <button>Add</button>
                                    </FormGroup>
                                </div> */}
                                </div>
                            )}

                            <FormGroup>
                                <div className="row m-0">
                                    <div className="col-md-6 col-12">
                                        <Label style={{ color: "black", fontSize: "12px" }}>Organization Type</Label>
                                        <Autocomplete

                                            options={organizationsId}
                                            getOptionLabel={(option) => option}

                                            style={{ width: "100%" }}
                                            renderInput={(params) =>
                                                <TextField {...params} a
                                                    onSelect={(e) => setOrgId1(e.target.value)}
                                                    label={"Organizations"}
                                                    variant="filled"
                                                    value={data.Organizations}

                                                    name={"Organizations"} />
                                            }

                                        />
                                        <Button style={{ float: "right" }} className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary ml-2 col-2" onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i><span className="d-md-inline d-none"> Search </span></Button>
                                    </div>
                                    {/* <div className="col-md-2 col-4 "> */}

                                    {/* </div> */}


                                    {levelList.length > 1 ?

                                        <div className="col-md-6 col-12">
                                            <Label style={{ color: "black", fontSize: "12px" }}>Level Id</Label>
                                            <Autocomplete

                                                options={levelList}
                                                getOptionLabel={(List) => List}

                                                style={{ width: "100%" }}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        onSelect={(e) => setOrgId2(e.target.value)}
                                                        label={"LevelId"}
                                                        variant="filled"
                                                        value={data.SelectedLevel}

                                                        name={"levelId"} />
                                                }

                                            />
                                            <Button style={{ float: "right" }} className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary ml-2 col-2" onClick={() => hubId(orgId2)}><i className="fa fa-search mr-1"></i><span className="d-md-inline d-none" > Search </span></Button>
                                        </div>
                                        : null}
                                </div>
                            </FormGroup>

                            {hubList.length > 0 ?
                                <FormGroup>
                                    <div className="col-md-12 col-12">
                                        <Label style={{ color: "black", fontSize: "12px" }}>Hub Type</Label>
                                        <Autocomplete

                                            options={hubList}
                                            getOptionLabel={(list) => list}

                                            className="w-100"
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    onSelect={event => handle(event)}
                                                    label={"HubId"}
                                                    variant="filled"
                                                    value={data.SelectedLevel}

                                                    name={"hubId"} />
                                            }


                                        />
                                    </div>
                                </FormGroup>
                                : null}
                        </div>






                    </div>
                    <div className="row m-0 px-3 justify-content-end">
                        {loader == false ?
                            <Button color="success" onClick={() => submit()}
                                style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                Device Create</Button>
                            : <img
                                src={LoaderGif}
                                className="w-100 m-1"
                                style={{ height: "34.8px" }}
                            />}
                    </div>
                </div>
            </div >
        </>
    );
}


export default AddDevicePopup