import React, { Component } from 'react';

import $ from 'jquery';

import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';

import LogsUI from './logsUI'

class LogsOrgs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            collapse: false,
            dropdownOpen: false
        }
    }

    componentWillMount() {
        //console.log(this.props.type)
        //console.log(this.props.events)
    }

    componentDidMount() {

        let height = $("#" + this.props.type + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.type + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.type + "-card").height();
            height = (height / 2);
            //console.log('Heightttttttttttttttttttttt:' + height)
            $("#" + this.props.type + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    toggle = async () => {
        if (!this.state.collapse) {

            $("#" + this.props.type).attr('class', 'col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12');


        }

        else {
            $("#" + this.props.type).attr('class', 'col-12 col-sm-12 col-md-6 col-xl-6 col-lg-12');
        }


        this.setState({ collapse: !this.state.collapse });

        //console.log('click')
    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div class="card" style={{ borderColor: '#31A2AC' }} id={this.props.type + "-card"}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>{this.props.type} ({this.props.events.length})</div>
                    <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i class="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i class="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            {(this.props.events.length > 0) ?
                                <div class="row">
                                    {this.props.events.map((event, key) => (
                                        <div key={key} className='col-sx-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 ' id={event.organization}>
                                            <LogsUI type={event.organization} events={event.logs} logType={this.props.type} />
                                        </div>
                                    ))}
                                </div>
                                :
                                <div class="card" style={{ borderColor: '#31A2AC' }}>
                                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>
                                        <small><b>No Events</b></small>
                                    </div>
                                </div >
                            }
                        </CardBody>
                    </Collapse>
                    : null
                }

            </div>
        );
    }
}

export default LogsOrgs;