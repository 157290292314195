import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import $ from 'jquery';

import { Card, Button, ButtonDropdown, ButtonGroup, ButtonToolbar, CardBody, Collapse, Col } from 'reactstrap';

import { getDeviceById } from '../../shared/services/events';

import DeviceStatusGraphs from './deviceStatusGraphs';

class FailedDevicesUI extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            dropdownOpen: false
        }
    }

    componentDidMount() {
        //console.log(this.props)

        let height = $("#" + this.props.id + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.id + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.id + "-card").height();
            height = (height / 2);
            //console.log('Heightttttttttttttttttttttt:' + height)
            $("#" + this.props.id + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    toggle = async () => {

        if (!this.state.collapse) {

            $("#" + this.props.id).attr('class', 'col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12');


        }

        else {
            $("#" + this.props.id).attr('class', 'col-12 col-sm-12 col-md-6 col-xl-6 col-lg-12');
        }


        this.setState({ collapse: !this.state.collapse });

        //console.log('click')
    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    handleDevice = (data) => {
        this.props.history.push({ pathname: '/operation/devices_status/' + data.deviceId, device: data })
    }

    render() {
        return (
            <div class="card" style={{ borderColor: '#31A2AC' }} id={this.props.type + "-card"}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>{this.props.id.split('_')[0]} ({this.props.devices.length})</div>
                    <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i class="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i class="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            <div class="row">
                                {this.props.devices.map(device => (
                                    <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6' id={device.deviceId}>
                                        <DeviceStatusGraphs device={device} />
                                    </div>
                                ))
                                }
                            </div>
                        </CardBody>
                    </Collapse>
                    : null
                }

            </div>
        );
    }
}

export default withRouter(FailedDevicesUI);