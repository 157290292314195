import React from 'react'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import MemoryIcon from '@material-ui/icons/Memory';
import HOST from '../../shared/config/dev'
// import Operation from '../../components/Operations/operations'
const Sidebar = () => {
    return (
        <div>
            <ul className="row" >
                <li className="col-12">

                    <Link id="Operations" title="Operation" className="text-white navbtn" to={"/"} ><i className="nav-icon icon-speedometer"></i> Operation</Link>
                    <Link id="Users" title="Users" className="text-white navbtn" to={"/users"}      ><i class="fas fa-users"></i>Users</Link>
                    <Link id="Levels" title="Levels" className="text-white navbtn" to={"/levels"} ><i className="fa fa-sliders-h mt-1"></i> Levels</Link>
                    <Link id="Hubs" title="Hubs" className="text-white navbtn" to={"/hubs"} ><i className="mt-1" ><DeviceHubIcon /></i>Hubs</Link>
                    <Link id="Devices" title="Devices" className="text-white navbtn" to={"/devices"}><i className="mt-1" ><MemoryIcon /></i>Devices</Link>
                    <Link id="Wingels" title="Wingles" className="text-white navbtn" to={"/wingles"}><i className="fas fa-broadcast-tower"></i>Wingles</Link>
                    {/* {HOST.HOST !== 'https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2' ? */}
                    <>
                        <Link id="DevicePublisher" title="MQTT" className='="text-white navbtn' to={"/MQTT"}><i className="fas fa-upload"></i>MQTT</Link>
                        <Link id="Jobs" title="Jobs" className='="text-white navbtn' to={"/jobs"}><i className="fa fa-hourglass-half"></i>Jobs</Link>
                    </>
                    {/* : null} */}
                    {/*<a id="Devices" title="Devices" className="text-white navbtn" href={"#"} ><i className="icon-speedometer"></i>Devices</a>
                    <a id="Configrations" title="Configration" className="text-white navbtn" href={"#"} ><i className="icon-speedometer"></i>Configrations</a>
                    <a id="Wingles" title="Wingles" className="text-white navbtn" href={"#"} ><i className="icon-speedometer"></i>Wingles</a> */}
                </li>

            </ul>
        </div>
    )
}

export default Sidebar
