import React, { useState, useEffect, useRef } from 'react'
import { Card, CardBody, Button } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import moment from 'moment';
import { subMinutes } from 'date-fns';
import cookies from '../../shared/utilities/cookies';
import axios from '../../shared/utilities/axios';
// import axios from '../../shared/utilities/axios';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import ToastPopup from '../../components/popup/toastPopup'
import LoaderGif from "../../assets/img/loader/loader.svg";
import { dataHandler } from '../../functions/functions';
// import ToastPopup from "../popup/toastPopup";

// import { Button, Container } from '@material-ui/core';




const AddWinglePopup = (props) => {
    const [loader, setLoader] = useState(false)
    // const child = useRef();
    // const url = "https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2/wingle"
    const [data, setData] = useState({
        imei: '',
        simmsisdn: '',
        imsi: '',
        location: '',
        espmac: '',
        operator: ''
    })
    let operatorArray = [
        "Jazz",
        "Telenor",
        "Zong",
        "Ufone",
        "Warid"
    ]
    const submit = () => {
        // refs.child.myToast_danger(header, massage);
        setLoader(true)
        let body = {
            imei: data.imei,
            simmsisdn: data.simmsisdn,
            imsi: data.imsi,
            location: data.location,
            espmac: data.espmac,
            operator: data.operator
        }
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        axios.post('/wingle', body)

            .then((res) => {
                let temp = dataHandler(res.data)
                setLoader(false)
                props.hideUsPopup()
                alert("Successful Created")
                return temp;
            }).catch(error => {
                if (error.response.data.message == "Duplicate Key error.") {
                    alert("Wingle Already Access")
                } else {
                    alert(error.response.data.message)
                }
                setLoader(false)
                props.hideUsPopup()
            })
    }



    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });
    const handle = (e) => {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata);
        console.log(data)
    }


    return (
        <>
            {/* <ToastPopup ref={child} /> */}
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1020' }}>
                <div onClick={() => props.hideUsPopup()} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', width: "50%", margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, maxHeight: "80vh" }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => props.hideUsPopup()} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        <h4 style={{ color: "black" }}>Add Wingle</h4>
                        <Form>
                            <div style={{ overflow: "auto", maxHeight: "calc(100vh - 300px" }}>
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}></Label>
                                    <Input onChange={(e) => handle(e)} id="imei" type="text" name="imei" value={data.imei} placeholder="Imei" />
                                </FormGroup>
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}></Label>
                                    <Input onChange={(e) => handle(e)} id="simmsisdn" type="text" name="simmsisdn" value={data.simmsisdn} placeholder="Simmisdn" />
                                </FormGroup>
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}></Label>
                                    <Input onChange={(e) => handle(e)} id="imsi" type="text" name="imsi" value={data.imsi} placeholder="Imsi" />
                                </FormGroup>
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}></Label>
                                    <Input onChange={(e) => handle(e)} id="location" type="text" name="location" value={data.location} placeholder="Location" />
                                </FormGroup>
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}></Label>
                                    <Input onChange={(e) => handle(e)} id="espmac" type="text" name="espmac" value={data.espmac} placeholder="Espmac" />
                                </FormGroup>
                                <FormGroup>
                                    <Label style={{ color: "black", fontSize: "12px" }}></Label>
                                    <Autocomplete filterOptions={filterOptions}

                                        options={operatorArray}
                                        getOptionLabel={(option) => option}
                                        onSelect={e => handle(e)}

                                        style={{ width: "100%" }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={"Operators"}
                                                variant="filled"
                                                value={data.operator}
                                                id="operator"
                                                name={"operator"} />
                                        }

                                    />
                                    {/* <Input onChange={(e) => handle(e)} id="operator" type="text" name="operator" value={data.operator} placeholder="Operator" /> */}
                                </FormGroup>
                            </div>
                            {loader == false ?


                                <Button color="success" onClick={submit}
                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                    User Create</Button>
                                : <img
                                    src={LoaderGif}
                                    className="w-100 m-1"
                                    style={{ height: "34.8px" }}
                                />}
                        </Form>


                    </div>
                </div>
            </div >
        </>
    );
}


export default AddWinglePopup