import React, { Component } from 'react';
import uniqid from 'uniqid'
import $ from 'jquery'
import Dropdown from 'react-dropdown';

import ProductionHubsUI from './productionHubsUI';
import { random } from 'core-js/core/number';

class HubsComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="row">
                {this.props.hubs.map(h => (
                    <div key={uniqid()} className='col-sx-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 ' id={h}>
                        <ProductionHubsUI hub={h} />
                    </div>
                ))}
            </div>
        );
    }
}

export default HubsComponent;