import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../../components/loader/loader';
import { getAllDevices } from '../../shared/services/production';
import DevicesStatusOrgs from './deviceStatusOrgs';

class failedEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            devices: null
        }
    }

    async componentDidMount() {
        if (this.props.location.devices) {
            this.setState({ devices: this.props.location.devices })
        }
        else {
            // let failedCmd = await axios.get('http://192.168.1.106:3004/api/v1/failedCommands');
            // failedCmd = failedCmd.data;

            let failedDevices = await getAllDevices();
            this.setState({ devices: failedDevices })
        }
    }

    render() {
        //console.log(this.state.devices)
        return (
            <React.Fragment>
                {(this.state.devices) ?
                    <div class="row" style={{ backgroundColor: '#f2f2f2', marginBottom: '20px' }}>
                        <div className='col-12' style={{ fontSize: 24, paddingTop: '12px' }}>
                            Failed Devices
                        </div>
                        <div className='col-12' style={{ marginTop: 20 }}>
                            <div class="row">
                                <div className='col-sx-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 ' id='Faulty'>
                                    <DevicesStatusOrgs orgs={this.state.devices.faulty} id="Faulty" />
                                </div>
                                <div className='col-sx-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 ' id='Working'>
                                    <DevicesStatusOrgs orgs={this.state.devices.working} id="Working" />
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='col-12'>
                        <Loader />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default failedEvents;