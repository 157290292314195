import React, { Component } from 'react';

import Loader from '../../components/loader/loader';
import { getLogs } from '../../shared/services/production'

import LogsOrgs from './logOrgs'

class Logs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logs: null,
            logTypes: []
        }
    }

    async componentDidMount() {
        //console.log(this.props)
        if (this.props.location.logs) {
            this.setState({ logs: this.props.location.logs, logTypes: Object.keys(this.props.location.logs) })
        }
        else {
            let logs = await getLogs(this.props.match.params.logType);
            this.setState({ logs, logTypes: Object.keys(logs) })
        }
    }

    render() {
        //console.log(this.state.logs);
        //console.log(this.state.logTypes);
        return (
            <React.Fragment>
                {(this.state.logs) ?
                    <div class="row" style={{ backgroundColor: '#f2f2f2', marginBottom: '20px' }}>
                        <div className='col-12' style={{ fontSize: 24, paddingTop: '12px' }}>
                            {this.state.logs.hubType} Hubs
                        </div>
                        <div className='col-12' style={{ marginTop: 20 }}>
                            <div class="row">
                                {this.state.logTypes.map((logType, key) => (
                                    (logType !== 'hubType' ?
                                        <div key={key} className='col-sx-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 ' id={logType}>
                                            <LogsOrgs type={logType} events={this.state.logs[logType]} />
                                        </div>
                                        :
                                        null
                                    )
                                ))}
                            </div>
                        </div>

                    </div>
                    :
                    <div className='col-12'>
                        <Loader />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Logs;