import React, { useState, useEffect } from 'react'
// import SocketIOClient from 'socket.io-client'
// import { useEffect } from 'react'
import Table from '@material-ui/core/Table';
import Loader from '../../components/loader/loader'
import { Button } from 'reactstrap';
import JSONInput from 'react-json-editor-ajrm';
import { dataHandler } from '../../functions/functions';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getdata1 } from '../../getAlerts'
import axios from '../../shared/utilities/axios'
import { getDate, getTime, todayDate } from '../../functions/functions'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import Logs from '../../operations/logs/logs';
// import axios from 'axios';
import { connect } from 'react-redux';
// import { blue } from '@material-ui/core/colors';

// let client = SocketIOClient()
let client;

const State = (props) => {
    let [data, setData] = useState([])

    const [uspopup, setUspopup] = useState(false)

    const Popup = (id, value,) => {
        let tempdata;
        tempdata = value
        setUspopup(

            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => setUspopup(null)} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{
                    background: 'rgb(255, 255, 255)', width: "60%", boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, position: "absolute",
                    top: "calc(50% + 25px)",
                    left: "calc(50% + 25px)",
                    transform: "translate(-50%, -50%)"
                }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => setUspopup(null)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        {
                            <div>
                                <JSONInput colors={{
                                    default: "black",
                                    background: "white",
                                    string: "red"

                                }} theme="light_mitsuketa_tribute" viewOnly={true} width="100%" placeholder={tempdata} height='25vh' />
                            </div>
                        }
                    </div>
                    {/* </Form> */}
                </div>
            </div>

        )

    }
    let [loader, setLoader] = useState(false)
    useEffect(async () => {
        console.log(props)
        setLoader(true)
        let result = await axios.get('/devices/' + props.hubId + '/logs?limit=1')
        let temp = dataHandler(result.data)
        setData(temp)
        setLoader(false)
    }, [])

    useEffect(async () => {
        let Events = [...data]
        let deviceEvent = props.stateReducer.currentState
        if (deviceEvent.hubId === props.hubId) {
            if ("type" in deviceEvent) {
                let newEvents = deviceEvent
                Events.map((item) => {
                    if (item.type !== newEvents.type) {
                        console.log("item", item.type, newEvents.type)
                    } else {
                        console.log("newEvents", newEvents)
                    }
                })
                let Events2 = Events.filter(item => item.type !== newEvents.type)
                Events2.unshift(
                    {
                        event: deviceEvent,
                        type: deviceEvent.type,
                        created: deviceEvent.created,
                    }
                )
                if (Events2.length > 20) {
                    Events2.splice(19, 20)
                }
                setData(Events2)
            }
        }
    }, [props.stateReducer])
    return (
        <div className="dropshadow rounded-1">


            <>

                <div
                    className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                >
                    {/* <div className="Index"></div> */}
                    <div className="row w-100 p-2 m-0">
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">
                            <b>Event</b>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 align-self-center">
                            <b>Type </b>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 align-self-center">
                            <b>Last Recived</b>
                        </div>
                    </div>
                </div>
                {loader ? <Loader /> :
                    <div
                        className="row m-0 rounded-brl-1"
                        style={{ maxHeight: "200px", minHeight: "100px", overflowY: "auto" }}
                    >
                        {data.length ? data.map((state, key) => (
                            <div className="row w-100 p-2 m-0 listView" key={key}>
                                <div title={JSON.stringify(state)} className="col-lg-6 col-md-6 col-12 Date align-self-center text-truncate">
                                    <b className="d-lg-none d-inline">Event</b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    <Button onClick={() => Popup(state.hubId, state, 'Value')} >{JSON.stringify(state)}</Button>

                                </div>
                                <div className="col-lg-3 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Type: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {state.type}
                                </div>
                                {/* <StyledTableCell >{getTime(state.created) + " " + getDate(state.created)}</StyledTableCell> */}

                                <div className="col-lg-3 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Last Recived: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {getTime(state.created) + "  " + getDate(state.created)}
                                </div>
                            </div>
                        )) : <div className="col noData">No State</div>}
                    </div>
                }
            </>

            {uspopup}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        stateReducer: state.stateReducer
    };
};

export default connect(mapStateToProps, null)(State);
