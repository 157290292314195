

const INITIAL_STATE = {
    State: [],
    connect: false,
    currentState: {
        created: 0.0,
        deviceId: 0.0,
        message: 0.0,
        type: 0.0,
        _id: 0.0,
        hubId: 0.0,
    },



    alerts: []
}
const stateReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case "ADD_STATE":
            //changed to 
            /* var oldevents = state.events;
            oldevents.push(action.payload); */


            return { ...state, currentState: action.payload };
        case "CONNECT":

            return { ...state, connect: true };

        case "DISCONNECT":

            return { ...state, connect: false, events: [] };
        default:
            return state
    }
}



export default stateReducer