import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoaderGif from '../../components/loader/loader.svg';
export default class Notfound extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            <div>
                <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white">Page Not found</h2>
                <div className="w-100 h-100 align-self-center d-flex py-5 " style={{ height: '100vh' }}>
                    <img className="align-self-center w-100 my-5" style={{ height: '200px' }} src={LoaderGif} />
                </div>


            </div>
        )
    }
}
