import React, { useState, useEffect } from 'react'
import AddHubPopup from './AddHubPopup'

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Form, FormGroup, Label, Input, FormText, Collapse, Button, Dropdown } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import LoaderGif from "../../assets/img/loader/loader.svg";
import Loader from '../../components/loader/loader';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ConfirmPopup from '../../components/popup/confirmPopup'
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
// import axios from 'axios'
import { dataHandler, toTitles } from '../../functions/functions'
import cookies from '../../shared/utilities/cookies';
import { connect } from "react-redux";
// import { type } from 'jquery';
function Hubs(props) {
    const [UsPopup, setUsPopup] = useState(false)
    const hideUsPopup = (check) => {
        setUsPopup(false)
        // props.reload()
    }
    const reload = () => {
        if (orgId1) {
            levelId(orgId1)
        }
    }
    const [typeArray, setTypeArray] = useState([])
    const hubType = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/hubs/')
        let temp = dataHandler(response.data)
        setTypeArray(temp)
        return;
    }
    useEffect(() => {
        hubType()
    }, [])
    let levelObj = [];

    let [array, setArray] = useState([])
    const [getHub, setGetHub] = useState([])
    const [loader, setLoader] = useState(false)
    const [isOpen, setIsOpen] = useState([]);
    const [orgId1, setOrgId1] = useState(null);
    const [data, setData] = useState({})
    const [hubId, setHubId] = useState(null)
    const [sendFileLoader, setSendFileLoader] = useState(false)
    const [toggleConfirm, setToggleConfirm] = useState(false)
    const [uspopup, setUspopup] = useState(false)

    let [organizationsId, setOrganizationId] = useState([])
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });
    // let [organizationsId, setOrganizationId] = useState([])
    const orgId = async () => {
        setLoader(true)
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getRootLevels')
        let temp = dataHandler(response1.data)
        setOrganizationId(temp)
        return setLoader(false)

    }
    const [searchHubs, setSearchHubs] = useState('')
    useEffect(() => {
        orgId()
    }, [])
    // const handle = (e) => {
    //     if (e) {
    //         if (e.target.value) {
    //             const newdata = { ...data }
    //             if (e.target.name === "Organizations") {
    //                 levelId(e.target.value)
    //                 newdata["SelectedLevel"] = null

    //             }
    //             newdata[e.target.name] = e.target.value
    //             setData(newdata);

    //             return
    //         }
    //     }
    // }
    const toggle = (index) => {


        isOpen.find(open => open === index) ?
            setIsOpen(isOpen.filter(open => open !== index)) :
            setIsOpen(isOpen.concat([index]));
    };
    const getHubObj = (obj) => {
        levelObj.push(obj)
        if (!obj.levels) {
            return
        }

        obj.levels.forEach(child => getHubObj(child))

    }
    const getLevelIDs = (obj) => {
        array.push(obj._id)
        if (!obj.levels) {
            return
        }

        obj.levels.forEach(child => getLevelIDs(child))
    }
    const levelId = async (_id) => {
        array = []
        levelObj = []

        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + _id + '/hubs')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {

                    getHubObj(temp)
                    setGetHub(levelObj);
                }
            } catch (error) {
                return console.log(error)
            }
        }


        return;


    }

    const changeLevelId = async (_id) => {
        // array = []
        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + _id + '/hierarchy')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {
                    getLevelIDs(temp)
                    console.log(array)
                    // setUser(user.data)
                    // setUser(temp.reverse())
                    return array

                }
            } catch (error) {
                return console.log(error)
            }
        }
    }

    const deleteHubs = async (_id) => {
        setToggleConfirm(true)
        setHubId(_id);

        return;
    }
    const confirmDelete = async (_id) => {
        setLoader(true)
        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.delete("/hubs/" + hubId)
                    .then((res) => {
                        alert("succesfully Deleted", hubId)
                        setToggleConfirm(false)
                        setLoader(false)
                        reload()
                    })
            } catch (error) {
                alert(error.response.data.message)
                setToggleConfirm(false)
                setLoader(false)
                reload()
            }
        }
        setToggleConfirm(false)
        return;

        setHubId(null)
        return;
    }
    const handle2 = (e) => {
        if (e) {
            if (e.target.value) {
                put = { id: e.target.id, body: { [e.target.name]: e.target.value } }
            }
        }
        return;
    }

    const update = async () => {
        setLoader(true)

        if (put) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.put("/hubs/" + put.id, put.body).then((res) => {
                    let temp = dataHandler(res.data)
                    alert("Successful Updated")
                    reload()
                    setLoader(false)
                    setUspopup(false)
                    return temp;
                })
            }
            catch (error) {
                alert(error.res.data.message)
                setUspopup(false)
                setLoader(false)
                return
            }
        }

    }
    const updateConfig = async () => {
        setLoader(true)
        put.body = config;
        if (put) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.put("/hubs/" + put.id + '/config', put.body).then((res) => {
                    let temp = dataHandler(res.data)
                    setLoader(false)
                    alert("Succesfully Updated")
                    setUspopup(false)
                    reload()
                    return temp;
                })
            }
            catch (error) {
                alert(error.response.data.message)
                setUspopup(false)
                setLoader(false)
                return
            }
        }



    }
    const [file, setFile] = useState(null)
    const [indexFile, setIndexFile] = useState()
    const changeFile = (e, key) => {
        if (e.target.files[0]) {
            setIndexFile(key)
            setFile(e.target.files[0])
        }
    }

    const sendFile = async (hub) => {
        setSendFileLoader(true)
        console.log(hub, file)
        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "file",
            file,
        );

        // Details of the uploaded file 
        // console.log(this.state.file);

        // Request made to the backend api 
        // Send formData object 
        try {
            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            await axios.put("hubs/" + hub + "/ota", formData).then((res) => {
                let temp
                if (typeof res.data == "string") {
                    temp = dataHandler(res.data);
                } else {
                    temp = res.data;
                }
                setSendFileLoader(false)
                alert("Succesfully Updated")
                setUspopup(false)
                setIndexFile()
                reload()
                return temp;
            })
        }
        catch (error) {
            alert(error.response.data.message)
            setUspopup(false)
            setSendFileLoader(false)
            setIndexFile()
            return
        }
    }
    const configHandler = (events) => {
        let e = events.target,
            type = e.name.split('zzzzz')[1],
            index = e.name.split('zzzzz')[2],
            keyName = configKeys[index],
            value = e.value
        if (type === "value") {
            if (value === "true") {
                value = true
            }
            else if (value === "false") {
                value = false
            }
            else if (value.match(/^[0-9]+$/)) {
                value = parseInt(value)
            }
            let temp = { [keyName]: value }
            setConfig({ ...config, ...temp })
        }
        else if (type === "key") {
            config[value] = config[keyName];
            delete config[keyName];
            configKeys[index] = value
            setConfigKeys(configKeys)
            setConfig(config);
        }

    }

    let [config, setConfig] = useState({})
    // const [keyArray, setKeyArray] = useState([])

    // const Config = async (hubId) => {
    //     let result = await axios.get('https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2/hubs/' + hubId + '/config')

    //     setConfig(result.data)

    // }
    // let addedfunction
    const addConfig = () => {
        // addedfunction =  key: 'value' 
        config["key" + (configKeys.length + 1)] = "value"
        setConfig(config)


        setConfigKeys(Object.keys(config))


    }
    const deleteconfig = (keyName, index) => {
        let temp = config
        setConfig({})
        setConfigKeys([])
        setTimeout(e => {
            delete temp[keyName]
            setConfig(temp)
            setConfigKeys(Object.keys(temp))
        }, 1)

    }

    let filterHubs = []
    if (getHub.length !== 0) {

        filterHubs = getHub[0].filter(item => {
            if (item.name) {
                return searchHubs !== "" ? item._id.toLowerCase().includes(searchHubs.toLowerCase()) || item.levelId.toLowerCase().includes(searchHubs.toLowerCase()) || item.type.toLowerCase().includes(searchHubs.toLowerCase()) || item.name.toLowerCase().includes(searchHubs.toLowerCase()) : item;
            }
        })
    }

    let [put, setPut] = useState(null)

    let [rowData, setRowData] = useState(null)
    let [configKeys, setConfigKeys] = useState([])
    let Popup = async (data, id, name) => {
        changeLevelId(id.split("_")[0])
        if (name === 'configUpdate') {
            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            let result = await axios.get('/hubs/' + id + '/config')
            let temp = dataHandler(result.data)
            config = temp;
            if (config) {
                setConfigKeys(Object.keys(config))
                setConfig(temp)
            }
            else {
                setConfigKeys([])
                setConfig({})
            }

        }
        let tempdata = data
        setPut({ id: id, body: { [name]: data } });
        setRowData({ data: data, id: id, name: name })

        setUspopup(true)



    }
    if ("operations" in props.userInfo.varification) {
        if (props.userInfo.varification.operations === "ADMIN") {
            return (
                <>

                    {toggleConfirm ?
                        <ConfirmPopup id={hubId} returnConfirm={confirmDelete} />
                        : null}
                    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                        Hubs
                        <button onClick={() => { setUsPopup(true) }} className="btn btn-light"><i className="fa fa-user-plus"></i> Add Hub</button>
                        {UsPopup ?
                            <AddHubPopup hideUsPopup={hideUsPopup} />
                            : null}
                    </h2>
                    <FormGroup>
                        <div className="row">
                            <div className="col-md-10 col-12">
                                <Autocomplete

                                    options={organizationsId}
                                    getOptionLabel={(option) => option}

                                    className="w-100"
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            onSelect={(e) => setOrgId1(e.target.value)}
                                            label={"Organizations"}
                                            variant="filled"
                                            value={data.Hubs}

                                            name={"Hubs"} />
                                    }

                                />
                            </div>
                            <div className="col-md-2 d-flex mt-1">
                                <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary w-100" onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i> Search</Button>
                            </div>
                        </div>

                    </FormGroup>
                    <TextField
                        className="m-0"
                        id="filled-full-width"
                        label="Hubs"
                        style={{ margin: 8 }}
                        placeholder="Search Hubs"
                        fullWidth
                        margin="normal"
                        onChange={(e) => { setSearchHubs(e.target.value); setIsOpen([]) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                    />
                    <Paper>
                        <Table>
                            {/* <TableHead>
                        <TableCell>
                            <div className="row m-0">
                                <div className="col">ID</div>
                                <div className="col-2">Name</div>
                                <div className="col-1">Type</div>
                                <div className="col-3">LevelId</div>
                                <div className="col-1">Config</div>
                                <div className="col-2">Devices</div>
                                <div className="col-1">Delete</div>
                            </div>
                        </TableCell>
                    </TableHead> */}
                            <div
                                className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                            >
                                {/* <div className="Index"></div> */}
                                <div className="row w-100 p-2 m-0">
                                    <div className="col-lg col-md-6 col-12 align-self-center">
                                        <b>ID</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                        <b>Name</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                        <b>Type</b>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                        <b>LevelId</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                        <b>Config</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                        <b>OTA</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                        <b>Devices</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                        <b>Delete</b>
                                    </div>
                                </div>
                            </div>
                            <TableBody>



                                <div
                                    className="row m-0 rounded-brl-1 "
                                    style={{ maxHeight: "350px", minHeight: "350px", overflowY: "auto" }}
                                >
                                    {filterHubs.length ? filterHubs.map((hub, key) => {
                                        return (
                                            <>
                                                <div className="row w-100 p-2 m-0 listView" key={key}>
                                                    <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Id: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        {hub._id}
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Name: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button onClick={() => Popup(hub.name, hub._id, 'name')}>   {hub.name}</Button>
                                                    </div>
                                                    {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Type: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button onClick={() => Popup(hub.type, hub._id, 'type')}>   {hub.type}</Button>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">LevelId: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button onClick={() => Popup(hub.levelId, hub._id, 'levelId')}>   {hub.levelId}</Button>
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Config: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button onClick={() => Popup(config, hub._id, 'configUpdate')} >Config  </Button>
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                        {!sendFileLoader ?
                                                            <React.Fragment>
                                                                <b className="d-lg-none d-inline">OTA: </b>
                                                                <br className="d-inline d-lg-none d-md-none " />
                                                                <label className='btn btn-dark gradient-primary3 m-0'>
                                                                    <i className='fa fa-file-upload'></i>
                                                                    <input type="file" accept='.bin' style={{ display: 'none' }} onChange={(e) => changeFile(e, key)} />
                                                                </label>
                                                                {key === indexFile ?
                                                                    <button onClick={() => sendFile(hub._id)} className='btn btn-dark gradient-primary2 ml-1'><i className='fa fa-save'></i></button>
                                                                    : null}
                                                            </React.Fragment>
                                                            : <img
                                                                src={LoaderGif}
                                                                className="w-100 m-1"
                                                                style={{ height: "34.8px" }}
                                                            />}
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Device: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button className=" btn btn-dark border-0 gradient-primary3  m-0" onClick={toggle.bind(this, key + 1)} style={{ marginBottom: '1rem' }}>Devices <i className="fa fa-angle-down"></i>  </Button>
                                                    </div>
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Delete: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button className="btn btn-danger  rounded-circle-pxl" onClick={() => deleteHubs(hub._id)}><i class="fas fa-minus-circle"></i></Button>
                                                    </div>
                                                </div>


                                                <TableRow>
                                                    <Collapse isOpen={isOpen.find(openedIndex => openedIndex === (key + 1))}>
                                                        <TableCell>
                                                            <h2 className="text-black" >Devices</h2>
                                                            {hub.devices.length > 0 ?
                                                                hub.devices.map((Hub, key2) =>

                                                                    <div key={key2} className="row card-body d-inline-block">
                                                                        < Paper >
                                                                            <TableRow  >
                                                                                <TableCell>

                                                                                    <div className="col-12">&nbsp; &nbsp; {Hub}</div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </Paper>
                                                                    </div>
                                                                ) : "N /A"
                                                            }
                                                        </TableCell>
                                                    </Collapse>
                                                </TableRow>


                                            </>

                                        )
                                    }) : null}

                                </div>
                            </TableBody>
                        </Table >
                    </Paper >
                    {uspopup ?
                        <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                            <div onClick={() => setUspopup(false)} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                            </div>
                            <div className="rounded-1" style={{
                                background: 'rgb(255, 255, 255)', width: "80%", boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, position: "absolute",
                                top: "calc(50% + 25px)",
                                left: "calc(50% + 25px)",
                                transform: "translate(-50%, -50%)"
                            }}>
                                <div className="card" style={{ border: '0px' }}>
                                    <a className="close align-self-end rounded-circle" onClick={() => setUspopup(false)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                                        &times;
                                    </a>
                                    {
                                        "levelId" in put.body ?

                                            <>
                                                <div>
                                                    <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                    <p>{rowData.id}</p>
                                                    <Autocomplete

                                                        options={array}
                                                        getOptionLabel={(option) => option}

                                                        defaultValue={rowData.data} id={rowData.id}
                                                        className="w-100"
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                onSelect={(e) => handle2(e)}
                                                                label={"Change LevelId"}
                                                                variant="filled"
                                                                name={rowData.name}
                                                            // name={"levelId"}
                                                            />
                                                        }

                                                    />
                                                </div>
                                                <div className="row m-0 px-3 justify-content-end">

                                                    {loader === false ?
                                                        <Button color="success" onClick={() => update()}
                                                            style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                                            Update</Button>
                                                        : <img
                                                            src={LoaderGif}
                                                            className="w-100 m-1"
                                                            style={{ height: "34.8px" }}
                                                        />}
                                                </div>
                                            </>

                                            :
                                            'configUpdate' in put.body ?
                                                config ?
                                                    <div style={{
                                                        maxHeight: "calc(75vh - 100px)",
                                                        overflowY: 'auto',
                                                        overflowX: "hidden"
                                                    }}>
                                                        <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                        <p>{rowData.id}</p>
                                                        {configKeys.map((name, index) =>
                                                            name === "hubId" || name === "_id" ?
                                                                <div className="row  split-b p-3" key={index}>
                                                                    <div className="col-md-6 ">

                                                                        <label>Key</label>
                                                                        <input disabled className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" value={name} />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Value</label>
                                                                        <input disabled className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" value={config[name]} />

                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row  split-b p-3" key={index}>
                                                                    <div className="col-md-6 ">

                                                                        <label>Key</label>
                                                                        <input className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" onChange={(e) => configHandler(e)} name={name + "zzzzzkeyzzzzz" + index} defaultValue={name} />
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <label>Value</label>
                                                                        <input className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" onChange={(e) => configHandler(e)} name={name + "zzzzzvaluezzzzz" + index} defaultValue={config[name]} />

                                                                    </div>
                                                                    <div className="col-md-1 mt-1 p-3"><Button className="btn btn-danger  rounded-circle-pxl" onClick={() => deleteconfig(name, index)} ><i class="fas fa-minus-circle"></i></Button>

                                                                    </div>
                                                                </div>
                                                        )}
                                                    </div>

                                                    : null






                                                :
                                                "type" in put.body ?

                                                    <>                                                    <div>
                                                        <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                        <p>{rowData.id}</p>
                                                        <Autocomplete

                                                            options={typeArray}
                                                            getOptionLabel={(option) => option}


                                                            defaultValue={rowData.data} id={rowData.id}
                                                            className="w-100"
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                    label={"Change HubType"}
                                                                    onSelect={(e) => handle2(e)}
                                                                    variant="filled"
                                                                    name={rowData.name}
                                                                // name={name}
                                                                />
                                                            }

                                                        />
                                                    </div>

                                                        <div className="row m-0 px-3 justify-content-end">

                                                            {loader === false ?
                                                                <Button color="success" onClick={() => update()}
                                                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                                                    Update</Button>
                                                                : <img
                                                                    src={LoaderGif}
                                                                    className="w-100 m-1"
                                                                    style={{ height: "34.8px" }}
                                                                />}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div>
                                                            <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                            <p>{rowData.id}</p>
                                                            <Input defaultValue={rowData.data} name={rowData.name} id={rowData.id} className="col-12 overflow-auto" onChange={(e) => handle2(e)} style={{ maxHeight: "65vh" }}></Input>
                                                        </div>
                                                        <div className="row m-0 px-3 justify-content-end">

                                                            {loader === false ?
                                                                <Button color="success" onClick={() => update()}
                                                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                                                    Update</Button>
                                                                : <img
                                                                    src={LoaderGif}
                                                                    className="w-100 m-1"
                                                                    style={{ height: "34.8px" }}
                                                                />}
                                                        </div>
                                                    </>
                                    }

                                    {configKeys.length > 0 ?
                                        'configUpdate' in put.body ?

                                            <div className="row m-0 px-3 justify-content-end">
                                                <Button color="success" onClick={() => { addConfig() }}

                                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                                    <i class="fas fa-plus"></i></Button>
                                                {loader === false ?
                                                    <Button color="success" onClick={() => updateConfig()}

                                                        style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                                        Update</Button>
                                                    : <img
                                                        src={LoaderGif}
                                                        className="w-100 m-1"
                                                        style={{ height: "34.8px" }}
                                                    />}
                                            </div>
                                            :
                                            <div className="row m-0 px-3 justify-content-end">

                                                {/* {loader === false ?
                                                    <Button color="success" onClick={() => update()}

                                                        style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                                        Update</Button>
                                                    : <img
                                                        src={LoaderGif}
                                                        className="w-100 m-1"
                                                        style={{ height: "34.8px" }}
                                                    />} */}
                                            </div> : "CONFIGIRATION IS NOT AVAILABLE"}
                                    {/* </Form> */}

                                </div>
                            </div>
                        </div >
                        : null}
                </>

            )
        } else {
            return (
                <>

                    {toggleConfirm ?
                        <ConfirmPopup returnConfirm={confirmDelete} />
                        : null}
                    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                        Hubs
                    </h2>
                    <FormGroup>
                        <div className="row">
                            <div className="col-md-10 col-12">
                                <Autocomplete

                                    options={organizationsId}
                                    getOptionLabel={(option) => option}

                                    className="w-100"
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            onSelect={(e) => setOrgId1(e.target.value)}
                                            label={"Organizations"}
                                            variant="filled"
                                            value={data.Hubs}

                                            name={"Hubs"} />
                                    }

                                />
                            </div>
                            <div className="col-md-2 d-flex mt-1">
                                <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary w-100" onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i> Search</Button>
                            </div>
                        </div>

                    </FormGroup>
                    <TextField
                        className="m-0"
                        id="filled-full-width"
                        label="Hubs"
                        style={{ margin: 8 }}
                        placeholder="Search Hubs"
                        fullWidth
                        margin="normal"
                        onChange={(e) => { setSearchHubs(e.target.value); setIsOpen([]) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                    />
                    <Paper>
                        <Table>
                            {/* <TableHead>
                        <TableCell>
                            <div className="row m-0">
                                <div className="col">ID</div>
                                <div className="col-2">Name</div>
                                <div className="col-1">Type</div>
                                <div className="col-3">LevelId</div>
                                <div className="col-1">Config</div>
                                <div className="col-2">Devices</div>
                                <div className="col-1">Delete</div>
                            </div>
                        </TableCell>
                    </TableHead> */}
                            <div
                                className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                            >
                                {/* <div className="Index"></div> */}
                                <div className="row w-100 p-2 m-0">
                                    <div className="col-lg col-md-6 col-12 align-self-center">
                                        <b>ID</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                        <b>Name</b>
                                    </div>
                                    <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                        <b>Type</b>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                        <b>LevelId</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                        <b>Config</b>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                        <b>Devices</b>
                                    </div>

                                </div>
                            </div>
                            <TableBody>



                                <div
                                    className="row m-0 rounded-brl-1 "
                                    style={{ maxHeight: "350px", minHeight: "350px", overflowY: "auto" }}
                                >
                                    {filterHubs.length ? filterHubs.map((hub, key) => {
                                        return (
                                            <>
                                                <div className="row w-100 p-2 m-0 listView" key={key}>
                                                    <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Id: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        {hub._id}
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Name: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button disabled>   {hub.name}</Button>
                                                    </div>
                                                    {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                                    <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Type: </b>
                                                        <br className="d-inline d-lg-none d-md-none" />
                                                        <Button disabled>   {hub.type}</Button>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">LevelId: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button disabled>   {hub.levelId}</Button>
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Config: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button onClick={() => Popup(config, hub._id, 'configUpdate')} >Config  </Button>
                                                    </div>
                                                    <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Device: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button className=" btn btn-dark border-0 gradient-primary3  m-0" onClick={toggle.bind(this, key + 1)} style={{ marginBottom: '1rem' }}>Devices <i className="fa fa-angle-down"></i>  </Button>
                                                    </div>
                                                    {/* <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Delete: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button className="btn btn-danger  rounded-circle-pxl" disabled ><i class="fas fa-minus-circle"></i></Button>
                                                    </div> */}
                                                </div>


                                                <TableRow>
                                                    <Collapse isOpen={isOpen.find(openedIndex => openedIndex === (key + 1))}>
                                                        <TableCell>
                                                            <h2 className="text-black" >Devices</h2>
                                                            {hub.devices.length > 0 ?
                                                                hub.devices.map((Hub, key2) =>

                                                                    <div key={key2} className="row card-body d-inline-block">
                                                                        < Paper >
                                                                            <TableRow  >
                                                                                <TableCell>

                                                                                    <div className="col-12">&nbsp; &nbsp; {Hub}</div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </Paper>
                                                                    </div>
                                                                ) : "N /A"
                                                            }
                                                        </TableCell>
                                                    </Collapse>
                                                </TableRow>


                                            </>

                                        )
                                    }) : null}

                                </div>
                            </TableBody>
                        </Table >
                    </Paper >
                    {uspopup ?
                        <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                            <div onClick={() => setUspopup(false)} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                            </div>
                            <div className="rounded-1" style={{
                                background: 'rgb(255, 255, 255)', width: "80%", boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, position: "absolute",
                                top: "calc(50% + 25px)",
                                left: "calc(50% + 25px)",
                                transform: "translate(-50%, -50%)"
                            }}>
                                <div className="card" style={{ border: '0px' }}>
                                    <a className="close align-self-end rounded-circle" onClick={() => setUspopup(false)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                                        &times;
                                    </a>
                                    {
                                        "levelId" in put.body ?


                                            <div>
                                                <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                <p>{rowData.id}</p>
                                                <Autocomplete

                                                    options={array}
                                                    getOptionLabel={(option) => option}

                                                    defaultValue={rowData.data} id={rowData.id}
                                                    className="w-100"
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            onSelect={(e) => handle2(e)}
                                                            label={"Change LevelId"}
                                                            variant="filled"
                                                            name={rowData.name}
                                                        // name={"levelId"}
                                                        />
                                                    }

                                                />
                                            </div>
                                            :
                                            'configUpdate' in put.body ?
                                                config ?
                                                    <div style={{
                                                        maxHeight: "calc(75vh - 100px)",
                                                        overflowY: 'auto',
                                                        overflowX: "hidden"
                                                    }}>
                                                        <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                        <p>{rowData.id}</p>
                                                        {configKeys.map((name, index) =>
                                                            name ?
                                                                <div className="row  split-b p-3" key={index}>
                                                                    <div className="col-md-6 ">

                                                                        <label>Key</label>
                                                                        <input disabled className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" value={name} />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Value</label>
                                                                        <input disabled className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" value={config[name]} />

                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row  split-b p-3" key={index}>
                                                                    <div className="col-md-6 ">

                                                                        <label>Key</label>
                                                                        <input className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" onChange={(e) => configHandler(e)} name={name + "zzzzzkeyzzzzz" + index} defaultValue={name} />
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <label>Value</label>
                                                                        <input className="btn btn-light border-0 rounded-circle-pxl w-100 text-left p-2 px-3" onChange={(e) => configHandler(e)} name={name + "zzzzzvaluezzzzz" + index} defaultValue={config[name]} />

                                                                    </div>

                                                                </div>
                                                        )}
                                                    </div>

                                                    : null






                                                :
                                                "type" in put.body ?


                                                    <div>
                                                        <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                        <p>{rowData.id}</p>

                                                        <Autocomplete

                                                            options={typeArray}
                                                            getOptionLabel={(option) => option}


                                                            defaultValue={rowData.data} id={rowData.id}
                                                            className="w-100"
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                    label={"Change HubType"}
                                                                    onSelect={(e) => handle2(e)}
                                                                    variant="filled"
                                                                    name={rowData.name}
                                                                // name={name}
                                                                />
                                                            }

                                                        />
                                                    </div>

                                                    :
                                                    <div>
                                                        <h3 className="text-primary ">{toTitles(rowData.name)}</h3>
                                                        <p>{rowData.id}</p>
                                                        <Input defaultValue={rowData.data} name={rowData.name} id={rowData.id} className="col-12 overflow-auto" onChange={(e) => handle2(e)} style={{ maxHeight: "65vh" }}></Input>
                                                    </div>
                                    }
                                    {configKeys.length > 0 ?
                                        'configUpdate' in put.body ?

                                            <div className="row m-0 px-3 justify-content-end">

                                            </div>
                                            :
                                            <div className="row m-0 px-3 justify-content-end">

                                                {loader === false ?
                                                    <Button color="success" onClick={() => update()}

                                                        style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                                        Update</Button>
                                                    : <img
                                                        src={LoaderGif}
                                                        className="w-100 m-1"
                                                        style={{ height: "34.8px" }}
                                                    />}
                                            </div> : "CONFIGIRATION IS NOT AVAILABLE"}
                                    {/* </Form> */}

                                </div>
                            </div>
                        </div >
                        : null}
                </>

            )
        }
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps, null)(Hubs);

