import React, { Component } from 'react';
import { withRouter } from 'react-router'

import { Doughnut } from 'react-chartjs-2';
import { powerDevices } from '../../config/config';
import { hslToHex } from '../../functions/functions'

const colors = ["#FF6B33", '#DEE943', '#abd372', '#f74545', '#f2b354', '#32a852', '#1cebdd', '#1149f0', '#760cf0', '#f00cd2', '#f00c32', '#ddf00c', '#f0950c', '#f0550c']

class PieLogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            doughnut: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        hoverBackgroundColor: [],
                    }],
            },
            length: null,
            options: {
                tooltips: {
                    enabled: true,

                },
                maintainAspectRatio: false,
                cutoutPercentage: 60,
                legend: {
                    display: false,
                    position: 'bottom',
                }
            }
        }
    }

    componentWillMount() {

        let logs = this.props.logs;
        let logTypes = Object.keys(this.props.logs);

        let arrdata = [];
        let arrbackgroundColor = [];
        let arrhoverBackgroundColor = [];
        let arrlabels = [];

        let index = 0;
        let len = 0
        let devices = 0;
        let org = 0
        logTypes.map((type) => {
            console.log(type, logTypes, logs)
            if (type !== 'hubType' && logs[type].length > 0) {
                if (this.props.logs.hubType === "Device Status") {
                    devices = 0;
                    console.log(logs[type], type, logTypes)
                    logs[type].map(d => {
                        if (typeof d === "object") { devices += d.devices.length; }
                        else {
                            devices += d
                        }
                    })
                    arrdata.push(devices)
                    //console.log("devices", arrdata)
                    len = logs[type].length
                    // logs[type].map(d => {
                    //     len += d.devices.length
                    // })
                    // } else if (this.props.logs.hubType === "Power" || this.props.logs.hubType === "Hub" || this.props.logs.hubType === "Atmosphere" || this.props.logs.hubType === "Water" || this.props.logs.hubType === "Agri" || this.props.logs.hubType === "Hub") {

                }
                else {
                    org = 0
                    console.log(logs[type])
                    logs[type].map(d => {
                        arrdata.push(org = d.logs.length)
                        // let labels = d.organization.split("_")
                        arrlabels.push(d.organization)
                        arrbackgroundColor.push(colors[index])
                        arrhoverBackgroundColor.push(colors[index])
                        index++;
                    })
                    len = logs[type].length
                }

                //     // //console.log(len)
                ////console.log('Nauman',type,logs[type].length)
                arrlabels.push(type)
                arrbackgroundColor.push(colors[index])
                arrhoverBackgroundColor.push(colors[index])
                index++;
            }
            else if (logs[type].length == 0) {
                arrdata.push(100)
                len = logs[type].length
                ////console.log('Nauman',type,logs[type].length)
                arrlabels.push("All_Devices_Working_Properly")
                arrbackgroundColor.push(colors[index + 2])
                arrhoverBackgroundColor.push(colors[index + 2])
                index++;

            }
        })
        // //console.log(this.state.doughnut)
        let doughnutObj = this.state.doughnut
        doughnutObj.labels = arrlabels;
        doughnutObj.datasets[0].data = arrdata;
        doughnutObj.datasets[0].backgroundColor = arrbackgroundColor;
        doughnutObj.datasets[0].hoverBackgroundColor = arrhoverBackgroundColor;

        this.setState({
            doughnut: doughnutObj,
            length: len,
        })
        // //console.log(doughnutObj, len)

    }

    _handleLogs = () => {
        this.props.history.push({ pathname: '/operation/logs/' + this.props.logs.hubType, logs: this.props.logs })
    }

    render() {
        return (
            <React.Fragment>
                <div class="card pb-3 dropshadow rounded-1" style={{ backgroundColor: 'white', borderColor: 'white' }} onClick={this._handleLogs}>
                    <div style={{ fontSize: 24, paddingBottom: '26px', paddingTop: '12px', paddingLeft: '10px' }}>
                        {this.props.logs.hubType === "Device Status" ?
                            this.props.logs.hubType :
                            this.props.logs.hubType + "Logs"}
                    </div>
                    <div id="chart">
                        <Doughnut data={this.state.doughnut} options={this.state.options} height={200} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(PieLogs);