import axios from '../utilities/axios';
import cookies from '../utilities/cookies';
import { dataHandler } from '../../functions/functions';

export let signIn = async (credentials) => {
    try {
        let response = await axios.post('/users/signin', credentials);
        let data = dataHandler(response.data)
        console.log(response, data)
        cookies.setCookie('operationToken', data.token, data.expiresIn);
        cookies.setCookie('operationuser', credentials._id, data.expiresIn);
        // console.log("LOGIN",data)
        return data;
    } catch (err) {
        // console.log("catch")
        err.response.data.error = true;
        return err.response.data;
    }
}

export let checkSignIn = () => {
    return cookies.getCookie('operationToken') ? true : false;
}

export let signOut = () => {
    cookies.deleteCookie('operationToken');
}


export let getSignedInUser = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/users/me');
        let data = dataHandler(response.data)
        return data;

    } catch (err) {
        //console.log(err.response);
        err.response.data.error = true;
        return err.response.data;
    }
}

export let userVerification = async (password) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.post('/users/confirmation', password);
        let data = dataHandler(response.data)

        //console.log(response)
        return data;

    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}

export let changePassword = async (oldpassword, newpassword) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.put('/users/updatepassword', { old: oldpassword, new: newpassword });
        let data = dataHandler(response.data)
        return data;

    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}
