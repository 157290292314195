import React, { Component } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { userVerification } from '../../shared/services/users'
import { publishDeviceData } from '../../getAlerts'
import { connect } from 'react-redux';
import CookieHandler from './../../shared/utilities/cookies'
import $ from 'jquery';
import { handleVerification } from '../../shared/services/handlingPublishData'

class ConfirmPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            wrong: false,
        };

        this.handlePassword = this.handlePassword.bind(this);
        this.handleRequest = this.handleRequest.bind(this);
    }
    // generateType = (device, status) => {
    //     return '' + this.props.currentHub.type + '_' + device.type + '_' + status
    // }
    async componentDidMount() {
        console.log(this.props)
    }
    handlePassword(event) {
        this.setState({
            password: event.target.value,
            wrong: false,
        });
    }


    // publishData = (type, value, device) => {
    //     if(this.props.selectedHub !== null){
    //         publishDeviceData(this.props.selectedHub.hubType, this.props.selectedHub.hubType.hubId, "AppEvents", "json", {
    //             hubId: this.props.selectedHub.hubId,
    //             restart: true,
    //             });

    //     }
    //     else{
    //         var data = {
    //             deviceId: this.props.device._id,
    //             hubId: this.props.device.hubId,
    //             value: value,
    //             type: type,
    //             createdBy: CookieHandler.getCookie('user')
    //         }
    //         publishDeviceData(this.props.currentHub.type, this.props.device.hubId, "AppEvents", "json", data);      
    //     }
    // }

    // async handleVerification(value) {
    //     // this.publishData(this.generateType(this.props.device, 'Status'), value)
    //     let res = await userVerification({ password: this.state.password })
    //     console.log("handleVerification",value)
    //     if (res === 'valid') {
    //         if(this.props.selectedHub !== null){
    //             this.publishData(null,null, this.props.selectedHub)
    //         }
    //         else{
    //             this.publishData(this.generateType(this.props.device, 'Status'), value)
    //         }
    //         this.setState({password: ''})    
    //         this.props.popup(null)
    //     }
    //     else {
    //         this.setState({password: ''})    
    //         console.log("Ye Chala")
    //         this.setState({ wrong: true })
    //     }
    // }

    async handleRequest() {
        // console.log("CONFIRM PASSWORD",this.props.verif_val)
        let response = await handleVerification(this.props.verif_val, this.state.password, this.props.data, this.props.device, this.props.selectedHub, this.props.popup, this.props.currentHub)
        this.setState({
            wrong: response,
            password: response ? this.state.password : ''
        })
    }

    render() {
        return (
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', display: 'flex', zIndex: '1020', backdropFilter: 'blur(5px)' }}>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => this.props.popup(null)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px' }}>
                            &times;
                        </a>
                        <div className="clearfix px-5 pb-3 card-body level-comp">
                            <h1 style={{ textAlign: 'center', margin: 0 }}>Confirm</h1>
                            <div className=" text-muted text-uppercase text-center font-weight-bold font-sm mdi mdi-account" style={{ marginBottom: '28px' }}> {this.props.activeOrgReducer.email}</div>
                            <h6 className="pl-3" style={{ marginBottom: '5px' }}>Enter Your Password</h6>
                            {/* <input style={{ width: '250px', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="password" value={this.state.password} onChange={this.handlePassword} /> */}
                            <input style={{ width: '250px', border: '0px', padding: '10px 20px', borderRadius: '50px', backgroundColor: 'rgb(240,240,240)' }} type="password" placeholder="Password"
                                value={this.state.password} onChange={this.handlePassword}
                            />
                            {(this.state.wrong) ?
                                <div className="font-xs pl-3" style={{ color: 'red' }} >Wrong Password</div>
                                : null
                            }
                            <div>
                                {/* <Button color="secondary" onClick={() => { this.handleVerification(1) }} style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>Submit</Button> */}
                                <Button color="success" onClick={this.handleRequest}
                                    style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                    Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrgReducer: state.activeOrgReducer,
    }
}

export default connect(mapStateToProps, null)(ConfirmPassword);