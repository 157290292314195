import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import $ from 'jquery';
import LoaderGif from './loader.svg';

import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
    FormGroup,
    Label,
    Input
  } from 'reactstrap';

class Loader extends Component{
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
      
    }


   

    render(){
    
        return(
            <div className="w-100 h-100 align-self-center d-flex py-5 " style={{height: '100vh'}}>
            <img className="align-self-center w-100 my-5" style={{height: '200px'}} src={LoaderGif} />
        </div>
             
        
        );
    }



}

const mapDispatchToProps = (dispatch) => {
    return {
        visit: (data) => { 
            dispatch({
                type : 'ADD_VISIT',
                payload : data
            }
            ) 
        }}
    }


const mapStateToProps = (state) => {
    return {
        organizationsReducer: state.organizationsReducer
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Loader); 





 