import * as config from './config';
import moment from 'moment';

export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export function flatenHierarchy(org) {
    let levels = [];
    traverseHierarchy(org, levels);

    return levels;
}

function traverseHierarchy(level, levels) {
    if (level) {
        if (level.levels) {
            for (let i = 0; i < level.levels.length; i++) {
                if (level.levels[i] && level.levels[i].levels) {
                    traverseHierarchy(level.levels[i], levels);
                    levels.push(level.levels[i]);
                } else {
                    levels.push(level.levels[i]);
                }
            }
        }
    }
}

export function convertToInt(date, start = null, end = null) {
    let dt = date
    dt = dt.replace('T', ' ');

    dt += '.000'
    //console.log(dt);
}

export function convertSheduleDate(date) {
    var dateStr = date.toString();
    var str
    if (dateStr.length == 4) {
        str = dateStr[0] + '' + dateStr[1] + ':' + dateStr[2] + '' + dateStr[3]
    }
    else {
        str = '0' + dateStr[0] + ':' + dateStr[1] + '' + dateStr[2]
    }

    return str
}


export function convertToDate(date, type) {
    date = date.toString();

    let year = date.substring(0, 4);
    let month = date.substring(4, 6);
    let day = date.substring(6, 8);
    let hours = date.substring(8, 10);
    let mins = date.substring(10, 12);
    let secs = date.substring(12, 14);

    if (type == 'day') {
        return hours;
    }
    else if (type == 'month') {
        return day;
    }

    else if (type == 'year') {
        return month;
    }

    else if (type == 'live') {
        return formatAMPM(hours, mins, secs);
    }
    else if (type == 'recent') {
        return hours;
    }

    else if (type == 'date') {
        return day + '-' + month + '-' + year;
    }

    else if (type == 'object') {
        return [year, month, day, hours, mins, secs];
    }

    return formatAMPM(hours, mins, secs) + ' ' + day + '-' + month + '-' + year;
    //return new Date(year, month, day, hours, mins, secs);
}


export function formatAMPM(hours, minutes, secs) {
    var hours = hours
    var minutes = minutes
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    if (minutes == 0) {
        minutes = "00"
    }
    else {
        minutes = minutes
    }
    //  minutes = minutes < 10 ? ''+minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + secs + " " + ampm;
    return strTime;
}

export function getUnit(type) {
    if (type === "Dust") {
        return "µg/m3"
    }
    else if (type === "Co2") {
        return "%"
    }
    else if (type === "Tvoc") {
        return "%"
    }
    else if (type === "Lpg") {
        return "%"
    }
    else if (type === "Co") {
        return "%"
    }
    else if (type === "Ch4") {
        return "%"
    }
}
export function getState(deviceType, status, sn) {

    if (deviceType == 'Ac') {
        if (sn == 1) {
            if (status === 0) {
                return 'Status : OFF';
            }
            else {
                return 'Status : ON';
            }
        }

        else if (sn === 2) {
            if (status === 0) {
                return 'Swing : OFF';
            }
            else {
                return 'Swing : ON';
            }
        }

        else {
            return 'Temperature' + ' ' + status + '°C'
        }
    }

    else if (deviceType == 'Fan' || deviceType == 'Light') {
        if (status === 0) {
            return 'Status : OFF';
        }
        else {
            return 'Status : ON';
        }
    }

    else if (deviceType == 'Door' || deviceType == 'Lock') {
        if (status === 0) {
            return 'Status : CLOSE';
        }
        else {
            return 'Status : OPEN';
        }
    }

    else if (deviceType == 'Curtain') {
        if (status === 0) {
            return 'Status : CLOSE';
        }
        else if (status == 1) {
            return 'Status : MID';
        }
        else {
            return 'Status : OPEN';
        }
    }

    else if (deviceType == 'Sound' || deviceType == 'Fire' || deviceType == 'Smoke') {
        if (status === 0) {
            return 'Status : NO ' + deviceType.toUpperCase();
        }
        else {
            return 'Status : ' + deviceType.toUpperCase();
        }
    }

    else if (deviceType == 'Temperature' || deviceType == 'Humidity') {
        return deviceType + ': ' + status + '' + (deviceType == 'Temperature' ? '°C' : '%')
    }

    else if (deviceType == 'Motion') {
        if (status === 0) {
            return 'Status : NO MOVEMENT';
        }
        else {
            return 'Status : MOVEMENT';
        }
    }

    else if (deviceType == 'WaterTank') {
        if (status === 0) {
            return 'Water Motor : OFF';
        }
        else {
            return 'Water Motor : ON';
        }
    }

    else if (deviceType == 'Moisture') {
        return ('Moisture : ' + status + '%')
    }
    else if (deviceType == 'Pressure') {
        return ('Pressure : ' + status + 'mbar')
    }

    else if (deviceType == 'Gust' || deviceType == 'WindSpeed') {
        return (deviceType + ' : ' + status + 'mph')
    }
    else if (deviceType == 'SolarRadiation' || deviceType == 'UV') {
        return (deviceType + ' : ' + status)
    }
    else if (deviceType == 'DewPoint') {
        return ('DewPoint : ' + status + '°C')
    }
    else if (deviceType == 'PhIndicator') {
        return ('Ph : ' + status)
    }

    else if (deviceType == 'Vibration') {
        if (status === 0) {
            return 'Status : NO VIBRATION';
        }
        else {
            return 'Status : VIBRATION';
        }
    }


    else if (deviceType == 'Position') {
        if (status === 0) {
            return 'Status : NORMAL';
        }
        else {
            return 'Status : TILT';
        }
    }

    else if (deviceType == 'Monitor') {
        if (status === 0) {
            return 'Status : OFF';
        }
        else {
            return 'Status : ON';
        }
    }
    else if (config.enviDeviceList().non_interactive.includes(deviceType)) {
        if (deviceType === 'Dust') {
            return (deviceType + ' : ' + status + ' µg/m')
        }
        else {
            return (deviceType + ' : ' + status + '%')
        }


    }




}


//mobile browser detection functions

export var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};