import React, { Component } from 'react';

import $ from 'jquery';

import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';

import Loader from '../../components/loader/loader';

import * as config from '../../config/config';

import LineGraphComponent from '../../components/graphs/general/line'
import BarGraphComponent from '../../components/graphs/general/barwater'
import ConsumptionWater from '../../components/graphs/consumption/consumptionwater'
import ConsumptionGraphComponent from '../../components/graphs/consumption/consumption'
import ConsumptionOil from '../../components/graphs/consumption/consumptionOil'
import BinaryGraphComponent from '../../components/graphs/general/binary'
import PowerGraphs from '../../components/graphs/power/graphs'

class DeviceGraphs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }
    }

    componentWillMount() {
        if (this.props.device) {
            this.setState({ data: this.props.device })
        }
        else if (this.props.location.device) {
            this.setState({ data: this.props.location.device })
        }
    }

    render() {

        if (!this.props.device) {
            if (this.props.location.device === undefined) {
                this.props.history.push('/operation/devices_status');
            }
        }

        if (this.state.data) {
            if (config.homeDevices.includes(this.state.data.device.type)) {
                return (
                    <div>
                        {(this.state.data) ?
                            ((this.state.data.device.type === "AC") ?
                                <div className="row">
                                    <div className='col-sx-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                        <BinaryGraphComponent hub={this.state.data.hub} device={this.state.data.device} sn={1} />
                                        <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} sn={3} />
                                        <BinaryGraphComponent hub={this.state.data.hub} device={this.state.data.device} sn={2} />
                                    </div>
                                </div>

                                :
                                ((this.state.data.device.type === "Temperature" || this.state.data.device.type === 'Humidity' || this.state.data.device.type === "Count") ?
                                    <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                    :
                                    ((this.state.data.device.type !== "Temperature" && this.state.data.device.type !== 'Humidity' && this.state.data.device.type !== "AC" && this.state.data.device.type !== "Count") ?
                                        <BinaryGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                        :
                                        null
                                    )
                                )
                            )
                            :
                            null
                        }
                    </div >
                )
            }

            else if (config.airqualityDevices.includes(this.state.data.device.type)) {
                return (
                    <div>
                        {(this.state.data) ?
                            ((this.state.data.device.type !== 'WaterTank') ?
                                <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                :
                                ((this.state.data.device.metadata.monitor === 'Oil') ?
                                    <div>
                                        <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                        <ConsumptionOil hub={this.state.data.hub} device={this.state.data.device} />
                                    </div>
                                    :
                                    <div>
                                        <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                        <ConsumptionWater hub={this.state.data.hub} device={this.state.data.device} />
                                    </div>
                                )
                            )
                            :
                            null
                        }

                    </div >
                )
            }

            else if (config.SIDevice.includes(this.state.data.device.type) || (config.fuelDevices.includes(this.state.data.device.type))) {
                return (
                    <div>
                        {(this.state.data) ?
                            ((this.state.data.device.type === "SourceIdentifier") ?
                                <BinaryGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                :
                                ((this.state.data.device.type === "Battery") ?
                                    <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                    :
                                    ((this.state.data.device.type === "FuelTank") ?
                                        <div>
                                            <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                            <ConsumptionGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                                        </div>
                                        :
                                        null
                                    )
                                )
                            )
                            :
                            null
                        }

                    </div >
                )
            }

            else if (config.powerDevices.includes(this.state.data.device.type)) {
                return (
                    <div>
                        {(this.state.data) ?
                            ((this.state.data.device.type === 'Unit' || this.state.data.device.type === "PackMachine") ?
                                <div>
                                    <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Voltage" />
                                    <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Current" />
                                    <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Power" />

                                </div>
                                :
                                ((this.state.data.device.type === 'Meter') ?
                                    <div>
                                        <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Voltage" />
                                        <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Current" />
                                        <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Power" />
                                        <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Energy" />
                                    </div>
                                    :
                                    ((this.state.data.device.type === 'PDU' || this.state.data.device.type === 'UPS') ?
                                        <div>
                                            <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Voltage" />
                                            <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Current" />
                                            <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Power" />
                                            <PowerGraphs hub={this.state.data.hub} device={this.state.data.device} graphType="Load" />
                                        </div>
                                        :
                                        null
                                    )
                                )
                            )
                            :
                            null
                        }

                    </div >
                )
            }

            else if (config.enviDevices.includes(this.state.data.device.type)) {
                return (
                    <div>
                        {(this.state.data) ?
                            <LineGraphComponent hub={this.state.data.hub} device={this.state.data.device} />
                            :
                            null
                        }

                    </div >
                )
            }

            else {
                return (
                    null
                )
            }
        }
        else {
            return (
                null
            )
        }
    }
}

export default DeviceGraphs;