import React, { Component } from 'react';

import $ from 'jquery';

import * as functions from '../../functions/functions';

import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';

class FailedEventsUI extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            dropdownOpen: false
        }
    }

    componentDidMount() {
        //console.log(this.props.failedEvents)

        let height = $("#" + this.props.failedEvents.deviceId + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.failedEvents.deviceId + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.failedEvents.deviceId + "-card").height();
            height = (height / 2);
            //console.log('Heightttttttttttttttttttttt:' + height)
            $("#" + this.props.failedEvents.deviceId + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    toggle = async () => {
        if (!this.state.collapse) {

            $("#" + this.props.failedEvents.deviceId).attr('class', 'col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12');


        }

        else {
            $("#" + this.props.failedEvents.deviceId).attr('class', 'col-12 col-sm-12 col-md-6 col-xl-6 col-lg-12');
        }


        this.setState({ collapse: !this.state.collapse });

        //console.log('click')
    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div class="card" style={{ borderColor: '#31A2AC' }} id={this.props.type + "-card"}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>{this.props.failedEvents.deviceId} ({this.props.failedEvents.events.length})</div>
                    <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i class="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i class="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            {this.props.failedEvents.events.map(event => (
                                <div class="card" style={{ borderColor: '#31A2AC' }}>
                                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>
                                        <small style={{ padding: '2px' }}> <b>_id: </b>{event._id}</small>
                                        <small style={{ display: 'block', padding: '2px 0px' }}><b>hubId: </b>{event.hubId}</small>
                                        <small style={{ display: 'block', padding: '2px 0px' }}><b>type: </b>{event.type}</small>
                                        <small style={{ display: 'block', padding: '2px 0px' }}><b>value: </b>{event.value}</small>
                                        {/* <small style={{ display: 'block', padding: '2px 0px' }}><b>created: </b>{event.created}</small> */}
                                        <small style={{ display: 'block', padding: '2px 0px' }}><b>created: </b>{functions.getTime(event.created) + ' ' + functions.getDate(event.created)}</small>
                                        <small style={{ display: 'block', padding: '2px 0px' }}><b>createdBy: </b>{event.createdBy}</small>
                                    </div>
                                </div >
                            ))
                            }
                        </CardBody>
                    </Collapse>
                :null
                }
            </div>
        );
    }
}

export default FailedEventsUI;