import axios from 'axios';
import config from '../config/dev1';

const instance = axios.create({
    baseURL: config.HOST1,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});


export default instance