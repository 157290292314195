import React, { useEffect, useState } from 'react'
import axios from '../../shared/utilities/axios';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import cookies from '../../shared/utilities/cookies';
import TableBody from '@material-ui/core/TableBody';



const Jobs = () => {

    const [jobs, setJobs] = useState([])
    const getUsers = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/jobs')
        // let temp = await response.json()
        // setUser(user.data)
        let data = response.data
        setJobs(data)
        console.log(data)
        return;
    }
    useEffect(() => {
        getUsers()
    }, [])
    return (
        <div>
            <Paper >
                <Table>
                    {/* <TableHead>
                                <TableCell>
                                    <div className="row m-0">
                                        <div className="col-1">#</div>
                                        <div className="col-3">Email</div>
                                        <div className="col-2">Rigestered</div>
                                        <div className="col-3">Logo</div>
                                        <div className="col-2">Organizations</div>
                                        <div className="col-1">Delete</div>
                                    </div>
                                </TableCell>
                            </TableHead> */}
                    <div
                        className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                    >
                        {/* <div className="Index"></div> */}
                        <div className="row w-100 p-2 m-0">
                            <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                <b>#</b>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                <b>ID</b>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                <b>Registered</b>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                <b>Logo</b>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                <b>Operation Access</b>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                <b>Organizations</b>
                            </div>
                            <div className="col-lg-1 col-md-6 col-12 align-self-center">
                                <b>Delete</b>
                            </div>
                        </div>
                    </div>
                    <TableBody>
                        {/* this.state.filterdocs.slice(this.state.selectedPage.start, this.state.selectedPage.end).map((data, index) => */}
                        {jobs.map((jobs, key) => {
                            return (
                                <>
                                    <div className="row w-100 p-2 m-0 listView" key={key}>
                                        <div className="col-lg-1 col-md-86col-12 Date align-self-center">
                                            <b className="d-lg-none d-inline"># </b>
                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                            {key + 1}
                                        </div>
                                        <div className="col-lg-2 col-md-86col-12 Date align-self-center">
                                            <b className="d-lg-none d-inline">ID </b>
                                            <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                            {jobs._id}
                                        </div>
                                    </div>
                                </>
                            )
                        })}



                        {/* <div className="d-flex justify-content-end my-3 mr-3">
                                    <div className="d-flex m-1">
                                        {pagenumbers.map(number =>
                                            <div key={number} className="page-item">
                                                <button onClick={() => paginate(number)} className="page-link">
                                                    {number}
                                                </button>
                                            </div>

                                        )}
                                    </div>
                                </div> */}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default Jobs