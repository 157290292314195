import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as config from '../../../config';
import * as configration from '../../../config/config';
import * as functions from '../../../functions/functions';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Bar, Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import ChartConfig from './chartConfig';
import $ from 'jquery';
import LoaderGif from '../../../assets/img/loader/loader.svg';
import 'chartjs-plugin-annotation';
import { getEventsByDevice, getEventsByDeviceAndDate } from '../../../shared/services/events'

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';


import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
} from 'reactstrap';


let selectedStartDate = null;
let selectedEndDate = null;       // for selecting Start and End Date

class BinaryGraphComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unmount: false,
      width: 0,
      rand: 0.0,
      radioSelected: 1,
      run: 0,
      renderVar: 20,
      overflow: 'scroll',
      graphDate: null,
      startDate: null,
      endDate: null,
      isMounted: false,
      shouldRedraw: false,
      eventValue: 0.0,
      limit: 10,
      eventDate: "0/0/0",
      motorDate: "0/0/0",
      isLoader: false,
      viewType: 'Status',
      max_dot_class: 'max-dot-red',
      avg_dot_class: 'avg-dot-green',
      max: 0.0,
      avg: 0.0,
      chartDetail: {
        id: this.props.device._id + "_graph",
        axisX: "date",
        axisY: "value",
        scrollbar: true,
        series: [
          {
            type: "binary",
            name: this.props.device._id,
            color: "#008df2",
            x: "date",
            y: "value",
          },
        ],
      },
      chartData: [],

    }
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.getGraph = this.getGraph.bind(this);
    this.todayDate = this.todayDate.bind(this);


  }


  componentDidMount() {

    this.state.isMounted = true
    this.state.startDate = this.todayDate('start');
    this.state.endDate = this.todayDate('end')
    if (this.state.isMounted) {
      this.getGraph("live");
    }



  }
  todayDate(type) {

    if (type === "start") {
      var currentdate = new Date();
      currentdate.setDate(currentdate.getDate());
      var currentYear = currentdate.getFullYear();
      var currentMonth = (currentdate.getMonth() + 1);
      if (currentMonth <= 9) {
        currentMonth = "0" + currentMonth;
      }
      var currentDate = currentdate.getDate();
      if (currentDate <= 9) {
        currentDate = "0" + currentDate;
      }
      var datetime = "" + currentYear + "-"
        + currentMonth + "-"
        + currentDate
        + "T00:00:00";


      return datetime

    }
    else {
      var currentdate = new Date();
      var currentYear = currentdate.getFullYear();
      var currentMonth = (currentdate.getMonth() + 1);
      var currentHours = currentdate.getHours();
      var currentMinutes = currentdate.getMinutes();
      var currentSeconds = currentdate.getMinutes();


      if (currentMonth <= 9) {
        currentMonth = "0" + currentMonth;
      }
      var currentDate = currentdate.getDate();
      if (currentDate <= 9) {
        currentDate = "0" + currentDate;
      }
      if (currentHours <= 9) {
        currentHours = "0" + currentHours;
      }
      if (currentMinutes <= 9) {
        currentMinutes = "0" + currentMinutes;
      }
      if (currentSeconds <= 9) {
        currentSeconds = "0" + currentSeconds;
      }

      var datetime = "" + currentYear + "-"
        + currentMonth + "-"
        + currentDate
        + "T" + currentHours + ":" + currentMinutes + ":" + currentSeconds;

      return datetime

    }

  }
  componentWillMount() {

  }
  componentWillUnmount() {
    this.todayDate("end")
    this.state.isMounted = false

  }
  onRadioBtnClick(value) {
    this.state.overflow = 'scroll'
    this.setState({
      radioSelected: value
    })
    if (value === 1) {
      this.state.limit = 10;
      this.state.renderVar = 20;
      this.getGraph('live');
      //defaults.global.responsive = true;
      this.state.shouldRedraw = true;
      this.forceUpdate();
      setTimeout(() => {
        this.state.shouldRedraw = false;
      }, 1000);

    }
    else {
      this.state.limit = 10;
      this.state.renderVar = 20;
      this.forceUpdate();
      // defaults.global.responsive = false;
      // this.state.shouldRedraw = true;
      this.forceUpdate();
      this.state.startDate = this.todayDate('start');
      this.state.endDate = this.todayDate('end')
      this.getGraph('day')
    }
  }
  UNSAFE_componentWillReceiveProps(props) {
    //   this.liveUpdate()


    /* var obj = {
      value : a.eventReducer.currentWaterEvent.value,
      date  : a.eventReducer.currentWaterEvent.createdon
    } */
    if (props.eventReducer.currentHomeEvent.deviceID === this.props.device._id) {
      if (props.eventReducer.currentHomeEvent.type === 'Battery_Status') {
        this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
      }
      else if (props.eventReducer.currentHomeEvent.type === 'Power_SourceIdentifier_Status') {
        this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
      }
      else {
        const eventTypeSplit = props.eventReducer.currentHomeEvent.type.split('_')
        const eventType = eventTypeSplit[eventTypeSplit.length - 2]
        if (configration.homeDevices.includes(eventType)) {
          if (eventType === 'AC') {
            if (this.props.sn) {
              if (this.props.sn === 1) {
                if (eventTypeSplit[eventTypeSplit.length - 1] === 'Status') {
                  this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
                }

              }
              else if (this.props.sn === 2) {
                if (eventTypeSplit[eventTypeSplit.length - 1] === 'Swing') {
                  this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
                }
              }
            }

          }
          else {
            if (eventTypeSplit[eventTypeSplit.length - 1] === 'Status') {
              this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
            }

          }


        }
      }


    }





  }
  // onRadioBtnClick(value) {
  //   this.state.overflow = 'scroll'
  //   this.setState({
  //     radioSelected: value
  //   })
  //   if (value === 1) {
  //     this.state.limit = 10;
  //     this.state.renderVar = 20;
  //     this.getGraph('live');
  //     //defaults.global.responsive = true;
  //     this.state.mainChartOpts.responsive = true;

  //     this.state.mainChartOpts.tooltips = {
  //       enabled: false,
  //       custom: CustomTooltips,
  //       intersect: true,
  //       mode: 'index',
  //       position: 'nearest',
  //       callbacks: {
  //         labelColor: function (tooltipItem, chart) {
  //           return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
  //         }
  //       }
  //     };
  //     this.state.shouldRedraw = true;
  //     this.forceUpdate();
  //     setTimeout(() => {
  //       this.state.shouldRedraw = false;
  //     }, 1000);

  //   }
  //   else {
  //     this.state.limit = 10;
  //     this.state.renderVar = 20;
  //     this.state.unmount = true;
  //     this.forceUpdate();
  //     this.state.unmount = false;
  //     // defaults.global.responsive = false;
  //     // this.state.shouldRedraw = true;
  //     this.state.mainChartOpts.responsive = false;

  //     this.state.mainChartOpts.tooltips = {};
  //     this.forceUpdate();
  //     this.getGraph('day')
  //     /*    setTimeout(() => {
  //          this.state.shouldRedraw = false;
  //        }, 1000); */
  //   }
  // }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {

      if (this.state.radioSelected === 2) {
        this.getGraph('day')

      }
      else if (this.state.radioSelected === 3) {
        this.getGraph('month')

      }

      else if (this.state.radioSelected === 4) {
        this.getGraph('year')

      }
    }
  }


  graphStartDateHandler(e) {
    selectedStartDate = moment(e._d).format('YYYY-MM-DDTHH:mm');
    if (e === '') {
      this.setState({
        // [e.target.name]: null
        startDate: null,
      })
      return;
    }
    this.setState({
      // [e.target.name]: e.target.value
      startDate: selectedStartDate,

    });
    setTimeout(() => {
      this.getGraph('day')
    }, 200);
  }

  graphEndDateHandler(e) {
    selectedEndDate = moment(e._d).format('YYYY-MM-DDTHH:mm');
    if (e === '') {
      this.setState({
        // [e.target.name]: null
        endDate: null,
      })
      return;
    }
    this.setState({
      // [e.target.name]: e.target.value
      endDate: selectedEndDate,

    });
    setTimeout(() => {
      this.getGraph('day')
    }, 200);
  }

  // liveUpdate(value, date) {
  //   if (this.state.radioSelected === 1) {
  //     var prevMainChart = this.state.mainChart;
  //     var prevData = prevMainChart.datasets[0].data;
  //     var prevLabel = prevMainChart.labels;
  //     if (prevData.length >= 10) {
  //       var newDt = prevData.slice(1, prevData.length);
  //       var newLb = prevLabel.slice(1, prevLabel.length);

  //     }
  //     else {
  //       var newDt = prevData.slice(0, prevData.length);
  //       var newLb = prevLabel.slice(0, prevLabel.length);
  //     }


  //     newDt.push(value);
  //     /* var arr=[];
  //     arr.push(functions.convertToDate(date,'live'));
  //     arr.push(functions.convertToDate(date,'date')); */
  //     var arr = functions.convertToDate(date, 'object')
  //     newLb.push(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

  //     var len = newDt.length;
  //     var total = 0;
  //     var i = 0;
  //     for (i = 0; i < len; i++) {
  //       total = total + newDt[i];
  //     }

  //     var prevState = this.state.mainChartOpts;
  //     prevMainChart.datasets[0].data = newDt;
  //     prevMainChart.labels = newLb;


  //     //prevData.push(value);
  //     /*   prevData.splice(0,1); */

  //     //prevLabel.push(date);
  //     /*    prevLabel.splice(0,1); */


  //     this.setState({
  //       mainChart: prevMainChart,
  //       mainChartOpts: prevState,
  //       eventValue: value,
  //       eventDate: functions.convertToDate(date, 'none'),


  //     })

  //   }
  // }
  // getLimit(e) {
  //   this.state.limit = e.target.value

  // }
  // returnStyle() {
  //   if (this.state.radioSelected === 1) {
  //     var obj = {

  //       height: 400,
  //       marginTop: 40
  //     }
  //     return obj

  //   }
  //   else {
  //     var obj = {
  //       overflowX: 'scroll',

  //       marginTop: 40
  //     }
  //     return obj

  //   }
  // }

  getHomeDeviceType = () => {
    if (this.props.device.type === 'AC') {
      if (this.props.sn) {
        if (this.props.sn === 1) {
          return 'Status'
        }
        else if (this.props.sn === 2) {
          return 'Swing'
        }
      }
    }
    else {
      return 'Status'
    }
  };
  async getGraph(type) {
    let chartData = [];
    this.setState({
      chartData
    })


    if (type === 'day') {
      let start = this.state.startDate,
        enddt = this.state.endDate,
        startDateInt = functions.convertToInt(start),
        endDateInt = functions.convertToInt(enddt);
      let limit;
      let deviceId;
      let hubtype = this.props.hub
      let type;
      if (this.props.device.type === 'FuelTank') {
        type = 'Fuel_FuelTank_Status';
        limit = 200;
        deviceId = this.props.device._id
      } else if (this.props.device.type === 'WaterTank') {
        type = 'Water_Status';
        limit = 200;
        deviceId = this.props.device._id
      } else if (configration.devicesWithStatus.includes(this.props.device.type)) {
        type = type = this.props.hub.type + '_' + this.props.device.type + '_Status';
        limit = 200;
        deviceId = this.props.device._id
      }
      else {
        if (hubtype) {
          type = this.props.hub.type + '_' + this.props.device.type;
          limit = 200
          deviceId = this.props.device._id;
          if (configration.homeDevices.includes(this.props.device.type) || configration.enviDevices.includes(this.props.device.type)) {
            if (this.props.hub.type !== "Atmosphere") {
              type = this.props.hub.type + '_' + this.props.device.type + '_' + this.getHomeDeviceType();
              limit = 200
              deviceId = this.props.device._id;
            }

          }
        }
      }
      // const getHomeDeviceType = () => {
      //   if (this.props.device.type === 'AC') {
      //     return 'Temperature'
      //   }
      //   else {
      //     return 'Status'
      //   }
      // };

      this.setState({
        isLoader: true
      })
      let data = await getEventsByDeviceAndDate(deviceId, type, startDateInt, endDateInt, limit);

      if (!data.error) {

        if (!data.error) {
          if (data.length != 0) {
            var eventValue = data[0].value;
            var eventDate = functions.convertToDate(data[0].created, "none")
            // this.props.onaddvalue(data[0].value, eventDate)
            if (this.state.isMounted) {
              data.map(row => {
                var arr = functions.convertToDate(row.created, 'object')
                let newDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
                chartData.push({
                  date: newDate.getTime(),
                  value: row.value
                })
              })
              chartData.reverse();
              this.setState({
                eventValue: eventValue,
                eventDate: eventDate,
                chartData
              })
            }



          }
        }
        this.setState({
          isLoader: false
        })
      }
      else {
        this.setState({
          isLoader: false
        })
      }
    }




    else if (type === 'live') {
      this.setState({
        radioSelected: 1
      })
      let type;
      let limit;
      let deviceId;
      if (this.props.device.type === 'FuelTank') {
        type = 'Fuel_FuelTank_Status';
        limit = 10;
        deviceId = this.props.device._id
      } else if (this.props.device.type === 'WaterTank') {
        type = 'Water_Status';
        limit = 10;
        deviceId = this.props.device._id
      } else if (configration.devicesWithStatus.includes(this.props.device.type)) {
        type = this.props.hub.type + '_' + this.props.device.type + '_Status';
        limit = 10;
        deviceId = this.props.device._id
      }
      else {
        if (this.props.hub.type) {
          type = this.props.hub.type + '_' + this.props.device.type;
          limit = 10
          deviceId = this.props.device._id;

          if (configration.homeDevices.includes(this.props.device.type) || configration.enviDevices.includes(this.props.device.type)) {
            if (this.props.hub.type !== "Atmosphere") {
              type = this.props.hub.type + '_' + this.props.device.type + '_' + this.getHomeDeviceType();
              limit = 10
              deviceId = this.props.device._id;
            }
          }
        }
      }


      let data = await getEventsByDevice(deviceId, type, limit);
      if (!data.error) {
        if (data.length != 0) {
          var eventValue = data[0].value;
          var eventDate = functions.convertToDate(data[0].created, "none")
          if (this.state.isMounted) {
            data.map(row => {
              var arr = functions.convertToDate(row.created, 'object')
              let newDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
              chartData.push({
                date: newDate.getTime(),
                value: row.value
              })
            })
            chartData.reverse();
            this.setState({
              eventValue: eventValue,
              eventDate: eventDate,
              chartData
            })
          }



        }
        else {

        }
      }

    }
  }



  render() {
    return (
      <Card className="rounded-1 dropshadow" style={{ border: 0 }}>
        <CardBody>
          <Row>
            <Col sm="12" md="12" lg="12" xs="12" xl="12">
              {(this.state.radioSelected === 1) ?
                <div>

                  <CardTitle className="mb-0">{functions.getState(this.props.device.type, this.state.eventValue, this.props.sn)}
                    {(this.props.device.type === 'Dust') ?
                      <sup>3</sup>
                      :
                      null
                    }</CardTitle>
                  <div className="small text-muted">Last updated: {this.state.eventDate}</div>
                </div>
                :
                <div>
                  <CardTitle className="mb-0">{this.props.device.type}
                    {(this.state.isLoader) ?

                      <img src={LoaderGif} className="login-loader" />

                      :
                      null

                    }
                  </CardTitle>
                  <div className=" text-muted">{this.state.graphDate}</div>
                </div>
              }



            </Col>
            <Col sm="12" md="12" lg="12" xs="12" xl="12">


              {
                (this.state.radioSelected === 1) ?

                  <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row margin-top-3">


                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>Start Date </label>
                      {/* <Input disabled type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="startDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disabled='true'
                            format='--/--/---- --:--'
                            name="startDate"
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphStartDateHandler.bind(this)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>


                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>End Date </label>
                      {/* <Input disabled type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="endDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disabled='true'
                            format='--/--/---- --:--'
                            onChange={this.graphEndDateHandler.bind(this)}
                            name="endDate"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <Col sm="12" md="4" lg="12" xs="12" xl="4">
                      <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                        <ButtonGroup className="float-right mr-3" aria-label="First group">
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>

                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>



                  </div>

                  :
                  null
              }
              {
                (this.state.radioSelected === 2) ?


                  <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row  margin-top-3">



                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>Start Date </label>
                      {/* <Input type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="startDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            name="startDate"
                            disableFuture="true"
                            value={this.state.startDate}
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphStartDateHandler.bind(this)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                    </div>


                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>End Date </label>
                      {/* <Input type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="endDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disableFuture="true"
                            value={this.state.endDate}
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphEndDateHandler.bind(this)}
                            name="endDate"
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                    </div>

                    <Col sm="12" md="4" lg="12" xs="12" xl="4">
                      <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                        <ButtonGroup className="float-right mr-3" aria-label="First group">
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>

                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>

                    {/* <div  className="col-12 col-sm-12 col-sx-12 col-md-2 col-lg-2  margin-top-2" style={{paddingLeft:5,paddingRight:5}}>
                <Button color="primary" onClick={()=>this.getGraph('day')} className="query-button">Search </Button> 
                </div> */}
                  </div>
                  :
                  null
              }








              {/*  <Row>
             <Col sm="9" md="9" lg="9" xs="12">
             </Col>
             <Col sm="3" md="3" lg="3" xs="12">
            <ButtonToolbar  aria-label="Toolbar with button groups" style={{display:"block"}}>
                <ButtonGroup className="float-right mr-3" aria-label="First group">
                <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                  <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Search</Button>
                
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
               </Row> */}
            </Col>
          </Row>



          {this.state.chartDetail && this.state.chartData.length ?
            <div style={{ height: 400, marginBottom: -20, overflow: "hidden" }}>
              <ChartConfig
                chart={this.state.chartDetail}
                data={this.state.chartData}
                graphcolor={"#008DF2"}
              />
            </div>
            : "No Data"}

        </CardBody>
      </Card>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    organizationsReducer: state.organizationsReducer,
    eventReducer: state.eventReducer
  }
}

export default connect(mapStateToProps, null)(BinaryGraphComponent);








