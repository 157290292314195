import * as ibmiot from "ibmiotf";

import React, { Component } from "react";
import { connect } from "react-redux";
import store from "./store";
import * as config from "./config";
import * as functions from "./functions/functions";
import * as functions1 from "./functions1";
import { postCommands } from "./shared/services/production";
import moment from "moment";
import axios from "axios";
import { io } from "socket.io-client";
// import io from 'socket.io'

var socket;
var appClient;
export async function publishDeviceData(type, hubId, topic, format, data) {
  var appClientConfig = config.mqttConfig();

  var appClient = new ibmiot.IotfApplication(appClientConfig);

  let date = functions.createDate();
  data.created = date.format();
  // console.log("MQTT Subscription Data",data)
  appClient.connect();
  appClient.on("connect", () => {
    //console.log("Check Send Data",type, hubId, topic, format, data)
    appClient.publishDeviceCommand(type, hubId, topic, format, data);
  });

  // let res = await postCommands(data);
}

export function getdata1() {
  var token = functions1.getCookie("operationToken");

  // socket = io("https://events.ottomatically.com", {
  socket = io("https://events.s5.ottomatically.com", {
    // socket = io("10.10.130.205:3005", {
    auth: { token },
    transports: ["websocket"],
  });
  socket.on("connect", () => {
    console.log("connected");
  });
  return socket;
}

export function getKey() {
  //   var appClientConfig1 = config.mqttConfig();
  //   var appClient1 = new ibmiot.IotfApplication(appClientConfig1);
  //   appClient1.connect();
  //   appClient1.on("connect", () => {
  //  //   /*  appClient.subscribeToDeviceEvents('Water');
  //  //    appClient.subscribeToDeviceEvents('Hub'); */
  //  //   /*  appClient.subscribeToDeviceEvents('Power'); */
  //     appClient1.subscribeToDeviceEvents('Server');
  //   });
  //   appClient1.on("deviceEvent", (deviceType, deviceId, eventType, format, payload) => {
  //       if(eventType === "secretKey"){
  //         var eventData = JSON.parse(payload);
  //         // console.log("SECRETKEY GETT",eventData.key)
  //         store.dispatch({type: "ADD_SECRETKEY", payload: eventData.key})
  //       }
  //   });
}