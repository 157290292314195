const INITIAL_STATE = {
    u_role: null,
    active_organization: null,
    email:null,
    name:null,
    secretKey:null,
}

const activeOrgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_ROLE":
            return { ...state, u_role: action.payload };

        case "ADD_Active_ORGANIZATION":
            return { ...state, active_organization: action.payload };

        case "ADD_EMAIL":
            return { ...state, email: action.payload};

        case "ADD_NAME":
            return { ...state, name: action.payload};
        
        case "ADD_SECRETKEY":
            return { ...state, secretKey: action.payload};

            case "CLEAR_ROLE":
            return { ...state, u_role: null };

        case "CLEAR_Active_ORGANIZATION":
            return { ...state, active_organization: null };

        case "CLEAR_ALL":
            return {
                active_organization: null, 
                email: null, 
                u_role: null,
                name:null
            };
    

        default:
            return state
    }
}



export default activeOrgReducer