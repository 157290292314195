import React, { useState, useEffect } from 'react'
// import SocketIOClient from 'socket.io-client'
// import { useEffect } from 'react'
import Table from '@material-ui/core/Table';
import Loader from '../../components/loader/loader'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getdata1 } from '../../getAlerts'
import axios from '../../shared/utilities/axios'
import { getDate, getTime, todayDate } from '../../functions/functions'
import { dataHandler } from '../../functions/functions';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
// import moment from 'moment';
// import axios from 'axios'
import Logs from '../../operations/logs/logs';
import { connect } from 'react-redux';
// import axios from '../../shared/utilities/axios'

// import { blue } from '@material-ui/core/colors';

// let client = SocketIOClient()
let client;
// console.warn(client)
const Socket = (props) => {
    let [hubId, setHubId] = useState()
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundImage: "linear-gradient(45deg, #0d3a5d, #1b75bc)",
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    // function clearConsole() {
    //     if (window.console || window.console.firebug) {
    //         console.clear();
    //     }
    // }
    let [oldData, setOldData] = useState()
    // const oldFailedLog = async () => {
    //     setLoader(true)
    //     setHubId(props.hubId)
    //     // let result = await axios.get('https://linked-things-s4-organizations.eu-de.mybluemix.net/api/v1/devices/all/logs')
    //     let result = await axios.get('/devices/' + hubId + '/logs?startDate=' + startDate + '&endDate=' + endDate + "&limit=50")
    //     setOldData(result.data);
    //     // clearConsole()
    //     setLoader(false)
    //     // setOldData(oldData.reverse())
    // }
    let [loader, setLoader] = useState(false)
    let [startDate, setStartDate] = useState(todayDate('start'))

    let [endDate, setEndDate] = useState(todayDate('end'))

    var [log, setLog] = useState(true)
    // const handlerStartDate = (event) => {
    //     // console.log(event)
    //     setStartDate(startDate = moment(event._d).format('YYYY-MM-DDTHH:mm'));
    //     // setStartDate(startDate);
    //     console.log(startDate)
    //     oldFailedLog()
    // }
    // const handlerEndDate = (event) => {
    //     setEndDate(endDate = moment(event._d).format('YYYY-MM-DDTHH:mm'));
    //     console.log(endDate)
    //     oldFailedLog()

    // }
    // const live = () => {
    //     setLog(true)
    // }
    // const history = () => {
    //     setStartDate(todayDate('start'))

    //     setEndDate(todayDate('end'))
    //     setLog(false)
    //     oldFailedLog()

    // }
    let [data, setData] = useState([])
    useEffect(async () => {
        setLoader(true)
        let result = await axios.get('/devices/' + props.hubId + '/events?type=' + props.type + '_Connection&limit=20')
        let temp = dataHandler(result.data)
        setData(temp)
        setLoader(false)
    }, [])
    useEffect(() => {
        let Events = [...data]
        let deviceEvent = props.eventReducer.currentHomeEvent;
        if (typeof deviceEvent.type !== "number") {
            if (deviceEvent.type.endsWith("Connection")) {
                if (deviceEvent.hubId === props.hubId) {
                    Events.unshift(
                        {
                            value: deviceEvent.value,
                            created: deviceEvent.created,
                        }
                    )
                    if (Events.length > 20) {
                        Events.splice(19, 20)
                    }
                }
            }
        }
        setData(Events)
    }, [props.eventReducer])

    // useEffect(() => {
    //     // console.log("helloworld")
    //     console.log(props)
    //     failedLog()
    //     // clearConsole()
    //     return () => {
    //         client.close()
    //     }
    // }, [])

    return (
        <div className="dropshadow rounded-1">

            <>

                <div
                    className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                >
                    {/* <div className="Index"></div> */}
                    <div className="row w-100 p-2 m-0">
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">
                            <b>Message </b>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">
                            <b>Date</b>
                        </div>
                    </div>
                </div>
                {loader ? <Loader /> :
                    <div
                        className="row m-0 rounded-brl-1 "
                        style={{ maxHeight: "200px", minHeight: "100px", overflowY: "auto" }}
                    >

                        {data.length ? data.map((log, key) => (
                            <div className="row w-100 p-2 m-0 listView" key={key}>
                                <div className="col-lg-6 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Message: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {log.value === 1 ? "Device Connected" : "Device Disconnected"}
                                </div>
                                {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                <div className="col-lg-6 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">TimeStamp: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {getTime(log.created) + "  " + getDate(log.created)}

                                </div>
                            </div>
                        )) :
                            <div className="col noData">No Log</div>}
                    </div>}
            </>



        </div>
    );

}
const mapStateToProps = (state) => {
    return {
        eventReducer: state.eventReducer
    };
};

export default connect(mapStateToProps, null)(Socket);