import React, { useState, useEffect } from 'react'
// import SocketIOClient from 'socket.io-client'
// import { useEffect } from 'react'
import Table from '@material-ui/core/Table';
import Loader from '../../components/loader/loader'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getdata1 } from '../../getAlerts'
import axios from '../../shared/utilities/axios'
import { dataHandler, getDate, getTime, todayDate } from '../../functions/functions'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';
// import { blue } from '@material-ui/core/colors';

// let client = SocketIOClient()
let client;
// console.warn(client)

const Socket = () => {
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundImage: "linear-gradient(45deg, #0d3a5d, #1b75bc)",
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    // function clearConsole() {
    //     if (window.console || window.console.firebug) {
    //         console.clear();
    //     }
    // }
    let [oldData, setOldData] = useState()
    const oldFailedLog = async () => {
        setLoader(true)
        // let result = await axios.get('https://linked-things-s4-organizations.eu-de.mybluemix.net/api/v1/devices/all/logs')
        let result = await axios.get('/devices/all/logs?startDate=' + startDate + '&endDate=' + endDate + "&limit=50")
        setOldData(dataHandler(result.data));
        // clearConsole()
        setLoader(false)
        // setOldData(oldData.reverse())
    }
    let [data, setData] = useState([])
    const failedLog = () => {
        client = getdata1()
        client.on("logs", (Log) => {
            // data.reverse()
            // console.log(Log)
            // if (!data.length) {
            //     data = data.reverse()
            // }
            // data.push(Log)
            // if (data.length > 9) {
            //     data.splice(0, 9)
            // }
            // data = data.reverse()

            // setData(data)
            // setData(currentLogs => {
            //     if (!currentLogs.length) {
            //         currentLogs = currentLogs.reverse()
            //     }
            //     currentLogs.push(Log)
            //     if (currentLogs.length > 9) {
            //         currentLogs.splice(0, 9)
            //     }
            //     currentLogs = currentLogs.reverse()
            // })
            // data = data.reverse()
            setData(currentLogs => [...currentLogs.length > 9 ? currentLogs.splice(9, 10) : currentLogs, Log])
            // setData(data.reverse())  


            // setData(data.reverse())

            // console.log('data', data)

        })

    }
    let [loader, setLoader] = useState(false)
    let [startDate, setStartDate] = useState(todayDate('start'))

    let [endDate, setEndDate] = useState(todayDate('end'))

    var [log, setLog] = useState(true)
    const handlerStartDate = (event) => {
        // console.log(event)
        setStartDate(startDate = moment(event._d).format('YYYY-MM-DDTHH:mm'));
        // setStartDate(startDate);
        console.log(startDate)
        oldFailedLog()
    }
    const handlerEndDate = (event) => {
        setEndDate(endDate = moment(event._d).format('YYYY-MM-DDTHH:mm'));
        console.log(endDate)
        oldFailedLog()

    }
    const live = () => {
        setLog(true)
    }
    const history = () => {
        setStartDate(todayDate('start'))

        setEndDate(todayDate('end'))
        setLog(false)
        oldFailedLog()

    }

    useEffect(() => {
        // console.log("helloworld")

        failedLog()
        // clearConsole()
        return () => {
            client.close()
        }
    }, [])

    return (
        <div className="dropshadow rounded-1">
            <div className="row m-0">
                <div className="col-md-8 col-12">
                    <div
                        title="Start Date"
                        className="col-12 col-md-4 col-sm-6 col-lg-3 mb-2"
                    >
                        <label style={{ marginBottom: 0 }}>Start Date </label>
                        <div id="startDate">
                            <MuiPickersUtilsProvider className="w-100" utils={MomentUtils}>
                                {log === true ? <DateTimePicker
                                    name="startDate"
                                    disabled={true}
                                    value={startDate}
                                    // format="--/--/---- --:--"
                                    onChange={() => handlerStartDate()}
                                /> :
                                    <DateTimePicker
                                        name="startDate"
                                        // disabled={true}
                                        disableFuture="true"
                                        value={startDate}
                                        // format="--/--/---- --:--"
                                        onChange={(event) =>
                                            handlerStartDate(event)
                                        }
                                    />
                                }


                            </MuiPickersUtilsProvider>
                        </div>
                    </div>

                    <div
                        title="End Date"
                        className="col-12 col-md-4 col-sm-6 col-lg-3 mb-2"
                    >
                        <label style={{ marginBottom: 0 }}>End Date </label>
                        {/* <Input type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                        <div id="endDate">

                            <MuiPickersUtilsProvider className="w-100" utils={MomentUtils}>
                                {log === true ? <DateTimePicker
                                    name="endDate"
                                    disabled={true}
                                    value={endDate}
                                    // format="--/--/---- --:--"
                                    onChange={(event) => handlerEndDate(event)}
                                // onChange={this.graphEndDateHandler.bind(this)}
                                />
                                    :
                                    <DateTimePicker
                                        name="endDate"
                                        // disabled={true}
                                        disableFuture="true"
                                        value={endDate}
                                        // format="--/--/---- --:--"
                                        onChange={(event) => handlerEndDate(event)}
                                    // onChange={this.graphEndDateHandler.bind(this)}
                                    />
                                }
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end col-md-4 col-12">
                    <div className="live_hisbtn m-3" style={{ width: '160px' }}>
                        <div className="d-flex gradient-primary3" style={{ boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.2)" }} >
                            <div onClick={() => live()} className={log === true ? "togglebtn selectbtn" : "togglebtn"}> Live </div>
                            <div onClick={() => history()} className={!log === true ? "togglebtn selectbtn" : "togglebtn"}> History </div>
                        </div>
                    </div>
                </div>
            </div>
            {log === true ?
                <>
                    <div
                        className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                    >
                        {/* <div className="Index"></div> */}
                        <div className="row w-100 p-2 m-0">
                            <div className="col-lg-4 col-md-6 col-12 align-self-center">
                                <b>device Id</b>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 align-self-center">
                                <b>Message </b>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 align-self-center">
                                <b>Date</b>
                            </div>
                        </div>
                    </div>

                    <div
                        className="row m-0 rounded-brl-1 "
                        style={{ maxHeight: "300px", minHeight: "250px", overflowY: "auto" }}
                    >

                        {data.length ? data.map((log, key) => (
                            <div className="row w-100 p-2 m-0 listView" key={key}>
                                <div className="col-lg-4 col-md-86col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Device Id: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {log.deviceId}
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Message: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {log.message}
                                </div>
                                {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                <div className="col-lg-4 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Date: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {getTime(log.created) + "  " + getDate(log.created)}
                                </div>
                            </div>
                        )) :
                            <div className="col noData">No Log</div>}
                    </div>
                </>
                :
                <>
                    <div
                        className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                    >
                        {/* <div className="Index"></div> */}
                        <div className="row w-100 p-2 m-0">
                            <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                <b>device Id</b>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                <b>levelId</b>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                <b>Message </b>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 align-self-center">
                                <b>Date</b>
                            </div>
                        </div>
                    </div>

                    <div
                        className="row m-0 rounded-brl-1"
                        style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                        {oldData && !loader ? oldData.map((log, key) => (
                            <div className="row w-100 p-2 m-0 listView" key={key}>
                                <div className="col-lg-3 col-md-86col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Device Id: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {log.deviceId}
                                </div>
                                <div className="col-lg-3 col-md-86col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Level Id: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {log.hub[0].levelId}
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Message: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {log.message}
                                </div>
                                {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                <div className="col-lg-3 col-md-6 col-12 Date align-self-center">
                                    <b className="d-lg-none d-inline">Date: </b>
                                    <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                    {getTime(log.created) + "  " + getDate(log.created)}
                                </div>
                            </div>
                        )) : <Loader />}
                    </div>
                </>
            }

        </div>
    );

}

export default Socket