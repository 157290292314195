
import { Redirect, HashRouter, Route, Switch } from 'react-router-dom';
import LoginContainer from './Container/login/LoginContainer'
import './scss/style.css'
import './App.css';
import { signIn, checkSignIn, signOut, getSignedInUser } from './shared/services/users'
import Layout from './Container/Layout/Layout';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
class App extends Component {
  static propTypes = {
    prop: PropTypes
  }

  render() {
    return (

      <div>
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={LoginContainer}>

            </Route>
            <Route path="/" name="Home" component={Layout}>
              {!checkSignIn() === true ? <Redirect to='/login' /> : null}
            </Route>
          </Switch>
        </HashRouter>
      </div>
    )
  }


}

export default App;

