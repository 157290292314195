import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import React from 'react'

export default function ModalOverview(props){
    return (
        <Modal isOpen={props.check_role}>
          <ModalHeader >Permission Alert</ModalHeader>
          <ModalBody>
            You are not an authorized person
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={props.toggleRole}>OK</Button>
          </ModalFooter>
        </Modal>
    )
}