import React, { Component } from 'react';
import $ from 'jquery';
var Popupstate = null
class ConfirmPopup extends Component {
    render() {
        return (
            <div onClick={this.props.onNo} id="confirmpopup" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', backdropFilter: 'blur(5px)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '1050' }}>
                {/* <alertComponent showConfirmPopup = {this.showConfirmPopup}/> */}
                <div className="dropshadow rounded-1 p-0 overflow-hidden text-center" style={{ minWidth: '300px', width: '40%', maxWidth: '400px', height: '180px', backgroundColor: 'white', position: 'absolute', margin: 'auto', top: 'calc(50% - 75px)', left: 0, right: 0 }}>
                    <div className="d-none far fa-times-circle w-100 gradient-primary1 text-white m-0 py-5" style={{ fontSize: '60px' }}></div>
                    <div className="p-3">
                        <h2>Are you sure?</h2>
                        <p>Do you realy want to delete?<br />{this.props.id}</p>
                        <button onClick={() => this.props.returnConfirm(true)} className="btn btn-danger mr-2" style={{ borderRadius: '50px', width: '80px' }}>Yes</button>
                        <button onClick={() => this.props.returnConfirm(false)} className="btn btn-light" style={{ borderRadius: '50px', width: '80px' }}>No</button>
                    </div>
                </div>
            </div>
        );
    }
}


export default ConfirmPopup;