import React, { Component, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, Alert } from 'reactstrap';
import $ from 'jquery';
// import { connect } from 'react-redux';
import Sidebar from './sidebar'
import OTTO from '../../assets/icon/otto.svg'
import BreadcrumComponent from './breadCrum'
import Header from './Header'
import Footer from "./Footer";
import routes from '../../routes'
import Operation from '../../operations/index'
// import Production   from './../../operations/index'

import {
    AppAside,
    AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav,
    AppSidebarToggler
} from '@coreui/react';
import { userSetter } from 'core-js/fn/symbol';
import { getSignedInUser, signOut } from '../../shared/services/users';
import { functions } from 'lodash';
import { SpriteState } from '@amcharts/amcharts4/core';
import Loader from '../../components/loader/loader';
import userInfoAction from '../../shared/action/userInfoAction';
// sidebar nav config
// import navigation from '../../_nav';
function sideNavToggle() {
    $(function () {
        var sideNav = $('#sideNavBar').width();
        if (sideNav !== 220) {
            $('#sideNavBar').css("width", "220px");
        } else {
            (window.screen.width < 720) ?
                $('#sideNavBar').css("width", "0px") :
                $('#sideNavBar').css("width", "50px")
        }
    });
}
function sideNavClose() {
    (window.screen.width < 720) ?
        $('#sideNavBar').css("width", "0px") :
        $('#sideNavBar').css("width", "50px")
}
function sideNavOpen() {
    var sideNav = $('#sideNavBar').width();
    if (sideNav !== 220) {
        $('#sideNavBar').css("width", "220px");
    }
}
const Layout = () => {
    let [loader, setLoader] = useState(true)
    useEffect(async () => {
        setLoader(true)
        await userInfoAction()
        setLoader(false)
    }, [])
    if (loader) {
        return (
            <Loader />
        )
    }
    else {
        return (
            // operationAcces !== "loader" ? operationAcces ?
            <div className="app" >
                <div className="dropshadow position-fixed bg-white w-100 d-flex" style={{ height: '50px', top: '0', zIndex: 1020 }}>
                    <Header />
                </div>
                <div className="app-body">
                    <div className="btn-lg position-fixed text-center" onClick={sideNavToggle} style={{ top: 0, zIndex: 10000, cursor: 'pointer', padding: 10, width: 50 }}><i className={(window.screen.width < 720) ? "fa fa-bars text-black" : "fa fa-bars text-white"}></i></div>
                    <div id="sideNavBar" className="dropshadow gradient-primary3 position-fixed" style={{ zIndex: 1020, top: 0, minHeight: '100%', width: (window.screen.width < 720) ? '0px' : '50px', overflow: 'hidden', transition: 'all 0.5s' }}>
                        {(window.screen.width < 720) ? null : <div style={{ width: '50px', backgroundColor: 'rgba(0,0,0,0.5)', position: 'absolute', top: 0, left: 0, bottom: 0 }}></div>}
                        {(window.screen.width < 720) ? null : <div style={{ height: '50px', backgroundColor: 'rgba(0,0,0,0.5)', position: 'absolute', top: 0, left: 0, right: 0, zIndex: -1 }}></div>}
                        <a className="text-decoration-none" style={{ textDecoration: 'none !important' }} href="#">
                            <h6 style={{ color: 'black', margin: '3px 0 0 60px', width: '150px', height: '50px' }}>
                                <img src={OTTO} width="30" height="30" alt="Linked-things Logo" style={{ filter: 'brightness(100)' }} />&nbsp; &nbsp;
                                <span style={{ marginTop: '12px', display: 'inline-block', color: 'white' }}><span style={{ fontSize: 14 }}></span>Ottomatically</span>
                            </h6>
                        </a>
                        <Sidebar sideNavClose={sideNavClose} sideNavOpen={sideNavOpen} />
                    </div>

                    {/* onClick={() => sideNavToggle = { sideNavClose }} */}
                    <main className="main" style={{ marginLeft: (window.screen.width < 720) ? 0 : '50px' }} onClick={() => sideNavClose()}>

                        {/* <AppBreadcrumb appRoutes={routes} />
                    <BreadcrumComponent history={this.props.history} /> */}
                        <div className="container-fluid main-container-pad">

                            <Switch>
                                {routes.map((route, idx) => {
                                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                        <route.component {...props} />
                                    )} />)
                                        : (null);
                                },
                                )}


                            </Switch>

                            {/*   {
                (this.state.dashboardRedirect)?
                null
                :
                <Loader />
              } */}
                            {/* <Redirect to="/users" /> */}
                        </div>
                    </main>
                    {/*  <AppAside fixed display="lg">
            <DefaultAside />
          </AppAside> */}
                </div>

                <AppFooter>
                    <Footer />
                </AppFooter>
            </div>


            // : <div>
            //     <div>
            //           
            //     </div>
            //     <div>
            //         <button onClick={() => signOut()}>Go to signIn Page</button>
            //     </div>
            // </div> : <Loader />
        )
    }
}

export default Layout