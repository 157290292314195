import axios from "../utilities/axios";
import axios1 from "../utilities/axios1";
import cookies from "../utilities/cookies";
import { decrypt } from "./decryption";

export let getDeviceById = async (deviceId) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get("/devices/" + deviceId);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getEventsByLevel = async (levelId) => {
  try {
    //console.log("Calling Events")
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get("/levels/" + levelId + "/events");
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    /*  return err.response.data; */
    return {};
  }
};

export let getStatusByLevel = async (levelId) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get("/levels/" + levelId + "/status");
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
};

export let getEventsByDevice = async (deviceId, type, limit, graph = null) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response;
    if (graph) {
      response = await axios.get(
        "/devices/" +
        deviceId +
        "/events?type=" +
        type +
        "&limit=" +
        limit +
        "&graph=" +
        graph
      );
    } else {
      response = await axios.get(
        "/devices/" + deviceId + "/events?type=" + type + "&limit=" + limit
      );
    }
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getEventsByDeviceCondition = async (
  deviceId,
  type,
  limit,
  startDate,
  endDate,
  graph = null,
  condition,
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response;
    response = await axios.get(
      "/devices/" +
      deviceId +
      "/events?type=" +
      type +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&graph=" +
      graph +
      "&condition=" +
      condition
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getEventsByDeviceList = async (
  deviceIds,
  types,
  limit,
  startDate,
  endDate
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response;
    if (startDate === null && endDate === null) {
      response = await axios.get(
        "/devices/events/compare?devices=" +
        deviceIds +
        "&types=" +
        types +
        "&limit=" +
        limit
      );
    } else {
      response = await axios.get(
        "/devices/events/compare?devices=" +
        deviceIds +
        "&types=" +
        types +
        "&limit=" +
        limit +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
      );
    }
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getDeviceDuration = async (
  deviceId,
  type,
  startDate = null,
  endDate = null,
  limit
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = null;
    // let response = [
    //     {
    //     deviceId: "AlNoor_c82b96861e6_Light",
    //     created: "2021-01-21T16:59:13.761Z",
    //     onDuration: 107.36,
    //     offDuration: 389.62
    //     },
    //     {
    //     deviceId: "AlNoor_c82b96861e6_Light",
    //     created: "2020-12-31T23:59:59.999Z",
    //     onDuration: 199.02,
    //     offDuration: 544.98
    //     },
    //     {
    //     deviceId: "AlNoor_c82b96861e6_Light",
    //     created: "2020-11-30T23:59:59.999Z",
    //     onDuration: 653.64,
    //     offDuration: 66.36
    //     },
    //     {
    //     deviceId: "AlNoor_c82b96861e6_Light",
    //     created: "2020-10-31T23:59:59.999Z",
    //     onDuration: 0,
    //     offDuration: 720
    //     }
    //     ]
    //     console.log("EVENTS",response)
    //     return response
    if (startDate) {
      response = await axios.get(
        "/devices/" +
        deviceId +
        "/duration?type=" +
        type +
        "&filter=daily" +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&limit=" +
        limit
      );
    } else {
      response = await axios.get(
        "/devices/" + deviceId + "/duration?type=" + type + "&filter=daily"
      );
    }
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let postDeviceOffReason = async (eventId, data) => {
  try {
    // console.log("Post Before",eventId, data)
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.post("/events/" + eventId + "/offreason", data);
    // console.log("Post",response)
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }

    // let response = [
    //     {
    //         created: "2021-01-28T12:00:29.858Z",
    //         reason: "Packet Nauman fault",
    //         counts: 2
    //     },
    // ]
    // return response
  } catch (err) {
    return "Error";
  }
};

export let getDeviceDurationReason = async (
  deviceId,
  type,
  startDate = null,
  endDate = null,
  limit
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = null;

    // response = {
    //     data: [
    //         {
    //             created: "2021-01-25T19:00:29.858Z",
    //             reason: "h",
    //             counts: 4,
    //             downTime: 324.345345
    //         },
    //         {
    //             created: "2021-01-25T20:00:29.858Z",
    //             reason: "b",
    //             counts: 8,
    //             downTime: 122.252478
    //         },
    //         {
    //             created: "2021-01-25T19:00:29.858Z",
    //             reason: "h",
    //             counts: 8,
    //             downTime: 5
    //         },
    //         {
    //             created: "2021-01-25T20:00:29.858Z",
    //             reason: "b",
    //             counts: 8,
    //             downTime: 21.5789
    //         }

    //     ]
    // }
    // return response
    if (startDate) {
      response = await axios.get(
        "/devices/" +
        deviceId +
        "/reasons/stats?type=" +
        type +
        "&filter=monthly" +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&limit=" +
        limit
      );
    } else {
      response = await axios.get(
        "/devices/" + deviceId + "/reasons/stats?type=" + type + "&filter=daily"
      );
    }

    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getHistoryReasonOnOff = async (
  deviceId,
  type,
  startDate,
  endDate,
  limit,
  desc,
  sortBy,
  filter
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let API =
      "/devices/" + deviceId + "/events/table?type=" + type + "&limit=" + limit;
    if (startDate !== null) {
      API += "&startDate=" + startDate;
    }
    if (endDate !== null) {
      API += "&endDate=" + endDate;
    }
    if (sortBy !== null) {
      API += "&sortBy=" + sortBy;
    }
    if (desc !== null) {
      API += "&descending=" + desc;
    }
    if (filter !== null) {
      API += "&filterBy=" + filter;
    }
    let response = await axios.get(API);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getSKU = async (deviceId) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get("/skus/" + deviceId);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
};
export let getDetailsSKU = async (deviceId, startDate, endDate) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let query = "/skus/" + deviceId + "/details";
    if (startDate !== null || endDate !== null) {
      query += "?history=true"
    }
    if (startDate !== null) {
      query += "&startDate=" + startDate;
    }
    if (endDate !== null) {
      query += "&endDate=" + endDate;
    }
    let response = await axios.get(query);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
};
export let getLastSKU = async (deviceId) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let query = "/skus/" + deviceId + "/details";
    query += "?history=true";
    query += "?limit=1";
    let response = await axios.get(query);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
};
export let postSKU = async (eventId, data) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let query = "/skus";
    if (eventId !== null) {
      query = "/skus/" + eventId + "/create";
    }

    let response = await axios.post(query, data);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
};
export let postRejected = async (skuId, data) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.put("/skus/" + skuId, data);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    err.response.data.error = true;
    return err.response.data;
  }
};
export let getDeviceOffReason = async (
  deviceId,
  type,
  startDate = null,
  endDate = null,
  limit
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = null;
    if (startDate) {
      response = await axios.get(
        "/devices/" + deviceId + "/offreasons?type=" + type
      );
    } else {
      response = await axios.get(
        "/devices/" + deviceId + "/offreasons?type=" + type
      );
    }
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getDevicesData = async (
  deviceIds,
  counterIds,
  startDate,
  endDate,
  meta,
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    if (deviceIds) {
      let response = null;
      let query = "/devices/efficiency/stats?devices=" + deviceIds + "&counters=" + counterIds;
      if (startDate) {
        query += "&startDate=" + startDate;
      }
      if (endDate) {
        query += "&endDate=" + endDate;
      }
      if (meta) {
        if ("components" in meta) {
          query += "&components=" + meta.components;
        }
        if ("download" in meta) {
          query += "&download=" + meta.download;
        }
      }
      response = await axios.get(query);
      if (typeof response.data == "string") {
        return await decrypt(response.data);
      } else {
        return response.data;
      }
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getDevicesReasons = async (
  deviceId,
  counterId,
  startDate,
  endDate
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    if (deviceId) {
      let response = null;
      let query = "/devices/efficiency/stats?devices=" + deviceId + "&counters=" + counterId;
      if (startDate) {
        query += "&startDate=" + startDate;
      }
      if (endDate) {
        query += "&endDate=" + endDate;
      }
      response = await axios.get(query);
      if (typeof response.data == "string") {
        return await decrypt(response.data);
      } else {
        return response.data;
      }
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getDevicesByLevel = async (levelId, type) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    if (levelId) {
      let response = null;
      let query = "/levels/" + levelId + "/devices";
      if (type) {
        query += "?type=" + type;
      }
      response = await axios.get(query);
      if (typeof response.data == "string") {
        return await decrypt(response.data);
      } else {
        return response.data;
      }
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getEventsByDeviceAndDate = async (
  deviceId,
  type,
  startDate,
  endDate,
  limit
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" +
      deviceId +
      "/events?type=" +
      type +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&limit=" +
      limit
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getEventsByDeviceAndFilter = async (
  deviceId,
  type,
  filter = "daily",
  startDate = null,
  endDate = null
) => {
  try {
    // console.log("getEventsByDeviceAndFilter", startDate);
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = null;
    if (startDate) {
      response = await axios.get(
        "/devices/" +
        deviceId +
        "/events/temp/stats?type=" +
        type +
        "&filter=" +
        filter +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
      );
    } else {
      response = await axios.get(
        "/devices/" +
        deviceId +
        "/events/temp/stats?type=" +
        type +
        "&filter=" +
        filter
      );
    }
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getShiftEventsByDeviceAndFilter = async (
  deviceId,
  type,
  filter,
  startDate = null,
  endDate = null
) => {
  try {
    // console.log("getEventsByDeviceAndFilter", startDate);
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let query = "/devices/" + deviceId + "/events/sum?type=" + type;
    if (filter) {
      query += "&filter=" + filter;
    }
    if (startDate) {
      query += "&startDate=" + startDate;
    }
    if (endDate) {
      query += "&endDate=" + endDate;
    }
    let response = null;
    response = await axios.get(query);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getShiftEventsByDevice = async (
  deviceId,
  type,
  filter,
  startDate = null,
  endDate = null
) => {
  try {
    // console.log("getEventsByDeviceAndFilter", startDate);
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let query = "/devices/" + deviceId + "/shift/sum?type=" + type;
    if (filter) {
      query += "&filter=" + filter;
    }
    if (startDate) {
      query += "&startDate=" + startDate;
    }
    if (endDate) {
      query += "&endDate=" + endDate;
    }
    let response = null;
    response = await axios.get(query);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getConsumptionByDevice = async (deviceId) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" + deviceId + "/water/consumption"
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let energyConsumptionByDevice = async (
  hubId,
  value,
  weight,
  pieces,
  count
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let query =
      "/hubs/" +
      hubId +
      "/energyconsumption?value=" +
      value +
      "&weight=" +
      weight +
      "&pieces=" +
      pieces;
    if (count) {
      query += "&count=" + count;
    }
    let response = await axios.get(query);
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getConsumptionByDeviceAndDate = async (deviceId, filter, date) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" +
      deviceId +
      "/water/consumption?filter=" +
      filter +
      "&endDate=" +
      date
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getConsumptionByDeviceWeight = async (deviceId, unit) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" + deviceId + "/water/consumption?unit=" + unit
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getConsumptionByDeviceAndDateWeight = async (
  deviceId,
  filter,
  date,
  unit
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" +
      deviceId +
      "/water/consumption?filter=" +
      filter +
      "&endDate=" +
      date +
      "&unit=" +
      unit
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getConsumptionByDeviceWater = async (deviceId) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get("/devices/" + deviceId + "/water/stats");
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getConsumptionByDeviceAndDateWater = async (
  deviceId,
  filter,
  date
) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" +
      deviceId +
      "/water/stats?filter=" +
      filter +
      "&endDate=" +
      date
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getEnergyByDeviceAndDate = async (deviceId, filter, date) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" +
      deviceId +
      "/power/energy?filter=" +
      filter +
      "&startDate=" +
      date
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
export let getEnergyConsumptionByDeviceAndDate = async (deviceId, filter, date) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" +
      deviceId +
      "/power/consumption?filter=" +
      filter +
      "&startDate=" +
      date
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};

export let getPeaksByDeviceAndDate = async (deviceId, filter, date) => {
  try {
    let token = cookies.getCookie("token");
    axios.defaults.headers["Authorization"] = "Bearer " + token;
    let response = await axios.get(
      "/devices/" + deviceId + "/peaks?filter=" + filter + "&startDate=" + date
    );
    if (typeof response.data == "string") {
      return await decrypt(response.data);
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      err.response.data.error = true;
      return err.response.data;
    } else {
      let error = {
        response: {
          data: {
            error: true,
            statusCode: 503,
            message: "Network Error",
          },
        },
      };
      return error.response.data;
    }
  }
};
