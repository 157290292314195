import React, { Component } from 'react';

import $ from 'jquery';

import { Card, Button, ButtonDropdown, ButtonGroup, ButtonToolbar, CardBody, Collapse, Col } from 'reactstrap';

import { getDeviceById } from '../../shared/services/events';

import * as functions from '../../functions/functions'
import DeviceGraphs from '../deviceGraphs/deviceGraphs';

class DeviceStatusGraphs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            device: null,
            collapse: false,
            dropdownOpen: false
        }
    }

    componentDidMount() {
        //console.log(this.props.device)

        let height = $("#" + this.props.device.deviceId + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.device.deviceId + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.device.deviceId + "-card").height();
            height = (height / 2);
            //console.log('Heightttttttttttttttttttttt:' + height)
            $("#" + this.props.device.deviceId + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    toggle = async () => {

        let device = await getDeviceById(this.props.device.deviceId);
        this.setState({ device: device })

        if (!this.state.collapse) {

            $("#" + this.props.device.deviceId).attr('class', 'col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12');


        }

        else {
            $("#" + this.props.device.deviceId).attr('class', 'col-12 col-sm-12 col-md-6 col-xl-6 col-lg-12');
        }


        this.setState({ collapse: !this.state.collapse });

        //console.log('click')
    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        //console.log(this.state.data)
        return (
            <div class="card" style={{ borderColor: '#31A2AC' }} id={this.props.device.deviceId + "-card"}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c' }}>
                        <div><b>_id: </b>{this.props.device.deviceId}</div>
                        {this.props.device.events.map(event => (
                            ((event.type === 'Water_Motor') ?
                                <div>
                                    <small><b>hubId: </b>{event.hubId}</small>
                                    <small style={{ display: 'block' }}><b>type: </b>{event.type}</small>
                                    <small style={{ display: 'block' }}><b>value: </b>{event.value}</small>
                                    <small style={{ display: 'block' }}><b>created: </b>{functions.getTime(event.created) + ' ' + functions.getDate(event.created)}</small>
                                </div>
                                :
                                null
                            )
                        ))}
                    </div>
                    <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i class="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i class="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            <div class="row">
                                {(this.state.device) ?
                                    <div className='col-sx-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                        <DeviceGraphs device={{ device: this.state.device, hub: this.props.device.hub }} />
                                    </div>
                                    :
                                    null
                                }

                            </div>
                        </CardBody>
                    </Collapse>
                    : null
                }
            </div>
        );
    }
}

export default DeviceStatusGraphs;