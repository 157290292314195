import React, { Component } from "react";
import LoaderGif from "../../assets/img/loader/loader.svg";
import LoginBanner from "./loginbanner.png";

import "../../scss/login.css";
import Logo from "../../assets/icon/otto.svg";
import LtLogo from "./logo.svg";


class Login extends Component {
    constructor(props) {
        super();

        this.state = {
            user: "",
            pass: "",
            renderSignup: false,
        };
    }

    inputHandlerUser(e) {
        this.setState({
            user: e.target.value,
        });
    }

    inputHandlerPass(e) {
        this.setState({
            pass: e.target.value,
        });
    }

    renderSignup() {
        this.setState({
            renderSignup: true,
        });
    }
    onKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.login(
                document.getElementById("_id").value,
                document.getElementById("pass").value
            );
        }
    };
    render() {
        return (
            < div
                className="container py-3 px-5 px-md-0 px-lg-5 d-flex"
                style={{ minHeight: "100vh" }
                }
            >
                <div className="bg-white dropshadow d-flex align-self-center rounded-1 p-0 overflow-hidden">
                    <div className="row m-0">
                        <div
                            className="col-6 py-5 d-none d-md-flex"
                            style={{
                                backgroundImage: "linear-gradient(-45deg, #0D3A5D, #1B75BC)",
                            }}
                        >
                            <div className="row justify-content-center align-self-center">
                                <img
                                    src={LoginBanner}
                                    className="col-10"
                                    style={{ marginBottom: "20px" }}
                                />
                                <h4 className="col-12 text-center text-light">
                                    IoT as a Service
                                </h4>
                                <p className="col-10 text-center text-light">
                                    SAVING - PRODUCTIVITY - INNOVATION
                                </p>
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-light border-0 m-1 dropshadow"
                                        onClick={
                                            () => window.open("https://www.linked-things.com", "_blank")
                                            // style={{
                                            //   backgroundImage: "linear-gradient(45deg, #0D3A5D, #1B75BC)",
                                            // }
                                        }
                                    >
                                        LEARN MORE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 pt-5 d-flex">
                            <div className="row d-flex justify-content-center align-self-center">
                                <div className="col-12">
                                    <center>
                                        <img
                                            width="100px"
                                            src={Logo}
                                        // style={{ filter: "brightness(10)" }}
                                        ></img>
                                        <h3
                                            className="text-center m-0 my-3"
                                            style={{
                                                color: "black",
                                            }}
                                        >
                                            OTTOMATICALLY
                                        </h3>
                                    </center>
                                </div>
                                <div className="col-12 px-5">
                                    <center>
                                        <input
                                            className="form-control rounded-1 mb-3"
                                            placeholder="Email"
                                            type="email"
                                            style={{
                                                borderColor: "#1B75BC",
                                            }}
                                            id="_id"
                                            onKeyPress={this.onKeyPress}
                                        />
                                        <input
                                            className="form-control rounded-1 mb-3"
                                            placeholder="Password"
                                            type="password"
                                            style={{
                                                borderColor: "#1B75BC",
                                            }}
                                            id="pass"
                                            onKeyPress={this.onKeyPress}
                                        />
                                        <a className="col-12" href="">
                                            Forgot your password?
                                        </a>
                                        {this.props.isLoader ? (
                                            <img
                                                src={LoaderGif}
                                                className="w-100 m-1"
                                                style={{ height: "50px" }}
                                            />
                                        ) : (
                                            <button
                                                className="col-8 btn-lg mt-3 border-0 btn-dark dropshadow"
                                                style={{
                                                    borderRadius: "50px",
                                                    backgroundImage:
                                                        "linear-gradient(-45deg, #0D3A5D, #1B75BC)",
                                                }}
                                                onClick={() => {
                                                    console.log('Sign In Pressed')
                                                    this.props.login(
                                                        document.getElementById("_id").value,
                                                        document.getElementById("pass").value
                                                    )
                                                }
                                                }
                                            >
                                                Sign In
                                            </button>
                                        )}
                                    </center>
                                </div>
                                <div className="col-12 d-flex justify-content-center" >
                                    <div className="mt-4 bg-white p-3 rounded">
                                        <img src={LtLogo} height={30} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Login;

