import CookieHandler from '../utilities/cookies'
import { userVerification } from '../services/users'
import { publishDeviceData } from '../../getAlerts'


export function generateType(stateCurrentHub, propsDevice, status) {
    return '' + stateCurrentHub.type + '_' + propsDevice.type + '_' + status
}

export function publishData(type, value, propsDevice, stateCurrentHub, propsSelectedHub = null) {
    // stateCurrentHub, propsDevice
    if (propsSelectedHub !== null) {
        publishDeviceData(propsSelectedHub.hubType, propsSelectedHub.hubId, "AppEvents", "json", {
            hubId: propsSelectedHub.hubId,
            restart: true,
        });

    }
    else {
        let val = value == 1 || value == 0 ? value ? 1 : 0 : value
        var data = {
            deviceId: propsDevice._id,
            hubId: propsDevice.hubId,
            value: val,
            type: type,
            createdBy: CookieHandler.getCookie('user')
        }
        publishDeviceData(stateCurrentHub.type, propsDevice.hubId, "AppEvents", "json", data);
    }

    // if(this.state.viewer){
    //   //console.log("CHECKING USER IS AUTH")
    // }
    // else{
    //   //console.log("CHECKING USER NOT AUTH")
    //   //alert('have been added!');
    //   this.setState({
    //     check_role : true
    //   })
    // }

}

export async function handleVerification(
    value,
    password,
    propsDevice,
    propsSelectedHub = null,
    popupFunc,
    propscurrentHub
) {

    let res = await userVerification({ password: password })

    if (res === 'valid') {
        if (propsSelectedHub !== null) {
            publishData(null, null, propsDevice, propscurrentHub, propsSelectedHub)
        }
        else {
            publishData(generateType(propscurrentHub, propsDevice, 'Status'), value, propsDevice, propscurrentHub, null)
        }

        popupFunc(null)
        return false
    }
    else {
        return 'true'
    }
}