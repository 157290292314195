export let lineGraphDevice = ["Dust", "CO2", "TVOC", "O3", "SO2", "NO2", "LPG", "CO", 'Altitude', "CH4", 'PhIndicator', 'Moisture', 'DewPoint', 'Gust', 'Pressure', 'SolarRadiation', 'UV', 'WindSpeed', 'WindDirection', 'Rain', 'Illuminance', 'WaterTank', 'FuelTank', 'Temperature', 'Humidity', 'Battery']

export let binaryGraphDevice = ["Identifier"]
export let maxGraphLine = ["CO2", "TVOC", "CO", "Dust"];
export let setMinMax = ["WaterTank"];
export let downloadData = ["WaterTank"];
export let vol_weight = ["WaterTank"];

export let airqualityDevices = ["Altitude", "AQI", "CO2", "TVOC", "O3", "SO2", "NO2", "CO", "Dust", "CH4", "WaterTank", "Oxygen", "FlowMeter", "Noise"];
export let homeDevices = ['Temperature', 'Humidity', 'Door', 'Sound', 'Smoke', 'Fire', 'Motion', 'Vibration', 'Position', 'Light', 'Fan', 'AC', 'Lock', 'Curtain', 'Socket', "Monitor", "Leakage", "Breaker", "Motor", "Valve", 'Count'];
export let enviDevices = ['PhIndicator', 'Moisture', 'DewPoint', 'Gust', 'SolarRadiation', 'UV', 'WindSpeed', 'WindDirection', 'Rain', 'Illuminance', "Pressure", "Gps", "Hydpressure"];
export let SIDevice = ["SourceIdentifier", "Battery"];
export let fuelDevices = ["FuelTank"];
export let powerDevices = ["Meter", "UPS", "PDU", "Unit", "Accmeter", "PackMachine"];
export let donotRender = ["Gps", "GPS"]
export let renderEnergy = ["KElectric_2462abb8bfc_Phase1"]
export let renderPower = ["LinkedThings_5ccf7fa2bde9_Phase1", "LinkedThings_5ccf7fa2bde9_Phase2", "LinkedThings_5ccf7fa2bde9_Phase3"]
export let devicesWithStatus = ["FlowMeter", "Noise", "Battery"];
export let machineOverviewDevices = ["Accmeter", "Temperature", "Hydpressure", "FlowMeter", "Noise"];
// export let donotRenderEnergy = []
export function enviMaxValue(type) {
    if (type === "Dust") {
        return "55"
    }
    else if (type === "CO2") {
        return "70.33"
    }
    else if (type === "TVOC") {
        return "72.72"
    }
    else if (type === "LPG") {
        return "50"
    }
    else if (type === "CO") {
        return "37.22"
    }
    else if (type === "CH4") {
        return "50"
    }
}