import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import uniqid from 'uniqid'
import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';

import { getHubLogs } from '../shared/services/production';

import { connect } from 'react-redux';
import ProductionHubsLogs from './productionHubsLogs';
import LoaderGif from '../assets/img/loader/loader.svg';

class ProductionHubsOrgs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            dropdownOpen: false,
            u_role: null,
        }
    }

    componentDidMount() {
        if ((this.props.organizationsReducer.length > 0 || this.props.organizationsReducer.totalOrganization !== null) && this.state.u_role === null) {
            this.setState({ u_role: this.props.organizationsReducer.totalOrganization.organizationList.levels[0].roles[0] })
        }

        let height = $("#" + this.props.hub.organization + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.hub.organization + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.hub.organization + "-card").height();
            height = (height / 2);
            $("#" + this.props.hub.organization + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    componentWillReceiveProps(props) {
        if (this.props.organizationsReducer.length > 0 || this.props.organizationsReducer.totalOrganization !== null || this.state.u_role === null) {
            this.setState({ u_role: this.props.organizationsReducer.totalOrganization.organizationList.levels[0].roles[0] })
        }
    }

    toggle = async () => {
        if (!this.state.collapse) {
            $("#" + this.props.hub.organization).attr('class', 'col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12');
        }
        else {
            $("#" + this.props.hub.organization).attr('class', 'col-12 col-sm-12 col-md-6 col-xl-6 col-lg-12');
        }

        this.setState({ collapse: !this.state.collapse });
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div class="card rounded-1 dropshadow gradient-primary3" style={{ border: 0 }} id={this.props.hub.organization + "-card"}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: 'white', fontWeight: 'bold' }}>{this.props.hub.organization.split('_')[0]}</div>
                    <div class=" small general-value-div" style={{ color: 'white', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i class="icon-arrow-down float-right text-white" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i class="icon-arrow-down float-right rotate-arrow text-white" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            <div class="row">
                                {this.props.hub.hubs.map(h => (
                                    <div key={uniqid()} className='col-sx-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                        <ProductionHubsLogs selectedHub={h} u_role={this.state.u_role} />
                                    </div>
                                ))
                                }
                            </div>
                        </CardBody>
                    </Collapse>
                    : null
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        organizationsReducer: state.organizationsReducer,
    }
}
export default connect(mapStateToProps, null)(ProductionHubsOrgs);