import React, { useState, useEffect, useRef } from 'react'
import { Card, CardBody, Button } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import moment from 'moment';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { subMinutes } from 'date-fns';
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
// import ToastPopup from '../../components/popup/toastPopup'
import LoaderGif from "../../assets/img/loader/loader.svg";
import { message } from 'antd';
import { dataHandler } from '../../functions/functions';
import cookies from '../../shared/utilities/cookies';
// import ToastPopup from "../popup/toastPopup";

// import { Button, Container } from '@material-ui/core';




const AddHubPopup = (props) => {

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });

    const [loader, setLoader] = useState(false)
    const [typeArray, setTypeArray] = useState([])
    let [levelList, setLevelList] = useState([])
    const hubType = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/hubs')

        let temp = await dataHandler(response.data)
        setTypeArray(temp)
        return;
    }
    useEffect(() => {
        hubType()
    }, [])
    useEffect(() => {
        orgId()
    }, [])
    const [orgId1, setOrgId1] = useState(null);
    let [organizationsId, setOrganizationId] = useState([])
    const orgId = async () => {
        setLoader(true)
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getRootLevels')
        let temp = dataHandler(response1.data)
        setOrganizationId(temp)
        return setLoader(false)

    }
    const getLevelIDs = (obj) => {
        levelArray.push(obj._id)
        if (!obj.levels) {
            return;
        }

        obj.levels.forEach(child => getLevelIDs(child))


    }
    const [data, setData] = useState('')
    const handle = (e) => {
        if (e) {
            const newdata = { ...data }
            if (e.target.name === "Organizations") {
                levelId(e.target.value)
                newdata["SelectedLevel"] = null
            }
            newdata[e.target.name] = e.target.value
            setData(newdata);
            return
        }
    }
    let levelArray = []
    const levelId = async (_id) => {
        levelArray = []
        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + _id + '/hierarchy')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {

                    getLevelIDs(temp)
                    setLevelList(levelArray)
                }
            } catch (error) {
                alert(error.response.data.message)
            }
        }


        return;


    }
    // const url = 'https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2/hubs/'
    const submit = async () => {
        setLoader(true)
        if ("_id" in data) {
            data["authToken"] = data._id
            data["version"] = "0.0.0"

        } else if (data.type !== "") {
            delete data.Organization
        }

        console.log(data)
        setLoader(false)
        props.hideUsPopup()
        try {
            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            await axios.post("/hubs/", data)
                .then((res) => {
                    let temp = dataHandler(res.data)
                    setLoader(false)
                    alert("Succesfully Created")
                    console.log(data)
                    props.hideUsPopup()
                    return temp;
                })
        } catch (error) {
            if (error.response.data.message == "Duplicate Key error.") {
                alert("Hub Already Access")
            } else {
                alert(error.response.data.message)
            }
            setLoader(false)
            props.hideUsPopup()
        }
        // props.reload()
    }


    return (
        <>
            {/* <ToastPopup ref={child} /> */}
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => props.hideUsPopup()} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{ background: 'rgb(255, 255, 255)', width: "80%", margin: 'auto', boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, maxHeight: "80vh" }}>
                    <div className="card" style={{ border: '0px' }}>
                        <a className="close align-self-end rounded-circle" onClick={() => props.hideUsPopup()} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                            &times;
                        </a>
                        <h4 style={{ color: "black" }}>Add Hub</h4>
                        <div style={{ overflow: "auto", maxHeight: "calc(100vh - 300px)" }}>
                            <div className="row m-0">
                                <div className="col-md-6 col-12">
                                    <FormGroup>
                                        <Label style={{ color: "black", fontSize: "12px" }}>
                                            MAC
                                        </Label>
                                        <Input style={{ width: "100%" }} onChange={(e) => handle(e)} type="text" value={data._id} name="_id" placeholder="minimum 11 digits" />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormGroup>
                                        <Label style={{ color: "black", fontSize: "12px" }}>
                                            Name
                                        </Label>
                                        <Input style={{ width: "100%" }} type="text" onChange={(e) => handle(e)} name="name" value={data.name} placeholder="name" />
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup>
                                <div className="row m-0">
                                    <div className="col-md-6 col-12">
                                        <Label style={{ color: "black", fontSize: "12px" }}>Hub Type</Label>
                                        <Autocomplete

                                            options={typeArray}
                                            getOptionLabel={(option) => option}
                                            // onSelect={event => handle(event)}

                                            style={{ width: "100%" }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    label={"Hub Type"}
                                                    variant="filled"
                                                    onSelect={(e) => handle(e)}
                                                    value={data.type}

                                                    name={"type"} />
                                            }

                                        />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Label style={{ color: "black", fontSize: "12px" }}>Organization Type</Label>
                                        <Autocomplete

                                            options={organizationsId}
                                            getOptionLabel={(option) => option}

                                            style={{ width: "100%" }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    onSelect={(e) => setOrgId1(e.target.value)}
                                                    label={"Organizations"}
                                                    variant="filled"
                                                    value={data.Organizations}

                                                    name={"Organizations"} />
                                            }

                                        />
                                        <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary ml-2 col-2 mt-2" style={{ float: "right" }} onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i><span className="d-md-inline d-none"> Search </span></Button>
                                    </div>
                                    {/* <div className="col-md-2 col-4 "> */}

                                    {/* </div> */}
                                </div>
                            </FormGroup>
                            {levelList.length > 1 ?
                                <FormGroup>
                                    <Autocomplete

                                        options={levelList}
                                        getOptionLabel={(list) => list}

                                        className="w-100"
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                onSelect={event => handle(event)}
                                                label={"LevelId"}
                                                variant="filled"
                                                value={data.SelectedLevel}

                                                name={"levelId"} />
                                        }


                                    />
                                </FormGroup>
                                : null}
                        </div>


                    </div>
                    <div className="row m-0 px-3 justify-content-end">
                        {loader == false ?
                            <Button color="success" onClick={() => submit()}
                                style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                Hub Create</Button>
                            : <img
                                src={LoaderGif}
                                className="w-100 m-1"
                                style={{ height: "34.8px" }}
                            />}
                    </div>
                </div>
            </div >
        </>
    );
}


export default AddHubPopup