import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as config from '../../../config';
import * as configration from '../../../config/config';
import * as functions from '../../../functions/functions';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Bar, Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import $ from 'jquery';
import LoaderGif from '../../../assets/img/loader/loader.svg';
import 'chartjs-plugin-annotation';
import { getEventsByDevice, getEventsByDeviceAndDate } from '../../../shared/services/events'

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';

import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')

let selectedStartDate = null;
let selectedEndDate = null;       // for selecting Start and End Date

class LineGraphComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unmount: false,
      width: 0,
      rand: 0.0,
      radioSelected: 1,
      run: 0,
      renderVar: 20,
      overflow: 'scroll',
      graphDate: null,
      startDate: null,
      endDate: null,
      isMounted: false,
      shouldRedraw: false,
      eventValue: 0.0,
      limit: 10,
      eventDate: "0/0/0",
      motorDate: "0/0/0",
      isLoader: false,
      viewType: 'Status',
      max_dot_class: 'max-dot-red',
      avg_dot_class: 'avg-dot-green',
      max: 0.0,
      avg: 0.0,
      mainChartOpts: {
        fill: false,
        layout: {
          padding: {
            top: 10 // half border width
          }
        },
        responsive: true,
        annotation: {
          // annotations: [
          //   {
          //     //  drawTime: "afterDatasetsDraw",
          //     // id: "hline",
          //     events: ['click'],
          //     type: "line",
          //     mode: "horizontal",
          //     scaleID: "y-axis-0",
          //     value: this.props.device.metadata.annotations.max,
          //     borderColor: '#E40C0C',
          //     borderWidth: 1.5,
          //     label: {


          //       // Font size of text, inherits from global
          //       fontSize: 9,

          //       // Font style of text, default below
          //       fontStyle: "normal",

          //       // Font color of text, default below
          //       fontColor: "#fff",

          //       // Padding of label to add left/right, default below


          //       // Radius of label rectangle, default below


          //       // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
          //       position: "left",


          //       // Whether the label is enabled and should be displayed
          //       enabled: false,

          //       // Text to display in label - default is null
          //       content: "Max: " + this.props.device.metadata.annotations.max
          //     },

          //   },
          //   {
          //     drawTime: "afterDatasetsDraw",
          //     // id: "hline",
          //     type: "line",
          //     mode: "horizontal",
          //     scaleID: "y-axis-0",
          //     value: this.props.device.metadata.annotations.min,
          //     borderColor: '#0A0D78',
          //     borderWidth: 1.5,
          //     label: {


          //       // Font size of text, inherits from global
          //       fontSize: 9,

          //       // Font style of text, default below
          //       fontStyle: "normal",

          //       // Font color of text, default below
          //       fontColor: "#fff",

          //       // Padding of label to add left/right, default below


          //       // Radius of label rectangle, default below


          //       // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
          //       position: "left",


          //       // Whether the label is enabled and should be displayed
          //       enabled: false,

          //       // Text to display in label - default is null
          //       content: "Min: " + this.props.device.metadata.annotations.min
          //     },
          //   }]
        },
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
            }
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        layout: {

        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 10,

              },
              gridLines: {
                drawOnChartArea: false,
              },
              type: 'time',
              distribution: 'linear',
              /*  time:{
                 displayFormats: {
                   minute: 'h:mm:ss a[\n]ll',
                   millisecond: 'h:mm:ss a[\n]ll',
                   second: 'h:mm:ss a[\n]ll',
                   hour: 'h:mm:ss a[\n]ll',
                   day: 'h:mm:ss a ll',
                   week: 'h:mm:ss a ll',
                   month: 'h:mm:ss a ll',
                   quater: 'h:mm:ss a ll',
                   year: 'h:mm:ss a [\n] ll'
                  
               }
               } */
            }],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                fontSize: 12,
                /* stepSize: Math.ceil(250 / 5),*/
                stepSize: 50,
                // max: (50 - (this.props.device.metadata.annotations.max + 30) % 50) + this.props.device.metadata.annotations.max + 30
              },

            }],

        },
        elements: {
          point: {
            radius: 2,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      },
      mainChart: {
        labels: [],
        datasets: [
          {
            label: 'Water Level',
            backgroundColor: hexToRgba(brandInfo, 12),
            steppedLine: true,
            borderColor: brandInfo,

            pointHoverBackgroundColor: '#fff',
            borderWidth: 2,
            data: [],
          }
        ],
      },
    }

    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.getGraph = this.getGraph.bind(this);
    this.returnStyle = this.returnStyle.bind(this);
    this.liveUpdate = this.liveUpdate.bind(this);
    this.todayDate = this.todayDate.bind(this);

  }


  componentDidMount() {

    $(window).resize(() => {
      if (this.state.radioSelected === 2) {
        //console.log('window resize')
        this.state.unmount = true;
        var len = this.state.mainChart.datasets[0].data.length
        if (len === 0) {
          this.state.limit = 100;
        }
        else {
          var graphWidth = $("#graph-" + this.props.device._id).width();

          var approxWidth = 20 * len
          if (approxWidth <= graphWidth) {
            var renderVar = graphWidth / len;
            this.state.renderVar = renderVar;
          }
          else {
            this.state.renderVar = 20;
          }
          this.state.limit = len;
        }
        this.forceUpdate();

        this.state.unmount = false;
        this.forceUpdate();

      }
    });
    this.state.isMounted = true

    this.state.startDate = this.todayDate('start');
    this.state.endDate = this.todayDate('end')
    var label = this.state.mainChart;
    label.datasets[0].label = this.props.device.name

    if (this.state.isMounted) {
      this.getGraph("live");
    }



  }
  todayDate(type) {

    if (type === "start") {
      var currentdate = new Date();
      currentdate.setDate(currentdate.getDate());
      var currentYear = currentdate.getFullYear();
      var currentMonth = (currentdate.getMonth() + 1);
      if (currentMonth <= 9) {
        currentMonth = "0" + currentMonth;
      }
      var currentDate = currentdate.getDate();
      if (currentDate <= 9) {
        currentDate = "0" + currentDate;
      }
      var datetime = "" + currentYear + "-"
        + currentMonth + "-"
        + currentDate
        + "T00:00:00";


      return datetime

    }
    else {
      var currentdate = new Date();
      var currentYear = currentdate.getFullYear();
      var currentMonth = (currentdate.getMonth() + 1);
      var currentHours = currentdate.getHours();
      var currentMinutes = currentdate.getMinutes();
      var currentSeconds = currentdate.getMinutes();


      if (currentMonth <= 9) {
        currentMonth = "0" + currentMonth;
      }
      var currentDate = currentdate.getDate();
      if (currentDate <= 9) {
        currentDate = "0" + currentDate;
      }
      if (currentHours <= 9) {
        currentHours = "0" + currentHours;
      }
      if (currentMinutes <= 9) {
        currentMinutes = "0" + currentMinutes;
      }
      if (currentSeconds <= 9) {
        currentSeconds = "0" + currentSeconds;
      }

      var datetime = "" + currentYear + "-"
        + currentMonth + "-"
        + currentDate
        + "T" + currentHours + ":" + currentMinutes + ":" + currentSeconds;

      return datetime

    }

  }
  componentWillMount() {
    // //console.log(this.props.device)
    // //console.log(this.props.hub)
  }
  componentWillUnmount() {
    this.todayDate("end")
    this.state.isMounted = false

  }
  UNSAFE_componentWillReceiveProps(props) {
    //   this.liveUpdate()


    /* var obj = {
      value : a.eventReducer.currentWaterEvent.value,
      date  : a.eventReducer.currentWaterEvent.createdon
    } */

    if (props.eventReducer.currentHomeEvent.deviceID === this.props.device._id) {
      if (configration.homeDevices.includes(this.props.device.type)) {
        if (this.props.device.type === 'AC') {
          const eventTypeSplit = props.eventReducer.currentHomeEvent.type.split('_')
          if (eventTypeSplit[eventTypeSplit.length - 1] === 'Temperature') {
            this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
          }
        }
        else {

          const eventTypeSplit = props.eventReducer.currentHomeEvent.type.split('_')
          if (eventTypeSplit[eventTypeSplit.length - 1] === 'Status') {
            this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
          }
        }

      }

      else {


        const eventTypeSplit = props.eventReducer.currentHomeEvent.type.split('_')
        let eventType = ""
        if (eventTypeSplit.length === 2) {
          eventType = eventTypeSplit[eventTypeSplit.length - 1]
        }
        else {
          eventType = eventTypeSplit[eventTypeSplit.length - 2]
        }

        if (configration.lineGraphDevice.includes(eventType) || props.eventReducer.currentHomeEvent.type === "Fuel_Status") {

          if (eventTypeSplit.length === 3) {
            if (eventTypeSplit[eventTypeSplit.length - 1] === 'Status') {
              this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
            }
          }
          else if (eventTypeSplit.length === 2) {
            if (eventTypeSplit[eventTypeSplit.length - 1] !== 'Connection') {
              this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
            }
          }


        }

      }


    }




  }
  onRadioBtnClick(value) {
    this.state.overflow = 'scroll'
    this.setState({
      radioSelected: value
    })
    if (value === 1) {
      this.state.limit = 10;
      this.state.renderVar = 20;
      this.getGraph('live');
      //defaults.global.responsive = true;
      this.state.mainChartOpts.responsive = true;

      this.state.mainChartOpts.tooltips = {
        enabled: false,
        custom: CustomTooltips,
        intersect: true,
        mode: 'index',
        position: 'nearest',
        callbacks: {
          labelColor: function (tooltipItem, chart) {
            return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
          }
        }
      };
      this.state.shouldRedraw = true;
      this.forceUpdate();
      setTimeout(() => {
        this.state.shouldRedraw = false;
      }, 1000);

    }
    else {
      this.state.limit = 10;
      this.state.renderVar = 20;
      this.state.unmount = true;
      this.forceUpdate();
      this.state.unmount = false;
      // defaults.global.responsive = false;
      // this.state.shouldRedraw = true;
      this.state.mainChartOpts.responsive = false;

      this.state.mainChartOpts.tooltips = {};
      this.forceUpdate();
      this.getGraph('day')
      /*    setTimeout(() => {
           this.state.shouldRedraw = false;
         }, 1000); */
    }
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {

      if (this.state.radioSelected === 2) {
        this.getGraph('day')

      }
      else if (this.state.radioSelected === 3) {
        this.getGraph('month')

      }

      else if (this.state.radioSelected === 4) {
        this.getGraph('year')

      }
    }
  }

  // graphDateHandler(e) {
  //   if (e.target.value === '') {
  //     this.setState({
  //       [e.target.name]: null

  //     })
  //     return;
  //   }
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  //   setTimeout(() => {
  //     this.getGraph('day')
  //   }, 200);
  // }

  graphStartDateHandler = (event) => {
    selectedStartDate = moment(event._d).format('YYYY-MM-DDTHH:mm');

    if (event === '') {
      this.setState({
        // [event.target.name]: null,
        startDate: null,
      })
      return;
    }
    this.setState({
      startDate: selectedStartDate,
    });
    setTimeout(() => {
      this.getGraph('day')
    }, 200);
  }

  graphEndDateHandler = (event) => {
    selectedEndDate = moment(event._d).format('YYYY-MM-DDTHH:mm');
    if (event === '') {
      this.setState({
        // [event.target.name]: null,
        endDate: null,
      })
      return;
    }
    this.setState({
      endDate: selectedEndDate,
    });
    setTimeout(() => {
      this.getGraph('day')
    }, 200);
  }

  liveUpdate(value, date) {
    if (this.state.radioSelected === 1) {
      var prevMainChart = this.state.mainChart;
      var prevData = prevMainChart.datasets[0].data;
      var prevLabel = prevMainChart.labels;

      if (prevData.length >= 10) {
        var newDt = prevData.slice(1, prevData.length);
        var newLb = prevLabel.slice(1, prevLabel.length);

      }
      else {
        var newDt = prevData.slice(0, prevData.length);
        var newLb = prevLabel.slice(0, prevLabel.length);
      }


      newDt.push(value);
      /* var arr=[];
      arr.push(functions.convertToDate(date,'live'));
      arr.push(functions.convertToDate(date,'date')); */
      var arr = functions.convertToDate(date, 'object')
      newLb.push(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

      var len = newDt.length;
      var total = 0;
      var i = 0;
      for (i = 0; i < len; i++) {
        total = total + newDt[i];
      }
      var avg = 0;
      var prevState = this.state.mainChartOpts;

      prevMainChart.datasets[0].data = newDt;
      prevMainChart.labels = newLb;

      if (configration.maxGraphLine.includes(this.props.device.type)) {

        /*    if(parseInt(config.enviMaxValue(this.props.items.type))>=Math.max(...newDt)){
             prevState.scales.yAxes[0].ticks.max=(parseInt(config.enviMaxValue(this.props.items.type))+10);
           
           
            
            
             //console.log('small')
           //console.log( prevState.scales.yAxes[0].ticks)
           }
           else{
             //console.log('big')
             if(prevState.scales.yAxes[0].ticks.max){
               //console.log('found max')
               prevState.scales.yAxes[0].ticks.max=(Math.round(Math.max(...newDt) * 100) / 100)+10
           
               
             }
             
             //console.log(this.state.mainChartOpts.scales.yAxes[0].ticks)
           } */
        var maxColor = 'red';
        var avgColor = 'green';


        if (total !== 0) {
          avg = total / len - 1;
          avg = Math.round(avg * 100) / 100

          /*  //console.log('real Time'+this.props.items._id,total,avg) */
          var maxValue = configration.enviMaxValue(this.props.device.type);
          if (maxValue < avg) {
            maxColor = 'black';
            avgColor = 'red';

            this.setState({
              max_dot_class: 'max-dot-black',
              avg_dot_class: "avg-dot-red"
            })
          }
          else {
            this.setState({
              max_dot_class: 'max-dot-red',
              avg_dot_class: "avg-dot-green"
            })
          }
        }



        else {
          var maxValue = configration.enviMaxValue(this.props.device.type);
          this.setState({
            max_dot_class: 'max-dot-red',
            avg_dot_class: "avg-dot-green"
          })
        }


      }
      //prevData.push(value);
      /*   prevData.splice(0,1); */

      //prevLabel.push(date);
      /*    prevLabel.splice(0,1); */


      this.setState({
        mainChart: prevMainChart,
        mainChartOpts: prevState,
        eventValue: value,
        eventDate: functions.convertToDate(date, 'none'),
        max: maxValue,
        avg: avg

      })

    }
  }
  getLimit(e) {
    this.state.limit = e.target.value

  }
  returnStyle() {
    if (this.state.radioSelected === 1) {
      var obj = {

        height: 400,
        marginTop: 40
      }
      return obj

    }
    else {
      var obj = {
        overflowX: 'scroll',

        marginTop: 40
      }
      return obj

    }
  }
  getHomeDeviceType = () => {
    if (this.props.device.type === 'AC') {
      return 'Temperature'
    }
    else {
      return 'Status'
    }
  };
  async getGraph(type) {

    if (type === 'day') {

      if ((this.state.startDate != null && this.state.endDate != null) || (this.state.startDate === null && this.state.endDate != null) || (this.state.startDate != null && this.state.endDate === null)) {
        var newdt;
        var enddt;

        if (this.state.startDate != null && this.state.endDate != null) {
          /*    var startSplit=this.state.startDate.split("T");
             var startDate=startSplit[0].split('-');
             var startTime = startSplit[1].split(':');
              newdt = new Date (startDate[0],startDate[1],startDate[2],startTime[0],startTime[1]);
       
             var endSplit=this.state.endDate.split("T");
             var endDate=endSplit[0].split('-');
             var endTime = endSplit[1].split(':');
             enddt = new Date (endDate[0],endDate[1],endDate[2],endTime[0],endTime[1]); */

          newdt = this.state.startDate;
          enddt = this.state.endDate;

        }

        else if (this.state.startDate === null && this.state.endDate != null) {



          var endSplit = this.state.endDate.split("T");
          var endDate = endSplit[0].split('-');
          var endTime = endSplit[1].split(':');
          enddt = new Date(endDate[0], endDate[1], endDate[2], endTime[0], endTime[1]);

          newdt.setDate(enddt.getDate() - 1);

        }
        else if (this.state.startDate != null && this.state.endDate === null) {



          var startSplit = this.state.startDate.split("T");
          var startDate = startSplit[0].split('-');
          var startTime = startSplit[1].split(':');
          newdt = new Date(startDate[0], startDate[1], startDate[2], startTime[0], startTime[1]);

          enddt.setDate(newdt.getDate() + 1);

        }

        var startDateInt = functions.convertToInt(newdt);
        var endDateInt = functions.convertToInt(enddt);
        //console.log(startDateInt, endDateInt);
        let type;
        let limit;
        let deviceId;
        if (this.props.device.type === 'FuelTank') {
          type = 'Fuel_Status';
          limit = 200;
          deviceId = this.props.device._id
        } else if (this.props.device.type === 'WaterTank') {
          type = 'Water_Status';
          limit = 200;
          deviceId = this.props.device._id
        }
        else {
          type = this.props.hub.type + '_' + this.props.device.type;
          limit = 200
          deviceId = this.props.device._id;
          if (configration.homeDevices.includes(this.props.device.type) || configration.enviDevices.includes(this.props.device.type)) {
            if (this.props.hub.type !== "Atmosphere") {
              type = this.props.hub.type + '_' + this.props.device.type + '_' + this.getHomeDeviceType();
              limit = 200
              deviceId = this.props.device._id;
            }

          }
        }

        this.setState({
          isLoader: true
        })

        let data = await getEventsByDeviceAndDate(deviceId, type, startDateInt, endDateInt, limit);

        if (!data.error) {

          this.setState({
            isLoader: false
          })
          //console.log(data);
          var len = data.length;
          var i;
          var newData = [];
          var newLabel = [];
          var total = 0;
          for (i = 0; i < len; i++) {
            newData.push(data[i].value);
            total = total + data[i].value;
            /*  var arr=[];
             arr.push(functions.convertToDate(data[i].created,'live'));
             arr.push(functions.convertToDate(data[i].created,'date')); */
            var arr = functions.convertToDate(data[i].created, 'object')
            newLabel.push(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

          }
          var avg = 0;
          var prevState = this.state.mainChartOpts;

          if (configration.maxGraphLine.includes(this.props.device.type)) {

            /*   if(parseInt(config.enviMaxValue(this.props.items.type))>=Math.max(...newData)){
                prevState.scales.yAxes[0].ticks.max=(parseInt(config.enviMaxValue(this.props.items.type))+10);
              
              } */
            var maxColor = 'red';
            var avgColor = 'green';
            if (total !== 0) {
              avg = total / len;
              avg = Math.round(avg * 100) / 100


              var maxValue = configration.enviMaxValue(this.props.device.type);
              if (maxValue < avg) {
                maxColor = 'black';
                avgColor = 'red';

                this.setState({
                  max_dot_class: 'max-dot-black',
                  avg_dot_class: "avg-dot-red"
                })
              }
              else {
                this.setState({
                  max_dot_class: 'max-dot-red',
                  avg_dot_class: "avg-dot-green"
                })
              }
            }



            else {
              var maxValue = configration.enviMaxValue(this.props.device.type);
              this.setState({
                max_dot_class: 'max-dot-red',
                avg_dot_class: "avg-dot-green"
              })
              // prevState.annotation.annotations = [
              //   {
              //     //  drawTime: "afterDatasetsDraw",
              //     // id: "hline",
              //     events: ['click'],
              //     type: "line",
              //     mode: "horizontal",
              //     scaleID: "y-axis-0",
              //     value: this.props.device.metadata.annotations.max,
              //     borderColor: "red",
              //     borderWidth: 1.5,
              //     label: {


              //       // Font size of text, inherits from global
              //       fontSize: 9,

              //       // Font style of text, default below
              //       fontStyle: "normal",

              //       // Font color of text, default below
              //       fontColor: "#fff",

              //       // Padding of label to add left/right, default below


              //       // Radius of label rectangle, default below


              //       // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
              //       position: "right",


              //       // Whether the label is enabled and should be displayed
              //       enabled: false,

              //       // Text to display in label - default is null
              //       content: "Max: " + this.props.device.metadata.annotations.max
              //     },

              //   }]
            }


          }
          newData.reverse();
          newLabel.reverse();
          var prevData = this.state.mainChart;
          prevData.datasets[0].data = newData;
          prevData.labels = newLabel;

          this.setState({
            mainChartOpts: prevState,
            mainChart: prevData,
            max: maxValue,
            avg: avg
          })

          this.setState({
            rand: Math.random()
          })
          /*  this.state.shouldRedraw = true;
           this.state.mainChartOpts.responsive=true;
       
           this.state.mainChartOpts.tooltips={};
           
          
           this.forceUpdate();
           
           setTimeout(() => {
             this.state.shouldRedraw = false;
           }, 1000); */

          // this.state.shouldRedraw = true;


          //  this.state.limit=2000;

          this.state.unmount = true;
          if (len === 0) {
            this.state.limit = 100;
            this.state.overflow = 'hidden';
            //console.log('len==0')
          }
          else {
            var graphWidth = $("#graph-" + this.props.device._id).width();

            var approxWidth = 20 * len
            if (approxWidth <= graphWidth) {
              var renderVar = graphWidth / len;
              this.state.renderVar = renderVar;
              this.state.overflow = 'hidden'
            }
            else {
              this.state.overflow = 'scroll'
              this.state.renderVar = 20;
            }
            this.state.limit = len;
          }

          this.forceUpdate();

          this.state.unmount = false;
          this.forceUpdate();


        }
        else {
          this.setState({
            isLoader: false
          })
        }

      }
    }




    else if (type === 'live') {
      this.setState({
        radioSelected: 1
      })
      let type;
      let limit;
      let deviceId;
      if (this.props.device.type === 'FuelTank') {
        type = 'Fuel_Status';
        limit = 10;
        deviceId = this.props.device._id
      } else if (this.props.device.type === 'WaterTank') {
        type = 'Water_Status';
        limit = 10;
        deviceId = this.props.device._id
      }
      else {
        type = this.props.hub.type + '_' + this.props.device.type;
        limit = 10
        deviceId = this.props.device._id;

        if (configration.homeDevices.includes(this.props.device.type) || configration.enviDevices.includes(this.props.device.type)) {
          if (this.props.hub.type !== "Atmosphere") {
            type = this.props.hub.type + '_' + this.props.device.type + '_' + this.getHomeDeviceType();
            limit = 10
            deviceId = this.props.device._id;
          }
        }
      }


      let data = await getEventsByDevice(deviceId, type, limit);

      if (!data.error) {
        if (data.length != 0) {



          var len = data.length;
          var i;
          var newData = [];
          var newLabel = [];
          var total = 0;
          for (i = 0; i < len; i++) {
            newData.push(data[i].value);
            total = total + data[i].value;
            /* var arr=[];
           arr.push(functions.convertToDate(data[i].created,type));
           arr.push(functions.convertToDate(data[i].created,'date'));
           newLabel.push(arr); */
            var arr = functions.convertToDate(data[i].created, 'object')
            newLabel.push(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

          }
          var avg = 0;
          var prevState = this.state.mainChartOpts;

          if (configration.maxGraphLine.includes(this.props.device.type)) {

            /*   if(parseInt(config.enviMaxValue(this.props.items.type))>=Math.max(...newData)){
                prevState.scales.yAxes[0].ticks.max=(parseInt(config.enviMaxValue(this.props.items.type))+10);
              
              } */
            var maxColor = 'red';
            var avgColor = 'green';
            if (total !== 0) {
              avg = total / len;
              avg = Math.round(avg * 100) / 100


              var maxValue = configration.enviMaxValue(this.props.device.type);
              if (maxValue < avg) {
                maxColor = 'black';
                avgColor = 'red';
                this.setState({
                  max_dot_class: 'max-dot-black',
                  avg_dot_class: "avg-dot-red"
                })
              }
              else {
                this.setState({
                  max_dot_class: 'max-dot-red',
                  avg_dot_class: "avg-dot-green"
                })
              }

            }



            else {
              var maxValue = configration.enviMaxValue(this.props.device.type);
              this.setState({
                max_dot_class: 'max-dot-red',
                avg_dot_class: "avg-dot-green"
              })
            }


          }
          var eventValue = data[0].value;
          var eventDate = functions.convertToDate(data[0].created, "none")
          newData.reverse();
          newLabel.reverse();
          var prevData = this.state.mainChart;
          prevData.datasets[0].data = newData;
          prevData.labels = newLabel;


          if (this.state.isMounted) {

            this.setState({
              mainChart: prevData,
              eventValue: eventValue,
              eventDate: eventDate,
              mainChartOpts: prevState,
              max: maxValue,
              avg: avg


            })
          }



        }
      }
      else {
        /*  if(error.responseJSON){
         var errorCode = error.responseJSON._response.statusCode;
                     if(errorCode === 429){
                       if(this.state.isMounted){
                         this.getGraph('live');
                       }
                     
                     }
                     
       } */

      }

    }
  }


  render() {
    return (
      <Card className="rounded-1 dropshadow" style={{ border: 0 }}>
        <CardBody>
          <Row>
            <Col sm="12" md="12" lg="12" xs="12" xl="12">
              {(this.state.radioSelected === 1) ?
                <div>
                  <CardTitle className="mb-0">{functions.getState(this.props.device.type, this.state.eventValue, this.props.sn)}
                    {(this.props.device.type === 'Dust') ?
                      <sup>3</sup>
                      :
                      null
                    }</CardTitle>
                  <div className="small text-muted">Last updated: {this.state.eventDate}</div>
                </div>
                :
                <div>
                  <CardTitle className="mb-0">{functions.getState(this.props.device.type, this.state.eventValue, this.props.sn)}
                    {(this.state.isLoader) ?

                      <img src={LoaderGif} className="login-loader" />

                      :
                      null

                    }
                  </CardTitle>
                  <div className=" text-muted">{this.state.graphDate}</div>
                </div>
              }



            </Col>
            <Col sm="12" md="12" lg="12" xs="12" xl="12">


              {
                (this.state.radioSelected === 1) ?

                  <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row margin-top-3">


                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>Start Date </label>
                      {/* <Input disabled type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                      <div id="startDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disabled='true'
                            format='--/--/---- --:--'
                            name="startDate"
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphStartDateHandler.bind(this)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>


                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>End Date </label>
                      {/* <Input disabled type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                      <div id="endDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disabled='true'
                            format='--/--/---- --:--'
                            onChange={this.graphEndDateHandler.bind(this)}
                            name="endDate"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <Col sm="12" md="4" lg="12" xs="12" xl="4">
                      <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                        <ButtonGroup className="float-right mr-3" aria-label="First group">
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>

                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>
                    {(configration.maxGraphLine.includes(this.props.device.type)) ?
                      <div style={{ marginLeft: -5, marginTop: 10 }} class="row margin-top-3">
                        <Col sm="12" md="12" lg="12" xs="12" xl="12">
                          <div><span className={this.state.max_dot_class + " mt-2"}></span><h6 className="d-inline pl-2">Maximum: </h6>{this.state.max} {functions.getUnit(this.props.device.type)}</div>
                          <div><span className={this.state.avg_dot_class + " mt-1"}></span><h6 className="d-inline pl-2">Average: </h6>{this.state.avg} {functions.getUnit(this.props.device.type)}</div>
                        </Col>
                      </div>
                      :
                      null
                    }


                  </div>

                  :
                  null
              }
              {
                (this.state.radioSelected === 2) ?


                  <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row  margin-top-3">



                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>Start Date </label>
                      {/* <Input type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                      <div id="startDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            name="startDate"
                            disableFuture="true"
                            value={this.state.startDate}
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphStartDateHandler.bind(this)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>


                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>End Date </label>
                      {/* <Input type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}

                      <div id="endDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disableFuture="true"
                            value={this.state.endDate}
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphEndDateHandler.bind(this)}
                            name="endDate"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <Col sm="12" md="4" lg="12" xs="12" xl="4">
                      <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                        <ButtonGroup className="float-right mr-3" aria-label="First group">
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>

                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>

                    {/* <div  className="col-12 col-sm-12 col-sx-12 col-md-2 col-lg-2  margin-top-2" style={{paddingLeft:5,paddingRight:5}}>
                <Button color="primary" onClick={()=>this.getGraph('day')} className="query-button">Search </Button> 
                </div> */}
                    {(configration.maxGraphLine.includes(this.props.device.type)) ?
                      <div style={{ marginLeft: -5, marginTop: 10 }} class="row margin-top-3">
                        <Col sm="12" md="12" lg="12" xs="12" xl="12">
                          <div><span className={this.state.max_dot_class + " mt-2"}></span><h6 className="d-inline pl-2">Maximum: </h6>{this.state.max} {functions.getUnit(this.props.device.type)}</div>
                          <div><span className={this.state.avg_dot_class + " mt-1"}></span><h6 className="d-inline pl-2">Average: </h6>{this.state.avg} {functions.getUnit(this.props.device.type)}</div>
                        </Col>
                      </div>
                      :
                      null
                    }

                  </div>







                  :
                  null
              }








              {/*  <Row>
             <Col sm="9" md="9" lg="9" xs="12">
             </Col>
             <Col sm="3" md="3" lg="3" xs="12">
            <ButtonToolbar  aria-label="Toolbar with button groups" style={{display:"block"}}>
                <ButtonGroup className="float-right mr-3" aria-label="First group">
                <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                  <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Search</Button>
                
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
               </Row> */}
            </Col>
          </Row>



          <div id={"graph-" + this.props.device._id} className="chart-wrapper" style={(this.state.radioSelected === 1) ? {
            height: 300 + 'px',
            marginTop: 15 + 'px'
          } : { overflowX: this.state.overflow, marginTop: 40 + 'px' }} rand={this.state.rand}>
            {(this.state.unmount) ?
              null :
              <Bar width={this.state.renderVar * this.state.limit} redraw={this.state.shouldRedraw}
                height={300}
                options={{
                  maintainAspectRatio: true
                }} data={this.state.mainChart} options={this.state.mainChartOpts} rand={this.state.rand} />
            }

          </div>


        </CardBody>
      </Card>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    organizationsReducer: state.organizationsReducer,
    eventReducer: state.eventReducer
  }
}

export default connect(mapStateToProps, null)(LineGraphComponent);