const INITIAL_STATE = {
    enableReports: false,
    enableSchedules: false,
    reportsLink: "",
    schedulesLink: ""
}

const sidebarReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case "SET_REPORT_LINK":
            return { ...state, enableReports: true, reportsLink: action.payload };

        case "REMOVE_REPORT_LINK":
            return { ...state, enableReports: false, reportsLink: "" };

        case "SET_SCHEDULE_LINK":
            return { ...state, enableSchedules: true, schedulesLink: action.payload };

        case "REMOVE_SCHEDULE_LINK":
            return { ...state, enableSchedules: false, schedulesLink: "" };

        case "SET_PRODUCTION_LINK":
            return { ...state, enableProduction: true, productionLink: action.payload };

        case "REMOVE_PRODUCTION_LINK":
            return { ...state, enableProduction: false, productionLink: "" };

        default:
            return state
    }
}



export default sidebarReducer