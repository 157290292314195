import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
  } from 'reactstrap';

class BreadcrumComponent extends Component{
    constructor(props) {
        super(props);
        
        this.state={
            radioSelected : 1
        }
      
      
    }
    componentDidMount(){
        //console.log('breadcrum mount')
    }
   componentWillReceiveProps(){
    //console.log('breadcrum props')
   }

    render(){
        if(this.props.organizationsReducer.breadcrumb!==null){
            return(
              
                <div>
             
             <Breadcrumb tag="nav" className="custom-crum" >
         
            { this.props.organizationsReducer.breadcrumb.list.map((item, index) =>  <BreadcrumbItem key={index} onClick={()=>this.props.history.push(item.link)} className="custom-breadcrum" tag="h6" >{item.name}</BreadcrumbItem>) }
      
         
         </Breadcrumb>
    
        
               
           
            
              </div>
     
            );
        }
        else{
            return(
              
                <div>
             
             <Breadcrumb tag="nav" className="custom-crum" >
         
           
      
         
         </Breadcrumb>
    
        
               
           
            
              </div>
     
            );
        }
      
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        visit: (data) => { 
            dispatch({
                type : 'ADD_VISIT',
                payload : data
            }
            ) 
        }}
    }


const mapStateToProps = (state) => {
    return {
        organizationsReducer: state.organizationsReducer
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumComponent); 





 