const INITIAL_STATE = {
    device: ""
}

const productionGraphReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_DEVICE":
            return { ...state, device: action.payload };
        default:
            return state
    }
}



export default productionGraphReducer