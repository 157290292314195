import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../../components/loader/loader';
import { getFailedEvent } from '../../shared/services/production';
import uniqid from 'uniqid'
import FailedEventsUI from './failedEventsUI';

class FailedEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: null
        }
    }

    async componentDidMount() {
        if (this.props.location.events) {
            this.setState({ events: this.props.location.events })
        }
        else {
            // let failedCmd = await axios.get('http://192.168.1.106:3004/api/v1/failedCommands');
            // failedCmd = failedCmd.data;

            let failedCmd = await getFailedEvent();
            this.setState({ events: failedCmd })
        }
    }

    render() {
        //console.log(this.state.events)
        return (
            <React.Fragment>
                {(this.state.events) ?
                    <div class="row" style={{ backgroundColor: '#f2f2f2', marginBottom: '20px' }}>
                        <div className='col-12' style={{ fontSize: 24, paddingTop: '12px' }}>
                            Failed Commands
                        </div>
                        <div className='col-12' style={{ marginTop: 20 }}>
                            <div class="row">
                                {this.state.events.map(event => (
                                    <div key={uniqid()} className='col-sx-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 ' id={event.deviceId}>
                                        <FailedEventsUI failedEvents={event} />
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                    :
                    <div className='col-12'>
                        <Loader />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default FailedEvents;