import { getdata } from './getAlerts'
import store from './store';

//Main Host
let host = "https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2";
// let host = "http://175.107.196.247:3000";                                    //VM
// let host = 'localhost:3000';                                                 //Local

let oldhost = "https://linked-things-orgs.eu-gb.mybluemix.net";

//Last Event Host
let evHost = "https://linked-things-events.eu-gb.mybluemix.net";


//Device in App
var homeDevice = {
    interactive: ['Light', 'Fan', 'Ac', 'Lock', 'Curtain'],
    non_interactive: ['Temperature', 'Humidity', 'Door', 'Sound', 'Smoke', 'Fire', 'Motion', 'Vibration', 'Position', 'Monitor']

}

var waterDevice = {
    interactive: [],
    non_interactive: ['WaterTank', 'FuelTank']
}

var powerDevice = {
    interactive: ['Plug'],
    non_interactive: ['PowerUnit', 'PowerUnit2']
}

var monitorDevice = {
    interactive: [],
    non_interactive: ['Monitor']
}

var agriDevice = {
    interactive: [],
    non_interactive: ['PhIndicator', 'Moisture', 'DewPoint', 'Gust', 'Pressure', 'SolarRadiation', 'UV', 'WindSpeed']
}

var enviDevice = {
    interactive: [],
    non_interactive: ["Dust", "Co2", "Tvoc", "Lpg", "Co", "Ch4", "O3", "No2", "So2"],
    types: ["Dust_Status", "Co2_Status", "Tvoc_Status", "Lpg_Status", "Co_Status", "Ch4_Status", "O3_Status", "No2_Status", "So2_Status", 'Smoke_Status', 'Fire_Status']
}

//Status
export function Ac_Status() {
    return ('Ac_Status');
}
export function Ac_Connection() {
    return ('Ac_Connection');
}
export function Ac_Swing() {
    return ("Ac_Swing");
}

export function Ac_Temperature() {
    return ("Ac_Temperature");
}

export function Fan_Status() {
    return ("Fan_Status");
}

export function Light_Status() {
    return ("Light_Status");
}

export function Curtain_Status() {
    return ("Curtain_Status");
}

export function Lock_Status() {
    return ("Lock_Status");
}

export function Motor_Status() {
    return ("WaterTank_Motor");
}

export function Water_Status() {
    return ("WaterTank_Status");
}


export function Plug_Status() {
    return ("Plug_Status");
}
export function Smoke_Status() {
    return ("Smoke_Status");
}
export function Fire_Status() {
    return ("Fire_Status");
}
export function Dust_Status() {
    return ("Dust_Status");
}
export function Co2_Status() {
    return ("Co2_Status");
}
export function Tvoc_Status() {
    return ("Tvoc_Status");
}
export function O3_Status() {
    return ("O3_Status");
} export function No2_Status() {
    return ("No2_Status");
} export function So2_Status() {
    return ("So2_Status");
}
export function Lpg_Status() {
    return ("Lpg_Status");
}
export function Co_Status() {
    return ("Co_Status");
}
export function Ch4_Status() {
    return ("Ch4_Status");
}

//API urls
export function addOrg() {
    return ("/levels");
}

export function addLev() {
    return (host + "/levels");
}

export function addDev() {
    return (host + "/devices");
}
export function addTriggers() {
    return (host + "/triggers/bulk");
}
export function getAC() {
    return (host + "/resources/ac");
}
export function getAllDevices() {
    return (host + "/levels/LinkedThings/devices");
}
export function getOrgList() {
    return ("/users/me");
}

export function signup() {
    return (host + "/users/signup");
}

export function login() {
    return (host + "/users/signin");
}

// export function events() {
//     return (evHost + "/levels");
// }

// export function eventsDev() {
//     return (evHost + "/devices");
// }


export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function mqttConfig() {
    return ({
        "org": "m4qjey",
        "id": guid(),
        "auth-key": "a-m4qjey-hjm41sfhtn",
        "auth-token": "IKeffU&1KEEi2098tq"
    });
}

export function decryptConfig(key = null) {
    if (key) {
        return key
    }
    return store.getState().activeOrgReducer.secretKey
    // return "18bc1675-7bef-4fcb-8487-7b35a3627099"
    //updated
}

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4();
}


export function homeDeviceList() {
    return (homeDevice)
}


export function waterDeviceList() {
    return (waterDevice)
}


export function powerDeviceList() {
    return (powerDevice)
}

export function agriDeviceList() {
    return (agriDevice)
}

export function monitorDeviceList() {
    return (monitorDevice)
}

export function enviDeviceList() {
    return (enviDevice)
}

export function enviMaxValue(type) {
    if (type === "Dust") {
        return "55"
    }
    else if (type === "Co2") {
        return "70.33"
    }
    else if (type === "Tvoc") {
        return "72.72"
    }
    else if (type === "Lpg") {
        return "50"
    }
    else if (type === "Co") {
        return "37.22"
    }
    else if (type === "Ch4") {
        return "50"
    }
}