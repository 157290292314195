import React, { Component } from 'react';

import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Bar, Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import $ from 'jquery';

import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')

class BarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mainChartOpts: {
                fill: false,
                layout: {
                    padding: {
                        top: 10 // half border width
                    }
                },
                responsive: true,

                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            display: false,
                            ticks: {
                                fontSize: 10,
                            },
                            gridLines: {
                                drawOnChartArea: false,
                            },
                        }],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                fontSize: 12,
                                /* stepSize: Math.ceil(250 / 5),*/
                                // stepSize: 50,
                                // max: (50 - (this.props.device.metadata.annotations.max + 30) % 50) + this.props.device.metadata.annotations.max + 30
                            },
                            gridLines: {
                                drawOnChartArea: false,
                            },
                        }],

                },
                elements: {
                    point: {
                        radius: 2,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            },
            mainChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Storage',
                        backgroundColor: hexToRgba(brandInfo, 12),
                        borderColor: brandInfo,

                        borderWidth: 2,
                        data: [],
                    }
                ],
            }
        }
    }

    componentDidMount() {
        //console.log(this.props.storageData)
        let tempLabels = [];
        let tempData = [];

        this.props.storageData.result.map(data => {
            if (this.props.type === 'Hubs') {
                if (data.hub !== 'All') {
                    tempLabels.push(data.hub)
                    tempData.push(data.percentage)
                }
            }
            else if (this.props.type === 'Organizations') {
                if (data.organization !== 'All') {
                    tempLabels.push(data.organization)
                    tempData.push(data.percentage)
                }
            }
        })

        let mainChartObj = this.state.mainChart
        mainChartObj.labels = tempLabels;
        mainChartObj.datasets[0].data = tempData;

        this.setState({
            mainChart: mainChartObj
        })
    }

    render() {
        return (
            <React.Fragment>
                <div class="card rounded-1 dropshadow p-3" style={{ backgroundColor: 'white', borderColor: 'white' }}>
                    <div style={{ fontSize: 24 }}>
                        {this.props.type} Storage
                    </div>
                    <div id="chart">
                        <Bar data={this.state.mainChart} options={this.state.mainChartOpts} height={250} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BarChart;