const INITIAL_STATE = {
    levels:[],
    levels1:[],
    hubs:[],
    devices:[],
    active_organization: null,
    aqiDevices:[]
}

const allData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_LEVEL":
            return { ...state, levels: action.payload };
           
        case "ADD_LEVEL1":
            return { ...state, levels1: action.payload };

        case "ADD_HUBS":
            return { ...state, hubs: action.payload };

        case "ADD_DEVICES":
            return { ...state, devices: action.payload };
        
        case "ADD_ACTIVE_ORG":
            return { ...state, active_organization: action.payload };

        case "ADD_AQI_DEVICES":
            return { ...state, aqiDevices: action.payload };

        default:
            return state
    }
}



export default allData