import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import uniqid from 'uniqid'

import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';

import { getHubLogs } from '../shared/services/production';

import ProductionHubsOrgs from './productionHubsOrgs';
import LoaderGif from '../assets/img/loader/loader.svg';

class productionHubsUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            dropdownOpen: false,
            selectedHub: null
        }
    }

    componentDidMount() {

        let height = $("#" + this.props.hub + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.hub + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.hub + "-card").height();
            height = (height / 2);
            $("#" + this.props.hub + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    toggle = async () => {
        //console.log("Nauman1000 ProductionHubUI Toggle")
        if (!this.state.collapse) {
            $("#" + this.props.hub).attr('class', 'col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12');
        }

        else {
            $("#" + this.props.hub).attr('class', 'col-12 col-sm-12 col-md-6 col-xl-6 col-lg-12');
        }


        this.setState({ collapse: !this.state.collapse });
        let selectedHub = await getHubLogs(this.props.hub);

        this.setState({
            selectedHub
        })
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div class="card rounded-1 dropshadow" style={{ border: 0 }} id={this.props.hub + "-card"}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>{this.props.hub}</div>
                    <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i class="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i class="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            <div class="row">
                                {(this.state.selectedHub) ?
                                    (this.state.selectedHub.map(h => (
                                        <div key={uniqid()} className='col-12 col-sm-12 col-md-6 col-xl-6 col-lg-12 ' id={h.organization}>
                                            <ProductionHubsOrgs hub={h} />
                                        </div>
                                    )))
                                    :
                                    <img src={LoaderGif} className="login-loader" />
                                }
                            </div>
                        </CardBody>
                    </Collapse>
                    : null
                }

            </div>
        );
    }
}

export default productionHubsUI;