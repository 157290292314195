import React, { useState } from 'react'
import AddLevelPopup from './AddLevelPopup.js';
const AddLevel = (props) => {
  const [UsPopup, setUsPopup] = useState(false)
  const hideUsPopup = (check) => {
    setUsPopup(false)
    props.reload()
  }
  return (
    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
      Levels
      <button onClick={() => { setUsPopup(true) }} className="btn btn-light"><i className="fa fa-user-plus"></i> Add Level</button>
      {UsPopup ?
        <AddLevelPopup hideUsPopup={hideUsPopup} org={props.org} orgId={props.orgId} />
        : null}
    </h2>
  );
}




export default AddLevel
