import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as config from '../../../config';
import * as configration from '../../../config/config';
import * as functions from '../../../functions/functions';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Bar, Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import $, { event } from 'jquery';
import LoaderGif from '../../../assets/img/loader/loader.svg';
import ChartConfig from './chartConfig';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';


import 'chartjs-plugin-annotation';
import { getEventsByDevice, getEventsByDeviceAndDate } from '../../../shared/services/events';
// import  'https://code.jquery.com/jquery-3.5.1.slim.js';
// import { CSVLink, CSVDownload } from "react-csv";

import ReactExport from "react-export-excel";

import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let xlsxdata = [];
let selectedStartDate = null;
let selectedEndDate = null;     // for selecting Start and End Date





class LineGraphComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      download: true,
      loadingdownload: false,
      unmount: false,
      width: 0,
      rand: 0.0,
      radioSelectedVW: 1,
      radioSelected: 1,
      run: 0,
      renderVar: 20,
      overflow: 'scroll',
      graphDate: null,
      startDate: null,
      endDate: null,
      isMounted: false,
      shouldRedraw: false,
      eventValue: 0.0,
      limit: 10,
      eventDate: "0/0/0",
      motorDate: "0/0/0",
      isLoader: false,
      viewType: 'Status',
      max_dot_class: 'max-dot-red',
      avg_dot_class: 'avg-dot-green',
      min_dot_class: 'avg-dot-green',
      max: 0.0,
      avg: 0.0,
      min: 0.0,
      chartDetail: {
        id: this.props.device._id + "_graph",
        axisX: "date",
        axisY: "value",
        scrollbar: true,
        series: [
          {
            type: "line",
            name: this.props.device._id,
            color: "#008df2",
            x: "date",
            y: "value",
          },
        ],
      },
      chartData: [],
      rangeLine: null
    }


    this.onRadioBtnClick = this.onRadioBtnClick.bind(this)
    this.getGraph = this.getGraph.bind(this);
    this.todayDate = this.todayDate.bind(this);


  }




  componentDidMount() {
    console.log("wearebest", this.props)
    this.state.isMounted = true
    this.state.startDate = this.todayDate('start');
    this.state.endDate = this.todayDate('end')
    if (this.state.isMounted) {
      this.getGraph("live");
    }
  }
  todayDate(type) {

    if (type === "start") {
      var currentdate = new Date();
      currentdate.setDate(currentdate.getDate());
      var currentYear = currentdate.getFullYear();
      var currentMonth = (currentdate.getMonth() + 1);
      if (currentMonth <= 9) {
        currentMonth = "0" + currentMonth;
      }
      var currentDate = currentdate.getDate();
      if (currentDate <= 9) {
        currentDate = "0" + currentDate;
      }
      var datetime = "" + currentYear + "-"
        + currentMonth + "-"
        + currentDate
        + "T00:00:00";


      return datetime

    }
    else {
      var currentdate = new Date();
      var currentYear = currentdate.getFullYear();
      var currentMonth = (currentdate.getMonth() + 1);
      var currentHours = currentdate.getHours();
      var currentMinutes = currentdate.getMinutes();
      var currentSeconds = currentdate.getMinutes();


      if (currentMonth <= 9) {
        currentMonth = "0" + currentMonth;
      }
      var currentDate = currentdate.getDate();
      if (currentDate <= 9) {
        currentDate = "0" + currentDate;
      }
      if (currentHours <= 9) {
        currentHours = "0" + currentHours;
      }
      if (currentMinutes <= 9) {
        currentMinutes = "0" + currentMinutes;
      }
      if (currentSeconds <= 9) {
        currentSeconds = "0" + currentSeconds;
      }

      var datetime = "" + currentYear + "-"
        + currentMonth + "-"
        + currentDate
        + "T" + currentHours + ":" + currentMinutes + ":" + currentSeconds;

      return datetime

    }

  }
  componentWillMount() {
  }
  componentWillUnmount() {
    this.todayDate("end")
    this.state.isMounted = false

  }
  onRadioBtnClick(value) {
    this.state.overflow = 'scroll'
    this.setState({
      radioSelected: value
    })
    if (value === 1) {
      this.state.limit = 10;
      this.state.renderVar = 20;
      this.getGraph('live');
      //defaults.global.responsive = true;
      this.state.shouldRedraw = true;
      this.forceUpdate();
      setTimeout(() => {
        this.state.shouldRedraw = false;
      }, 1000);

    }
    else {
      this.state.limit = 10;
      this.state.renderVar = 20;
      this.forceUpdate();
      // defaults.global.responsive = false;
      // this.state.shouldRedraw = true;
      this.forceUpdate();
      this.state.startDate = this.todayDate('start');
      this.state.endDate = this.todayDate('end')
      this.getGraph('day')
    }
  }
  UNSAFE_componentWillReceiveProps(props) {
    //   this.liveUpdate()


    /* var obj = {
      value : a.eventReducer.currentWaterEvent.value,
      date  : a.eventReducer.currentWaterEvent.createdon
    } */

    if (props.eventReducer.currentHomeEvent.deviceID === this.props.device._id) {
      if (configration.homeDevices.includes(this.props.device.type)) {
        if (this.props.device.type === 'AC') {
          const eventTypeSplit = props.eventReducer.currentHomeEvent.type.split('_')
          if (eventTypeSplit[eventTypeSplit.length - 1] === 'Temperature') {
            this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
          }
        }
        else {

          const eventTypeSplit = props.eventReducer.currentHomeEvent.type.split('_')
          if (eventTypeSplit[eventTypeSplit.length - 1] === 'Status') {
            this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
          }
        }

      }

      else {


        const eventTypeSplit = props.eventReducer.currentHomeEvent.type.split('_')
        let eventType = ""
        if (eventTypeSplit.length === 2) {
          eventType = eventTypeSplit[eventTypeSplit.length - 1]
        }
        else {
          eventType = eventTypeSplit[eventTypeSplit.length - 2]
        }

        if (configration.lineGraphDevice.includes(eventType) || props.eventReducer.currentHomeEvent.type === "Fuel_Status" || props.eventReducer.currentHomeEvent.type === "Fuel_FuelTank_Status") {

          if (eventTypeSplit.length === 3) {
            if (eventTypeSplit[eventTypeSplit.length - 1] === 'Status') {
              this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
            }
          }
          else if (eventTypeSplit.length === 2) {
            if (eventTypeSplit[eventTypeSplit.length - 1] !== 'Connection') {
              this.liveUpdate(props.eventReducer.currentHomeEvent.value, props.eventReducer.currentHomeEvent.created);
            }
          }


        }

      }


    }




  }




  onKeyPress = (e) => {
    if (e.key === 'Enter') {

      if (this.state.radioSelected === 2) {
        this.getGraph('day')

      }
      else if (this.state.radioSelected === 3) {
        this.getGraph('month')

      }

      else if (this.state.radioSelected === 4) {
        this.getGraph('year')

      }
    }
  }



  graphStartDateHandler = (event) => {
    selectedStartDate = moment(event._d).format('YYYY-MM-DDTHH:mm');

    if (event === '') {
      this.setState({
        // [event.target.name]: null,
        startDate: null,
        download: false
      })
      return;
    }
    this.setState({
      startDate: selectedStartDate,
      download: true
    });
    setTimeout(() => {
      this.getGraph('day')

      if (configration.downloadData.includes(this.props.device.type)) {
        this.downloadFunc();
      }
    }, 200);


  }

  graphEndDateHandler = (event) => {
    selectedEndDate = moment(event._d).format('YYYY-MM-DDTHH:mm');
    if (event === '') {
      this.setState({
        // [event.target.name]: null,
        endDate: null,
        download: false
      })
      return;
    }
    this.setState({
      endDate: selectedEndDate,
      download: true
    });
    setTimeout(() => {
      this.getGraph('day')

      if (configration.downloadData.includes(this.props.device.type)) {
        this.downloadFunc();
      }
    }, 200);


  }

  // liveUpdate(value, date) {
  //   if (this.state.radioSelected === 1) {
  //     var prevMainChart = this.state.mainChart;
  //     var prevData = prevMainChart.datasets[0].data;
  //     var prevLabel = prevMainChart.labels;
  //     if (prevData.length >= 10) {
  //       var newDt = prevData.slice(1, prevData.length);
  //       var newLb = prevLabel.slice(1, prevLabel.length);

  //     }
  //     else {
  //       var newDt = prevData.slice(0, prevData.length);
  //       var newLb = prevLabel.slice(0, prevLabel.length);
  //     }


  //     newDt.push(value);
  //     /* var arr=[];
  //     arr.push(functions.convertToDate(date,'live'));
  //     arr.push(functions.convertToDate(date,'date')); */
  //     var arr = functions.convertToDate(date, 'object')
  //     newLb.push(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

  //     var len = newDt.length;
  //     var total = 0;
  //     var i = 0;
  //     for (i = 0; i < len; i++) {
  //       total = total + newDt[i];
  //     }
  //     var avg = 0;
  //     var prevState = this.state.mainChartOpts;
  //     prevMainChart.datasets[0].data = newDt;
  //     prevMainChart.labels = newLb;

  //     if (this.props.device.metadata) {
  //       if (this.props.device.metadata.minMaxAnn) {
  //         var maxColor = 'red';
  //         var minColor = 'green';

  //         prevState.annotation.annotations = [
  //           {
  //             //  drawTime: "afterDatasetsDraw",
  //             // id: "hline",
  //             events: ['click'],
  //             type: "line",
  //             mode: "horizontal",
  //             scaleID: "y-axis-0",
  //             value: parseInt(this.props.device.metadata.maxAnn),
  //             borderColor: maxColor,
  //             borderWidth: 1.5,
  //             label: {


  //               // Font size of text, inherits from global
  //               fontSize: 9,

  //               // Font style of text, default below
  //               fontStyle: "normal",

  //               // Font color of text, default below
  //               fontColor: "#fff",

  //               // Padding of label to add left/right, default below


  //               // Radius of label rectangle, default below


  //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
  //               position: "left",


  //               // Whether the label is enabled and should be displayed
  //               enabled: false,

  //               // Text to display in label - default is null
  //               content: "Max: " + this.props.device.metadata.maxAnn
  //             },

  //           },
  //           {
  //             drawTime: "afterDatasetsDraw",
  //             // id: "hline",
  //             type: "line",
  //             mode: "horizontal",
  //             scaleID: "y-axis-0",
  //             value: parseInt(this.props.device.metadata.minAnn),
  //             borderColor: minColor,
  //             borderWidth: 1.5,
  //             label: {


  //               // Font size of text, inherits from global
  //               fontSize: 9,

  //               // Font style of text, default below
  //               fontStyle: "normal",

  //               // Font color of text, default below
  //               fontColor: "#fff",

  //               // Padding of label to add left/right, default below


  //               // Radius of label rectangle, default below


  //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
  //               position: "left",


  //               // Whether the label is enabled and should be displayed
  //               enabled: false,

  //               // Text to display in label - default is null
  //               content: "Min: " + this.props.device.metadata.minAnn
  //             },
  //           },
  //         ]
  //       }
  //     }

  //     if (configration.maxGraphLine.includes(this.props.device.type)) {

  //       var maxColor = 'red';
  //       var avgColor = 'green';


  //       if (total !== 0) {
  //         avg = total / len - 1;
  //         avg = Math.round(avg * 100) / 100

  //         /*  //console.log('real Time'+this.props.items._id,total,avg) */
  //         var maxValue = configration.enviMaxValue(this.props.device.type);
  //         if (maxValue < avg) {
  //           maxColor = 'black';
  //           avgColor = 'red';

  //           this.setState({
  //             max_dot_class: 'max-dot-black',
  //             avg_dot_class: "avg-dot-red"
  //           })
  //         }
  //         else {
  //           this.setState({
  //             max_dot_class: 'max-dot-red',
  //             avg_dot_class: "avg-dot-green"
  //           })
  //         }
  //         prevState.annotation.annotations = [
  //           {
  //             //  drawTime: "afterDatasetsDraw",
  //             // id: "hline",
  //             events: ['click'],
  //             type: "line",
  //             mode: "horizontal",
  //             scaleID: "y-axis-0",
  //             value: parseInt(maxValue),
  //             borderColor: maxColor,
  //             borderWidth: 1.5,
  //             label: {


  //               // Font size of text, inherits from global
  //               fontSize: 9,

  //               // Font style of text, default below
  //               fontStyle: "normal",

  //               // Font color of text, default below
  //               fontColor: "#fff",

  //               // Padding of label to add left/right, default below


  //               // Radius of label rectangle, default below


  //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
  //               position: "left",


  //               // Whether the label is enabled and should be displayed
  //               enabled: false,

  //               // Text to display in label - default is null
  //               content: "Max: " + maxValue
  //             },

  //           },
  //           {
  //             drawTime: "afterDatasetsDraw",
  //             // id: "hline",
  //             type: "line",
  //             mode: "horizontal",
  //             scaleID: "y-axis-0",
  //             value: avg,
  //             borderColor: avgColor,
  //             borderWidth: 1.5,
  //             label: {


  //               // Font size of text, inherits from global
  //               fontSize: 9,

  //               // Font style of text, default below
  //               fontStyle: "normal",

  //               // Font color of text, default below
  //               fontColor: "#fff",

  //               // Padding of label to add left/right, default below


  //               // Radius of label rectangle, default below


  //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
  //               position: "left",


  //               // Whether the label is enabled and should be displayed
  //               enabled: false,

  //               // Text to display in label - default is null
  //               content: "Avg: " + avg
  //             },
  //           },
  //         ]

  //       }



  //       else {
  //         var maxValue = configration.enviMaxValue(this.props.device.type);
  //         this.setState({
  //           max_dot_class: 'max-dot-red',
  //           avg_dot_class: "avg-dot-green"
  //         })
  //         prevState.annotation.annotations = [
  //           {
  //             //  drawTime: "afterDatasetsDraw",
  //             // id: "hline",
  //             events: ['click'],
  //             type: "line",
  //             mode: "horizontal",
  //             scaleID: "y-axis-0",
  //             value: parseInt(maxValue),
  //             borderColor: "red",
  //             borderWidth: 1.5,
  //             label: {


  //               // Font size of text, inherits from global
  //               fontSize: 9,

  //               // Font style of text, default below
  //               fontStyle: "normal",

  //               // Font color of text, default below
  //               fontColor: "#fff",

  //               // Padding of label to add left/right, default below


  //               // Radius of label rectangle, default below


  //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
  //               position: "left",


  //               // Whether the label is enabled and should be displayed
  //               enabled: false,

  //               // Text to display in label - default is null
  //               content: "Max: " + maxValue
  //             },

  //           }]
  //       }


  //     }
  //     //prevData.push(value);
  //     /*   prevData.splice(0,1); */

  //     //prevLabel.push(date);
  //     /*    prevLabel.splice(0,1); */


  //     this.setState({
  //       mainChart: prevMainChart,
  //       mainChartOpts: prevState,
  //       eventValue: value,
  //       eventDate: functions.convertToDate(date, 'none'),
  //       max: maxValue,
  //       avg: avg

  //     })

  //   }
  // }
  // getLimit(e) {
  //   this.state.limit = e.target.value

  // }
  // returnStyle() {
  //   if (this.state.radioSelected === 1) {
  //     var obj = {

  //       height: 400,
  //       marginTop: 40
  //     }
  //     return obj

  //   }
  //   else {
  //     var obj = {
  //       overflowX: 'scroll',

  //       marginTop: 40
  //     }
  //     return obj

  //   }
  // }
  getHomeDeviceType = () => {
    if (this.props.device.type === 'AC') {
      return 'Temperature'
    }
    else {
      return 'Status'
    }
  };
  async getGraph(type) {
    let chartData = [];
    let rangeLine = null;
    let max = 0;
    let avg = 0;
    let checkMaxAvg = (configration.maxGraphLine.includes(this.props.device.type))
    this.setState({
      chartData
    })
    if (checkMaxAvg) {
      max = configration.enviMaxValue(this.props.device.type);
    }
    if (type === 'day') {
      console.log(configration)
      let start = this.state.startDate,
        enddt = this.state.endDate,
        startDateInt = functions.convertToInt(start),
        endDateInt = functions.convertToInt(enddt);
      let limit;
      let deviceId;
      let hubtype = this.props.hub
      let type;
      if (this.props.device.type === 'FuelTank') {
        type = 'Fuel_FuelTank_Status';
        limit = 200;
        deviceId = this.props.device._id
      } else if (this.props.device.type === 'WaterTank') {
        type = 'Water_Status';
        limit = 200;
        deviceId = this.props.device._id
      } else if (configration.devicesWithStatus.includes(this.props.device.type)) {
        type = type = this.props.hub.type + '_' + this.props.device.type + '_Status';
        limit = 200;
        deviceId = this.props.device._id
      }
      else {
        if (hubtype) {
          type = this.props.hub.type + '_' + this.props.device.type;
          limit = 200
          deviceId = this.props.device._id;
          if (configration.homeDevices.includes(this.props.device.type) || configration.enviDevices.includes(this.props.device.type)) {
            if (this.props.hub.type !== "Atmosphere") {
              type = this.props.hub.type + '_' + this.props.device.type + '_' + this.getHomeDeviceType();
              limit = 200
              deviceId = this.props.device._id;
            }

          }
        }
      }

      this.setState({
        isLoader: true
      })
      let data = await getEventsByDeviceAndDate(deviceId, type, startDateInt, endDateInt, limit);

      if (!data.error) {

        console.log("DATE", data)
        if (!data.error) {
          if (data.length != 0) {
            let len = data.length
            console.log("date", data)
            var eventValue = data[0].value;
            var eventDate = functions.convertToDate(data[0].created, "none")
            // this.props.onaddvalue(data[0].value, eventDate)
            if (this.state.isMounted) {
              data.map(row => {
                var arr = functions.convertToDate(row.created, 'object')
                chartData.push({
                  date: new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]),
                  value: row.value
                })

                if (checkMaxAvg) {
                  avg = avg + row.value;
                }
              })
              let chartDetail = this.state.chartDetail;
              if (checkMaxAvg) {
                avg = avg / len
                avg = Math.round(avg * 100) / 100
                rangeLine = {
                  color: "#ff0000",
                  value: max,
                  name: "Maximum",
                  color2: "#03a100",
                  value2: avg,
                  name2: "Average"
                }
                chartDetail['max'] = 60
              }
              chartData.reverse();
              console.log("Date2", chartData)
              this.setState({
                chartDetail,
                eventValue: eventValue,
                eventDate: eventDate,
                chartData,
                rangeLine,
                max,
                avg
              })
            }



          }
        }
        this.setState({
          isLoader: false
        })
      }
      else {
        this.setState({
          isLoader: false
        })
      }
    }


    //console.log("graphType", type + " " + this.props.device.type)





    else if (type === 'live') {
      this.setState({
        radioSelected: 1
      })
      let type;
      let limit;
      let deviceId;
      if (this.props.device.type === 'FuelTank') {
        type = 'Fuel_FuelTank_Status';
        limit = 10;
        deviceId = this.props.device._id
      } else if (this.props.device.type === 'WaterTank') {
        type = 'Water_Status';
        limit = 10;
        deviceId = this.props.device._id
      } else if (configration.devicesWithStatus.includes(this.props.device.type)) {
        type = type = this.props.hub.type + '_' + this.props.device.type + '_Status';
        limit = 10;
        deviceId = this.props.device._id
      }
      else {
        console.log("gggg", this.props.hub.type + '_' + this.props.device.type)
        type = this.props.hub.type + '_' + this.props.device.type;
        limit = 10
        deviceId = this.props.device._id;

        if (configration.homeDevices.includes(this.props.device.type) || configration.enviDevices.includes(this.props.device.type)) {
          if (this.props.hub.type !== "Atmosphere") {
            type = this.props.hub.type + '_' + this.props.device.type + '_' + this.getHomeDeviceType();
            limit = 10
            deviceId = this.props.device._id;
          }
        }
      }
      let data = await getEventsByDevice(deviceId, type, limit);
      console.log("rgxdx", data, deviceId, type, limit, this.getHomeDeviceType())
      if (!data.error) {
        if (data.length != 0) {
          let len = data.length
          console.log("live", data)
          var eventValue = data[0].value;
          var eventDate = functions.convertToDate(data[0].created, "none")
          if (this.state.isMounted) {
            data.map(row => {
              var arr = functions.convertToDate(row.created, 'object')
              chartData.push({
                date: new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]),
                value: row.value
              })
              if (checkMaxAvg) {
                avg = avg + row.value;
              }
            })
            let chartDetail = this.state.chartDetail;
            if (checkMaxAvg) {
              avg = avg / len
              avg = Math.round(avg * 100) / 100
              rangeLine = {
                color: "#ff0000",
                value: max,
                name: "Safe Limit",
                color2: "#03a100",
                value2: avg,
                name2: "Average"
              }
              chartDetail['max'] = 60
            }
            chartData.reverse();
            console.log("live2", chartData)
            this.setState({
              chartDetail,
              eventValue: eventValue,
              eventDate: eventDate,
              chartData,
              rangeLine,
              max,
              avg
            })
          }



        }
        else {

        }
      }
      // let data = await getEventsByDevice(deviceId, type, limit);

      // if (!data.error) {
      //   if (data.length != 0) {



      //     var len = data.length;
      //     var i;
      //     var newData = [];
      //     var newLabel = [];
      //     var total = 0;
      //     for (i = 0; i < len; i++) {
      //       newData.push(data[i].value);
      //       total = total + data[i].value;
      //       /* var arr=[];
      //      arr.push(functions.convertToDate(data[i].created,type));
      //      arr.push(functions.convertToDate(data[i].created,'date'));
      //      newLabel.push(arr); */
      //       var arr = functions.convertToDate(data[i].created, 'object')
      //       newLabel.push(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

      //     }
      //     var avg = 0;
      //     var prevState = this.state.mainChartOpts;

      //     if (this.props.device.metadata) {
      //       if (this.props.device.metadata.minMaxAnn) {
      //         var maxColor = 'red';
      //         var minColor = 'green';

      //         prevState.annotation.annotations = [
      //           {
      //             //  drawTime: "afterDatasetsDraw",
      //             // id: "hline",
      //             events: ['click'],
      //             type: "line",
      //             mode: "horizontal",
      //             scaleID: "y-axis-0",
      //             value: parseInt(this.props.device.metadata.maxAnn),
      //             borderColor: maxColor,
      //             borderWidth: 1.5,
      //             label: {


      //               // Font size of text, inherits from global
      //               fontSize: 9,

      //               // Font style of text, default below
      //               fontStyle: "normal",

      //               // Font color of text, default below
      //               fontColor: "#fff",

      //               // Padding of label to add left/right, default below


      //               // Radius of label rectangle, default below


      //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
      //               position: "left",


      //               // Whether the label is enabled and should be displayed
      //               enabled: false,

      //               // Text to display in label - default is null
      //               content: "Max: " + this.props.device.metadata.maxAnn
      //             },

      //           },
      //           {
      //             drawTime: "afterDatasetsDraw",
      //             // id: "hline",
      //             type: "line",
      //             mode: "horizontal",
      //             scaleID: "y-axis-0",
      //             value: parseInt(this.props.device.metadata.minAnn),
      //             borderColor: minColor,
      //             borderWidth: 1.5,
      //             label: {


      //               // Font size of text, inherits from global
      //               fontSize: 9,

      //               // Font style of text, default below
      //               fontStyle: "normal",

      //               // Font color of text, default below
      //               fontColor: "#fff",

      //               // Padding of label to add left/right, default below


      //               // Radius of label rectangle, default below


      //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
      //               position: "left",


      //               // Whether the label is enabled and should be displayed
      //               enabled: false,

      //               // Text to display in label - default is null
      //               content: "Min: " + this.props.device.metadata.minAnn
      //             },
      //           },
      //         ]
      //       }
      //     }

      //     if (configration.maxGraphLine.includes(this.props.device.type)) {

      //       /*   if(parseInt(config.enviMaxValue(this.props.items.type))>=Math.max(...newData)){
      //           prevState.scales.yAxes[0].ticks.max=(parseInt(config.enviMaxValue(this.props.items.type))+10);

      //         } */
      //       var maxColor = 'red';
      //       var avgColor = 'green';
      //       if (total !== 0) {
      //         avg = total / len;
      //         avg = Math.round(avg * 100) / 100


      //         var maxValue = configration.enviMaxValue(this.props.device.type);
      //         if (maxValue < avg) {
      //           maxColor = 'black';
      //           avgColor = 'red';
      //           this.setState({
      //             max_dot_class: 'max-dot-black',
      //             avg_dot_class: "avg-dot-red"
      //           })
      //         }
      //         else {
      //           this.setState({
      //             max_dot_class: 'max-dot-red',
      //             avg_dot_class: "avg-dot-green"
      //           })
      //         }
      //         prevState.annotation.annotations = [
      //           {
      //             //  drawTime: "afterDatasetsDraw",
      //             // id: "hline",
      //             events: ['click'],
      //             type: "line",
      //             mode: "horizontal",
      //             scaleID: "y-axis-0",
      //             value: parseInt(maxValue),
      //             borderColor: maxColor,
      //             borderWidth: 1.5,
      //             label: {


      //               // Font size of text, inherits from global
      //               fontSize: 9,

      //               // Font style of text, default below
      //               fontStyle: "normal",

      //               // Font color of text, default below
      //               fontColor: "#fff",

      //               // Padding of label to add left/right, default below


      //               // Radius of label rectangle, default below


      //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
      //               position: "left",


      //               // Whether the label is enabled and should be displayed
      //               enabled: false,

      //               // Text to display in label - default is null
      //               content: "Max: " + maxValue
      //             },

      //           },
      //           {
      //             drawTime: "afterDatasetsDraw",
      //             // id: "hline",
      //             type: "line",
      //             mode: "horizontal",
      //             scaleID: "y-axis-0",
      //             value: avg,
      //             borderColor: avgColor,
      //             borderWidth: 1.5,
      //             label: {


      //               // Font size of text, inherits from global
      //               fontSize: 9,

      //               // Font style of text, default below
      //               fontStyle: "normal",

      //               // Font color of text, default below
      //               fontColor: "#fff",

      //               // Padding of label to add left/right, default below


      //               // Radius of label rectangle, default below


      //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
      //               position: "left",


      //               // Whether the label is enabled and should be displayed
      //               enabled: false,

      //               // Text to display in label - default is null
      //               content: "Avg: " + avg
      //             },
      //           },
      //         ]

      //       }



      //       else {
      //         var maxValue = configration.enviMaxValue(this.props.device.type);
      //         this.setState({
      //           max_dot_class: 'max-dot-red',
      //           avg_dot_class: "avg-dot-green"
      //         })
      //         prevState.annotation.annotations = [
      //           {
      //             //  drawTime: "afterDatasetsDraw",
      //             // id: "hline",
      //             events: ['click'],
      //             type: "line",
      //             mode: "horizontal",
      //             scaleID: "y-axis-0",
      //             value: parseInt(maxValue),
      //             borderColor: "red",
      //             borderWidth: 1.5,
      //             label: {


      //               // Font size of text, inherits from global
      //               fontSize: 9,

      //               // Font style of text, default below
      //               fontStyle: "normal",

      //               // Font color of text, default below
      //               fontColor: "#fff",

      //               // Padding of label to add left/right, default below


      //               // Radius of label rectangle, default below


      //               // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
      //               position: "left",


      //               // Whether the label is enabled and should be displayed
      //               enabled: false,

      //               // Text to display in label - default is null
      //               content: "Max: " + maxValue
      //             },

      //           }]
      //       }


      //     }

      //     //console.log(this.props.device)

      //     if (configration.setMinMax.includes(this.props.device.type)) {
      //       if (this.props.device.metadata) {
      //         if (this.props.device.metadata.ticks) {
      //           prevState.scales.yAxes[0].ticks.beginAtZero = false;
      //           prevState.scales.yAxes[0].ticks.min = this.props.device.metadata.ticks.min;
      //           prevState.scales.yAxes[0].ticks.max = this.props.device.metadata.ticks.max;
      //         }
      //       }
      //     }

      //     var eventValue = data[0].value;
      //     var eventDate = functions.convertToDate(data[0].created, "none")
      //     newData.reverse();
      //     newLabel.reverse();
      //     var prevData = this.state.mainChart;
      //     prevData.datasets[0].data = newData;
      //     prevData.labels = newLabel;


      //     if (this.state.isMounted) {

      //       this.setState({
      //         mainChart: prevData,
      //         eventValue: eventValue,
      //         eventDate: eventDate,
      //         mainChartOpts: prevState,
      //         max: maxValue,
      //         avg: avg


      //       })
      //     }



      //   }
      // }
      // else {
      //   /*  if(error.responseJSON){
      //    var errorCode = error.responseJSON._response.statusCode;
      //                if(errorCode === 429){
      //                  if(this.state.isMounted){
      //                    this.getGraph('live');
      //                  }

      //                }

      //  } */

      // }

    }
  }


  render() {

    console.log("this.props.device.metadata", this.props.device.metadata)
    return (
      <Card className="rounded-1 dropshadow" style={{ border: 0 }}>
        <CardBody>
          <Row>
            <Col sm="12" md="12" lg="12" xs="12" xl="12">
              {(this.state.radioSelected === 1) ?
                <div>
                  <CardTitle className="mb-0" style={{ fontWeight: 'bold' }}>{functions.getState(this.props.device, this.state.eventValue, this.props.sn)}
                    {(this.props.device.type === 'Dust') ?
                      <sup>3</sup>
                      :
                      null
                    }</CardTitle>
                  <div className="small text-muted">Last updated: {this.state.eventDate}</div>
                </div>
                :
                ((this.props.device.metadata) ?
                  ((this.props.device.metadata.monitor) ?
                    ((this.props.device.metadata.monitor === 'Oil') ?
                      < div >
                        <CardTitle className="mb-0" style={{ fontWeight: 'bold' }}>Oil Tank
                          {(this.state.isLoader) ?

                            <img src={LoaderGif} className="login-loader" />

                            :
                            null

                          }
                        </CardTitle>
                        <div className=" text-muted">{this.state.graphDate}</div>
                      </div>
                      :
                      < div >
                        <CardTitle className="mb-0" style={{ fontWeight: 'bold' }}>{this.props.device.name}
                          {(this.state.isLoader) ?

                            <img src={LoaderGif} className="login-loader" />

                            :
                            null

                          }
                        </CardTitle>
                        <div className=" text-muted">{this.state.graphDate}</div>
                      </div>
                    )
                    :
                    < div >
                      <CardTitle className="mb-0" style={{ fontWeight: 'bold' }}>{this.props.device.name}
                        {(this.state.isLoader) ?

                          <img src={LoaderGif} className="login-loader" />

                          :
                          null

                        }
                      </CardTitle>
                      <div className=" text-muted">{this.state.graphDate}</div>
                    </div>
                  )
                  :
                  < div >
                    <CardTitle className="mb-0" style={{ fontWeight: 'bold' }}>{this.props.device.name}
                      {(this.state.isLoader) ?

                        <img src={LoaderGif} className="login-loader" />

                        :
                        null

                      }
                    </CardTitle>
                    <div className=" text-muted">{this.state.graphDate}</div>
                  </div>
                )
              }



            </Col>
            <Col sm="12" md="12" lg="12" xs="12" xl="12">


              {
                (this.state.radioSelected === 1) ?

                  <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row margin-top-3">


                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>Start Date </label>
                      {/* <Input disabled type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="startDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disabled='true'
                            format='--/--/---- --:--'
                            name="startDate"
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphStartDateHandler.bind(this)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>


                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>End Date </label>
                      {/* <Input disabled type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="endDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            disabled='true'
                            format='--/--/---- --:--'
                            onChange={this.graphEndDateHandler.bind(this)}
                            name="endDate"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <Col sm="12" md="4" lg="12" xs="12" xl="4">
                      <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                        <ButtonGroup className="float-right mr-3" aria-label="First group">
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>

                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>

                    {(configration.maxGraphLine.includes(this.props.device.type)) ?
                      <div style={{ marginLeft: -5, marginTop: 10 }} class="row margin-top-3">
                        <Col sm="12" md="12" lg="12" xs="12" xl="12">
                          <div><span className={this.state.max_dot_class + " mt-2"}></span><h6 className="d-inline pl-2">Safe Limit: </h6>{this.state.max} {functions.getUnit(this.props.device.type)}</div>
                          <div><span className={this.state.avg_dot_class + " mt-1"}></span><h6 className="d-inline pl-2">Average: </h6>{this.state.avg} {functions.getUnit(this.props.device.type)}</div>
                        </Col>
                      </div>
                      :
                      null
                    }

                    {(this.props.device.metadata) ?
                      ((this.props.device.metadata.minMaxAnn) ?
                        <div style={{ marginLeft: -5, marginTop: 10 }} class="row margin-top-3">
                          <Col sm="12" md="12" lg="12" xs="12" xl="12">
                            <div><span className={this.state.max_dot_class + " mt-2"}></span><h6 className="d-inline pl-2">Maximum: </h6>{this.props.device.metadata.maxAnn} {functions.getUnit(this.props.device.type)}</div>
                            <div><span className={this.state.avg_dot_class + " mt-1"}></span><h6 className="d-inline pl-2">Minimum: </h6>{this.props.device.metadata.minAnn} {functions.getUnit(this.props.device.type)}</div>
                          </Col>
                        </div>
                        :
                        null
                      )
                      :
                      null
                    }


                  </div>

                  :
                  null
              }
              {
                (this.state.radioSelected === 2) ?


                  <div style={{ marginBottom: 10, marginLeft: -5, marginTop: 10 }} class="row  margin-top-3">



                    <div title="Start Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  " style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>Start Date </label>
                      {/* <Input type="datetime-local" name="startDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="startDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            name="startDate"
                            disableFuture="true"
                            value={this.state.startDate}
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphStartDateHandler.bind(this)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>


                    <div title="End Date" className="col-12 col-sm-12 col-sx-12 col-md-4 col-lg-12 col-xl-4 width-85  margin-top-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <label style={{ marginBottom: 0 }}>End Date </label>
                      {/* <Input type="datetime-local" name="endDate" placeholder="date placeholder" className="mr-2" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} defaultValue={null} /> */}
                      <div id="endDate">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            name="endDate"
                            disableFuture="true"
                            value={this.state.endDate}
                            // format='DD-MM-YYYY HH:mm'
                            onChange={this.graphEndDateHandler.bind(this)}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                    </div>




                    <Col sm="12" md="2" lg="2" xs="12" xl="2">
                      {(configration.vol_weight.includes(this.props.device.type)) ?
                        ((this.props.device.metadata.monitor === 'Oil') ?
                          <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                            <ButtonGroup className="float-right mr-3" aria-label="First group">
                              <Button color="outline-secondary" onClick={() => this.onRadioBtnClickVW(1)} active={this.state.radioSelectedVW === 1}>%</Button>
                              <Button color="outline-secondary" onClick={() => this.onRadioBtnClickVW(2)} active={this.state.radioSelectedVW === 2}>Ltr</Button>
                              <Button color="outline-secondary" onClick={() => this.onRadioBtnClickVW(3)} active={this.state.radioSelectedVW === 3}>KG</Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                          :
                          null
                        )
                        :
                        null
                      }
                    </Col>

                    <Col sm="12" md="2" lg="2" xs="12" xl="2">
                      <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 21 }}>
                        <ButtonGroup className="float-right mr-3" aria-label="First group">
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                          <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>History</Button>

                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>

                    <Col sm="12" md="12" lg="12" xs="12" xl="12">
                      {(configration.downloadData.includes(this.props.device.type)) ?
                        ((this.state.download) ?
                          ((!this.state.loadingdownload) ?
                            <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block", marginTop: 10 }}>
                              <ButtonGroup className="float-right mr-3" aria-label="First group">
                                <ExcelFile filename={this.props.device.name + " " + functions.convertToDate(this.state.startDate, 'date') + " " + functions.convertToDate(this.state.endDate, 'date')} element={<Button color="primary" active>Download</Button>}>
                                  <ExcelSheet data={xlsxdata} name="History">
                                    <ExcelColumn label={this.props.device.name} />
                                    <ExcelColumn label="Date" value="date" />
                                    <ExcelColumn label="Time" value="time" />
                                    <ExcelColumn label="Value" value="value" />
                                    <ExcelColumn label="Volume" value="volume" />
                                    <ExcelColumn label="Weight" value="weight" />
                                  </ExcelSheet>
                                </ExcelFile>
                              </ButtonGroup>
                            </ButtonToolbar>
                            :
                            <img src={LoaderGif} className="login-loader" />
                          )
                          :
                          null
                        )
                        :
                        null
                      }
                    </Col>

                    {/* {(xlsxdata) ?
                      <ExcelFile>
                        <ExcelSheet data={xlsxdata} name="History">
                          <ExcelColumn label="Created" value="created" />
                          <ExcelColumn label="Value" value="value" />
                        </ExcelSheet>
                      </ExcelFile>
                      :
                      null
                    } */}

                    {/* <div  className="col-12 col-sm-12 col-sx-12 col-md-2 col-lg-2  margin-top-2" style={{paddingLeft:5,paddingRight:5}}>
                <Button color="primary" onClick={()=>this.getGraph('day')} className="query-button">Search </Button> 
                </div> */}
                    {(configration.maxGraphLine.includes(this.props.device.type)) ?
                      <div style={{ marginLeft: -5, marginTop: 10 }} class="row margin-top-3">
                        <Col sm="12" md="12" lg="12" xs="12" xl="12">
                          <div><span className={this.state.max_dot_class + " mt-2"}></span><h6 className="d-inline pl-2">Maximum: </h6>{this.state.max} {functions.getUnit(this.props.device.type)}</div>
                          <div><span className={this.state.avg_dot_class + " mt-1"}></span><h6 className="d-inline pl-2">Average: </h6>{this.state.avg} {functions.getUnit(this.props.device.type)}</div>
                        </Col>
                      </div>
                      :
                      null
                    }

                    {(this.props.device.metadata) ?
                      ((this.props.device.metadata.minMaxAnn) ?
                        <div style={{ marginLeft: -5, marginTop: 10 }} class="row margin-top-3">
                          <Col sm="12" md="12" lg="12" xs="12" xl="12">
                            <div><span className={this.state.max_dot_class + " mt-2"}></span><h6 className="d-inline pl-2">Maximum: </h6>{this.props.device.metadata.maxAnn} {functions.getUnit(this.props.device.type)}</div>
                            <div><span className={this.state.avg_dot_class + " mt-1"}></span><h6 className="d-inline pl-2">Minimum: </h6>{this.props.device.metadata.minAnn} {functions.getUnit(this.props.device.type)}</div>
                          </Col>
                        </div>
                        :
                        null
                      )
                      :
                      null
                    }

                  </div>







                  :
                  null
              }








              {/*  <Row>
             <Col sm="9" md="9" lg="9" xs="12">
             </Col>
             <Col sm="3" md="3" lg="3" xs="12">
            <ButtonToolbar  aria-label="Toolbar with button groups" style={{display:"block"}}>
                <ButtonGroup className="float-right mr-3" aria-label="First group">
                <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Live</Button>
                  <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Search</Button>
                
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
               </Row> */}
            </Col>
          </Row>



          {this.state.chartDetail && this.state.chartData.length ?
            <div style={{ height: 400, marginBottom: -20, overflow: "hidden" }}>
              <ChartConfig
                chart={this.state.chartDetail}
                data={this.state.chartData}
                graphcolor={"#008DF2"}
                rangeLine={this.state.rangeLine ? this.state.rangeLine : null}
              />
            </div>
            : "No Data"}


        </CardBody>
      </Card >
    )
  }

}

const mapStateToProps = (state) => {
  return {
    organizationsReducer: state.organizationsReducer,
    eventReducer: state.eventReducer
  }
}

export default connect(mapStateToProps, null)(LineGraphComponent);








