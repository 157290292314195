import React, { useState, useEffect } from 'react'

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Form, FormGroup, Label, Input, FormText, Collapse, Button, Dropdown, Container } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import LoaderGif from "../../assets/img/loader/loader.svg";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ConfirmPopup from '../../components/popup/confirmPopup'
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
import { dataHandler, toTitles, getDate, getTime } from '../../functions/functions'
import cookies from '../../shared/utilities/cookies';
import { connect } from "react-redux";
import RecentEvent from './recentEvent';
import State from './state';
import Logs from './logs';
import Loader from '../../components/loader/loader';
// import { type } from 'jquery';
function MQTT(props) {
    const [UsPopup, setUsPopup] = useState(false)
    const hideUsPopup = (check) => {
        setUsPopup(false)
        // props.reload()
    }
    const reload = () => {
        if (orgId1) {
            levelId(orgId1)
        }
    }
    const [typeArray, setTypeArray] = useState([])
    const hubType = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response = await axios.get('/hubs/')
        let temp = dataHandler(response.data)
        setTypeArray(temp)
        return;
    }
    useEffect(() => {
        hubType()
    }, [])
    let hubs = [];

    let [array, setArray] = useState([])
    const [getHub, setGetHub] = useState([])
    const [loader, setLoader] = useState(false)
    const [isOpen, setIsOpen] = useState([]);
    const [orgId1, setOrgId1] = useState(null);
    const [events, setEvents] = useState("recentEvents")

    let [organizationsId, setOrganizationId] = useState([])
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });

    // let [organizationsId, setOrganizationId] = useState([])
    const orgId = async () => {
        setLoader(true)
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getRootLevels')
        let temp = dataHandler(response1.data)
        setOrganizationId(temp)

    }
    const [searchHubs, setSearchHubs] = useState('')
    useEffect(() => {
        orgId()
        AllEvents()
    }, [])
    // const handle = (e) => {
    //     if (e) {
    //         if (e.target.value) {
    //             const newdata = { ...data }
    //             if (e.target.name === "Organizations") {
    //                 levelId(e.target.value)
    //                 newdata["SelectedLevel"] = null

    //             }
    //             newdata[e.target.name] = e.target.value
    //             setData(newdata);

    //             return
    //         }
    //     }
    // }
    const toggle = (index) => {


        isOpen.find(open => open === index) ?
            setIsOpen(isOpen.filter(open => open !== index)) :
            setIsOpen(isOpen.concat([index]));
    };

    const getHubObj = (obj) => {
        hubs.push(obj)
        if (!obj.levels) {
            return
        }

        obj.levels.forEach(child => getHubObj(child))

    }
    const getLevelIDs = (obj) => {
        array.push(obj._id)
        if (!obj.levels) {
            return
        }

        obj.levels.forEach(child => getLevelIDs(child))
    }
    const AllEvents = async () => {
        array = []
        var HubsArray = []
        try {

            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            const response = await axios.get('/levels/*/events')
            let temp = dataHandler(response.data)
            let obj = {}
            for (let index = 0; index < temp.devices.length; index++) {
                const element = temp.devices[index];
                let hub = element._id.split('_')[0] + '_' + element._id.split('_')[1];
                obj[hub] = element.events[2] ? element.events[2].value : element.events[1] ? element.events[1].value : 0;
            }
            let hubs = await levelId();
            for (let index = 0; index < hubs.length; index++) {
                let element = hubs[index];
                element.connection = obj[element._id]
            }
            setGetHub(hubs);
            setLoader(false)
        } catch (error) {
            return console.log(error)
        }
        return;
    }
    const levelId = async (_id) => {
        array = []
        try {
            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            const response = await axios.get('/allhubs')
            return dataHandler(response.data)
        } catch (error) {
            return console.log(error)
        }
    }
    let [config, setConfig] = useState({})
    // const [keyArray, setKeyArray] = useState([])

    // const Config = async (hubId) => {
    //     let result = await axios.get('https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v2/hubs/' + hubId + '/config')

    //     setConfig(result.data)

    // }
    // let addedfunction

    useEffect(() => {
        // console.log(props.eventReducer.currentHomeEvent)
        let getHub1 = getHub
        let deviceEvent = props.eventReducer.currentHomeEvent;
        if (typeof deviceEvent.type !== "number") {
            if (deviceEvent.type.endsWith("Connection")) {
                // console.log("level__1", props.eventReducer.currentHomeEvent)
                getHub1.map((hub, index) => {
                    // console.log("level__2", props.eventReducer.currentHomeEvent, hub, deviceEvent.hubId)
                    if (hub._id === deviceEvent.hubId) {
                        // console.log("level__1", getHub1[index], deviceEvent)
                        deviceEvent._id = deviceEvent.hubId
                        getHub1[index].connection = deviceEvent.value
                        // console.log("level__2", getHub1[index])
                    }
                })
            }
        }
        setGetHub(getHub1)
    }, [props.eventReducer.currentHomeEvent])
    let filterHubs = []
    if (getHub.length !== 0) {

        filterHubs = getHub.filter(item => {
            if (item.name) {
                return searchHubs !== "" ? item._id.toLowerCase().includes(searchHubs.toLowerCase()) || item.levelId.toLowerCase().includes(searchHubs.toLowerCase()) || item.type.toLowerCase().includes(searchHubs.toLowerCase()) || item.name.toLowerCase().includes(searchHubs.toLowerCase()) : item;
            }
        })
    }
    const eventSet = (e) => {
        setEvents(e)
    }
    let [put, setPut] = useState(null)

    let [rowData, setRowData] = useState(null)
    let [configKeys, setConfigKeys] = useState([])
    if ("operations" in props.userInfo.varification) {

        return (
            <>

                <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                    MQTT
                </h2>
                <TextField
                    className="m-0"
                    id="filled-full-width"
                    label="Hubs"
                    style={{ margin: 8 }}
                    placeholder="Search Hubs"
                    fullWidth
                    margin="normal"
                    onChange={(e) => { setSearchHubs(e.target.value); setIsOpen([]) }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="filled"
                />



                <div
                    className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                >
                    {/* <div className="Index"></div> */}
                    <div className="row w-100 p-2 m-0 align-self-center">
                        <div className="col-lg-1 col-md-6 col-12 align-self-center"></div>
                        <div className="col-lg-3 col-md-6 col-12 align-self-center">
                            <b>ID</b>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12 align-self-center">
                            <b>Name</b>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12 align-self-center">
                            <b>Created Date</b>
                        </div>
                        <div className="col-lg-1 col-md-6 col-12 align-self-center">
                            <b>Type</b>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 align-self-center">
                            <b>LevelId</b>
                        </div>
                        {/* <div className="col-lg-2 col-md-6 col-12 align-self-center">
                                        <b>Devices</b>
                                    </div> */}

                    </div>
                </div>




                <div
                    className="row m-0 rounded-brl-1 "
                    style={{ maxHeight: "500px", minHeight: "350px", overflowY: "auto" }}
                >
                    {loader ? <Loader /> :


                        filterHubs.length ? filterHubs.map((hub, key) => {
                            return (
                                <React.Fragment>

                                    <div className="row w-100 p-2 m-0 listView" key={key} >
                                        <div className="col-lg-1 col-md-6 col-12 Date align-self-center">
                                            <div className='align-self-center' style={{ width: 10, height: 10, borderRadius: 50, marginLeft: 10, backgroundColor: hub.connection === 1 ? "green" : "gray" }} title={hub.connection === 1 ? "Connected" : "Disconnected"}></div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12 Date align-self-center" onClick={toggle.bind(this, key + 1)}>
                                            <b className="d-lg-none d-inline">Id: </b>
                                            <br className="d-inline d-lg-none d-md-none" />
                                            {hub._id}
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-6 Date align-self-center" onClick={toggle.bind(this, key + 1)}>
                                            <b className="d-lg-none d-inline">Name: </b>
                                            <br className="d-inline d-lg-none d-md-none" />
                                            {hub.name}
                                        </div>
                                        {/* <StyledTableCell >{getTime(log.created) + " " + getDate(log.created)}</StyledTableCell> */}
                                        <div className="col-lg-2 col-md-6 col-6 Date align-self-center" onClick={toggle.bind(this, key + 1)}>
                                            <b className="d-lg-none d-inline">Created Date: </b>
                                            <br className="d-inline d-lg-none d-md-none" />
                                            {getTime(hub.created) + " " + getDate(hub.created)}
                                        </div>
                                        <div className="col-lg-1 col-md-6 col-6 Date align-self-center" onClick={toggle.bind(this, key + 1)}>
                                            <b className="d-lg-none d-inline">Type: </b>
                                            <br className="d-inline d-lg-none d-md-none" />
                                            <Button >   {hub.type}</Button>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6 Date align-self-center text-truncate" onClick={toggle.bind(this, key + 1)}>
                                            <b className="d-lg-none d-inline">LevelId: </b>
                                            <br className="d-inline d-lg-none d-md-none " />
                                            <Button >   {hub.levelId}</Button>
                                        </div>
                                        {/* <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                        <b className="d-lg-none d-inline">Device: </b>
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button className=" btn btn-dark border-0 gradient-primary3  m-0" onClick={toggle.bind(this, key + 1)} style={{ marginBottom: '1rem' }}>Devices <i className="fa fa-angle-down"></i>  </Button>
                                                    </div> */}

                                        {/* <div className="col-lg-1 col-md-6 col-6 Date  align-self-center ">
                                                        <br className="d-inline d-lg-none d-md-none " />
                                                        <Button className="m-0 " onClick={toggle.bind(this, key + 1)} style={{ marginBottom: '1rem' }}><i className="fa fa-angle-down"></i>  </Button>
                                                    </div> */}
                                    </div>


                                    <div style={{ width: "100%" }}>
                                        <Collapse isOpen={isOpen.find(openedIndex => openedIndex === (key + 1))}>
                                            {isOpen.find(openedIndex => openedIndex === (key + 1)) ?
                                                <div className='py-3 px-5 mb-3'>
                                                    <div className='row w-100 p-2 mx-0 my-3'>
                                                        <button onClick={(e) => eventSet("recentEvents")} className={events === "recentEvents" ? "btn btn-primary col-lg-4 col-md-6 col-12 align-self-center" : "btn btn-light col-lg-4 col-md-6 col-12 align-self-center"}>
                                                            <b>Recent Events</b>
                                                        </button>
                                                        <button onClick={(e) => eventSet("state")} className={events === "state" ? "btn btn-primary col-lg-4 col-md-6 col-12 align-self-center" : "btn btn-light col-lg-4 col-md-6 col-12 align-self-center"}>
                                                            <b>State</b>
                                                        </button>
                                                        <button onClick={(e) => eventSet("logs")} className={events === "logs" ? "btn btn-primary col-lg-4 col-md-6 col-12 align-self-center" : "btn btn-light col-lg-4 col-md-6 col-12 align-self-center"}>
                                                            <b>Logs</b>
                                                        </button>
                                                    </div>
                                                    <div >
                                                        {events === "recentEvents" ? <RecentEvent hubId={hub._id} /> : null}
                                                        {events === "logs" ? <Logs hubId={hub._id} type={hub.type} /> : null}
                                                        {events === "state" ? <State hubId={hub._id} /> : null}
                                                    </div>

                                                    {/* {hub.devices.length > 0 ?
                                                                hub.devices.map((Hub, key2) =>

                                                                    <div key={key2} className="row card-body d-inline-block">
                                                                        < Paper >
                                                                            <TableRow  >
                                                                                <TableCell>

                                                                                    <div className="col-12">&nbsp; &nbsp; {Hub}</div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </Paper>
                                                                    </div>
                                                                ) : "N /A"
                                                            } */}

                                                </div> : null}
                                        </Collapse>
                                    </div>
                                </React.Fragment>





                            )
                        }) : null
                    }

                </div>



            </>

        )

    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        eventReducer: state.eventReducer
    };
};

export default connect(mapStateToProps, null)(MQTT);

