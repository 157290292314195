
const userInfo = (state = null, action) => {

    switch (action.type) {
        case "Verification":
            return { ...state, varification: action.payload };
        default:
            return state
    }
}
export default userInfo