import React, { Component } from 'react';

import $ from 'jquery';

import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';

import LogsGraph from '../../components/graphs/operations/logs';

import * as functions from '../../functions/functions'

class LogsGraphs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            collapse: false,
            dropdownOpen: false,
            logs: null
        }
    }

    componentDidMount() {

        let height = $("#" + this.props.log._id + '-card').height();
        height = (height / 2);
        // //console.log('Heightttttttttttttttttttttt:' + height)
        $("#" + this.props.log._id + "-arrow").css("padding-top", "" + height + "px");


        $(window).resize(() => {
            let height = $("#" + this.props.log._id + "-card").height();
            height = (height / 2);
            //console.log('Heightttttttttttttttttttttt:' + height)
            $("#" + this.props.log._id + "-arrow").css("padding-top", "" + height + "px");
        })
    }

    toggle = async () => {

        this.setState({ collapse: !this.state.collapse });

        //console.log('click')
    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div class="card" style={{ borderColor: '#31A2AC' }} id={this.props.log._id + "-card"}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: '#20536c', fontWeight: 'bold' }}>
                        <small style={{ padding: '2px 0px' }}> <b>_id: </b>{this.props.log._id}</small>
                        <small style={{ display: 'block', padding: '2px 0px' }}><b>deviceId: </b>{this.props.log.deviceId}</small>
                        <small style={{ display: 'block', padding: '2px 0px' }}><b>message: </b>{this.props.log.message}</small>
                        <small style={{ display: 'block', padding: '2px 0px' }}><b>created: </b>{functions.getTime(this.props.log.created) + ' ' + functions.getDate(this.props.log.created)}</small>
                    </div>
                    <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i class="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i class="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {(this.state.collapse) ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            <LogsGraph deviceId={this.props.log.deviceId} logType={this.props.logType} />
                        </CardBody>
                    </Collapse>
                    : null
                }

            </div>
        );
    }
}

export default LogsGraphs;