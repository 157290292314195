import React from 'react';

import Loadable from 'react-loadable'

import Production from './operations/index'
import Notfound from './Pages/Notfound/Notfound'
import FailedEvents from './operations/failedEvents/failedEvents';
import Logs from './operations/logs/logs';
import DevicesStatus from './operations/devicesStatus/devicesStatus';
import Users from './Pages/Users/users'

import Levels from './Pages/Levels/levels'
import Hubs from './Pages/Hubs/hubs'
import Wingles from './Pages/Wingles/Wingles';
import Devices from './Pages/Device/Devices';
import DevicePublisher from './Pages/devicePublisher/DevicePublisher';
import Jobs from './Pages/Jobs/Jobs'

function Loading() {
  return <div>Loading...</div>;
}

const LoaderImage = Loadable({
  loader: () => import('./components/loader/loader'),
  loading: Loading,
});

// const NwAlert = Loadable({
//   loader: () => import('./components/alerts/nwAlert'),
//   loading: Loading,
// });
// const Breadcrumbs = Loadable({
//   loader: () => import('./views/Base/Breadcrumbs'),
//   loading: Loading,
// });

// const Cards = Loadable({
//   loader: () => import('./views/Base/Cards'),
//   loading: Loading,
// });

// const Carousels = Loadable({
//   loader: () => import('./views/Base/Carousels'),
//   loading: Loading,
// });

// const Collapses = Loadable({
//   loader: () => import('./views/Base/Collapses'),
//   loading: Loading,
// });

// const Dropdowns = Loadable({
//   loader: () => import('./views/Base/Dropdowns'),
//   loading: Loading,
// });

// const Forms = Loadable({
//   loader: () => import('./views/Base/Forms'),
//   loading: Loading,
// });

// const Jumbotrons = Loadable({
//   loader: () => import('./views/Base/Jumbotrons'),
//   loading: Loading,
// });

// const ListGroups = Loadable({
//   loader: () => import('./views/Base/ListGroups'),
//   loading: Loading,
// });

// const Navbars = Loadable({
//   loader: () => import('./views/Base/Navbars'),
//   loading: Loading,
// });

// const Navs = Loadable({
//   loader: () => import('./views/Base/Navs'),
//   loading: Loading,
// });

// const Paginations = Loadable({
//   loader: () => import('./views/Base/Paginations'),
//   loading: Loading,
// });

// const Popovers = Loadable({
//   loader: () => import('./views/Base/Popovers'),
//   loading: Loading,
// });

// const ProgressBar = Loadable({
//   loader: () => import('./views/Base/ProgressBar'),
//   loading: Loading,
// });

// const Switches = Loadable({
//   loader: () => import('./views/Base/Switches'),
//   loading: Loading,
// });

// const Tables = Loadable({
//   loader: () => import('./views/Base/Tables'),
//   loading: Loading,
// });

// const Tabs = Loadable({
//   loader: () => import('./views/Base/Tabs'),
//   loading: Loading,
// });

// const Tooltips = Loadable({
//   loader: () => import('./views/Base/Tooltips'),
//   loading: Loading,
// });

// const BrandButtons = Loadable({
//   loader: () => import('./views/Buttons/BrandButtons'),
//   loading: Loading,
// });

// const ButtonDropdowns = Loadable({
//   loader: () => import('./views/Buttons/ButtonDropdowns'),
//   loading: Loading,
// });

// const ButtonGroups = Loadable({
//   loader: () => import('./views/Buttons/ButtonGroups'),
//   loading: Loading,
// });

// const Buttons = Loadable({
//   loader: () => import('./views/Buttons/Buttons'),
//   loading: Loading,
// });

// const Charts = Loadable({
//   loader: () => import('./views/Charts'),
//   loading: Loading,
// });



// const CoreUIIcons = Loadable({
//   loader: () => import('./views/Icons/CoreUIIcons'),
//   loading: Loading,
// });

// const Flags = Loadable({
//   loader: () => import('./views/Icons/Flags'),
//   loading: Loading,
// });

// const FontAwesome = Loadable({
//   loader: () => import('./views/Icons/FontAwesome'),
//   loading: Loading,
// });

// const SimpleLineIcons = Loadable({
//   loader: () => import('./views/Icons/SimpleLineIcons'),
//   loading: Loading,
// });

// const Alerts = Loadable({
//   loader: () => import('./views/Notifications/Alerts'),
//   loading: Loading,
// });

// const Badges = Loadable({
//   loader: () => import('./views/Notifications/Badges'),
//   loading: Loading,
// });

// const Modals = Loadable({
//   loader: () => import('./views/Notifications/Modals'),
//   loading: Loading,
// });

// const Colors = Loadable({
//   loader: () => import('./views/Theme/Colors'),
//   loading: Loading,
// });

// const Typography = Loadable({
//   loader: () => import('./views/Theme/Typography'),
//   loading: Loading,
// });

// const Widgets = Loadable({
//   loader: () => import('./views/Widgets/Widgets'),
//   loading: Loading,
// });

// const User = Loadable({
//   loader: () => import('./views/Users/User'),
//   loading: Loading,
// });

// const Settings = Loadable({
//   loader: () => import('./Container/settings/settingsContainer'),
//   loading: Loading,
// });

export function setRoute(route) {
  routes = route
  console.log(routes)
}

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Operations', component: Production },
  { path: '/jobs', exact: true, name: "Jobs", component: Jobs },
  { path: '/users', exact: true, name: "Users", component: Users },
  { path: "/levels", exact: true, name: "Levels", component: Levels },
  { path: "/hubs", exact: true, name: "Hubs", component: Hubs },
  { path: '/Wingles', exact: true, name: "Wingles", component: Wingles },
  { path: '/devices', exact: true, name: "Devices", component: Devices },
  { path: '/operation/failedevents', exact: true, name: "FailedEvents", component: FailedEvents },
  { path: '/operation/devices_status', exact: true, name: 'Dashboard', component: DevicesStatus },
  { path: '/MQTT', exact: true, name: "MQTT", component: DevicePublisher },
  { path: '/operation/logs/:logType', exact: true, name: 'Dashboard', component: Logs },
  { path: "*", exact: true, name: "Notfound", component: Notfound },
]

export default routes;
