
import React, { useEffect, useState } from 'react'
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import OTTO from '../../assets/icon/otto.svg'
import { getdata1 } from '../../getAlerts'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown } from 'reactstrap';
import store from '../../store';



const Header = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const toggle = () => {
        setDropdownOpen(prevState => !prevState)

    };
    const toggle1 = () => {
        setModal(!modal);

    };
    const setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    const Logout = () => {
        console.log("im in")
        setCookie("operationToken", null, 0);
        setCookie("operationuser", null, 0);
        // store.dispatch({
        //     type: 'DISCONNECT'
        // })
        setRedirect({
            redirect: false
        })

        window.location.reload();
    }
    let client = null
    useEffect(() => {
        client = getdata1();
    }, [])
    useEffect(() => {
        client.on("deviceEvent", (body) => {
            let { eventType, ...payload } = body;
            var eventData = payload;
            var deviceID = eventData.deviceId;
            var type = eventData.type;
            var levelId = eventData.levelId;
            var data = {
                hubId: eventData.hubId,
                eventId: eventData._id,
                deviceID: deviceID,
                levelId: levelId,
                M1: eventData.M1,
                M2: eventData.M2,
                value: eventData.value,
                type: type,
                created: eventData.created,
                realCreated: eventData.realCreated,
                voltage: eventData.voltage,
                current: eventData.current,
                power: eventData.power,
                frequency: eventData.frequency,
                pf: eventData.pf,
                load: eventData.load,
                energy: eventData.energy,
                totalEnergy: eventData.totalEnergy,
                lat: eventData.lat,
                lng: eventData.lng,
                h: eventData.h,
                hf: eventData.hf,
                lf: eventData.lf,
                eventType: eventType,
                t1: eventData.t1,
                t2: eventData.t2,
                t3: eventData.t3,
                v: eventData.v,
                w: eventData.w,
                sum: eventData.sum,
            }
            store.dispatch({
                type: "ADD_EVENT",
                payload: data,
                eventType: "HomeEvents",
            });

        });
    }, [client])
    useEffect(() => {
        client.on("logs", (Log) => {
            store.dispatch({
                type: "ADD_LOGS",
                payload: Log,
                eventType: "currentLogs",
            });

        });
    }, [client])
    useEffect(() => {
        client.on("state", (State) => {
            store.dispatch({
                type: "ADD_STATE",
                payload: State,
                eventType: "currentState",
            });

        });
    }, [client])
    const getCookie = (key) => {
        let name = key + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let decodedCookieParts = decodedCookie.split(';');


        for (let i = 0; i < decodedCookieParts.length; i++) {
            let c = decodedCookieParts[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    return (
        <React.Fragment>

            <a className="text-decoration-none" style={{ textDecoration: 'none !important' }} href="#/">
                <h6 style={{ color: 'black', margin: '3px 0 0 60px' }}>
                    <img src={OTTO} width="30" height="30" alt="Linked-things Logo" />&nbsp; &nbsp;
                    <span style={{ marginTop: '12px', display: 'inline-block' }}><span style={{ fontSize: 14 }}></span>Ottomatically</span>
                </h6>
            </a>
            <Nav className="ml-auto" style={{ marginTop: 5 }}>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle nav>
                        <i className="far fa-user-circle font-xl d-block text-dark"></i>
                    </DropdownToggle>
                    <DropdownMenu right style={{ right: "auto" }} >
                        <DropdownItem ><i className="far fa-user text-dark"></i>{" "}
                            {getCookie('operationuser')}
                        </DropdownItem>
                        {/* <DropdownItem onClick={(props) =>
                                history.push({ pathname: "/change_password" })
                            }>
                                <i className="fa fa-lock text-dark"></i> Change Password
                        </DropdownItem> */}
                        <DropdownItem onClick={() => toggle1()}>
                            <i className="fa fa-power-off text-dark"></i> Sign Out</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Nav>
            <Modal isOpen={modal} toggle={() => toggle1()} className={"rounded-1 dropshadow"} style={{ border: 0 }}>
                <ModalHeader toggle={() => toggle1()} className="gradient-primary3 dropshadow text-white" style={{ border: 0, color: 'white' }}>Warning</ModalHeader>
                <ModalBody className="py-4" style={{ border: 0 }}>
                    Are you sure to Logout?
                </ModalBody>
                <ModalFooter className="p-1" style={{ border: 0 }}>
                    <Button className="gradient-primary3 text-white" onClick={() => Logout()} >Logout</Button>{' '}
                    <Button className="btn-light" onClick={() => toggle1()} >Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}




export default Header
