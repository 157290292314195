import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Redirect, Switch } from 'react-router-dom';
import { signIn, checkSignIn, signOut, getSignedInUser } from '../../shared/services/users'
import Login from '../../Pages/Login/login'

class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loginData: {},
            modal: false,
            redirect: false,
            loginLoader: false,
            operationAccess: "loader"
        };

        this.toggle = this.toggle.bind(this);
    }

    // useEffect(async () => {
    //     let response = await getSignedInUser()
    //     console.log(response);
    //     if ("operations" in response) {
    //         setOperationAcess(true)
    //     }
    //     else {
    //         setOperationAcess(false)
    //     }
    // }, [])
    async componentDidMount() {

    }

    componentWillMount() {
        console.log(this.props);

        if (checkSignIn(true)) {
            this.setState({
                redirect: true
            });
            console.log(this.props);
        }
        else {
            console.log("not login");
        }

    }
    Logout = () => {
        // console.log("im in")
        this.setCookie("operationToken", null, 0);
        this.setCookie("operationuser", null, 0);
        // store.dispatch({
        //     type: 'DISCONNECT'
        // })


        window.location.reload();
    }
    setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    toggle() {
        if (!this.state.operationAccess && this.state.modal) {
            this.Logout()
        }
        else {
            this.setState({
                modal: !this.state.modal
            });
        }
    }

    login = async (username, password) => {

        this.setState({
            loginLoader: true
        })

        console.log("Before LOGIN", username, password)

        let response = await signIn({
            _id: username,
            password: password
        });
        if (response.error) {
            this.setState({
                loginLoader: false
            })
            this.toggle();
        } else {
            this.setState({
                loginData: response,
                loginLoader: false

            });
            let response1 = await getSignedInUser()
            if ("operations" in response1) {
                if (response1.operations !== "") {
                    this.setState({ operationAccess: true })
                    window.location.reload()
                }
                else {
                    this.setState({ operationAccess: false })
                    this.toggle()
                }
            }
            else {
                this.setState({ operationAccess: false })
                this.toggle()


            }

        }



    }

    render() {
        if (this.state.redirect === true) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div style={{ height: '100%' }}>
                    <Login history={this.props.history} login={this.login} isLoader={this.state.loginLoader} />
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>Error in login</ModalHeader>
                        {!this.state.operationAccess ? <ModalBody>
                            This Account has no Access
                        </ModalBody> :
                            <ModalBody>
                                Invalid Username / Password
                            </ModalBody>}
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            );
        }

    }

}


export default LoginContainer;
