const INITIAL_STATE = {
    organization: [],
    visit: [],
    totalOrganization: null,
    organizationTree: [],
    routes: [],
    breadcrumb: null,
    status: true,
}

const organizationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_ROUTE":
            return { ...state, routes: action.payload };

        case "SET_BREADCRUMB":
            return { ...state, breadcrumb: action.payload };

        case "ADD_ORGANIZATION":

            var newOrg = state.organization;
            newOrg.push(action.payload);
            return { ...state, organization: newOrg };


        case "ADD_ORGANIZATION_ARRAY":

            var newOrg = state.organizationTree;
            newOrg.push(action.payload);
            return { ...state, organizationTree: newOrg };

        case "ADD_VISIT":
            var newVisit = state.visit;
            newVisit.push(action.payload);
            return { ...state, visit: newVisit };


        case "ORGANIZATION_LIST":

            return { ...state, totalOrganization: action.payload };

        case "CLEAR_ALL":
            return { ...state, totalOrganization: null, visit: [], organization: [] };

        case 'DISCONNECT':
            return { ...state, status: false };

        case "CLEAR_VISIT":
            return { ...state, visit: [] };


        case "CLEAR_ORGANIZATIONS":
            return { ...state, organization: [] };

        case 'UPDATE_METADATA':
            var arr = state.visit;
            arr[arr.length - 1].metadata = action.payload;
            arr[arr.length - 1].name = action.name;
            return { ...state, visit: arr }

        case "REMOVE_VISIT":
            var newVisit = state.visit;
            newVisit.length = (action.payload + 1);
            return { ...state, visit: newVisit }

        case "UPDATE_ORGANIZATION":
            var oldOrganizations = state.organization;
            oldOrganizations[action.index] = action.payload
            return { ...state, organization: oldOrganizations }


        case "REPLACE_HISTORY":
            return { ...state, visit: action.payload }

        default:
            return state
    }
}



export default organizationsReducer