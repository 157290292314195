import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import * as functions from '../functions/functions'

import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';
import LoaderGif from '../assets/img/loader/loader.svg';

class connectedHubs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            dropdownOpen: false
        }
    }

    componentDidMount() {

    }

    toggle = async () => {
        this.setState({ collapse: !this.state.collapse });

    }
    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div className="card" style={{ borderColor: 'red' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className=" small general-value-div" style={{ margin: '8px', fontSize: 16, color: 'red', fontWeight: 'bold' }}>{this.props.title} ({this.props.data.length})</div>
                    <div className=" small general-value-div" style={{ color: 'red', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                        {(!this.state.collapse) ?
                            <i className="icon-arrow-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                            <i className="icon-arrow-down float-right rotate-arrow" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                        }
                    </div>
                </div>
                {this.state.collapse ?
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            {this.props.data.map(d => (
                                <div className="card" style={{ borderColor: '#31A2AC', padding: '5px 10px', color: '#20536c', marginBottom: '1rem' }}>
                                    <small style={{ display: 'block' }}><b>_id: </b>{d.deviceId}</small>
                                    <small style={{ display: 'block' }}><b>Name: </b>{d.name}</small>
                                    <small style={{ display: 'block' }}><b>LevelId: </b>{d.levelId}</small>
                                    <small style={{ display: 'block' }}><b>Type: </b>{d.type}</small>
                                    <small style={{ display: 'block' }}><b>Last Updated: </b>{functions.convertToDate(d.created)}</small>
                                </div>
                            ))}
                        </CardBody>
                    </Collapse>
                    : null
                }
            </div>
        );
    }
}

export default connectedHubs;