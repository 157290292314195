import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';


import organizationsReducer from './reducers/organizationsReducer'
import eventReducer from './reducers/eventReducer'
import logReducer from './reducers/logReducer'
import stateReducer from './reducers/stateReducer'
import sidebarReducer from './reducers/sidebarReducer'
import productionGraphReducer from './reducers/productionGraphReducer'
import activeOrgReducer from './reducers/activeOrganizationReducer'
import allOrgData from './reducers/allOrganizationData'
import userInfo from './reducers/userInfo'

let composeEnhancers = compose
// let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

export default createStore(
    combineReducers({
        organizationsReducer,
        eventReducer,
        logReducer,
        stateReducer,
        sidebarReducer,
        productionGraphReducer,
        activeOrgReducer,
        allOrgData,
        userInfo
    }
    ),
    composeEnhancers(applyMiddleware(thunk))
)