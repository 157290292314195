import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import AddLevel from './addLevel'
import TextField from '@material-ui/core/TextField';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import LoaderGif from "../../assets/img/loader/loader.svg";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Form, FormGroup, Label, Input, FormText, Collapse, Button, Dropdown } from 'reactstrap';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ConfirmPopup from '../../components/popup/confirmPopup'
import axios from '../../shared/utilities/axios'
// import axios from '../../shared/utilities/axios'
import { dataHandler } from '../../functions/functions';
import moment from 'moment'
import { EditText, EditTextarea } from 'react-edit-text';
import { StaticRouter } from 'react-router-dom';
import { Alert } from 'bootstrap';
import { getDate, getTime, toTitles } from '../../functions/functions'
import cookies from '../../shared/utilities/cookies';



function Levels(props) {
    const reload = () => {
        org();
        if (orgId1) {
            levelId(orgId1)
        }
        setLoader(false)
    }
    const [loader, setLoader] = useState(false)
    const [toggleConfirm, setToggleConfirm] = useState(false)
    const [levelsId, setLevelsId] = useState(null)
    let [levelOrginaization, setLevelOrginaization] = useState([])
    const org = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getdistinct')
        let temp = dataHandler(response1.data)
        setLevelOrginaization(temp)
        return;

    }
    // org()
    useEffect(() => {
        org()
    }, [])

    let [organizationsId, setOrganizationId] = useState([])
    const orgId = async () => {
        setLoader(true)
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const response1 = await axios.get('/getRootLevels')
        let temp = dataHandler(response1.data)
        setOrganizationId(temp)
        return setLoader(false)

    }

    useEffect(() => {
        orgId()
        leveltype()
    }, [])
    let levelArray = [];
    let levelObj = [];
    let [levelList, setLevelList] = useState([])
    let [typeArray, setTypeArray] = useState([])
    let [getLevel, setGetLevel] = useState([])
    const [isOpen, setIsOpen] = useState([]);
    const [orgId1, setOrgId1] = useState(null);

    const leveltype = async () => {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        const responseleveltype = await axios.get('/getdistinct')
        let temp = dataHandler(responseleveltype.data)
        setTypeArray(temp)
    }
    const levelId = async (_id) => {
        levelArray = []
        levelObj = []

        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                const response = await axios.get('/levels/' + _id + '/hierarchy')
                let temp = dataHandler(response.data)
                if (!temp.statusCode) {

                    getLevelIDs(temp)
                    getLevelObj(temp)
                    setLevelList(levelArray)
                    setGetLevel(levelObj);
                }
            } catch (error) {
                return console.log(error)
            }
        }

        return;

    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option,
    });
    let put = null;
    const handle = (e) => {
        if (e) {
            if (e.target.value) {
                const newdata = { ...data }
                if (e.target.name === "Organizations") {
                    levelId(e.target.value)
                    newdata["SelectedLevel"] = null
                }
                newdata[e.target.name] = e.target.value
                setData(newdata);
                return
            }
        }
    }
    let [obj, setObj] = useState()

    const handleJson = async (e, name, id) => {
        // console.log(e, name, id);
        let obj1 = await e.jsObject
        setObj(obj1)
        if (e.jsObject) {
            put = { id: id, body: { [name]: e.jsObject } }
        }
        else { put = { id: id, body: { [name]: null } } }
        return;
    }
    const handle2 = (e) => {
        if (e) {
            console.log(e)
            if (e.target.value) {
                if (put.body[e.target.name] === "name" || "type") {
                    put.body[e.target.name] = e.target.value
                }
                // else {
                //     put = { id: e.target.id, body: { [e.target.name]: e.target.value } }
                // }
            }

        }
        return;
    }

    const [data, setData] = useState({})

    const deleteLevels = async (_id) => {
        setToggleConfirm(true)
        setLevelsId(_id);
        return;
    }
    const update = async () => {
        setLoader(true)

        if (put) {
            let token = cookies.getCookie('operationToken');
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            await axios.put("/levels/" + put.id, put.body)
                .then((res) => {
                    let temp = dataHandler(res.data)
                    alert("updated")
                    reload()
                    setUspopup(null)
                    return temp;
                    // props.reload()


                }).catch(e => {
                    setUspopup(null)
                    alert(e.response.data.message)
                    return
                })
        }


    }


    const confirmDelete = async (_id) => {
        setLoader(true)
        if (_id) {
            try {
                let token = cookies.getCookie('operationToken');
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.delete("/levels/" + levelsId)
                    .then((res) => {
                        setLoader(false)
                        alert("successful Delete", levelsId)
                        setToggleConfirm(false)
                        setLevelsId(null)
                    })
                // let temp = dataHandler(result.data)


            } catch (error) {
                alert(error)
                setLoader(false)
                setToggleConfirm(false)
                setLevelsId(null)
            }
        }
        setToggleConfirm(false)
        return



    }

    const getLevelIDs = (obj) => {
        levelArray.push(obj._id)
        if (!obj.levels) {
            return;
        }

        obj.levels.forEach(child => getLevelIDs(child))

    }
    const getLevelObj = (obj) => {
        levelObj.push(obj)
        if (!obj.levels) {
            return
        }

        obj.levels.forEach(child => getLevelObj(child))

    }

    let [uspopup, setUspopup] = useState(null)
    const Popup = (data, id, name) => {
        let tempdata;
        if (name === "name" || name === "type") {
            tempdata = data
        }
        else {
            tempdata = data
        }
        put = { id: id, body: { [name]: data } };
        setUspopup(
            <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', zIndex: '1019' }}>
                <div onClick={() => setUspopup(null)} style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                </div>
                <div className="rounded-1" style={{
                    background: 'rgb(255, 255, 255)', width: "80%", boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', padding: '10px', zIndex: 1, position: "absolute",
                    top: "calc(50% + 25px)",
                    left: "calc(50% + 25px)",
                    transform: "translate(-50%, -50%)"
                }}>
                    {"operations" in props.userInfo.varification && props.userInfo.varification.operations === "ADMIN" ?
                        <div className="card" style={{ border: '0px' }}>
                            <a className="close align-self-end rounded-circle" onClick={() => setUspopup(null)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                                &times;
                            </a>
                            {/* <Form> */}
                            {"type" in put.body ?


                                <div>
                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                    <p>{id}</p>
                                    <Autocomplete
                                        options={typeArray}
                                        getOptionLabel={(option) => option}


                                        defaultValue={tempdata} id={id}
                                        className="w-100"
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={"Change HubType"}
                                                onSelect={(e) => handle2(e)}
                                                variant="filled"
                                                name={name}
                                            // name={name}
                                            />
                                        }

                                    />
                                </div>

                                :

                                <div>
                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                    <p>{id}</p>
                                    {"name" in put.body ? <Input defaultValue={tempdata} name={name} id={id} className="col-12 overflow-auto" onChange={(e) => handle2(e)} style={{ maxHeight: "65vh" }}></Input>
                                        :
                                        <JSONInput colors={{
                                            default: "black",
                                            background: "white",
                                            string: "red"

                                        }} theme="light_mitsuketa_tribute" width="100%" placeholder={tempdata} locale={locale} onChange={(e) => handleJson(e, name, id)} height='65vh' />
                                    }
                                </div>
                            }



                            <div className="row m-0 px-3 justify-content-end">
                                {loader === false ?
                                    <Button color="success" onClick={() => update()}
                                        style={{ display: 'block', marginTop: '10px', borderRadius: '50px', float: 'right' }}>
                                        Update</Button>
                                    : <img
                                        src={LoaderGif}
                                        className="w-100 m-1"
                                        style={{ height: "34.8px" }}
                                    />}
                            </div>
                            {/* </Form> */}

                        </div>

                        :
                        <div className="card" style={{ border: '0px' }}>
                            <a className="close align-self-end rounded-circle" onClick={() => setUspopup(null)} style={{ textAlign: 'center', color: 'red', lineHeight: '18px', width: '22px', paddingBottom: '4px', cursor: "pointer" }}>
                                &times;
                            </a>
                            {/* <Form> */}
                            {"type" in put.body ?


                                <div>
                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                    <p>{id}</p>
                                    <Autocomplete

                                        options={typeArray}
                                        getOptionLabel={(option) => option}


                                        defaultValue={tempdata} id={id}
                                        className="w-100"
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={"Change HubType"}
                                                onSelect={(e) => handle2(e)}
                                                variant="filled"
                                                name={name}
                                            // name={name}
                                            />
                                        }

                                    />
                                </div>

                                :

                                <div>
                                    <h3 className="text-primary ">{toTitles(name)}</h3>
                                    <p>{id}</p>
                                    {"name" in put.body ? <Input defaultValue={tempdata} name={name} id={id} className="col-12 overflow-auto" onChange={(e) => handle2(e)} style={{ maxHeight: "65vh" }}></Input>
                                        :
                                        <JSONInput colors={{
                                            default: "black",
                                            background: "white",
                                            string: "red"

                                        }} theme="light_mitsuketa_tribute" viewOnly={true} width="100%" placeholder={tempdata} locale={locale} onChange={(e) => handleJson(e, name, id)} height='65vh' />
                                    }
                                </div>
                            }
                            {/* </Form> */}

                        </div>}
                </div>
            </div >

        );
    }
    if ("operations" in props.userInfo.varification) {
        if (props.userInfo.varification.operations === "ADMIN") {
            return (
                <div>
                    {levelOrginaization ?
                        <AddLevel reload={reload} org={levelOrginaization} orgId={organizationsId} />
                        : null}
                    {toggleConfirm ?
                        <ConfirmPopup id={levelsId} returnConfirm={confirmDelete} />
                        : null}
                    <FormGroup>
                        <div className="row">
                            <div className="col-md-10 col-12">
                                <Autocomplete

                                    options={organizationsId}
                                    getOptionLabel={(option) => option}

                                    className="w-100"
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            onSelect={(e) => setOrgId1(e.target.value)}
                                            label={"Organizations"}
                                            variant="filled"
                                            value={data.Organizations}

                                            name={"Organizations"} />
                                    }

                                />
                            </div>
                            <div className="col-md-2 d-flex mt-1">
                                <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary w-100" onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i> Search</Button>
                            </div>
                        </div>

                    </FormGroup>
                    {/* {console.log("Second Check", levelList)} */}
                    {levelList.length > 1 ?
                        <Autocomplete

                            options={levelList}
                            getOptionLabel={(list) => list}

                            className="w-100"
                            renderInput={(params) =>
                                <TextField {...params}
                                    onSelect={event => handle(event)}
                                    label={"All Level List of Selected"}
                                    variant="filled"
                                    value={data.SelectedLevel}

                                    name={"SelectedLevel"} />
                            }

                        />
                        : null}
                    <Table>
                        {/* <TableHead>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Level Type</TableCell>
                    <TableCell>Config</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Metadata</TableCell>
                    <TableCell>Remove</TableCell> */}
                        <div
                            className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                        >
                            {/* <div className="Index"></div> */}
                            <div className="row w-100 p-2 m-0">
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>ID</b>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>Name</b>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>Created</b>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>Level Type</b>
                                </div>
                                <div className="col-lg-1 col-md-2 col-12 align-self-center">
                                    <b>Config</b>
                                </div>
                                <div className="col-lg-1 col-md-2 col-12 align-self-center">
                                    <b>Tags</b>
                                </div>
                                <div className="col-lg-1 col-md-2 col-12 align-self-center">
                                    <b>Metadata</b>
                                </div>
                                <div className="col-lg-1 col-md-2 col-12 align-self-center">
                                    <b>Delete</b>
                                </div>

                            </div>
                        </div>




                        {/* {console.log("Get data", getLevel, data.Organizations, data.SelectedLevel)} */}
                        <div
                            className="row m-0 rounded-brl-1 "
                            style={{ maxHeight: "350px", minHeight: "350", overflowY: "auto" }}
                        >
                            {(getLevel.length !== 0) ?
                                getLevel.map((level, key) =>
                                    data.SelectedLevel ?
                                        level._id === data.SelectedLevel ?
                                            <div className="row w-100 p-2 m-0 listView" key={key}>
                                                <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Id: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    {level._id}
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Name: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.name, level._id, 'name')} className=" btn btn-light border-0 primary1  m-0" >
                                                        {level.name}
                                                    </Button>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Created Date: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    {getTime(level.created) + " " + getDate(level.created)}
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Level Type: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.type, level._id, 'type')} className=" btn btn-light border-0 primary1  m-0" >
                                                        {level.type}
                                                    </Button>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Config: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.config, level._id, 'config')} className=" btn btn-light border-0 primary1  m-0" >
                                                        Config
                                                    </Button>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Tags: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.tags, level._id, 'tags')} className=" btn btn-light border-0 primary1  m-0" >
                                                        Tags
                                                    </Button>
                                                </div>




                                                <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Metadata: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.metadata, level._id, 'metadata')} className=" btn btn-light border-0 primary1  m-0" >
                                                        Metadata
                                                    </Button>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Delete: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button className="btn btn-danger rounded-circle-pxl" onClick={() => deleteLevels(level._id)} ><i class="fas fa-minus-circle"></i></Button>
                                                </div>

                                            </div>

                                            : null
                                        :
                                        level._id.split("_")[0] === data.Organizations ?
                                            <TableRow key={key}>
                                                <TableCell >{level._id}</TableCell>
                                                <TableCell >{level.created}</TableCell>
                                                <TableCell>{level.type}</TableCell>
                                                <TableCell >

                                                    <Button onClick={() => Popup(level.config, level._id, 'config')} className=" btn btn-light border-0 primary1  m-0">Config</Button>
                                                </TableCell>
                                                <TableCell>

                                                    <Button onClick={() => Popup(level.tags, level._id, 'tags')} className=" btn btn-light border-0 primary1  m-0">Tags</Button>
                                                </TableCell>




                                                <TableCell>

                                                    <Button onClick={() => Popup(level.metadata, level._id, 'metadata')} className=" btn btn-light border-0 primary1  m-0">MetaData</Button>
                                                </TableCell>
                                                <TableCell>
                                                    {/* {loader === false ? */}
                                                    <Button className="btn btn-danger  rounded-circle-pxl" onClick={() => deleteLevels(level._id)}><i class="fas fa-minus-circle"></i></Button>
                                                    {/* : <img
                                                    src={LoaderGif}
                                                    className="w-100 m-1"
                                                    style={{ height: "34.8px" }}
                                                />} */}
                                                </TableCell>
                                            </TableRow>

                                            : null

                                )
                                : null}
                        </div>
                    </Table>
                    {uspopup}
                </div >
            )
        } else {
            return (
                <div>
                    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
                        Levels
                    </h2>
                    {toggleConfirm ?
                        <ConfirmPopup returnConfirm={confirmDelete} />
                        : null}
                    <FormGroup>
                        <div className="row">
                            <div className="col-md-10 col-12">
                                <Autocomplete

                                    options={organizationsId}
                                    getOptionLabel={(option) => option}

                                    className="w-100"
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            onSelect={(e) => setOrgId1(e.target.value)}
                                            label={"Organizations"}
                                            variant="filled"
                                            value={data.Organizations}

                                            name={"Organizations"} />
                                    }

                                />
                            </div>
                            <div className="col-md-2 d-flex mt-1">
                                <Button className="btn gradient-primary3 text-light rounded-circle-pxl btn-secondary w-100" onClick={() => levelId(orgId1)}><i className="fa fa-search mr-1"></i> Search</Button>
                            </div>
                        </div>

                    </FormGroup>
                    {/* {console.log("Second Check", levelList)} */}
                    {levelList.length > 1 ?
                        <Autocomplete

                            options={levelList}
                            getOptionLabel={(list) => list}

                            className="w-100"
                            renderInput={(params) =>
                                <TextField {...params}
                                    onSelect={event => handle(event)}
                                    label={"All Level List of Selected"}
                                    variant="filled"
                                    value={data.SelectedLevel}

                                    name={"SelectedLevel"} />
                            }

                        />
                        : null}
                    <Table>
                        {/* <TableHead>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Level Type</TableCell>
                    <TableCell>Config</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Metadata</TableCell>
                    <TableCell>Remove</TableCell> */}
                        <div
                            className="col-12 d-lg-flex d-none p-0 listView dropshadow-1"
                        >
                            {/* <div className="Index"></div> */}
                            <div className="row w-100 p-2 m-0">
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>ID</b>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>Name</b>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>Created</b>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 align-self-center">
                                    <b>Level Type</b>
                                </div>
                                <div className="col-lg-1 col-md-2 col-12 align-self-center">
                                    <b>Config</b>
                                </div>
                                <div className="col-lg-1 col-md-2 col-12 align-self-center">
                                    <b>Tags</b>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12 align-self-center">
                                    <b>Metadata</b>
                                </div>
                            </div>
                        </div>




                        {/* {console.log("Get data", getLevel, data.Organizations, data.SelectedLevel)} */}
                        <div
                            className="row m-0 rounded-brl-1 "
                            style={{ maxHeight: "350px", minHeight: "350", overflowY: "auto" }}
                        >
                            {(getLevel.length !== 0) ?
                                getLevel.map((level, key) =>
                                    data.SelectedLevel ?
                                        level._id === data.SelectedLevel ?
                                            <div className="row w-100 p-2 m-0 listView" key={key}>
                                                <div className="col-lg-2 col-md-6 col-12 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Id: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    {level._id}
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Name: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button disabled className=" btn btn-light border-0 primary1  m-0" >
                                                        {level.name}
                                                    </Button>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Created Date: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    {getTime(level.created) + " " + getDate(level.created)}
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Level Type: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button disabled className=" btn btn-light border-0 primary1  m-0" >
                                                        {level.type}
                                                    </Button>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Config: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.config, level._id, 'config')} className=" btn btn-light border-0 primary1  m-0" >
                                                        Config
                                                    </Button>
                                                </div>
                                                <div className="col-lg-1 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Tags: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.tags, level._id, 'tags')} className=" btn btn-light border-0 primary1  m-0" >
                                                        Tags
                                                    </Button>
                                                </div>




                                                <div className="col-lg-2 col-md-6 col-6 Date align-self-center">
                                                    <b className="d-lg-none d-inline">Metadata: </b>
                                                    <br className="d-inline d-lg-none d-md-none" />
                                                    <Button onClick={() => Popup(level.metadata, level._id, 'metadata')} className=" btn btn-light border-0 primary1  m-0" >
                                                        Metadata
                                                    </Button>
                                                </div>
                                            </div>

                                            : null
                                        :
                                        level._id.split("_")[0] === data.Organizations ?
                                            <TableRow key={key}>
                                                <TableCell >{level._id}</TableCell>
                                                <TableCell >{level.created}</TableCell>
                                                <TableCell>{level.type}</TableCell>
                                                <TableCell >

                                                    <Button onClick={() => Popup(level.config, level._id, 'config')} className=" btn btn-light border-0 primary1  m-0">Config</Button>
                                                </TableCell>
                                                <TableCell>

                                                    <Button onClick={() => Popup(level.tags, level._id, 'tags')} className=" btn btn-light border-0 primary1  m-0">Tags</Button>
                                                </TableCell>




                                                <TableCell>

                                                    <Button onClick={() => Popup(level.metadata, level._id, 'metadata')} className=" btn btn-light border-0 primary1  m-0">MetaData</Button>
                                                </TableCell>
                                                <TableCell>
                                                    {/* {loader === false ? */}
                                                    <Button className="btn btn-danger  rounded-circle-pxl" disabled><i class="fas fa-minus-circle"></i></Button>
                                                    {/* : <img
                                                    src={LoaderGif}
                                                    className="w-100 m-1"
                                                    style={{ height: "34.8px" }}
                                                />} */}
                                                </TableCell>
                                            </TableRow>

                                            : null

                                )
                                : null}
                        </div>
                    </Table>
                    {uspopup}
                </div >
            )
        }
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps, null)(Levels);

