import store from '../../store'
import { getSignedInUser } from '../services/users';


const userInfoAction = async () => {

    let response = await getSignedInUser()
    store.dispatch({
        type: "Verification",
        payload: response
    });
};
export default userInfoAction;


