import React, { useState } from 'react'
// import Popup from '../../../../React-Material-UI-Dialog-Modal-Popup/src/components/Popup.js';
// import { Children } from 'react';
import AddUserPopup from './AddUserPopup.js';
// import { Popup } from './Popup.js';

const AddUser = (props) => {
  const [UsPopup, setUsPopup] = useState(false)
  const hideUsPopup = (check) => {
    setUsPopup(false)
    props.reload()
  }
  return (
    <h2 className="gradient-primary3 p-3 mt-3 dropshadow rounded-1 text-white d-flex justify-content-between">
      Users
      <button onClick={() => { setUsPopup(true) }} className="btn btn-light"><i className="fa fa-user-plus"></i> Add User</button>
      {UsPopup ?
        <AddUserPopup hideUsPopup={hideUsPopup} createdBy />
        : null}
    </h2>
  );
}




export default AddUser
