import axios from '../utilities/axios';
import cookies from '../utilities/cookies';
import { decrypt } from './decryption'

export let getAllUsers = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/users');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getAllDevices = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/devices');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}
export let getAllDevicesCount = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/countDevices');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}
export let getAllSensors = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/sensors');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getAllHubs = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/hubs');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getHubLogs = async (hubType) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/hubs/type/' + hubType);
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getHubDevices = async (hubType, hubId) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/hubs/type/' + hubType + '/hubs/' + hubId);
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getLogs = async (hubType) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/devices/logs/' + hubType);
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getLogsGraph = async (deviceId, type) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/devices/' + deviceId + '/logs?type=' + type);
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getLogsGraphByDate = async (deviceId, type, startDate, endDate, limit) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/devices/' + deviceId + '/logs?type=' + type + '&startDate=' + startDate + '&endDate=' + endDate + '&limit=' + limit);
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getFailedEvent = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/events/failedcmds');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getHubsStorage = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/hubs/storage');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getOrganizationsStorage = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/organizations/storage');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let getConnectedDevices = async () => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/hubs/devices/connection');
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}

export let postCommands = async (data) => {
    try {
        let token = cookies.getCookie('operationToken');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.post('/events/failedcmds', data);
        if (typeof response.data == 'string') {
            return await decrypt(response.data);
        }
        else {
            return response.data;
        }
    } catch (err) {
        return err;
    }
}